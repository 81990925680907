/*!
* Tabler v1.0.0-beta17 (https://tabler.io)
* @version 1.0.0-beta17
* @link https://tabler.io
* Copyright 2018-2023 The Tabler Authors
* Copyright 2018-2023 codecalm.net Paweł Kuna
* Licensed under MIT (https://github.com/tabler/tabler/blob/master/LICENSE)
*/
@charset "UTF-8";
:root,
[data-bs-theme="light"] {
   --tblr-blue: #18a0fb;
   --tblr-indigo: #4263eb;
   --tblr-purple: #ae3ec9;
   --tblr-pink: #d6336c;
   --tblr-red: #d63939;
   --tblr-orange: #f76707;
   --tblr-yellow: #f59f00;
   --tblr-green: #2fb344;
   --tblr-teal: #0ca678;
   --tblr-cyan: #17a2b8;
   --tblr-black: #000000;
   --tblr-white: #ffffff;
   --tblr-gray: #49566c;
   --tblr-gray-dark: #1d273b;
   --tblr-gray-100: #f1f5f9;
   --tblr-gray-200: #e2e8f0;
   --tblr-gray-300: #c8d3e1;
   --tblr-gray-400: #9ba9be;
   --tblr-gray-500: #6c7a91;
   --tblr-gray-600: #49566c;
   --tblr-gray-700: #313c52;
   --tblr-gray-800: #1d273b;
   --tblr-gray-900: #0f172a;
   --tblr-primary: #18a0fb;
   --tblr-secondary: #616876;
   --tblr-success: #2fb344;
   --tblr-info: #4299e1;
   --tblr-warning: #f76707;
   --tblr-danger: #d63939;
   --tblr-light: #f8fafc;
   --tblr-dark: #1d273b;
   --tblr-muted: #616876;
   --tblr-blue: #206bc4;
   --tblr-azure: #4299e1;
   --tblr-indigo: #4263eb;
   --tblr-purple: #ae3ec9;
   --tblr-pink: #d6336c;
   --tblr-red: #d63939;
   --tblr-orange: #f76707;
   --tblr-yellow: #f59f00;
   --tblr-lime: #74b816;
   --tblr-green: #2fb344;
   --tblr-teal: #0ca678;
   --tblr-cyan: #17a2b8;
   --tblr-facebook: #1877f2;
   --tblr-twitter: #1da1f2;
   --tblr-linkedin: #0a66c2;
   --tblr-google: #dc4e41;
   --tblr-youtube: #ff0000;
   --tblr-vimeo: #1ab7ea;
   --tblr-dribbble: #ea4c89;
   --tblr-github: #181717;
   --tblr-instagram: #e4405f;
   --tblr-pinterest: #bd081c;
   --tblr-vk: #6383a8;
   --tblr-rss: #ffa500;
   --tblr-flickr: #0063dc;
   --tblr-bitbucket: #0052cc;
   --tblr-tabler: #206bc4;
   --tblr-primary-rgb: 32, 107, 196;
   --tblr-secondary-rgb: 97, 104, 118;
   --tblr-success-rgb: 47, 179, 68;
   --tblr-info-rgb: 66, 153, 225;
   --tblr-warning-rgb: 247, 103, 7;
   --tblr-danger-rgb: 214, 57, 57;
   --tblr-light-rgb: 248, 250, 252;
   --tblr-dark-rgb: 29, 39, 59;
   --tblr-muted-rgb: 97, 104, 118;
   --tblr-blue-rgb: 32, 107, 196;
   --tblr-azure-rgb: 66, 153, 225;
   --tblr-indigo-rgb: 66, 99, 235;
   --tblr-purple-rgb: 174, 62, 201;
   --tblr-pink-rgb: 214, 51, 108;
   --tblr-red-rgb: 214, 57, 57;
   --tblr-orange-rgb: 247, 103, 7;
   --tblr-yellow-rgb: 245, 159, 0;
   --tblr-lime-rgb: 116, 184, 22;
   --tblr-green-rgb: 47, 179, 68;
   --tblr-teal-rgb: 12, 166, 120;
   --tblr-cyan-rgb: 23, 162, 184;
   --tblr-facebook-rgb: 24, 119, 242;
   --tblr-twitter-rgb: 29, 161, 242;
   --tblr-linkedin-rgb: 10, 102, 194;
   --tblr-google-rgb: 220, 78, 65;
   --tblr-youtube-rgb: 255, 0, 0;
   --tblr-vimeo-rgb: 26, 183, 234;
   --tblr-dribbble-rgb: 234, 76, 137;
   --tblr-github-rgb: 24, 23, 23;
   --tblr-instagram-rgb: 228, 64, 95;
   --tblr-pinterest-rgb: 189, 8, 28;
   --tblr-vk-rgb: 99, 131, 168;
   --tblr-rss-rgb: 255, 165, 0;
   --tblr-flickr-rgb: 0, 99, 220;
   --tblr-bitbucket-rgb: 0, 82, 204;
   --tblr-tabler-rgb: 32, 107, 196;
   --tblr-primary-text: #1a569d;
   --tblr-secondary-text: #49566c;
   --tblr-success-text: #268f36;
   --tblr-info-text: #0e616e;
   --tblr-warning-text: #935f00;
   --tblr-danger-text: #ab2e2e;
   --tblr-light-text: #49566c;
   --tblr-dark-text: #313c52;
   --tblr-primary-bg-subtle: #d2e1f3;
   --tblr-secondary-bg-subtle: #f1f5f9;
   --tblr-success-bg-subtle: #d5f0da;
   --tblr-info-bg-subtle: #d1ecf1;
   --tblr-warning-bg-subtle: #fdeccc;
   --tblr-danger-bg-subtle: #f7d7d7;
   --tblr-light-bg-subtle: #f8fafc;
   --tblr-dark-bg-subtle: #9ba9be;
   --tblr-primary-border-subtle: #a6c4e7;
   --tblr-secondary-border-subtle: #e2e8f0;
   --tblr-success-border-subtle: #ace1b4;
   --tblr-info-border-subtle: #a2dae3;
   --tblr-warning-border-subtle: #fbd999;
   --tblr-danger-border-subtle: #efb0b0;
   --tblr-light-border-subtle: #e2e8f0;
   --tblr-dark-border-subtle: #6c7a91;
   --tblr-white-rgb: 255, 255, 255;
   --tblr-black-rgb: 0, 0, 0;
   --tblr-body-color-rgb: 29, 39, 59;
   --tblr-body-bg-rgb: 241, 245, 249;
   --tblr-font-sans-serif: -apple-system, BlinkMacSystemFont, San Francisco,
      Segoe UI, Roboto, Helvetica Neue, sans-serif;
   --tblr-font-monospace: Menlo, Monaco, Consolas, Liberation Mono, Courier New,
      monospace;
   --tblr-gradient: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0)
   );
   --tblr-body-font-family: var(--tblr-font-sans-serif);
   --tblr-body-font-size: 0.875rem;
   --tblr-body-font-weight: 400;
   --tblr-body-line-height: 1.4285714286;
   --tblr-body-color: #1d273b;
   --tblr-emphasis-color: #000000;
   --tblr-emphasis-color-rgb: 0, 0, 0;
   --tblr-secondary-color: rgba(29, 39, 59, 0.75);
   --tblr-secondary-color-rgb: 29, 39, 59;
   --tblr-secondary-bg: #e2e8f0;
   --tblr-secondary-bg-rgb: 226, 232, 240;
   --tblr-tertiary-color: rgba(29, 39, 59, 0.5);
   --tblr-tertiary-color-rgb: 29, 39, 59;
   --tblr-tertiary-bg: #f1f5f9;
   --tblr-tertiary-bg-rgb: 241, 245, 249;
   --tblr-body-bg: #f1f5f9;
   --tblr-body-bg-rgb: 241, 245, 249;
   --tblr-link-color: #206bc4;
   --tblr-link-color-rgb: 32, 107, 196;
   --tblr-link-decoration: none;
   --tblr-link-hover-color: #1a569d;
   --tblr-link-hover-color-rgb: 26, 86, 157;
   --tblr-link-hover-decoration: underline;
   --tblr-code-color: var(--tblr-gray-600);
   --tblr-highlight-bg: #fdeccc;
   --tblr-border-width: 1px;
   --tblr-border-style: solid;
   --tblr-border-color: #e6e7e9;
   --tblr-border-color-translucent: rgba(97, 104, 118, 0.16);
   --tblr-border-radius: 4px;
   --tblr-border-radius-sm: 2px;
   --tblr-border-radius-lg: 8px;
   --tblr-border-radius-xl: 1rem;
   --tblr-border-radius-2xl: 2rem;
   --tblr-border-radius-pill: 100rem;
   --tblr-box-shadow: 0 0.5rem 1rem rgba(var(--tblr-body-color-rgb), 0.15);
   --tblr-box-shadow-sm: 0 0.125rem 0.25rem
      rgba(var(--tblr-body-color-rgb), 0.075);
   --tblr-box-shadow-lg: 0 1rem 3rem rgba(var(--tblr-body-color-rgb), 0.175);
   --tblr-box-shadow-inset: inset 0 1px 2px
      rgba(var(--tblr-body-color-rgb), 0.075);
   --tblr-emphasis-color: #000000;
   --tblr-form-control-bg: var(--tblr-body-bg);
   --tblr-form-control-disabled-bg: var(--tblr-secondary-bg);
   --tblr-highlight-bg: #fdeccc;
   --tblr-breakpoint-xs: 0;
   --tblr-breakpoint-sm: 576px;
   --tblr-breakpoint-md: 768px;
   --tblr-breakpoint-lg: 992px;
   --tblr-breakpoint-xl: 1200px;
   --tblr-breakpoint-xxl: 1400px;
}

[data-bs-theme="dark"] {
   --tblr-body-color: #6c7a91;
   --tblr-body-color-rgb: 108, 122, 145;
   --tblr-body-bg: #0f172a;
   --tblr-body-bg-rgb: 15, 23, 42;
   --tblr-emphasis-color: #f1f5f9;
   --tblr-emphasis-color-rgb: 241, 245, 249;
   --tblr-secondary-color: rgba(108, 122, 145, 0.75);
   --tblr-secondary-color-rgb: 108, 122, 145;
   --tblr-secondary-bg: #1d273b;
   --tblr-secondary-bg-rgb: 29, 39, 59;
   --tblr-tertiary-color: rgba(108, 122, 145, 0.5);
   --tblr-tertiary-color-rgb: 108, 122, 145;
   --tblr-tertiary-bg: #161f33;
   --tblr-tertiary-bg-rgb: 22, 31, 51;
   --tblr-emphasis-color: #ffffff;
   --tblr-primary-text: #79a6dc;
   --tblr-secondary-text: #c8d3e1;
   --tblr-success-text: #82d18f;
   --tblr-info-text: #74c7d4;
   --tblr-warning-text: #f9c566;
   --tblr-danger-text: #e68888;
   --tblr-light-text: #f1f5f9;
   --tblr-dark-text: #c8d3e1;
   --tblr-primary-bg-subtle: #061527;
   --tblr-secondary-bg-subtle: #0f172a;
   --tblr-success-bg-subtle: #09240e;
   --tblr-info-bg-subtle: #052025;
   --tblr-warning-bg-subtle: #312000;
   --tblr-danger-bg-subtle: #2b0b0b;
   --tblr-light-bg-subtle: #1d273b;
   --tblr-dark-bg-subtle: #0f141e;
   --tblr-primary-border-subtle: #134076;
   --tblr-secondary-border-subtle: #313c52;
   --tblr-success-border-subtle: #1c6b29;
   --tblr-info-border-subtle: #09414a;
   --tblr-warning-border-subtle: #624000;
   --tblr-danger-border-subtle: #802222;
   --tblr-light-border-subtle: #313c52;
   --tblr-dark-border-subtle: #1d273b;
   --tblr-heading-color: #fff;
   --tblr-link-color: #79a6dc;
   --tblr-link-hover-color: #a6c4e7;
   --tblr-link-color-rgb: 121, 166, 220;
   --tblr-link-hover-color-rgb: 166, 196, 231;
   --tblr-code-color: #e685a7;
   --tblr-border-color: #313c52;
   --tblr-border-color-translucent: rgba(255, 255, 255, 0.15);
}

*,
*::before,
*::after {
   box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
   :root {
      scroll-behavior: smooth;
   }
}

body {
   margin: 0;
   font-family: var(--tblr-body-font-family);
   font-weight: normal !important;
   font-style: normal;
   font-display: swap !important;
   font-size: var(--tblr-body-font-size);
   // font-weight: var(--tblr-body-font-weight);
   line-height: var(--tblr-body-line-height);
   color: var(--tblr-body-color);
   text-align: var(--tblr-body-text-align);
   background-color: var(--tblr-body-bg);
   -webkit-text-size-adjust: 100%;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
   img {
      user-select: none;
   }
}

hr,
.hr {
   margin: 2rem 0;
   color: inherit;
   border: 0;
   border-top: var(--tblr-border-width) solid;
   opacity: 0.16;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
   margin-top: 0;
   margin-bottom: 0.5rem;
   font-weight: var(--tblr-font-weight-bold);
   line-height: 1.2;
   color: var(--tblr-heading-color, inherit);
}

h1,
.h1 {
   font-size: 1.5rem;
}

h2,
.h2 {
   font-size: 1.25rem;
}

h3,
.h3 {
   font-size: 1rem;
}

h4,
.h4 {
   font-size: 0.875rem;
}

h5,
.h5 {
   font-size: 0.75rem;
}

h6,
.h6 {
   font-size: 0.625rem;
}

p {
   margin-top: 0;
   margin-bottom: 1rem;
}

abbr[title] {
   -webkit-text-decoration: underline dotted;
   text-decoration: underline dotted;
   cursor: help;
   -webkit-text-decoration-skip-ink: none;
   text-decoration-skip-ink: none;
}

address {
   margin-bottom: 1rem;
   font-style: normal;
   line-height: inherit;
}

ol,
ul {
   padding-left: 2rem;
}

ol,
ul,
dl {
   margin-top: 0;
   margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
   margin-bottom: 0;
}

dt {
   font-weight: 600;
}

dd {
   margin-bottom: 0.5rem;
   margin-left: 0;
}

blockquote {
   margin: 0 0 1rem;
}

b,
strong {
   font-weight: bolder;
}

small,
.small {
   font-size: 85.714285%;
}

mark,
.mark {
   padding: 0.1875em;
   background-color: var(--tblr-highlight-bg);
}

sub,
sup {
   position: relative;
   font-size: 0.75em;
   line-height: 0;
   vertical-align: baseline;
}

sub {
   bottom: -0.25em;
}

sup {
   top: -0.5em;
}

a {
   color: rgba(var(--tblr-link-color-rgb), var(--tblr-link-opacity, 1));
   text-decoration: none;
}
a:hover {
   --tblr-link-color-rgb: var(--tblr-link-hover-color-rgb);
   text-decoration: underline;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
   color: inherit;
   text-decoration: none;
}

pre,
code,
kbd,
samp {
   font-family: var(--tblr-font-monospace);
   font-size: 1em;
}

pre {
   display: block;
   margin-top: 0;
   margin-bottom: 1rem;
   overflow: auto;
   font-size: 85.714285%;
   color: var(--tblr-light);
}
pre code {
   font-size: inherit;
   color: inherit;
   word-break: normal;
}

code {
   font-size: 85.714285%;
   color: var(--tblr-code-color);
   word-wrap: break-word;
}
a > code {
   color: inherit;
}

kbd {
   padding: 0.25rem 0.5rem;
   font-size: var(--tblr-font-size-h5);
   color: var(--tblr-muted-dark);
   background-color: var(--tblr-code-bg);
   border-radius: 2px;
}
kbd kbd {
   padding: 0;
   font-size: 1em;
}

figure {
   margin: 0 0 1rem;
}

img,
svg {
   vertical-align: middle;
}

table {
   caption-side: bottom;
   border-collapse: collapse;
}

caption {
   padding-top: 0.75rem;
   padding-bottom: 0.75rem;
   color: #616876;
   text-align: left;
}

th {
   text-align: inherit;
   text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
   border-color: inherit;
   border-style: solid;
   border-width: 0;
}

label {
   display: inline-block;
}

button {
   border-radius: 0;
}

button:focus:not(:focus-visible) {
   outline: 0;
}

input,
button,
select,
optgroup,
textarea {
   margin: 0;
   font-family: inherit;
   font-size: inherit;
   line-height: inherit;
}

button,
select {
   text-transform: none;
}

[role="button"] {
   cursor: pointer;
}

select {
   word-wrap: normal;
}
select:disabled {
   opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not(
      [type="week"]
   ):not([type="time"])::-webkit-calendar-picker-indicator {
   display: none !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
   -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
   cursor: pointer;
}

::-moz-focus-inner {
   padding: 0;
   border-style: none;
}

textarea {
   resize: vertical;
}

fieldset {
   min-width: 0;
   padding: 0;
   margin: 0;
   border: 0;
}

legend {
   float: left;
   width: 100%;
   padding: 0;
   margin-bottom: 0.5rem;
   font-size: 1.5rem;
   line-height: inherit;
}
legend + * {
   clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
   padding: 0;
}

::-webkit-inner-spin-button {
   height: auto;
}

[type="search"] {
   outline-offset: -2px;
   -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
   -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
   padding: 0;
}

::-webkit-file-upload-button {
   font: inherit;
   -webkit-appearance: button;
}

::file-selector-button {
   font: inherit;
   -webkit-appearance: button;
}

output {
   display: inline-block;
}

iframe {
   border: 0;
}

summary {
   display: list-item;
   cursor: pointer;
}

progress {
   vertical-align: baseline;
}

[hidden] {
   display: none !important;
}

.lead {
   font-size: 0.875rem;
   font-weight: var(--tblr-font-weight-normal);
}

.display-1 {
   font-size: 5rem;
   font-weight: 300;
   line-height: 1.2;
}

.display-2 {
   font-size: 4.5rem;
   font-weight: 300;
   line-height: 1.2;
}

.display-3 {
   font-size: 4rem;
   font-weight: 300;
   line-height: 1.2;
}

.display-4 {
   font-size: 3.5rem;
   font-weight: 300;
   line-height: 1.2;
}

.display-5 {
   font-size: 3rem;
   font-weight: 300;
   line-height: 1.2;
}

.display-6 {
   font-size: 2rem;
   font-weight: 300;
   line-height: 1.2;
}

.list-unstyled {
   padding-left: 0;
   list-style: none;
}

.list-inline {
   padding-left: 0;
   list-style: none;
}

.list-inline-item {
   display: inline-block;
}
.list-inline-item:not(:last-child) {
   margin-right: 0.5rem;
}

.initialism {
   font-size: 85.714285%;
   text-transform: uppercase;
}

.blockquote {
   margin-bottom: 1rem;
   font-size: 0.875rem;
}
.blockquote > :last-child {
   margin-bottom: 0;
}

.blockquote-footer {
   margin-top: -1rem;
   margin-bottom: 1rem;
   font-size: 85.714285%;
   color: #49566c;
}
.blockquote-footer::before {
   content: "— ";
}

.img-fluid {
   max-width: 100%;
   height: auto;
}

.img-thumbnail {
   padding: 0.25rem;
   background-color: var(--tblr-body-bg);
   border: var(--tblr-border-width) solid var(--tblr-border-color);
   border-radius: var(--tblr-border-radius);
   max-width: 100%;
   height: auto;
}

.figure {
   display: inline-block;
}

.figure-img {
   margin-bottom: 0.5rem;
   line-height: 1;
}

.figure-caption {
   font-size: 85.714285%;
   color: var(--tblr-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
   --tblr-gutter-x: var(--tblr-content-padding);
   --tblr-gutter-y: 0;
   width: 100%;
   padding-right: calc(var(--tblr-gutter-x) * 0.5);
   padding-left: calc(var(--tblr-gutter-x) * 0.5);
   margin-right: auto;
   margin-left: auto;
}

@media (min-width: 576px) {
   .container-sm,
   .container {
      max-width: 540px;
   }
}
@media (min-width: 768px) {
   .container-md,
   .container-sm,
   .container {
      max-width: 720px;
   }
}
@media (min-width: 992px) {
   .container-lg,
   .container-md,
   .container-sm,
   .container {
      max-width: 960px;
   }
}
@media (min-width: 1200px) {
   .container-xl,
   .container-lg,
   .container-md,
   .container-sm,
   .container {
      max-width: 1140px;
   }
}
@media (min-width: 1400px) {
   .container-xxl,
   .container-xl,
   .container-lg,
   .container-md,
   .container-sm,
   .container {
      max-width: 1320px;
   }
}
.row {
   --tblr-gutter-x: var(--tblr-content-padding);
   --tblr-gutter-y: 0;
   display: flex;
   flex-wrap: wrap;
   margin-top: calc(-1 * var(--tblr-gutter-y));
   margin-right: calc(-0.5 * var(--tblr-gutter-x));
   margin-left: calc(-0.5 * var(--tblr-gutter-x));
}
.row > * {
   flex-shrink: 0;
   width: 100%;
   max-width: 100%;
   padding-right: calc(var(--tblr-gutter-x) * 0.5);
   padding-left: calc(var(--tblr-gutter-x) * 0.5);
   // margin-top: var(--tblr-gutter-y);
}

.grid {
   display: grid;
   grid-template-rows: repeat(var(--tblr-rows, 1), 1fr);
   grid-template-columns: repeat(var(--tblr-columns, 12), 1fr);
   gap: var(--tblr-gap, var(--tblr-content-padding));
}
.grid .g-col-1 {
   grid-column: auto/span 1;
}
.grid .g-col-2 {
   grid-column: auto/span 2;
}
.grid .g-col-3 {
   grid-column: auto/span 3;
}
.grid .g-col-4 {
   grid-column: auto/span 4;
}
.grid .g-col-5 {
   grid-column: auto/span 5;
}
.grid .g-col-6 {
   grid-column: auto/span 6;
}
.grid .g-col-7 {
   grid-column: auto/span 7;
}
.grid .g-col-8 {
   grid-column: auto/span 8;
}
.grid .g-col-9 {
   grid-column: auto/span 9;
}
.grid .g-col-10 {
   grid-column: auto/span 10;
}
.grid .g-col-11 {
   grid-column: auto/span 11;
}
.grid .g-col-12 {
   grid-column: auto/span 12;
}
.grid .g-start-1 {
   grid-column-start: 1;
}
.grid .g-start-2 {
   grid-column-start: 2;
}
.grid .g-start-3 {
   grid-column-start: 3;
}
.grid .g-start-4 {
   grid-column-start: 4;
}
.grid .g-start-5 {
   grid-column-start: 5;
}
.grid .g-start-6 {
   grid-column-start: 6;
}
.grid .g-start-7 {
   grid-column-start: 7;
}
.grid .g-start-8 {
   grid-column-start: 8;
}
.grid .g-start-9 {
   grid-column-start: 9;
}
.grid .g-start-10 {
   grid-column-start: 10;
}
.grid .g-start-11 {
   grid-column-start: 11;
}
@media (min-width: 576px) {
   .grid .g-col-sm-1 {
      grid-column: auto/span 1;
   }
   .grid .g-col-sm-2 {
      grid-column: auto/span 2;
   }
   .grid .g-col-sm-3 {
      grid-column: auto/span 3;
   }
   .grid .g-col-sm-4 {
      grid-column: auto/span 4;
   }
   .grid .g-col-sm-5 {
      grid-column: auto/span 5;
   }
   .grid .g-col-sm-6 {
      grid-column: auto/span 6;
   }
   .grid .g-col-sm-7 {
      grid-column: auto/span 7;
   }
   .grid .g-col-sm-8 {
      grid-column: auto/span 8;
   }
   .grid .g-col-sm-9 {
      grid-column: auto/span 9;
   }
   .grid .g-col-sm-10 {
      grid-column: auto/span 10;
   }
   .grid .g-col-sm-11 {
      grid-column: auto/span 11;
   }
   .grid .g-col-sm-12 {
      grid-column: auto/span 12;
   }
   .grid .g-start-sm-1 {
      grid-column-start: 1;
   }
   .grid .g-start-sm-2 {
      grid-column-start: 2;
   }
   .grid .g-start-sm-3 {
      grid-column-start: 3;
   }
   .grid .g-start-sm-4 {
      grid-column-start: 4;
   }
   .grid .g-start-sm-5 {
      grid-column-start: 5;
   }
   .grid .g-start-sm-6 {
      grid-column-start: 6;
   }
   .grid .g-start-sm-7 {
      grid-column-start: 7;
   }
   .grid .g-start-sm-8 {
      grid-column-start: 8;
   }
   .grid .g-start-sm-9 {
      grid-column-start: 9;
   }
   .grid .g-start-sm-10 {
      grid-column-start: 10;
   }
   .grid .g-start-sm-11 {
      grid-column-start: 11;
   }
}
@media (min-width: 768px) {
   .grid .g-col-md-1 {
      grid-column: auto/span 1;
   }
   .grid .g-col-md-2 {
      grid-column: auto/span 2;
   }
   .grid .g-col-md-3 {
      grid-column: auto/span 3;
   }
   .grid .g-col-md-4 {
      grid-column: auto/span 4;
   }
   .grid .g-col-md-5 {
      grid-column: auto/span 5;
   }
   .grid .g-col-md-6 {
      grid-column: auto/span 6;
   }
   .grid .g-col-md-7 {
      grid-column: auto/span 7;
   }
   .grid .g-col-md-8 {
      grid-column: auto/span 8;
   }
   .grid .g-col-md-9 {
      grid-column: auto/span 9;
   }
   .grid .g-col-md-10 {
      grid-column: auto/span 10;
   }
   .grid .g-col-md-11 {
      grid-column: auto/span 11;
   }
   .grid .g-col-md-12 {
      grid-column: auto/span 12;
   }
   .grid .g-start-md-1 {
      grid-column-start: 1;
   }
   .grid .g-start-md-2 {
      grid-column-start: 2;
   }
   .grid .g-start-md-3 {
      grid-column-start: 3;
   }
   .grid .g-start-md-4 {
      grid-column-start: 4;
   }
   .grid .g-start-md-5 {
      grid-column-start: 5;
   }
   .grid .g-start-md-6 {
      grid-column-start: 6;
   }
   .grid .g-start-md-7 {
      grid-column-start: 7;
   }
   .grid .g-start-md-8 {
      grid-column-start: 8;
   }
   .grid .g-start-md-9 {
      grid-column-start: 9;
   }
   .grid .g-start-md-10 {
      grid-column-start: 10;
   }
   .grid .g-start-md-11 {
      grid-column-start: 11;
   }
}
@media (min-width: 992px) {
   .grid .g-col-lg-1 {
      grid-column: auto/span 1;
   }
   .grid .g-col-lg-2 {
      grid-column: auto/span 2;
   }
   .grid .g-col-lg-3 {
      grid-column: auto/span 3;
   }
   .grid .g-col-lg-4 {
      grid-column: auto/span 4;
   }
   .grid .g-col-lg-5 {
      grid-column: auto/span 5;
   }
   .grid .g-col-lg-6 {
      grid-column: auto/span 6;
   }
   .grid .g-col-lg-7 {
      grid-column: auto/span 7;
   }
   .grid .g-col-lg-8 {
      grid-column: auto/span 8;
   }
   .grid .g-col-lg-9 {
      grid-column: auto/span 9;
   }
   .grid .g-col-lg-10 {
      grid-column: auto/span 10;
   }
   .grid .g-col-lg-11 {
      grid-column: auto/span 11;
   }
   .grid .g-col-lg-12 {
      grid-column: auto/span 12;
   }
   .grid .g-start-lg-1 {
      grid-column-start: 1;
   }
   .grid .g-start-lg-2 {
      grid-column-start: 2;
   }
   .grid .g-start-lg-3 {
      grid-column-start: 3;
   }
   .grid .g-start-lg-4 {
      grid-column-start: 4;
   }
   .grid .g-start-lg-5 {
      grid-column-start: 5;
   }
   .grid .g-start-lg-6 {
      grid-column-start: 6;
   }
   .grid .g-start-lg-7 {
      grid-column-start: 7;
   }
   .grid .g-start-lg-8 {
      grid-column-start: 8;
   }
   .grid .g-start-lg-9 {
      grid-column-start: 9;
   }
   .grid .g-start-lg-10 {
      grid-column-start: 10;
   }
   .grid .g-start-lg-11 {
      grid-column-start: 11;
   }
}
@media (min-width: 1200px) {
   .grid .g-col-xl-1 {
      grid-column: auto/span 1;
   }
   .grid .g-col-xl-2 {
      grid-column: auto/span 2;
   }
   .grid .g-col-xl-3 {
      grid-column: auto/span 3;
   }
   .grid .g-col-xl-4 {
      grid-column: auto/span 4;
   }
   .grid .g-col-xl-5 {
      grid-column: auto/span 5;
   }
   .grid .g-col-xl-6 {
      grid-column: auto/span 6;
   }
   .grid .g-col-xl-7 {
      grid-column: auto/span 7;
   }
   .grid .g-col-xl-8 {
      grid-column: auto/span 8;
   }
   .grid .g-col-xl-9 {
      grid-column: auto/span 9;
   }
   .grid .g-col-xl-10 {
      grid-column: auto/span 10;
   }
   .grid .g-col-xl-11 {
      grid-column: auto/span 11;
   }
   .grid .g-col-xl-12 {
      grid-column: auto/span 12;
   }
   .grid .g-start-xl-1 {
      grid-column-start: 1;
   }
   .grid .g-start-xl-2 {
      grid-column-start: 2;
   }
   .grid .g-start-xl-3 {
      grid-column-start: 3;
   }
   .grid .g-start-xl-4 {
      grid-column-start: 4;
   }
   .grid .g-start-xl-5 {
      grid-column-start: 5;
   }
   .grid .g-start-xl-6 {
      grid-column-start: 6;
   }
   .grid .g-start-xl-7 {
      grid-column-start: 7;
   }
   .grid .g-start-xl-8 {
      grid-column-start: 8;
   }
   .grid .g-start-xl-9 {
      grid-column-start: 9;
   }
   .grid .g-start-xl-10 {
      grid-column-start: 10;
   }
   .grid .g-start-xl-11 {
      grid-column-start: 11;
   }
}
@media (min-width: 1400px) {
   .grid .g-col-xxl-1 {
      grid-column: auto/span 1;
   }
   .grid .g-col-xxl-2 {
      grid-column: auto/span 2;
   }
   .grid .g-col-xxl-3 {
      grid-column: auto/span 3;
   }
   .grid .g-col-xxl-4 {
      grid-column: auto/span 4;
   }
   .grid .g-col-xxl-5 {
      grid-column: auto/span 5;
   }
   .grid .g-col-xxl-6 {
      grid-column: auto/span 6;
   }
   .grid .g-col-xxl-7 {
      grid-column: auto/span 7;
   }
   .grid .g-col-xxl-8 {
      grid-column: auto/span 8;
   }
   .grid .g-col-xxl-9 {
      grid-column: auto/span 9;
   }
   .grid .g-col-xxl-10 {
      grid-column: auto/span 10;
   }
   .grid .g-col-xxl-11 {
      grid-column: auto/span 11;
   }
   .grid .g-col-xxl-12 {
      grid-column: auto/span 12;
   }
   .grid .g-start-xxl-1 {
      grid-column-start: 1;
   }
   .grid .g-start-xxl-2 {
      grid-column-start: 2;
   }
   .grid .g-start-xxl-3 {
      grid-column-start: 3;
   }
   .grid .g-start-xxl-4 {
      grid-column-start: 4;
   }
   .grid .g-start-xxl-5 {
      grid-column-start: 5;
   }
   .grid .g-start-xxl-6 {
      grid-column-start: 6;
   }
   .grid .g-start-xxl-7 {
      grid-column-start: 7;
   }
   .grid .g-start-xxl-8 {
      grid-column-start: 8;
   }
   .grid .g-start-xxl-9 {
      grid-column-start: 9;
   }
   .grid .g-start-xxl-10 {
      grid-column-start: 10;
   }
   .grid .g-start-xxl-11 {
      grid-column-start: 11;
   }
}

.col {
   flex: 1 0 0%;
}

.row-cols-auto > * {
   flex: 0 0 auto;
   width: auto;
}

.row-cols-1 > * {
   flex: 0 0 auto;
   width: 100%;
}

.row-cols-2 > * {
   flex: 0 0 auto;
   width: 50%;
}

.row-cols-3 > * {
   flex: 0 0 auto;
   width: 33.3333333333%;
}

.row-cols-4 > * {
   flex: 0 0 auto;
   width: 25%;
}

.row-cols-5 > * {
   flex: 0 0 auto;
   width: 20%;
}

.row-cols-6 > * {
   flex: 0 0 auto;
   width: 16.6666666667%;
}

.col-auto {
   flex: 0 0 auto;
   width: auto;
}

.col-1 {
   flex: 0 0 auto;
   width: 8.33333333%;
}

.col-2 {
   flex: 0 0 auto;
   width: 16.66666667%;
}

.col-3 {
   flex: 0 0 auto;
   width: 25%;
}

.col-4 {
   flex: 0 0 auto;
   width: 33.33333333%;
}

.col-5 {
   flex: 0 0 auto;
   width: 41.66666667%;
}

.col-6 {
   flex: 0 0 auto;
   width: 50%;
}

.col-7 {
   flex: 0 0 auto;
   width: 58.33333333%;
}

.col-8 {
   flex: 0 0 auto;
   width: 66.66666667%;
}

.col-9 {
   flex: 0 0 auto;
   width: 75%;
}

.col-10 {
   flex: 0 0 auto;
   width: 83.33333333%;
}

.col-11 {
   flex: 0 0 auto;
   width: 91.66666667%;
}

.col-12 {
   flex: 0 0 auto;
   width: 100%;
}

.offset-1 {
   margin-left: 8.33333333%;
}

.offset-2 {
   margin-left: 16.66666667%;
}

.offset-3 {
   margin-left: 25%;
}

.offset-4 {
   margin-left: 33.33333333%;
}

.offset-5 {
   margin-left: 41.66666667%;
}

.offset-6 {
   margin-left: 50%;
}

.offset-7 {
   margin-left: 58.33333333%;
}

.offset-8 {
   margin-left: 66.66666667%;
}

.offset-9 {
   margin-left: 75%;
}

.offset-10 {
   margin-left: 83.33333333%;
}

.offset-11 {
   margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
   --tblr-gutter-x: 0;
}

.g-0,
.gy-0 {
   --tblr-gutter-y: 0;
}

.g-1,
.gx-1 {
   --tblr-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
   --tblr-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
   --tblr-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
   --tblr-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
   --tblr-gutter-x: 1rem;
}

.g-3,
.gy-3 {
   --tblr-gutter-y: 1rem;
}

.g-4,
.gx-4 {
   --tblr-gutter-x: 2rem;
}

.g-4,
.gy-4 {
   --tblr-gutter-y: 2rem;
}

.g-5,
.gx-5 {
   --tblr-gutter-x: 4rem;
}

.g-5,
.gy-5 {
   --tblr-gutter-y: 4rem;
}

@media (min-width: 576px) {
   .col-sm {
      flex: 1 0 0%;
   }
   .row-cols-sm-auto > * {
      flex: 0 0 auto;
      width: auto;
   }
   .row-cols-sm-1 > * {
      flex: 0 0 auto;
      width: 100%;
   }
   .row-cols-sm-2 > * {
      flex: 0 0 auto;
      width: 50%;
   }
   .row-cols-sm-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
   }
   .row-cols-sm-4 > * {
      flex: 0 0 auto;
      width: 25%;
   }
   .row-cols-sm-5 > * {
      flex: 0 0 auto;
      width: 20%;
   }
   .row-cols-sm-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
   }
   .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
   }
   .col-sm-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
   }
   .col-sm-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
   }
   .col-sm-3 {
      flex: 0 0 auto;
      width: 25%;
   }
   .col-sm-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
   }
   .col-sm-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
   }
   .col-sm-6 {
      flex: 0 0 auto;
      width: 50%;
   }
   .col-sm-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
   }
   .col-sm-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
   }
   .col-sm-9 {
      flex: 0 0 auto;
      width: 75%;
   }
   .col-sm-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
   }
   .col-sm-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
   }
   .col-sm-12 {
      flex: 0 0 auto;
      width: 100%;
   }
   .offset-sm-0 {
      margin-left: 0;
   }
   .offset-sm-1 {
      margin-left: 8.33333333%;
   }
   .offset-sm-2 {
      margin-left: 16.66666667%;
   }
   .offset-sm-3 {
      margin-left: 25%;
   }
   .offset-sm-4 {
      margin-left: 33.33333333%;
   }
   .offset-sm-5 {
      margin-left: 41.66666667%;
   }
   .offset-sm-6 {
      margin-left: 50%;
   }
   .offset-sm-7 {
      margin-left: 58.33333333%;
   }
   .offset-sm-8 {
      margin-left: 66.66666667%;
   }
   .offset-sm-9 {
      margin-left: 75%;
   }
   .offset-sm-10 {
      margin-left: 83.33333333%;
   }
   .offset-sm-11 {
      margin-left: 91.66666667%;
   }
   .g-sm-0,
   .gx-sm-0 {
      --tblr-gutter-x: 0;
   }
   .g-sm-0,
   .gy-sm-0 {
      --tblr-gutter-y: 0;
   }
   .g-sm-1,
   .gx-sm-1 {
      --tblr-gutter-x: 0.25rem;
   }
   .g-sm-1,
   .gy-sm-1 {
      --tblr-gutter-y: 0.25rem;
   }
   .g-sm-2,
   .gx-sm-2 {
      --tblr-gutter-x: 0.5rem;
   }
   .g-sm-2,
   .gy-sm-2 {
      --tblr-gutter-y: 0.5rem;
   }
   .g-sm-3,
   .gx-sm-3 {
      --tblr-gutter-x: 1rem;
   }
   .g-sm-3,
   .gy-sm-3 {
      --tblr-gutter-y: 1rem;
   }
   .g-sm-4,
   .gx-sm-4 {
      --tblr-gutter-x: 2rem;
   }
   .g-sm-4,
   .gy-sm-4 {
      --tblr-gutter-y: 2rem;
   }
   .g-sm-5,
   .gx-sm-5 {
      --tblr-gutter-x: 4rem;
   }
   .g-sm-5,
   .gy-sm-5 {
      --tblr-gutter-y: 4rem;
   }
}
@media (min-width: 768px) {
   .col-md {
      flex: 1 0 0%;
   }
   .row-cols-md-auto > * {
      flex: 0 0 auto;
      width: auto;
   }
   .row-cols-md-1 > * {
      flex: 0 0 auto;
      width: 100%;
   }
   .row-cols-md-2 > * {
      flex: 0 0 auto;
      width: 50%;
   }
   .row-cols-md-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
   }
   .row-cols-md-4 > * {
      flex: 0 0 auto;
      width: 25%;
   }
   .row-cols-md-5 > * {
      flex: 0 0 auto;
      width: 20%;
   }
   .row-cols-md-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
   }
   .col-md-auto {
      flex: 0 0 auto;
      width: auto;
   }
   .col-md-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
   }
   .col-md-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
   }
   .col-md-3 {
      flex: 0 0 auto;
      width: 25%;
   }
   .col-md-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
   }
   .col-md-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
   }
   .col-md-6 {
      flex: 0 0 auto;
      width: 50%;
   }
   .col-md-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
   }
   .col-md-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
   }
   .col-md-9 {
      flex: 0 0 auto;
      width: 75%;
   }
   .col-md-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
   }
   .col-md-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
   }
   .col-md-12 {
      flex: 0 0 auto;
      width: 100%;
   }
   .offset-md-0 {
      margin-left: 0;
   }
   .offset-md-1 {
      margin-left: 8.33333333%;
   }
   .offset-md-2 {
      margin-left: 16.66666667%;
   }
   .offset-md-3 {
      margin-left: 25%;
   }
   .offset-md-4 {
      margin-left: 33.33333333%;
   }
   .offset-md-5 {
      margin-left: 41.66666667%;
   }
   .offset-md-6 {
      margin-left: 50%;
   }
   .offset-md-7 {
      margin-left: 58.33333333%;
   }
   .offset-md-8 {
      margin-left: 66.66666667%;
   }
   .offset-md-9 {
      margin-left: 75%;
   }
   .offset-md-10 {
      margin-left: 83.33333333%;
   }
   .offset-md-11 {
      margin-left: 91.66666667%;
   }
   .g-md-0,
   .gx-md-0 {
      --tblr-gutter-x: 0;
   }
   .g-md-0,
   .gy-md-0 {
      --tblr-gutter-y: 0;
   }
   .g-md-1,
   .gx-md-1 {
      --tblr-gutter-x: 0.25rem;
   }
   .g-md-1,
   .gy-md-1 {
      --tblr-gutter-y: 0.25rem;
   }
   .g-md-2,
   .gx-md-2 {
      --tblr-gutter-x: 0.5rem;
   }
   .g-md-2,
   .gy-md-2 {
      --tblr-gutter-y: 0.5rem;
   }
   .g-md-3,
   .gx-md-3 {
      --tblr-gutter-x: 1rem;
   }
   .g-md-3,
   .gy-md-3 {
      --tblr-gutter-y: 1rem;
   }
   .g-md-4,
   .gx-md-4 {
      --tblr-gutter-x: 2rem;
   }
   .g-md-4,
   .gy-md-4 {
      --tblr-gutter-y: 2rem;
   }
   .g-md-5,
   .gx-md-5 {
      --tblr-gutter-x: 4rem;
   }
   .g-md-5,
   .gy-md-5 {
      --tblr-gutter-y: 4rem;
   }
}
@media (min-width: 992px) {
   .col-lg {
      flex: 1 0 0%;
   }
   .row-cols-lg-auto > * {
      flex: 0 0 auto;
      width: auto;
   }
   .row-cols-lg-1 > * {
      flex: 0 0 auto;
      width: 100%;
   }
   .row-cols-lg-2 > * {
      flex: 0 0 auto;
      width: 50%;
   }
   .row-cols-lg-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
   }
   .row-cols-lg-4 > * {
      flex: 0 0 auto;
      width: 25%;
   }
   .row-cols-lg-5 > * {
      flex: 0 0 auto;
      width: 20%;
   }
   .row-cols-lg-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
   }
   .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
   }
   .col-lg-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
   }
   .col-lg-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
   }
   .col-lg-3 {
      flex: 0 0 auto;
      width: 25%;
   }
   .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
   }
   .col-lg-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
   }
   .col-lg-6 {
      flex: 0 0 auto;
      width: 50%;
   }
   .col-lg-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
   }
   .col-lg-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
   }
   .col-lg-9 {
      flex: 0 0 auto;
      width: 75%;
   }
   .col-lg-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
   }
   .col-lg-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
   }
   .col-lg-12 {
      flex: 0 0 auto;
      width: 100%;
   }
   .offset-lg-0 {
      margin-left: 0;
   }
   .offset-lg-1 {
      margin-left: 8.33333333%;
   }
   .offset-lg-2 {
      margin-left: 16.66666667%;
   }
   .offset-lg-3 {
      margin-left: 25%;
   }
   .offset-lg-4 {
      margin-left: 33.33333333%;
   }
   .offset-lg-5 {
      margin-left: 41.66666667%;
   }
   .offset-lg-6 {
      margin-left: 50%;
   }
   .offset-lg-7 {
      margin-left: 58.33333333%;
   }
   .offset-lg-8 {
      margin-left: 66.66666667%;
   }
   .offset-lg-9 {
      margin-left: 75%;
   }
   .offset-lg-10 {
      margin-left: 83.33333333%;
   }
   .offset-lg-11 {
      margin-left: 91.66666667%;
   }
   .g-lg-0,
   .gx-lg-0 {
      --tblr-gutter-x: 0;
   }
   .g-lg-0,
   .gy-lg-0 {
      --tblr-gutter-y: 0;
   }
   .g-lg-1,
   .gx-lg-1 {
      --tblr-gutter-x: 0.25rem;
   }
   .g-lg-1,
   .gy-lg-1 {
      --tblr-gutter-y: 0.25rem;
   }
   .g-lg-2,
   .gx-lg-2 {
      --tblr-gutter-x: 0.5rem;
   }
   .g-lg-2,
   .gy-lg-2 {
      --tblr-gutter-y: 0.5rem;
   }
   .g-lg-3,
   .gx-lg-3 {
      --tblr-gutter-x: 1rem;
   }
   .g-lg-3,
   .gy-lg-3 {
      --tblr-gutter-y: 1rem;
   }
   .g-lg-4,
   .gx-lg-4 {
      --tblr-gutter-x: 2rem;
   }
   .g-lg-4,
   .gy-lg-4 {
      --tblr-gutter-y: 2rem;
   }
   .g-lg-5,
   .gx-lg-5 {
      --tblr-gutter-x: 4rem;
   }
   .g-lg-5,
   .gy-lg-5 {
      --tblr-gutter-y: 4rem;
   }
}
@media (min-width: 1200px) {
   .col-xl {
      flex: 1 0 0%;
   }
   .row-cols-xl-auto > * {
      flex: 0 0 auto;
      width: auto;
   }
   .row-cols-xl-1 > * {
      flex: 0 0 auto;
      width: 100%;
   }
   .row-cols-xl-2 > * {
      flex: 0 0 auto;
      width: 50%;
   }
   .row-cols-xl-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
   }
   .row-cols-xl-4 > * {
      flex: 0 0 auto;
      width: 25%;
   }
   .row-cols-xl-5 > * {
      flex: 0 0 auto;
      width: 20%;
   }
   .row-cols-xl-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
   }
   .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
   }
   .col-xl-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
   }
   .col-xl-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
   }
   .col-xl-3 {
      flex: 0 0 auto;
      width: 25%;
   }
   .col-xl-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
   }
   .col-xl-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
   }
   .col-xl-6 {
      flex: 0 0 auto;
      width: 50%;
   }
   .col-xl-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
   }
   .col-xl-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
   }
   .col-xl-9 {
      flex: 0 0 auto;
      width: 75%;
   }
   .col-xl-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
   }
   .col-xl-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
   }
   .col-xl-12 {
      flex: 0 0 auto;
      width: 100%;
   }
   .offset-xl-0 {
      margin-left: 0;
   }
   .offset-xl-1 {
      margin-left: 8.33333333%;
   }
   .offset-xl-2 {
      margin-left: 16.66666667%;
   }
   .offset-xl-3 {
      margin-left: 25%;
   }
   .offset-xl-4 {
      margin-left: 33.33333333%;
   }
   .offset-xl-5 {
      margin-left: 41.66666667%;
   }
   .offset-xl-6 {
      margin-left: 50%;
   }
   .offset-xl-7 {
      margin-left: 58.33333333%;
   }
   .offset-xl-8 {
      margin-left: 66.66666667%;
   }
   .offset-xl-9 {
      margin-left: 75%;
   }
   .offset-xl-10 {
      margin-left: 83.33333333%;
   }
   .offset-xl-11 {
      margin-left: 91.66666667%;
   }
   .g-xl-0,
   .gx-xl-0 {
      --tblr-gutter-x: 0;
   }
   .g-xl-0,
   .gy-xl-0 {
      --tblr-gutter-y: 0;
   }
   .g-xl-1,
   .gx-xl-1 {
      --tblr-gutter-x: 0.25rem;
   }
   .g-xl-1,
   .gy-xl-1 {
      --tblr-gutter-y: 0.25rem;
   }
   .g-xl-2,
   .gx-xl-2 {
      --tblr-gutter-x: 0.5rem;
   }
   .g-xl-2,
   .gy-xl-2 {
      --tblr-gutter-y: 0.5rem;
   }
   .g-xl-3,
   .gx-xl-3 {
      --tblr-gutter-x: 1rem;
   }
   .g-xl-3,
   .gy-xl-3 {
      --tblr-gutter-y: 1rem;
   }
   .g-xl-4,
   .gx-xl-4 {
      --tblr-gutter-x: 2rem;
   }
   .g-xl-4,
   .gy-xl-4 {
      --tblr-gutter-y: 2rem;
   }
   .g-xl-5,
   .gx-xl-5 {
      --tblr-gutter-x: 4rem;
   }
   .g-xl-5,
   .gy-xl-5 {
      --tblr-gutter-y: 4rem;
   }
}
@media (min-width: 1400px) {
   .col-xxl {
      flex: 1 0 0%;
   }
   .row-cols-xxl-auto > * {
      flex: 0 0 auto;
      width: auto;
   }
   .row-cols-xxl-1 > * {
      flex: 0 0 auto;
      width: 100%;
   }
   .row-cols-xxl-2 > * {
      flex: 0 0 auto;
      width: 50%;
   }
   .row-cols-xxl-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
   }
   .row-cols-xxl-4 > * {
      flex: 0 0 auto;
      width: 25%;
   }
   .row-cols-xxl-5 > * {
      flex: 0 0 auto;
      width: 20%;
   }
   .row-cols-xxl-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
   }
   .col-xxl-auto {
      flex: 0 0 auto;
      width: auto;
   }
   .col-xxl-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
   }
   .col-xxl-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
   }
   .col-xxl-3 {
      flex: 0 0 auto;
      width: 25%;
   }
   .col-xxl-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
   }
   .col-xxl-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
   }
   .col-xxl-6 {
      flex: 0 0 auto;
      width: 50%;
   }
   .col-xxl-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
   }
   .col-xxl-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
   }
   .col-xxl-9 {
      flex: 0 0 auto;
      width: 75%;
   }
   .col-xxl-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
   }
   .col-xxl-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
   }
   .col-xxl-12 {
      flex: 0 0 auto;
      width: 100%;
   }
   .offset-xxl-0 {
      margin-left: 0;
   }
   .offset-xxl-1 {
      margin-left: 8.33333333%;
   }
   .offset-xxl-2 {
      margin-left: 16.66666667%;
   }
   .offset-xxl-3 {
      margin-left: 25%;
   }
   .offset-xxl-4 {
      margin-left: 33.33333333%;
   }
   .offset-xxl-5 {
      margin-left: 41.66666667%;
   }
   .offset-xxl-6 {
      margin-left: 50%;
   }
   .offset-xxl-7 {
      margin-left: 58.33333333%;
   }
   .offset-xxl-8 {
      margin-left: 66.66666667%;
   }
   .offset-xxl-9 {
      margin-left: 75%;
   }
   .offset-xxl-10 {
      margin-left: 83.33333333%;
   }
   .offset-xxl-11 {
      margin-left: 91.66666667%;
   }
   .g-xxl-0,
   .gx-xxl-0 {
      --tblr-gutter-x: 0;
   }
   .g-xxl-0,
   .gy-xxl-0 {
      --tblr-gutter-y: 0;
   }
   .g-xxl-1,
   .gx-xxl-1 {
      --tblr-gutter-x: 0.25rem;
   }
   .g-xxl-1,
   .gy-xxl-1 {
      --tblr-gutter-y: 0.25rem;
   }
   .g-xxl-2,
   .gx-xxl-2 {
      --tblr-gutter-x: 0.5rem;
   }
   .g-xxl-2,
   .gy-xxl-2 {
      --tblr-gutter-y: 0.5rem;
   }
   .g-xxl-3,
   .gx-xxl-3 {
      --tblr-gutter-x: 1rem;
   }
   .g-xxl-3,
   .gy-xxl-3 {
      --tblr-gutter-y: 1rem;
   }
   .g-xxl-4,
   .gx-xxl-4 {
      --tblr-gutter-x: 2rem;
   }
   .g-xxl-4,
   .gy-xxl-4 {
      --tblr-gutter-y: 2rem;
   }
   .g-xxl-5,
   .gx-xxl-5 {
      --tblr-gutter-x: 4rem;
   }
   .g-xxl-5,
   .gy-xxl-5 {
      --tblr-gutter-y: 4rem;
   }
}
.table,
.markdown > table {
   --tblr-table-color: inherit;
   --tblr-table-bg: transparent;
   --tblr-table-border-color: var(--tblr-border-color-translucent);
   --tblr-table-accent-bg: transparent;
   --tblr-table-striped-color: inherit;
   --tblr-table-striped-bg: var(--tblr-bg-surface-tertiary);
   --tblr-table-active-color: inherit;
   --tblr-table-active-bg: rgba(0, 0, 0, 0.1);
   --tblr-table-hover-color: inherit;
   --tblr-table-hover-bg: rgba(0, 0, 0, 0.075);
   width: 100%;
   margin-bottom: 1rem;
   color: var(--tblr-table-color);
   vertical-align: top;
   border-color: var(--tblr-table-border-color);
}
.table > :not(caption) > * > *,
.markdown > table > :not(caption) > * > * {
   padding: 0.75rem 0.75rem;
   background-color: var(--tblr-table-bg);
   border-bottom-width: var(--tblr-border-width);
   box-shadow: inset 0 0 0 9999px var(--tblr-table-accent-bg);
}
.table > tbody,
.markdown > table > tbody {
   vertical-align: inherit;
}
.table > thead,
.markdown > table > thead {
   vertical-align: bottom;
}

.table-group-divider {
   border-top: calc(var(--tblr-border-width) * 2) solid
      var(--tblr-border-color-translucent);
}

.caption-top {
   caption-side: top;
}

.table-sm > :not(caption) > * > * {
   padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > *,
.markdown > table > :not(caption) > * {
   border-width: var(--tblr-border-width) 0;
}
.table-bordered > :not(caption) > * > *,
.markdown > table > :not(caption) > * > * {
   border-width: 0 var(--tblr-border-width);
}

.table-borderless > :not(caption) > * > * {
   border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
   border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
   --tblr-table-accent-bg: var(--tblr-table-striped-bg);
   color: var(--tblr-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
   --tblr-table-accent-bg: var(--tblr-table-striped-bg);
   color: var(--tblr-table-striped-color);
}

.table-active {
   --tblr-table-accent-bg: var(--tblr-table-active-bg);
   color: var(--tblr-table-active-color);
}

.table-hover > tbody > tr:hover > * {
   --tblr-table-accent-bg: var(--tblr-table-hover-bg);
   color: var(--tblr-table-hover-color);
}

.table-primary {
   --tblr-table-color: #1d273b;
   --tblr-table-bg: #d2e1f3;
   --tblr-table-border-color: #c0cee1;
   --tblr-table-striped-bg: #c9d8ea;
   --tblr-table-striped-color: #1d273b;
   --tblr-table-active-bg: #c0cee1;
   --tblr-table-active-color: #f8fafc;
   --tblr-table-hover-bg: #c4d3e5;
   --tblr-table-hover-color: #1d273b;
   color: var(--tblr-table-color);
   border-color: var(--tblr-table-border-color);
}

.table-secondary {
   --tblr-table-color: #1d273b;
   --tblr-table-bg: #dfe1e4;
   --tblr-table-border-color: #ccced3;
   --tblr-table-striped-bg: #d5d8dc;
   --tblr-table-striped-color: #1d273b;
   --tblr-table-active-bg: #ccced3;
   --tblr-table-active-color: #f8fafc;
   --tblr-table-hover-bg: #d0d3d7;
   --tblr-table-hover-color: #1d273b;
   color: var(--tblr-table-color);
   border-color: var(--tblr-table-border-color);
}

.table-success {
   --tblr-table-color: #1d273b;
   --tblr-table-bg: #d5f0da;
   --tblr-table-border-color: #c3dcca;
   --tblr-table-striped-bg: #cce6d2;
   --tblr-table-striped-color: #1d273b;
   --tblr-table-active-bg: #c3dcca;
   --tblr-table-active-color: #1d273b;
   --tblr-table-hover-bg: #c7e1ce;
   --tblr-table-hover-color: #1d273b;
   color: var(--tblr-table-color);
   border-color: var(--tblr-table-border-color);
}

.table-info {
   --tblr-table-color: #1d273b;
   --tblr-table-bg: #d9ebf9;
   --tblr-table-border-color: #c6d7e6;
   --tblr-table-striped-bg: #d0e1f0;
   --tblr-table-striped-color: #1d273b;
   --tblr-table-active-bg: #c6d7e6;
   --tblr-table-active-color: #1d273b;
   --tblr-table-hover-bg: #cbdceb;
   --tblr-table-hover-color: #1d273b;
   color: var(--tblr-table-color);
   border-color: var(--tblr-table-border-color);
}

.table-warning {
   --tblr-table-color: #1d273b;
   --tblr-table-bg: #fde1cd;
   --tblr-table-border-color: #e7cebe;
   --tblr-table-striped-bg: #f2d8c6;
   --tblr-table-striped-color: #1d273b;
   --tblr-table-active-bg: #e7cebe;
   --tblr-table-active-color: #1d273b;
   --tblr-table-hover-bg: #ecd3c2;
   --tblr-table-hover-color: #1d273b;
   color: var(--tblr-table-color);
   border-color: var(--tblr-table-border-color);
}

.table-danger {
   --tblr-table-color: #1d273b;
   --tblr-table-bg: #f7d7d7;
   --tblr-table-border-color: #e1c5c7;
   --tblr-table-striped-bg: #eccecf;
   --tblr-table-striped-color: #1d273b;
   --tblr-table-active-bg: #e1c5c7;
   --tblr-table-active-color: #f8fafc;
   --tblr-table-hover-bg: #e7cacb;
   --tblr-table-hover-color: #1d273b;
   color: var(--tblr-table-color);
   border-color: var(--tblr-table-border-color);
}

.table-light {
   --tblr-table-color: #1d273b;
   --tblr-table-bg: #f8fafc;
   --tblr-table-border-color: #e2e5e9;
   --tblr-table-striped-bg: #edeff2;
   --tblr-table-striped-color: #1d273b;
   --tblr-table-active-bg: #e2e5e9;
   --tblr-table-active-color: #1d273b;
   --tblr-table-hover-bg: #e8eaee;
   --tblr-table-hover-color: #1d273b;
   color: var(--tblr-table-color);
   border-color: var(--tblr-table-border-color);
}

.table-dark {
   --tblr-table-color: #f8fafc;
   --tblr-table-bg: #1d273b;
   --tblr-table-border-color: #333c4e;
   --tblr-table-striped-bg: #283245;
   --tblr-table-striped-color: #f8fafc;
   --tblr-table-active-bg: #333c4e;
   --tblr-table-active-color: #f8fafc;
   --tblr-table-hover-bg: #2d3749;
   --tblr-table-hover-color: #f8fafc;
   color: var(--tblr-table-color);
   border-color: var(--tblr-table-border-color);
}

.table-responsive {
   overflow-x: auto;
   -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
   .table-responsive-sm {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
   }
}
@media (max-width: 767.98px) {
   .table-responsive-md {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
   }
}
@media (max-width: 991.98px) {
   .table-responsive-lg {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
   }
}
@media (max-width: 1199.98px) {
   .table-responsive-xl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
   }
}
@media (max-width: 1399.98px) {
   .table-responsive-xxl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
   }
}
.form-label {
   margin-bottom: 0.5rem;
   font-size: 0.875rem;
   font-weight: var(--tblr-font-weight-medium);
}

.col-form-label {
   padding-top: calc(0.4375rem + var(--tblr-border-width));
   padding-bottom: calc(0.4375rem + var(--tblr-border-width));
   margin-bottom: 0;
   font-size: inherit;
   font-weight: var(--tblr-font-weight-medium);
   line-height: 1.4285714286;
}

.col-form-label-lg {
   padding-top: calc(0.5rem + var(--tblr-border-width));
   padding-bottom: calc(0.5rem + var(--tblr-border-width));
   font-size: 1.25rem;
}

.col-form-label-sm {
   padding-top: calc(0.125rem + var(--tblr-border-width));
   padding-bottom: calc(0.125rem + var(--tblr-border-width));
   font-size: 0.75rem;
}

.form-text {
   margin-top: 0.25rem;
   font-size: 85.714285%;
   color: #616876;
}

.form-control {
   display: block;
   width: 100%;
   padding: 0.4375rem 0.75rem;
   font-family: var(--tblr-font-sans-serif);
   font-size: 0.875rem;
   font-weight: 400;
   line-height: 1.4285714286;
   color: inherit;
   background-color: var(--tblr-bg-forms);
   background-clip: padding-box;
   border: var(--tblr-border-width) solid var(--tblr-border-color);
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   border-radius: var(--tblr-border-radius);
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
   .form-control {
      transition: none;
   }
}
.form-control[type="file"] {
   overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]) {
   cursor: pointer;
}
.form-control:focus {
   color: inherit;
   background-color: var(--tblr-bg-forms);
   border-color: #90b5e2;
   outline: 0;
   box-shadow: 0 0 0 0.25rem rgba(32, 107, 196, 0.25);
}
.form-control::-webkit-date-and-time-value {
   height: 1.4285714286em;
}
.form-control::-webkit-datetime-edit {
   display: block;
   padding: 0;
}
.form-control::-webkit-input-placeholder {
   color: #a5a9b1;
   opacity: 1;
}
.form-control::-moz-placeholder {
   color: #a5a9b1;
   opacity: 1;
}
.form-control:-ms-input-placeholder {
   color: #a5a9b1;
   opacity: 1;
}
.form-control::-ms-input-placeholder {
   color: #a5a9b1;
   opacity: 1;
}
.form-control::placeholder {
   color: #a5a9b1;
   opacity: 1;
}
.form-control:disabled {
   background-color: var(--tblr-bg-surface-secondary);
   opacity: 1;
}
.form-control::-webkit-file-upload-button {
   padding: 0.4375rem 0.75rem;
   margin: -0.4375rem -0.75rem;
   -webkit-margin-end: 0.75rem;
   margin-inline-end: 0.75rem;
   color: inherit;
   background-color: var(--tblr-tertiary-bg);
   pointer-events: none;
   border-color: inherit;
   border-style: solid;
   border-width: 0;
   border-inline-end-width: var(--tblr-border-width);
   border-radius: 0;
   -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::file-selector-button {
   padding: 0.4375rem 0.75rem;
   margin: -0.4375rem -0.75rem;
   -webkit-margin-end: 0.75rem;
   margin-inline-end: 0.75rem;
   color: inherit;
   background-color: var(--tblr-tertiary-bg);
   pointer-events: none;
   border-color: inherit;
   border-style: solid;
   border-width: 0;
   border-inline-end-width: var(--tblr-border-width);
   border-radius: 0;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
   .form-control::-webkit-file-upload-button {
      -webkit-transition: none;
      transition: none;
   }
   .form-control::file-selector-button {
      transition: none;
   }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
   background-color: var(--tblr-secondary-bg);
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
   background-color: var(--tblr-secondary-bg);
}

.form-control-plaintext {
   display: block;
   width: 100%;
   padding: 0.4375rem 0;
   margin-bottom: 0;
   line-height: 1.4285714286;
   color: var(--tblr-body-color);
   background-color: transparent;
   border: solid transparent;
   border-width: var(--tblr-border-width) 0;
}
.form-control-plaintext:focus {
   outline: 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
   padding-right: 0;
   padding-left: 0;
}

.form-control-sm {
   min-height: calc(
      1.4285714286em + 0.25rem + calc(var(--tblr-border-width) * 2)
   );
   padding: 0.125rem 0.25rem;
   font-size: 0.75rem;
   border-radius: 2px;
}
.form-control-sm::-webkit-file-upload-button {
   padding: 0.125rem 0.25rem;
   margin: -0.125rem -0.25rem;
   -webkit-margin-end: 0.25rem;
   margin-inline-end: 0.25rem;
}
.form-control-sm::file-selector-button {
   padding: 0.125rem 0.25rem;
   margin: -0.125rem -0.25rem;
   -webkit-margin-end: 0.25rem;
   margin-inline-end: 0.25rem;
}

.form-control-lg {
   min-height: calc(1.4285714286em + 1rem + calc(var(--tblr-border-width) * 2));
   padding: 0.5rem 0.75rem;
   font-size: 1.25rem;
   border-radius: 8px;
}
.form-control-lg::-webkit-file-upload-button {
   padding: 0.5rem 0.75rem;
   margin: -0.5rem -0.75rem;
   -webkit-margin-end: 0.75rem;
   margin-inline-end: 0.75rem;
}
.form-control-lg::file-selector-button {
   padding: 0.5rem 0.75rem;
   margin: -0.5rem -0.75rem;
   -webkit-margin-end: 0.75rem;
   margin-inline-end: 0.75rem;
}

textarea.form-control {
   min-height: calc(
      1.4285714286em + 0.875rem + calc(var(--tblr-border-width) * 2)
   );
}
textarea.form-control-sm {
   min-height: calc(
      1.4285714286em + 0.25rem + calc(var(--tblr-border-width) * 2)
   );
}
textarea.form-control-lg {
   min-height: calc(1.4285714286em + 1rem + calc(var(--tblr-border-width) * 2));
}

.form-control-color {
   width: 3rem;
   height: calc(1.4285714286em + 0.875rem + calc(var(--tblr-border-width) * 2));
   padding: 0.4375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
   cursor: pointer;
}
.form-control-color::-moz-color-swatch {
   border: 0 !important;
   border-radius: var(--tblr-border-radius);
}
.form-control-color::-webkit-color-swatch {
   border-radius: var(--tblr-border-radius);
}
.form-control-color.form-control-sm {
   height: calc(1.4285714286em + 0.25rem + calc(var(--tblr-border-width) * 2));
}
.form-control-color.form-control-lg {
   height: calc(1.4285714286em + 1rem + calc(var(--tblr-border-width) * 2));
}

.form-select {
   --tblr-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23a5a9b1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
   display: block;
   width: 100%;
   padding: 0.4375rem 2.25rem 0.4375rem 0.75rem;
   -moz-padding-start: calc(0.75rem - 3px);
   font-family: var(--tblr-font-sans-serif);
   font-size: 0.875rem;
   font-weight: 400;
   line-height: 1.4285714286;
   color: inherit;
   background-color: var(--tblr-bg-forms);
   background-image: var(--tblr-form-select-bg-img),
      var(--tblr-form-select-bg-icon, none);
   background-repeat: no-repeat;
   background-position: right 0.75rem center;
   background-size: 16px 12px;
   border: var(--tblr-border-width) solid var(--tblr-border-color);
   border-radius: var(--tblr-border-radius);
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
}
@media (prefers-reduced-motion: reduce) {
   .form-select {
      transition: none;
   }
}
.form-select:focus {
   border-color: #90b5e2;
   outline: 0;
   box-shadow: 0 0 0 0.25rem rgba(32, 107, 196, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
   padding-right: 0.75rem;
   background-image: none;
}
.form-select:disabled {
   background-color: var(--tblr-bg-surface-secondary);
}
.form-select:-moz-focusring {
   color: transparent;
   text-shadow: 0 0 0 inherit;
}

.form-select-sm {
   padding-top: 0.125rem;
   padding-bottom: 0.125rem;
   padding-left: 0.25rem;
   font-size: 0.75rem;
   border-radius: 2px;
}

.form-select-lg {
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
   padding-left: 0.75rem;
   font-size: 1.25rem;
   border-radius: 8px;
}

[data-bs-theme="dark"] .form-select {
   --tblr-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236c7a91' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
   display: block;
   min-height: 1.25rem;
   padding-left: 1.5rem;
   margin-bottom: 0.5rem;
}
.form-check .form-check-input {
   float: left;
   margin-left: -1.5rem;
}

.form-check-reverse {
   padding-right: 1.5rem;
   padding-left: 0;
   text-align: right;
}
.form-check-reverse .form-check-input {
   float: right;
   margin-right: -1.5rem;
   margin-left: 0;
}

.form-check-input {
   --tblr-form-check-bg: var(--tblr-bg-forms);
   width: 1rem;
   height: 1rem;
   margin-top: 0.2142857143rem;
   vertical-align: top;
   background-color: var(--tblr-form-check-bg);
   background-image: var(--tblr-form-check-bg-image);
   background-repeat: no-repeat;
   background-position: center;
   background-size: contain;
   border: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color-translucent);
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   -webkit-print-color-adjust: exact;
   color-adjust: exact;
   print-color-adjust: exact;
}
.form-check-input[type="checkbox"] {
   border-radius: var(--tblr-border-radius);
}
.form-check-input[type="radio"] {
   border-radius: 50%;
}
.form-check-input:active {
   filter: brightness(90%);
}
.form-check-input:focus {
   border-color: #90b5e2;
   outline: 0;
   box-shadow: 0 0 0 0.25rem rgba(32, 107, 196, 0.25);
}
.form-check-input:checked {
   background-color: var(--tblr-primary);
   border-color: var(--tblr-border-color-translucent);
}
.form-check-input:checked[type="checkbox"] {
   --tblr-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8.5l2.5 2.5l5.5 -5.5'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
   --tblr-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3ccircle r='3' fill='%23ffffff' cx='8' cy='8' /%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
   background-color: #206bc4;
   border-color: #206bc4;
   --tblr-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
   pointer-events: none;
   filter: none;
   opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
   cursor: default;
   opacity: 0.7;
}

.form-switch {
   padding-left: 2.5rem;
}
.form-switch .form-check-input {
   --tblr-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23e6e7e9'/%3e%3c/svg%3e");
   width: 2rem;
   margin-left: -2.5rem;
   background-image: var(--tblr-form-switch-bg);
   background-position: left center;
   border-radius: 2rem;
   transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
   .form-switch .form-check-input {
      transition: none;
   }
}
.form-switch .form-check-input:focus {
   --tblr-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2390b5e2'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
   background-position: right center;
   --tblr-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
   padding-right: 2.5rem;
   padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
   margin-right: -2.5rem;
   margin-left: 0;
}

.form-check-inline {
   display: inline-block;
   margin-right: 1rem;
}

.btn-check {
   position: absolute;
   clip: rect(0, 0, 0, 0);
   pointer-events: none;
}
.btn-check[disabled] + .btn,
.btn-check:disabled + .btn {
   pointer-events: none;
   filter: none;
   opacity: 0.4;
}

[data-bs-theme="dark"]
   .form-switch
   .form-check-input:not(:checked):not(:focus) {
   --tblr-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
   width: 100%;
   height: 1.25rem;
   padding: 0;
   background-color: transparent;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
}
.form-range:focus {
   outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
   box-shadow: 0 0 0 1px #f1f5f9, 0 0 0 0.25rem rgba(32, 107, 196, 0.25);
}
.form-range:focus::-moz-range-thumb {
   box-shadow: 0 0 0 1px #f1f5f9, 0 0 0 0.25rem rgba(32, 107, 196, 0.25);
}
.form-range::-moz-focus-outer {
   border: 0;
}
.form-range::-webkit-slider-thumb {
   width: 1rem;
   height: 1rem;
   margin-top: -0.375rem;
   background-color: var(--tblr-primary);
   border: 2px var(--tblr-border-style) #ffffff;
   border-radius: 1rem;
   -webkit-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   -webkit-appearance: none;
   appearance: none;
}
@media (prefers-reduced-motion: reduce) {
   .form-range::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none;
   }
}
.form-range::-webkit-slider-thumb:active {
   background-color: #bcd3ed;
}
.form-range::-webkit-slider-runnable-track {
   width: 100%;
   height: 0.25rem;
   color: transparent;
   cursor: pointer;
   background-color: var(--tblr-border-color);
   border-color: transparent;
   border-radius: 1rem;
}
.form-range::-moz-range-thumb {
   width: 1rem;
   height: 1rem;
   background-color: var(--tblr-primary);
   border: 2px var(--tblr-border-style) #ffffff;
   border-radius: 1rem;
   -moz-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   -moz-appearance: none;
   appearance: none;
}
@media (prefers-reduced-motion: reduce) {
   .form-range::-moz-range-thumb {
      -moz-transition: none;
      transition: none;
   }
}
.form-range::-moz-range-thumb:active {
   background-color: #bcd3ed;
}
.form-range::-moz-range-track {
   width: 100%;
   height: 0.25rem;
   color: transparent;
   cursor: pointer;
   background-color: var(--tblr-border-color);
   border-color: transparent;
   border-radius: 1rem;
}
.form-range:disabled {
   pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
   background-color: var(--tblr-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
   background-color: var(--tblr-secondary-color);
}

.form-floating {
   position: relative;
}
.form-floating::before:not(.form-control:disabled) {
   position: absolute;
   top: var(--tblr-border-width);
   left: var(--tblr-border-width);
   width: calc(
      100% -
         (
            calc(
               calc(0.3571428572em + 0.21875rem) +
                  calc(0.7142857143em + 0.4375rem)
            )
         )
   );
   height: 1.875em;
   content: "";
   background-color: var(--tblr-bg-forms);
   border-radius: var(--tblr-border-radius);
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
   height: calc(3.5rem + calc(var(--tblr-border-width) * 2));
   line-height: 1.25;
}
.form-floating > label {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   padding: 1rem 0.75rem;
   overflow: hidden;
   text-align: start;
   text-overflow: ellipsis;
   white-space: nowrap;
   pointer-events: none;
   border: var(--tblr-border-width) solid transparent;
   transform-origin: 0 0;
   transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
   .form-floating > label {
      transition: none;
   }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
   padding: 1rem 0.75rem;
}
.form-floating > .form-control::-webkit-input-placeholder,
.form-floating > .form-control-plaintext::-webkit-input-placeholder {
   color: transparent;
}
.form-floating > .form-control::-moz-placeholder,
.form-floating > .form-control-plaintext::-moz-placeholder {
   color: transparent;
}
.form-floating > .form-control:-ms-input-placeholder,
.form-floating > .form-control-plaintext:-ms-input-placeholder {
   color: transparent;
}
.form-floating > .form-control::-ms-input-placeholder,
.form-floating > .form-control-plaintext::-ms-input-placeholder {
   color: transparent;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
   color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown),
.form-floating > .form-control-plaintext:not(:-moz-placeholder-shown) {
   padding-top: 1.625rem;
   padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-ms-input-placeholder),
.form-floating > .form-control-plaintext:not(:-ms-input-placeholder) {
   padding-top: 1.625rem;
   padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
   padding-top: 1.625rem;
   padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
   padding-top: 1.625rem;
   padding-bottom: 0.625rem;
}
.form-floating > .form-select {
   padding-top: 1.625rem;
   padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
   opacity: 0.65;
   transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
   opacity: 0.65;
   transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
   opacity: 0.65;
   transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
   opacity: 0.65;
   transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
   border-width: var(--tblr-border-width) 0;
}
.form-floating > .form-control:disabled ~ label {
   color: #49566c;
}

.input-group {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   align-items: stretch;
   width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
   position: relative;
   flex: 1 1 auto;
   width: 1%;
   min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
   z-index: 5;
}
.input-group .btn {
   position: relative;
   z-index: 2;
}
.input-group .btn:focus {
   z-index: 5;
}

.input-group-text {
   display: flex;
   align-items: center;
   padding: 0.4375rem 0.75rem;
   font-size: 0.875rem;
   font-weight: 400;
   line-height: 1.4285714286;
   color: var(--tblr-muted);
   text-align: center;
   white-space: nowrap;
   background-color: #f8fafc;
   border: var(--tblr-border-width) solid var(--tblr-border-color);
   border-radius: var(--tblr-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
   padding: 0.5rem 0.75rem;
   font-size: 1.25rem;
   border-radius: 8px;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
   padding: 0.125rem 0.25rem;
   font-size: 0.75rem;
   border-radius: 2px;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
   padding-right: 3rem;
}

.input-group:not(.has-validation)
   > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
      .form-floating
   ),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
   > .form-floating:not(:last-child)
   > .form-control,
.input-group:not(.has-validation)
   > .form-floating:not(:last-child)
   > .form-select {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
}
.input-group.has-validation
   > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(
      .form-floating
   ),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
   > .form-floating:nth-last-child(n + 3)
   > .form-control,
.input-group.has-validation
   > .form-floating:nth-last-child(n + 3)
   > .form-select {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
}
.input-group
   > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
      .valid-feedback
   ):not(.invalid-tooltip):not(.invalid-feedback) {
   margin-left: calc(var(--tblr-border-width) * -1);
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
}

.valid-feedback {
   display: none;
   width: 100%;
   margin-top: 0.25rem;
   font-size: 85.714285%;
   color: var(--tblr-success-text);
}

.valid-tooltip {
   position: absolute;
   top: 100%;
   z-index: 5;
   display: none;
   max-width: 100%;
   padding: 0.25rem 0.5rem;
   margin-top: 0.1rem;
   font-size: 0.765625rem;
   color: #fff;
   background-color: var(--tblr-success);
   border-radius: var(--tblr-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
   display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
   border-color: var(--tblr-success);
   padding-right: calc(1.4285714286em + 0.875rem);
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%232fb344' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='20 6 9 17 4 12'%3e%3c/polyline%3e%3c/svg%3e");
   background-repeat: no-repeat;
   background-position: right calc(0.3571428572em + 0.21875rem) center;
   background-size: calc(0.7142857143em + 0.4375rem)
      calc(0.7142857143em + 0.4375rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
   border-color: var(--tblr-success);
   box-shadow: 0 0 0 0.25rem rgba(var(--tblr-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
   padding-right: calc(1.4285714286em + 0.875rem);
   background-position: top calc(0.3571428572em + 0.21875rem) right
      calc(0.3571428572em + 0.21875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
   border-color: var(--tblr-success);
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
   --tblr-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%232fb344' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='20 6 9 17 4 12'%3e%3c/polyline%3e%3c/svg%3e");
   padding-right: 4.125rem;
   background-position: right 0.75rem center, center right 2.25rem;
   background-size: 16px 12px,
      calc(0.7142857143em + 0.4375rem) calc(0.7142857143em + 0.4375rem);
}
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
   border-color: var(--tblr-success);
   box-shadow: 0 0 0 0.25rem rgba(var(--tblr-success-rgb), 0.25);
}

.was-validated .form-control-color:valid,
.form-control-color.is-valid {
   width: calc(3rem + calc(1.4285714286em + 0.875rem));
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
   border-color: var(--tblr-success);
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
   background-color: var(--tblr-success-text);
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
   box-shadow: 0 0 0 0.25rem rgba(var(--tblr-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
   color: var(--tblr-success-text);
}

.form-check-inline .form-check-input ~ .valid-feedback {
   margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid,
.input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
   z-index: 3;
}

.invalid-feedback {
   display: none;
   width: 100%;
   margin-top: 0.25rem;
   font-size: 85.714285%;
   color: var(--tblr-danger-text);
}

.invalid-tooltip {
   position: absolute;
   top: 100%;
   z-index: 5;
   display: none;
   max-width: 100%;
   padding: 0.25rem 0.5rem;
   margin-top: 0.1rem;
   font-size: 0.765625rem;
   color: #fff;
   background-color: var(--tblr-danger);
   border-radius: var(--tblr-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
   display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
   border-color: var(--tblr-danger);
   padding-right: calc(1.4285714286em + 0.875rem);
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23d63939' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e");
   background-repeat: no-repeat;
   background-position: right calc(0.3571428572em + 0.21875rem) center;
   background-size: calc(0.7142857143em + 0.4375rem)
      calc(0.7142857143em + 0.4375rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
   border-color: var(--tblr-danger);
   box-shadow: 0 0 0 0.25rem rgba(var(--tblr-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
   padding-right: calc(1.4285714286em + 0.875rem);
   background-position: top calc(0.3571428572em + 0.21875rem) right
      calc(0.3571428572em + 0.21875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
   border-color: var(--tblr-danger);
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
   --tblr-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23d63939' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e");
   padding-right: 4.125rem;
   background-position: right 0.75rem center, center right 2.25rem;
   background-size: 16px 12px,
      calc(0.7142857143em + 0.4375rem) calc(0.7142857143em + 0.4375rem);
}
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
   border-color: var(--tblr-danger);
   box-shadow: 0 0 0 0.25rem rgba(var(--tblr-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
   width: calc(3rem + calc(1.4285714286em + 0.875rem));
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
   border-color: var(--tblr-danger);
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
   background-color: var(--tblr-danger-text);
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
   box-shadow: 0 0 0 0.25rem rgba(var(--tblr-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
   color: var(--tblr-danger-text);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
   margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid,
.input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
   z-index: 4;
}

.btn {
   --tblr-btn-padding-x: 1rem;
   --tblr-btn-padding-y: 0.4375rem;
   --tblr-btn-font-family: var(--tblr-font-sans-serif);
   --tblr-btn-font-size: 0.875rem;
   --tblr-btn-font-weight: var(--tblr-font-weight-medium);
   --tblr-btn-line-height: 1.4285714286;
   --tblr-btn-color: #1d273b;
   --tblr-btn-bg: transparent;
   --tblr-btn-border-width: var(--tblr-border-width);
   --tblr-btn-border-color: transparent;
   --tblr-btn-border-radius: var(--tblr-border-radius);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
      0 1px 1px rgba(0, 0, 0, 0.075);
   --tblr-btn-disabled-opacity: 0.4;
   --tblr-btn-focus-box-shadow: 0 0 0 0.25rem
      rgba(var(--tblr-btn-focus-shadow-rgb), 0.5);
   display: inline-block;
   padding: var(--tblr-btn-padding-y) var(--tblr-btn-padding-x);
   font-family: var(--tblr-btn-font-family);
   font-size: var(--tblr-btn-font-size);
   font-weight: var(--tblr-btn-font-weight);
   line-height: var(--tblr-btn-line-height);
   color: var(--tblr-btn-color);
   text-align: center;
   vertical-align: middle;
   cursor: pointer;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   border: var(--tblr-btn-border-width) solid var(--tblr-btn-border-color);
   border-radius: var(--tblr-btn-border-radius);
   background-color: var(--tblr-btn-bg);
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
   .btn {
      transition: none;
   }
}
.btn:hover {
   color: var(--tblr-btn-hover-color);
   text-decoration: none;
   background-color: var(--tblr-btn-hover-bg);
   border-color: var(--tblr-btn-hover-border-color);
}
.btn-check + .btn:hover {
   color: var(--tblr-btn-color);
   background-color: var(--tblr-btn-bg);
   border-color: var(--tblr-btn-border-color);
}
.btn:focus-visible {
   color: var(--tblr-btn-hover-color);
   background-color: var(--tblr-btn-hover-bg);
   border-color: var(--tblr-btn-hover-border-color);
   outline: 0;
   box-shadow: var(--tblr-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
   border-color: var(--tblr-btn-hover-border-color);
   outline: 0;
   box-shadow: var(--tblr-btn-focus-box-shadow);
}
.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
   color: var(--tblr-btn-active-color);
   background-color: var(--tblr-btn-active-bg);
   border-color: var(--tblr-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible,
:not(.btn-check) + .btn:active:focus-visible,
.btn:first-child:active:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible {
   box-shadow: var(--tblr-btn-focus-box-shadow);
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
   color: var(--tblr-btn-disabled-color);
   pointer-events: none;
   background-color: var(--tblr-btn-disabled-bg);
   border-color: var(--tblr-btn-disabled-border-color);
   opacity: var(--tblr-btn-disabled-opacity);
}

.btn-link {
   --tblr-btn-font-weight: 400;
   --tblr-btn-color: var(--tblr-link-color);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-link-hover-color);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-color: var(--tblr-link-hover-color);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-disabled-color: #49566c;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-btn-box-shadow: none;
   --tblr-btn-focus-shadow-rgb: 64, 128, 204;
   text-decoration: none;
}
.btn-link:hover,
.btn-link:focus-visible {
   text-decoration: underline;
}
.btn-link:focus-visible {
   color: var(--tblr-btn-color);
}
.btn-link:hover {
   color: var(--tblr-btn-hover-color);
}

.btn-lg,
.btn-group-lg > .btn {
   --tblr-btn-padding-y: 0.5rem;
   --tblr-btn-padding-x: 0.75rem;
   --tblr-btn-font-size: 1.25rem;
   --tblr-btn-border-radius: 8px;
}

.btn-sm,
.btn-group-sm > .btn {
   --tblr-btn-padding-y: 0.125rem;
   --tblr-btn-padding-x: 0.25rem;
   --tblr-btn-font-size: 0.75rem;
   --tblr-btn-border-radius: 2px;
}

.fade {
   transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
   .fade {
      transition: none;
   }
}

.fade:not(.show) {
   opacity: 0;
}

.collapse:not(.show) {
   display: none;
}

.collapsing {
   height: 0;
   overflow: hidden;
   transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
   .collapsing {
      transition: none;
   }
}
.collapsing.collapse-horizontal {
   width: 0;
   height: auto;
   transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
   .collapsing.collapse-horizontal {
      transition: none;
   }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
   position: relative;
}

.dropdown-toggle {
   white-space: nowrap;
}
.dropdown-toggle:after {
   content: "";
   display: inline-block;
   vertical-align: 0.306em;
   width: 0.36em;
   height: 0.36em;
   border-bottom: 1px var(--tblr-border-style);
   border-left: 1px var(--tblr-border-style);
   margin-right: 0.1em;
   margin-left: 0.4em;
   transform: rotate(-45deg);
}

.dropdown-menu {
   --tblr-dropdown-zindex: 1000;
   --tblr-dropdown-min-width: 11rem;
   --tblr-dropdown-padding-x: 0;
   --tblr-dropdown-padding-y: 0.25rem;
   --tblr-dropdown-spacer: 1px;
   --tblr-dropdown-font-size: 0.875rem;
   --tblr-dropdown-color: var(--tblr-body-color);
   --tblr-dropdown-bg: var(--tblr-bg-surface);
   --tblr-dropdown-border-color: var(--tblr-border-color-translucent);
   --tblr-dropdown-border-radius: 4px;
   --tblr-dropdown-border-width: var(--tblr-border-width);
   --tblr-dropdown-inner-border-radius: calc(4px - var(--tblr-border-width));
   --tblr-dropdown-divider-bg: var(--tblr-border-color-translucent);
   --tblr-dropdown-divider-margin-y: 0.5rem;
   --tblr-dropdown-box-shadow: 0 0.5rem 1rem
      rgba(var(--tblr-body-color-rgb), 0.15);
   --tblr-dropdown-link-color: inherit;
   --tblr-dropdown-link-hover-color: inherit;
   --tblr-dropdown-link-hover-bg: rgba(var(--tblr-muted-rgb), 0.04);
   --tblr-dropdown-link-active-color: var(--tblr-primary);
   --tblr-dropdown-link-active-bg: var(--tblr-active-bg);
   --tblr-dropdown-link-disabled-color: #6c7a91;
   --tblr-dropdown-item-padding-x: 0.75rem;
   --tblr-dropdown-item-padding-y: 0.5rem;
   --tblr-dropdown-header-color: #49566c;
   --tblr-dropdown-header-padding-x: 0.75rem;
   --tblr-dropdown-header-padding-y: 0.25rem;
   position: absolute;
   z-index: var(--tblr-dropdown-zindex);
   display: none;
   min-width: var(--tblr-dropdown-min-width);
   padding: var(--tblr-dropdown-padding-y) var(--tblr-dropdown-padding-x);
   margin: 0;
   font-size: var(--tblr-dropdown-font-size);
   color: var(--tblr-dropdown-color);
   text-align: left;
   list-style: none;
   background-color: var(--tblr-dropdown-bg);
   background-clip: padding-box;
   border: var(--tblr-dropdown-border-width) solid
      var(--tblr-dropdown-border-color);
   border-radius: var(--tblr-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
   top: 100%;
   left: 0;
   margin-top: var(--tblr-dropdown-spacer);
}

.dropdown-menu-start {
   --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
   right: auto;
   left: 0;
}

.dropdown-menu-end {
   --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
   right: 0;
   left: auto;
}

@media (min-width: 576px) {
   .dropdown-menu-sm-start {
      --bs-position: start;
   }
   .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0;
   }
   .dropdown-menu-sm-end {
      --bs-position: end;
   }
   .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto;
   }
}
@media (min-width: 768px) {
   .dropdown-menu-md-start {
      --bs-position: start;
   }
   .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0;
   }
   .dropdown-menu-md-end {
      --bs-position: end;
   }
   .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto;
   }
}
@media (min-width: 992px) {
   .dropdown-menu-lg-start {
      --bs-position: start;
   }
   .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0;
   }
   .dropdown-menu-lg-end {
      --bs-position: end;
   }
   .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto;
   }
}
@media (min-width: 1200px) {
   .dropdown-menu-xl-start {
      --bs-position: start;
   }
   .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0;
   }
   .dropdown-menu-xl-end {
      --bs-position: end;
   }
   .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto;
   }
}
@media (min-width: 1400px) {
   .dropdown-menu-xxl-start {
      --bs-position: start;
   }
   .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0;
   }
   .dropdown-menu-xxl-end {
      --bs-position: end;
   }
   .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto;
   }
}
.dropup .dropdown-menu[data-bs-popper] {
   top: auto;
   bottom: 100%;
   margin-top: 0;
   margin-bottom: var(--tblr-dropdown-spacer);
}
.dropup .dropdown-toggle:after {
   content: "";
   display: inline-block;
   vertical-align: 0.306em;
   width: 0.36em;
   height: 0.36em;
   border-bottom: 1px var(--tblr-border-style);
   border-left: 1px var(--tblr-border-style);
   margin-right: 0.1em;
   margin-left: 0.4em;
   transform: rotate(135deg);
}

.dropend .dropdown-menu[data-bs-popper] {
   top: 0;
   right: auto;
   left: 100%;
   margin-top: 0;
   margin-left: var(--tblr-dropdown-spacer);
}
.dropend .dropdown-toggle:after {
   content: "";
   display: inline-block;
   vertical-align: 0.306em;
   width: 0.36em;
   height: 0.36em;
   border-bottom: 1px var(--tblr-border-style);
   border-left: 1px var(--tblr-border-style);
   margin-right: 0.1em;
   margin-left: 0.4em;
   transform: rotate(-135deg);
}
.dropend .dropdown-toggle::after {
   vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
   top: 0;
   right: 100%;
   left: auto;
   margin-top: 0;
   margin-right: var(--tblr-dropdown-spacer);
}
.dropstart .dropdown-toggle:after {
   content: "";
   display: inline-block;
   vertical-align: 0.306em;
   width: 0.36em;
   height: 0.36em;
   border-bottom: 1px var(--tblr-border-style);
   border-left: 1px var(--tblr-border-style);
   margin-right: 0.1em;
   margin-left: 0.4em;
   transform: rotate(45deg);
}
.dropstart .dropdown-toggle::before {
   vertical-align: 0;
}

.dropdown-divider {
   height: 0;
   margin: var(--tblr-dropdown-divider-margin-y) 0;
   overflow: hidden;
   border-top: 1px solid var(--tblr-dropdown-divider-bg);
   opacity: 1;
}

.dropdown-item {
   display: block;
   width: 100%;
   padding: var(--tblr-dropdown-item-padding-y)
      var(--tblr-dropdown-item-padding-x);
   clear: both;
   font-weight: 400;
   color: var(--tblr-dropdown-link-color);
   text-align: inherit;
   white-space: nowrap;
   background-color: transparent;
   border: 0;
   border-radius: var(--tblr-dropdown-item-border-radius, 0);
}
.dropdown-item:hover,
.dropdown-item:focus {
   color: var(--tblr-dropdown-link-hover-color);
   text-decoration: none;
   background-color: var(--tblr-dropdown-link-hover-bg);
}
.dropdown-item.active,
.dropdown-item:active {
   color: var(--tblr-dropdown-link-active-color);
   text-decoration: none;
   background-color: var(--tblr-dropdown-link-active-bg);
}
.dropdown-item.disabled,
.dropdown-item:disabled {
   color: var(--tblr-dropdown-link-disabled-color);
   pointer-events: none;
   background-color: transparent;
}

.dropdown-menu.show {
   display: block;
}

.dropdown-header {
   display: block;
   padding: var(--tblr-dropdown-header-padding-y)
      var(--tblr-dropdown-header-padding-x);
   margin-bottom: 0;
   font-size: 0.765625rem;
   color: var(--tblr-dropdown-header-color);
   white-space: nowrap;
}

.dropdown-item-text {
   display: block;
   padding: var(--tblr-dropdown-item-padding-y)
      var(--tblr-dropdown-item-padding-x);
   color: var(--tblr-dropdown-link-color);
}

.dropdown-menu-dark {
   --tblr-dropdown-color: #c8d3e1;
   --tblr-dropdown-bg: #1d273b;
   --tblr-dropdown-border-color: var(--tblr-border-color-translucent);
   --tblr-dropdown-box-shadow: ;
   --tblr-dropdown-link-color: #c8d3e1;
   --tblr-dropdown-link-hover-color: #ffffff;
   --tblr-dropdown-divider-bg: var(--tblr-border-color-translucent);
   --tblr-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
   --tblr-dropdown-link-active-color: var(--tblr-primary);
   --tblr-dropdown-link-active-bg: var(--tblr-active-bg);
   --tblr-dropdown-link-disabled-color: #6c7a91;
   --tblr-dropdown-header-color: #6c7a91;
}

.btn-group,
.btn-group-vertical {
   position: relative;
   display: inline-flex;
   vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
   position: relative;
   flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
   z-index: 1;
}

.btn-toolbar {
   display: flex;
   flex-wrap: wrap;
   justify-content: flex-start;
}
.btn-toolbar .input-group {
   width: auto;
}

.btn-group {
   border-radius: var(--tblr-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
   margin-left: calc(var(--tblr-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
   padding-right: 0.75rem;
   padding-left: 0.75rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
   margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
   margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
   padding-right: 0.1875rem;
   padding-left: 0.1875rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
   padding-right: 0.5625rem;
   padding-left: 0.5625rem;
}

.btn-group-vertical {
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
   width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
   margin-top: calc(var(--tblr-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
   border-bottom-right-radius: 0;
   border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
   border-top-left-radius: 0;
   border-top-right-radius: 0;
}

.nav {
   --tblr-nav-link-padding-x: 0.75rem;
   --tblr-nav-link-padding-y: 0.5rem;
   --tblr-nav-link-font-weight: ;
   --tblr-nav-link-color: var(--tblr-muted);
   --tblr-nav-link-hover-color: var(--tblr-link-hover-color);
   --tblr-nav-link-disabled-color: var(--tblr-disabled-color);
   display: flex;
   flex-wrap: wrap;
   padding-left: 0;
   margin-bottom: 0;
   list-style: none;
}

.nav-link {
   display: block;
   padding: var(--tblr-nav-link-padding-y) var(--tblr-nav-link-padding-x);
   font-size: var(--tblr-nav-link-font-size);
   font-weight: var(--tblr-nav-link-font-weight);
   color: var(--tblr-nav-link-color);
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
   .nav-link {
      transition: none;
   }
}
.nav-link:hover,
.nav-link:focus {
   color: var(--tblr-nav-link-hover-color);
   text-decoration: none;
}
.nav-link.disabled {
   color: var(--tblr-nav-link-disabled-color);
   pointer-events: none;
   cursor: default;
}

.nav-tabs {
   --tblr-nav-tabs-border-width: var(--tblr-border-width);
   --tblr-nav-tabs-border-color: var(--tblr-border-color);
   --tblr-nav-tabs-border-radius: var(--tblr-border-radius);
   --tblr-nav-tabs-link-hover-border-color: var(--tblr-border-color)
      var(--tblr-border-color) var(--tblr-border-color);
   --tblr-nav-tabs-link-active-color: var(--tblr-body-color);
   --tblr-nav-tabs-link-active-bg: var(--tblr-body-bg);
   --tblr-nav-tabs-link-active-border-color: var(--tblr-border-color)
      var(--tblr-border-color) var(--tblr-border-color);
   border-bottom: var(--tblr-nav-tabs-border-width) solid
      var(--tblr-nav-tabs-border-color);
}
.nav-tabs .nav-link {
   margin-bottom: calc(-1 * var(--tblr-nav-tabs-border-width));
   background: none;
   border: var(--tblr-nav-tabs-border-width) solid transparent;
   border-top-left-radius: var(--tblr-nav-tabs-border-radius);
   border-top-right-radius: var(--tblr-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
   isolation: isolate;
   border-color: var(--tblr-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
   color: var(--tblr-nav-link-disabled-color);
   background-color: transparent;
   border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
   color: var(--tblr-nav-tabs-link-active-color);
   background-color: var(--tblr-nav-tabs-link-active-bg);
   border-color: var(--tblr-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
   margin-top: calc(-1 * var(--tblr-nav-tabs-border-width));
   border-top-left-radius: 0;
   border-top-right-radius: 0;
}

.nav-pills {
   --tblr-nav-pills-border-radius: 4px;
   --tblr-nav-pills-link-active-color: var(--tblr-primary);
   --tblr-nav-pills-link-active-bg: var(--tblr-active-bg);
}
.nav-pills .nav-link {
   background: none;
   border: 0;
   border-radius: var(--tblr-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
   color: var(--tblr-nav-link-disabled-color);
   background-color: transparent;
   border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
   color: var(--tblr-nav-pills-link-active-color);
   background-color: var(--tblr-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
   flex: 1 1 auto;
   text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
   flex-basis: 0;
   flex-grow: 1;
   text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
   width: 100%;
}

.tab-content > .tab-pane {
   display: none;
}
.tab-content > .active {
   display: block;
}

.navbar {
   --tblr-navbar-padding-x: 0;
   --tblr-navbar-padding-y: 0.25rem;
   --tblr-navbar-color: var(--tblr-body-color);
   --tblr-navbar-hover-color: rgba(var(--tblr-emphasis-color-rgb), 0.8);
   --tblr-navbar-disabled-color: var(--tblr-disabled-color);
   --tblr-navbar-active-color: var(--tblr-body-color) color;
   --tblr-navbar-brand-padding-y: 0.5rem;
   --tblr-navbar-brand-margin-end: 1rem;
   --tblr-navbar-brand-font-size: 1rem;
   --tblr-navbar-brand-color: var(--tblr-body-color);
   --tblr-navbar-brand-hover-color: var(--tblr-body-color) color;
   --tblr-navbar-nav-link-padding-x: 0.75rem;
   --tblr-navbar-toggler-padding-y: 0;
   --tblr-navbar-toggler-padding-x: 0;
   --tblr-navbar-toggler-font-size: 1rem;
   --tblr-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2829, 39, 59, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
   --tblr-navbar-toggler-border-color: rgba(
      var(--tblr-emphasis-color-rgb),
      0.15
   );
   --tblr-navbar-toggler-border-radius: var(--tblr-border-radius);
   --tblr-navbar-toggler-focus-width: 0;
   --tblr-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
   position: relative;
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: space-between;
   padding: var(--tblr-navbar-padding-y) var(--tblr-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
   display: flex;
   flex-wrap: inherit;
   align-items: center;
   justify-content: space-between;
}
.navbar-brand {
   padding-top: var(--tblr-navbar-brand-padding-y);
   padding-bottom: var(--tblr-navbar-brand-padding-y);
   margin-right: var(--tblr-navbar-brand-margin-end);
   font-size: var(--tblr-navbar-brand-font-size);
   color: var(--tblr-navbar-brand-color);
   white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
   color: var(--tblr-navbar-brand-hover-color);
   text-decoration: none;
}

.navbar-nav {
   --tblr-nav-link-padding-x: 0;
   --tblr-nav-link-padding-y: 0.5rem;
   --tblr-nav-link-font-weight: ;
   --tblr-nav-link-color: var(--tblr-navbar-color);
   --tblr-nav-link-hover-color: var(--tblr-navbar-hover-color);
   --tblr-nav-link-disabled-color: var(--tblr-navbar-disabled-color);
   display: flex;
   flex-direction: column;
   padding-left: 0;
   margin-bottom: 0;
   list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
   color: var(--tblr-navbar-active-color);
}
.navbar-nav .dropdown-menu {
   position: static;
}

.navbar-text {
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
   color: var(--tblr-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
   color: var(--tblr-navbar-active-color);
}

.navbar-collapse {
   flex-basis: 100%;
   flex-grow: 1;
   align-items: center;
}

.navbar-toggler {
   padding: var(--tblr-navbar-toggler-padding-y)
      var(--tblr-navbar-toggler-padding-x);
   font-size: var(--tblr-navbar-toggler-font-size);
   line-height: 1;
   color: var(--tblr-navbar-color);
   background-color: transparent;
   border: var(--tblr-border-width) solid
      var(--tblr-navbar-toggler-border-color);
   border-radius: var(--tblr-navbar-toggler-border-radius);
   transition: var(--tblr-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
   .navbar-toggler {
      transition: none;
   }
}
.navbar-toggler:hover {
   text-decoration: none;
}
.navbar-toggler:focus {
   text-decoration: none;
   outline: 0;
   box-shadow: 0 0 0 var(--tblr-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
   display: inline-block;
   width: 1.5em;
   height: 1.5em;
   vertical-align: middle;
   background-image: var(--tblr-navbar-toggler-icon-bg);
   background-repeat: no-repeat;
   background-position: center;
   background-size: 100%;
}

.navbar-nav-scroll {
   max-height: var(--tblr-scroll-height, 75vh);
   overflow-y: auto;
}

@media (min-width: 576px) {
   .navbar-expand-sm {
      flex-wrap: nowrap;
      justify-content: flex-start;
   }
   .navbar-expand-sm .navbar-nav {
      flex-direction: row;
   }
   .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
   }
   .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: var(--tblr-navbar-nav-link-padding-x);
      padding-left: var(--tblr-navbar-nav-link-padding-x);
   }
   .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible;
   }
   .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
   }
   .navbar-expand-sm .navbar-toggler {
      display: none;
   }
   .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none;
   }
   .navbar-expand-sm .offcanvas .offcanvas-header {
      display: none;
   }
   .navbar-expand-sm .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
   }
}
@media (min-width: 768px) {
   .navbar-expand-md {
      flex-wrap: nowrap;
      justify-content: flex-start;
   }
   .navbar-expand-md .navbar-nav {
      flex-direction: row;
   }
   .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
   }
   .navbar-expand-md .navbar-nav .nav-link {
      padding-right: var(--tblr-navbar-nav-link-padding-x);
      padding-left: var(--tblr-navbar-nav-link-padding-x);
   }
   .navbar-expand-md .navbar-nav-scroll {
      overflow: visible;
   }
   .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
   }
   .navbar-expand-md .navbar-toggler {
      display: none;
   }
   .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none;
   }
   .navbar-expand-md .offcanvas .offcanvas-header {
      display: none;
   }
   .navbar-expand-md .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
   }
}
@media (min-width: 992px) {
   .navbar-expand-lg {
      flex-wrap: nowrap;
      justify-content: flex-start;
   }
   .navbar-expand-lg .navbar-nav {
      flex-direction: row;
   }
   .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
   }
   .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: var(--tblr-navbar-nav-link-padding-x);
      padding-left: var(--tblr-navbar-nav-link-padding-x);
   }
   .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible;
   }
   .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
   }
   .navbar-expand-lg .navbar-toggler {
      display: none;
   }
   .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none;
   }
   .navbar-expand-lg .offcanvas .offcanvas-header {
      display: none;
   }
   .navbar-expand-lg .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
   }
}
@media (min-width: 1200px) {
   .navbar-expand-xl {
      flex-wrap: nowrap;
      justify-content: flex-start;
   }
   .navbar-expand-xl .navbar-nav {
      flex-direction: row;
   }
   .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
   }
   .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: var(--tblr-navbar-nav-link-padding-x);
      padding-left: var(--tblr-navbar-nav-link-padding-x);
   }
   .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible;
   }
   .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
   }
   .navbar-expand-xl .navbar-toggler {
      display: none;
   }
   .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none;
   }
   .navbar-expand-xl .offcanvas .offcanvas-header {
      display: none;
   }
   .navbar-expand-xl .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
   }
}
@media (min-width: 1400px) {
   .navbar-expand-xxl {
      flex-wrap: nowrap;
      justify-content: flex-start;
   }
   .navbar-expand-xxl .navbar-nav {
      flex-direction: row;
   }
   .navbar-expand-xxl .navbar-nav .dropdown-menu {
      position: absolute;
   }
   .navbar-expand-xxl .navbar-nav .nav-link {
      padding-right: var(--tblr-navbar-nav-link-padding-x);
      padding-left: var(--tblr-navbar-nav-link-padding-x);
   }
   .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible;
   }
   .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
   }
   .navbar-expand-xxl .navbar-toggler {
      display: none;
   }
   .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none;
   }
   .navbar-expand-xxl .offcanvas .offcanvas-header {
      display: none;
   }
   .navbar-expand-xxl .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
   }
}
.navbar-expand {
   flex-wrap: nowrap;
   justify-content: flex-start;
}
.navbar-expand .navbar-nav {
   flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
   position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
   padding-right: var(--tblr-navbar-nav-link-padding-x);
   padding-left: var(--tblr-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
   overflow: visible;
}
.navbar-expand .navbar-collapse {
   display: flex !important;
   flex-basis: auto;
}
.navbar-expand .navbar-toggler {
   display: none;
}
.navbar-expand .offcanvas {
   position: static;
   z-index: auto;
   flex-grow: 1;
   width: auto !important;
   height: auto !important;
   visibility: visible !important;
   background-color: transparent !important;
   border: 0 !important;
   transform: none !important;
   transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
   display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
   display: flex;
   flex-grow: 0;
   padding: 0;
   overflow-y: visible;
}

.navbar-dark {
   --tblr-navbar-color: rgba(255, 255, 255, 0.7);
   --tblr-navbar-hover-color: rgba(255, 255, 255, 0.75);
   --tblr-navbar-disabled-color: var(--tblr-disabled-color);
   --tblr-navbar-active-color: #ffffff;
   --tblr-navbar-brand-color: #ffffff;
   --tblr-navbar-brand-hover-color: #ffffff;
   --tblr-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
   --tblr-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.7%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar {
   --tblr-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.7%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
   --tblr-card-spacer-y: 1rem;
   --tblr-card-spacer-x: 1.5rem;
   --tblr-card-title-spacer-y: 1.25rem;
   --tblr-card-title-color: ;
   --tblr-card-subtitle-color: ;
   --tblr-card-border-width: var(--tblr-border-width);
   --tblr-card-border-color: var(--tblr-border-color);
   --tblr-card-border-radius: var(--tblr-border-radius);
   --tblr-card-box-shadow: var(--tblr-shadow-card);
   --tblr-card-inner-border-radius: calc(
      var(--tblr-border-radius) - (var(--tblr-border-width))
   );
   --tblr-card-cap-padding-y: 1rem;
   --tblr-card-cap-padding-x: 1.5rem;
   --tblr-card-cap-bg: var(--tblr-bg-surface-tertiary);
   --tblr-card-cap-color: inherit;
   --tblr-card-height: ;
   --tblr-card-color: inherit;
   --tblr-card-bg: var(--tblr-bg-surface);
   --tblr-card-img-overlay-padding: 1rem;
   --tblr-card-group-margin: 1.5rem;
   position: relative;
   display: flex;
   flex-direction: column;
   min-width: 0;
   height: var(--tblr-card-height);
   word-wrap: break-word;
   background-color: var(--tblr-card-bg);
   background-clip: border-box;
   border: var(--tblr-card-border-width) solid var(--tblr-card-border-color);
   border-radius: var(--tblr-card-border-radius);
}
.card > hr,
.card > .hr {
   margin-right: 0;
   margin-left: 0;
}
.card > .list-group {
   border-top: inherit;
   border-bottom: inherit;
}
.card > .list-group:first-child {
   border-top-width: 0;
   border-top-left-radius: var(--tblr-card-inner-border-radius);
   border-top-right-radius: var(--tblr-card-inner-border-radius);
}
.card > .list-group:last-child {
   border-bottom-width: 0;
   border-bottom-right-radius: var(--tblr-card-inner-border-radius);
   border-bottom-left-radius: var(--tblr-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
   border-top: 0;
}

.card-body {
   flex: 1 1 auto;
   padding: var(--tblr-card-spacer-y) var(--tblr-card-spacer-x);
   color: var(--tblr-card-color);
}

.card-title {
   margin-bottom: var(--tblr-card-title-spacer-y);
   color: var(--tblr-card-title-color);
}

.card-subtitle {
   margin-top: calc(-0.5 * var(--tblr-card-title-spacer-y));
   margin-bottom: 0;
   color: var(--tblr-card-subtitle-color);
}

.card-text:last-child {
   margin-bottom: 0;
}

.card-link:hover {
   text-decoration: none;
}
.card-link + .card-link {
   margin-left: var(--tblr-card-spacer-x);
}

.card-header {
   padding: var(--tblr-card-cap-padding-y) var(--tblr-card-cap-padding-x);
   margin-bottom: 0;
   color: var(--tblr-card-cap-color);
   background-color: var(--tblr-card-cap-bg);
   border-bottom: var(--tblr-card-border-width) solid
      var(--tblr-card-border-color);
}
.card-header:first-child {
   border-radius: var(--tblr-card-inner-border-radius)
      var(--tblr-card-inner-border-radius) 0 0;
}

.card-footer {
   padding: var(--tblr-card-cap-padding-y) var(--tblr-card-cap-padding-x);
   color: var(--tblr-card-cap-color);
   background-color: var(--tblr-card-cap-bg);
   border-top: var(--tblr-card-border-width) solid var(--tblr-card-border-color);
}
.card-footer:last-child {
   border-radius: 0 0 var(--tblr-card-inner-border-radius)
      var(--tblr-card-inner-border-radius);
}

.card-header-tabs {
   margin-right: calc(-0.5 * var(--tblr-card-cap-padding-x));
   margin-bottom: calc(-1 * var(--tblr-card-cap-padding-y));
   margin-left: calc(-0.5 * var(--tblr-card-cap-padding-x));
   border-bottom: 0;
}
.card-header-tabs .nav-link.active {
   background-color: var(--tblr-card-bg);
   border-bottom-color: var(--tblr-card-bg);
}

.card-header-pills {
   margin-right: calc(-0.5 * var(--tblr-card-cap-padding-x));
   margin-left: calc(-0.5 * var(--tblr-card-cap-padding-x));
}

.card-img-overlay {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   padding: var(--tblr-card-img-overlay-padding);
   border-radius: var(--tblr-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
   width: 100%;
}

.card-img,
.card-img-top {
   border-top-left-radius: var(--tblr-card-inner-border-radius);
   border-top-right-radius: var(--tblr-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
   border-bottom-right-radius: var(--tblr-card-inner-border-radius);
   border-bottom-left-radius: var(--tblr-card-inner-border-radius);
}

.card-group > .card {
   margin-bottom: var(--tblr-card-group-margin);
}
@media (min-width: 576px) {
   .card-group {
      display: flex;
      flex-flow: row wrap;
   }
   .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0;
   }
   .card-group > .card + .card {
      margin-left: 0;
      border-left: 0;
   }
   .card-group > .card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
   }
   .card-group > .card:not(:last-child) .card-img-top,
   .card-group > .card:not(:last-child) .card-header {
      border-top-right-radius: 0;
   }
   .card-group > .card:not(:last-child) .card-img-bottom,
   .card-group > .card:not(:last-child) .card-footer {
      border-bottom-right-radius: 0;
   }
   .card-group > .card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
   }
   .card-group > .card:not(:first-child) .card-img-top,
   .card-group > .card:not(:first-child) .card-header {
      border-top-left-radius: 0;
   }
   .card-group > .card:not(:first-child) .card-img-bottom,
   .card-group > .card:not(:first-child) .card-footer {
      border-bottom-left-radius: 0;
   }
}

.accordion {
   --tblr-accordion-color: var(--tblr-body-color);
   --tblr-accordion-bg: transparent;
   --tblr-accordion-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
   --tblr-accordion-border-color: var(--tblr-border-color-translucent);
   --tblr-accordion-border-width: var(--tblr-border-width);
   --tblr-accordion-border-radius: var(--tblr-border-radius);
   --tblr-accordion-inner-border-radius: calc(
      var(--tblr-border-radius) - (var(--tblr-border-width))
   );
   --tblr-accordion-btn-padding-x: 1.25rem;
   --tblr-accordion-btn-padding-y: 1rem;
   --tblr-accordion-btn-color: var(--tblr-body-color);
   --tblr-accordion-btn-bg: transparent;
   --tblr-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231d273b'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
   --tblr-accordion-btn-icon-width: 1rem;
   --tblr-accordion-btn-icon-transform: rotate(-180deg);
   --tblr-accordion-btn-icon-transition: transform 0.2s ease-in-out;
   --tblr-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231a569d'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
   --tblr-accordion-btn-focus-border-color: var(
      --tblr-border-color-translucent
   );
   --tblr-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(32, 107, 196, 0.25);
   --tblr-accordion-body-padding-x: 1.25rem;
   --tblr-accordion-body-padding-y: 1rem;
   --tblr-accordion-active-color: inherit;
   --tblr-accordion-active-bg: transparent;
}

.accordion-button {
   position: relative;
   display: flex;
   align-items: center;
   width: 100%;
   padding: var(--tblr-accordion-btn-padding-y)
      var(--tblr-accordion-btn-padding-x);
   font-size: 0.875rem;
   color: var(--tblr-accordion-btn-color);
   text-align: left;
   background-color: var(--tblr-accordion-btn-bg);
   border: 0;
   border-radius: 0;
   overflow-anchor: none;
   transition: var(--tblr-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
   .accordion-button {
      transition: none;
   }
}
.accordion-button:not(.collapsed) {
   color: var(--tblr-accordion-active-color);
   background-color: var(--tblr-accordion-active-bg);
   box-shadow: inset 0 calc(-1 * var(--tblr-accordion-border-width)) 0
      var(--tblr-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
   background-image: var(--tblr-accordion-btn-active-icon);
   transform: var(--tblr-accordion-btn-icon-transform);
}
.accordion-button::after {
   flex-shrink: 0;
   width: var(--tblr-accordion-btn-icon-width);
   height: var(--tblr-accordion-btn-icon-width);
   margin-left: auto;
   content: "";
   background-image: var(--tblr-accordion-btn-icon);
   background-repeat: no-repeat;
   background-size: var(--tblr-accordion-btn-icon-width);
   transition: var(--tblr-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
   .accordion-button::after {
      transition: none;
   }
}
.accordion-button:hover {
   z-index: 2;
}
.accordion-button:focus {
   z-index: 3;
   border-color: var(--tblr-accordion-btn-focus-border-color);
   outline: 0;
   box-shadow: var(--tblr-accordion-btn-focus-box-shadow);
}

.accordion-header {
   margin-bottom: 0;
}

.accordion-item {
   color: var(--tblr-accordion-color);
   background-color: var(--tblr-accordion-bg);
   border: var(--tblr-accordion-border-width) solid
      var(--tblr-accordion-border-color);
}
.accordion-item:first-of-type {
   border-top-left-radius: var(--tblr-accordion-border-radius);
   border-top-right-radius: var(--tblr-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
   border-top-left-radius: var(--tblr-accordion-inner-border-radius);
   border-top-right-radius: var(--tblr-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
   border-top: 0;
}
.accordion-item:last-of-type {
   border-bottom-right-radius: var(--tblr-accordion-border-radius);
   border-bottom-left-radius: var(--tblr-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
   border-bottom-right-radius: var(--tblr-accordion-inner-border-radius);
   border-bottom-left-radius: var(--tblr-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
   border-bottom-right-radius: var(--tblr-accordion-border-radius);
   border-bottom-left-radius: var(--tblr-accordion-border-radius);
}

.accordion-body {
   padding: var(--tblr-accordion-body-padding-y)
      var(--tblr-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
   border-width: 0;
}
.accordion-flush .accordion-item {
   border-right: 0;
   border-left: 0;
   border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
   border-top: 0;
}
.accordion-flush .accordion-item:last-child {
   border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
   border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button::after {
   --tblr-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2379a6dc'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
   --tblr-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2379a6dc'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
   --tblr-breadcrumb-padding-x: 0;
   --tblr-breadcrumb-padding-y: 0;
   --tblr-breadcrumb-margin-bottom: 1rem;
   --tblr-breadcrumb-bg: ;
   --tblr-breadcrumb-border-radius: ;
   --tblr-breadcrumb-divider-color: var(--tblr-muted);
   --tblr-breadcrumb-item-padding-x: 0.5rem;
   --tblr-breadcrumb-item-active-color: inherit;
   display: flex;
   flex-wrap: wrap;
   padding: var(--tblr-breadcrumb-padding-y) var(--tblr-breadcrumb-padding-x);
   margin-bottom: var(--tblr-breadcrumb-margin-bottom);
   font-size: var(--tblr-breadcrumb-font-size);
   list-style: none;
   background-color: var(--tblr-breadcrumb-bg);
   border-radius: var(--tblr-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
   padding-left: var(--tblr-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
   float: left;
   padding-right: var(--tblr-breadcrumb-item-padding-x);
   color: var(--tblr-breadcrumb-divider-color);
   content: var(--tblr-breadcrumb-divider, "/")
      /* rtl: var(--tblr-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
   color: var(--tblr-breadcrumb-item-active-color);
}

.pagination {
   --tblr-pagination-padding-x: 0.25rem;
   --tblr-pagination-padding-y: 0.25rem;
   --tblr-pagination-font-size: 0.875rem;
   --tblr-pagination-color: var(--tblr-muted);
   --tblr-pagination-bg: transparent;
   --tblr-pagination-border-width: 0;
   --tblr-pagination-border-color: var(--tblr-border-color);
   --tblr-pagination-border-radius: var(--tblr-border-radius);
   --tblr-pagination-hover-color: var(--tblr-link-hover-color);
   --tblr-pagination-hover-bg: var(--tblr-tertiary-bg);
   --tblr-pagination-hover-border-color: var(--tblr-border-color);
   --tblr-pagination-focus-color: var(--tblr-link-hover-color);
   --tblr-pagination-focus-bg: var(--tblr-secondary-bg);
   --tblr-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(32, 107, 196, 0.25);
   --tblr-pagination-active-color: #ffffff;
   --tblr-pagination-active-bg: var(--tblr-primary);
   --tblr-pagination-active-border-color: var(--tblr-primary);
   --tblr-pagination-disabled-color: var(--tblr-disabled-color);
   --tblr-pagination-disabled-bg: transparent;
   --tblr-pagination-disabled-border-color: var(--tblr-border-color);
   display: flex;
   padding-left: 0;
   list-style: none;
}

.page-link {
   position: relative;
   display: block;
   padding: var(--tblr-pagination-padding-y) var(--tblr-pagination-padding-x);
   font-size: var(--tblr-pagination-font-size);
   color: var(--tblr-pagination-color);
   background-color: var(--tblr-pagination-bg);
   border: var(--tblr-pagination-border-width) solid
      var(--tblr-pagination-border-color);
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
   .page-link {
      transition: none;
   }
}
.page-link:hover {
   z-index: 2;
   color: var(--tblr-pagination-hover-color);
   text-decoration: none;
   background-color: var(--tblr-pagination-hover-bg);
   border-color: var(--tblr-pagination-hover-border-color);
}
.page-link:focus {
   z-index: 3;
   color: var(--tblr-pagination-focus-color);
   background-color: var(--tblr-pagination-focus-bg);
   outline: 0;
   box-shadow: var(--tblr-pagination-focus-box-shadow);
}
.page-link.active,
.active > .page-link {
   z-index: 3;
   color: var(--tblr-pagination-active-color);
   background-color: var(--tblr-pagination-active-bg);
   border-color: var(--tblr-pagination-active-border-color);
}
.page-link.disabled,
.disabled > .page-link {
   color: var(--tblr-pagination-disabled-color);
   pointer-events: none;
   background-color: var(--tblr-pagination-disabled-bg);
   border-color: var(--tblr-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
   margin-left: 0;
}
.page-item:first-child .page-link {
   border-top-left-radius: var(--tblr-pagination-border-radius);
   border-bottom-left-radius: var(--tblr-pagination-border-radius);
}
.page-item:last-child .page-link {
   border-top-right-radius: var(--tblr-pagination-border-radius);
   border-bottom-right-radius: var(--tblr-pagination-border-radius);
}

.pagination-lg {
   --tblr-pagination-padding-x: 1.5rem;
   --tblr-pagination-padding-y: 0.75rem;
   --tblr-pagination-font-size: 1.09375rem;
   --tblr-pagination-border-radius: 8px;
}

.pagination-sm {
   --tblr-pagination-padding-x: 0.5rem;
   --tblr-pagination-padding-y: 0.25rem;
   --tblr-pagination-font-size: 0.765625rem;
   --tblr-pagination-border-radius: 2px;
}

.badge {
   --tblr-badge-padding-x: 0.5em;
   --tblr-badge-padding-y: 0.25em;
   --tblr-badge-font-size: 85.714285%;
   --tblr-badge-font-weight: var(--tblr-font-weight-medium);
   --tblr-badge-color: #ffffff;
   --tblr-badge-border-radius: 4px;
   display: inline-block;
   padding: var(--tblr-badge-padding-y) var(--tblr-badge-padding-x);
   font-size: var(--tblr-badge-font-size);
   font-weight: var(--tblr-badge-font-weight);
   line-height: 1;
   color: var(--tblr-badge-color);
   text-align: center;
   white-space: nowrap;
   vertical-align: baseline;
   border-radius: var(--tblr-badge-border-radius);
}
.badge:empty {
   display: none;
}

.btn .badge {
   position: relative;
   top: -1px;
}

.alert {
   --tblr-alert-bg: transparent;
   --tblr-alert-padding-x: 1rem;
   --tblr-alert-padding-y: 1rem;
   --tblr-alert-margin-bottom: 1rem;
   --tblr-alert-color: inherit;
   --tblr-alert-border-color: transparent;
   --tblr-alert-border: var(--tblr-border-width) solid
      var(--tblr-alert-border-color);
   --tblr-alert-border-radius: 4px;
   --tblr-alert-link-color: inherit;
   position: relative;
   padding: var(--tblr-alert-padding-y) var(--tblr-alert-padding-x);
   margin-bottom: var(--tblr-alert-margin-bottom);
   color: var(--tblr-alert-color);
   background-color: var(--tblr-alert-bg);
   border: var(--tblr-alert-border);
   border-radius: var(--tblr-alert-border-radius);
}

.alert-heading {
   color: inherit;
}

.alert-link {
   font-weight: var(--tblr-font-weight-bold);
   color: var(--tblr-alert-link-color);
}

.alert-dismissible {
   padding-right: 3rem;
}
.alert-dismissible .btn-close {
   position: absolute;
   top: 0;
   right: 0;
   z-index: 2;
   padding: 1.25rem 1rem;
}

.alert-primary {
   --tblr-alert-color: var(--tblr-primary-text);
   --tblr-alert-bg: var(--tblr-primary-bg-subtle);
   --tblr-alert-border-color: var(--tblr-primary-border-subtle);
   --tblr-alert-link-color: var(--tblr-primary-text);
}

.alert-secondary {
   --tblr-alert-color: var(--tblr-secondary-text);
   --tblr-alert-bg: var(--tblr-secondary-bg-subtle);
   --tblr-alert-border-color: var(--tblr-secondary-border-subtle);
   --tblr-alert-link-color: var(--tblr-secondary-text);
}

.alert-success {
   --tblr-alert-color: var(--tblr-success-text);
   --tblr-alert-bg: var(--tblr-success-bg-subtle);
   --tblr-alert-border-color: var(--tblr-success-border-subtle);
   --tblr-alert-link-color: var(--tblr-success-text);
}

.alert-info {
   --tblr-alert-color: var(--tblr-info-text);
   --tblr-alert-bg: var(--tblr-info-bg-subtle);
   --tblr-alert-border-color: var(--tblr-info-border-subtle);
   --tblr-alert-link-color: var(--tblr-info-text);
}

.alert-warning {
   --tblr-alert-color: var(--tblr-warning-text);
   --tblr-alert-bg: var(--tblr-warning-bg-subtle);
   --tblr-alert-border-color: var(--tblr-warning-border-subtle);
   --tblr-alert-link-color: var(--tblr-warning-text);
}

.alert-danger {
   --tblr-alert-color: var(--tblr-danger-text);
   --tblr-alert-bg: var(--tblr-danger-bg-subtle);
   --tblr-alert-border-color: var(--tblr-danger-border-subtle);
   --tblr-alert-link-color: var(--tblr-danger-text);
}

.alert-light {
   --tblr-alert-color: var(--tblr-light-text);
   --tblr-alert-bg: var(--tblr-light-bg-subtle);
   --tblr-alert-border-color: var(--tblr-light-border-subtle);
   --tblr-alert-link-color: var(--tblr-light-text);
}

.alert-dark {
   --tblr-alert-color: var(--tblr-dark-text);
   --tblr-alert-bg: var(--tblr-dark-bg-subtle);
   --tblr-alert-border-color: var(--tblr-dark-border-subtle);
   --tblr-alert-link-color: var(--tblr-dark-text);
}

.alert-muted {
   --tblr-alert-color: var(--tblr-muted-text);
   --tblr-alert-bg: var(--tblr-muted-bg-subtle);
   --tblr-alert-border-color: var(--tblr-muted-border-subtle);
   --tblr-alert-link-color: var(--tblr-muted-text);
}

.alert-blue {
   --tblr-alert-color: var(--tblr-blue-text);
   --tblr-alert-bg: var(--tblr-blue-bg-subtle);
   --tblr-alert-border-color: var(--tblr-blue-border-subtle);
   --tblr-alert-link-color: var(--tblr-blue-text);
}

.alert-azure {
   --tblr-alert-color: var(--tblr-azure-text);
   --tblr-alert-bg: var(--tblr-azure-bg-subtle);
   --tblr-alert-border-color: var(--tblr-azure-border-subtle);
   --tblr-alert-link-color: var(--tblr-azure-text);
}

.alert-indigo {
   --tblr-alert-color: var(--tblr-indigo-text);
   --tblr-alert-bg: var(--tblr-indigo-bg-subtle);
   --tblr-alert-border-color: var(--tblr-indigo-border-subtle);
   --tblr-alert-link-color: var(--tblr-indigo-text);
}

.alert-purple {
   --tblr-alert-color: var(--tblr-purple-text);
   --tblr-alert-bg: var(--tblr-purple-bg-subtle);
   --tblr-alert-border-color: var(--tblr-purple-border-subtle);
   --tblr-alert-link-color: var(--tblr-purple-text);
}

.alert-pink {
   --tblr-alert-color: var(--tblr-pink-text);
   --tblr-alert-bg: var(--tblr-pink-bg-subtle);
   --tblr-alert-border-color: var(--tblr-pink-border-subtle);
   --tblr-alert-link-color: var(--tblr-pink-text);
}

.alert-red {
   --tblr-alert-color: var(--tblr-red-text);
   --tblr-alert-bg: var(--tblr-red-bg-subtle);
   --tblr-alert-border-color: var(--tblr-red-border-subtle);
   --tblr-alert-link-color: var(--tblr-red-text);
}

.alert-orange {
   --tblr-alert-color: var(--tblr-orange-text);
   --tblr-alert-bg: var(--tblr-orange-bg-subtle);
   --tblr-alert-border-color: var(--tblr-orange-border-subtle);
   --tblr-alert-link-color: var(--tblr-orange-text);
}

.alert-yellow {
   --tblr-alert-color: var(--tblr-yellow-text);
   --tblr-alert-bg: var(--tblr-yellow-bg-subtle);
   --tblr-alert-border-color: var(--tblr-yellow-border-subtle);
   --tblr-alert-link-color: var(--tblr-yellow-text);
}

.alert-lime {
   --tblr-alert-color: var(--tblr-lime-text);
   --tblr-alert-bg: var(--tblr-lime-bg-subtle);
   --tblr-alert-border-color: var(--tblr-lime-border-subtle);
   --tblr-alert-link-color: var(--tblr-lime-text);
}

.alert-green {
   --tblr-alert-color: var(--tblr-green-text);
   --tblr-alert-bg: var(--tblr-green-bg-subtle);
   --tblr-alert-border-color: var(--tblr-green-border-subtle);
   --tblr-alert-link-color: var(--tblr-green-text);
}

.alert-teal {
   --tblr-alert-color: var(--tblr-teal-text);
   --tblr-alert-bg: var(--tblr-teal-bg-subtle);
   --tblr-alert-border-color: var(--tblr-teal-border-subtle);
   --tblr-alert-link-color: var(--tblr-teal-text);
}

.alert-cyan {
   --tblr-alert-color: var(--tblr-cyan-text);
   --tblr-alert-bg: var(--tblr-cyan-bg-subtle);
   --tblr-alert-border-color: var(--tblr-cyan-border-subtle);
   --tblr-alert-link-color: var(--tblr-cyan-text);
}

.alert-facebook {
   --tblr-alert-color: var(--tblr-facebook-text);
   --tblr-alert-bg: var(--tblr-facebook-bg-subtle);
   --tblr-alert-border-color: var(--tblr-facebook-border-subtle);
   --tblr-alert-link-color: var(--tblr-facebook-text);
}

.alert-twitter {
   --tblr-alert-color: var(--tblr-twitter-text);
   --tblr-alert-bg: var(--tblr-twitter-bg-subtle);
   --tblr-alert-border-color: var(--tblr-twitter-border-subtle);
   --tblr-alert-link-color: var(--tblr-twitter-text);
}

.alert-linkedin {
   --tblr-alert-color: var(--tblr-linkedin-text);
   --tblr-alert-bg: var(--tblr-linkedin-bg-subtle);
   --tblr-alert-border-color: var(--tblr-linkedin-border-subtle);
   --tblr-alert-link-color: var(--tblr-linkedin-text);
}

.alert-google {
   --tblr-alert-color: var(--tblr-google-text);
   --tblr-alert-bg: var(--tblr-google-bg-subtle);
   --tblr-alert-border-color: var(--tblr-google-border-subtle);
   --tblr-alert-link-color: var(--tblr-google-text);
}

.alert-youtube {
   --tblr-alert-color: var(--tblr-youtube-text);
   --tblr-alert-bg: var(--tblr-youtube-bg-subtle);
   --tblr-alert-border-color: var(--tblr-youtube-border-subtle);
   --tblr-alert-link-color: var(--tblr-youtube-text);
}

.alert-vimeo {
   --tblr-alert-color: var(--tblr-vimeo-text);
   --tblr-alert-bg: var(--tblr-vimeo-bg-subtle);
   --tblr-alert-border-color: var(--tblr-vimeo-border-subtle);
   --tblr-alert-link-color: var(--tblr-vimeo-text);
}

.alert-dribbble {
   --tblr-alert-color: var(--tblr-dribbble-text);
   --tblr-alert-bg: var(--tblr-dribbble-bg-subtle);
   --tblr-alert-border-color: var(--tblr-dribbble-border-subtle);
   --tblr-alert-link-color: var(--tblr-dribbble-text);
}

.alert-github {
   --tblr-alert-color: var(--tblr-github-text);
   --tblr-alert-bg: var(--tblr-github-bg-subtle);
   --tblr-alert-border-color: var(--tblr-github-border-subtle);
   --tblr-alert-link-color: var(--tblr-github-text);
}

.alert-instagram {
   --tblr-alert-color: var(--tblr-instagram-text);
   --tblr-alert-bg: var(--tblr-instagram-bg-subtle);
   --tblr-alert-border-color: var(--tblr-instagram-border-subtle);
   --tblr-alert-link-color: var(--tblr-instagram-text);
}

.alert-pinterest {
   --tblr-alert-color: var(--tblr-pinterest-text);
   --tblr-alert-bg: var(--tblr-pinterest-bg-subtle);
   --tblr-alert-border-color: var(--tblr-pinterest-border-subtle);
   --tblr-alert-link-color: var(--tblr-pinterest-text);
}

.alert-vk {
   --tblr-alert-color: var(--tblr-vk-text);
   --tblr-alert-bg: var(--tblr-vk-bg-subtle);
   --tblr-alert-border-color: var(--tblr-vk-border-subtle);
   --tblr-alert-link-color: var(--tblr-vk-text);
}

.alert-rss {
   --tblr-alert-color: var(--tblr-rss-text);
   --tblr-alert-bg: var(--tblr-rss-bg-subtle);
   --tblr-alert-border-color: var(--tblr-rss-border-subtle);
   --tblr-alert-link-color: var(--tblr-rss-text);
}

.alert-flickr {
   --tblr-alert-color: var(--tblr-flickr-text);
   --tblr-alert-bg: var(--tblr-flickr-bg-subtle);
   --tblr-alert-border-color: var(--tblr-flickr-border-subtle);
   --tblr-alert-link-color: var(--tblr-flickr-text);
}

.alert-bitbucket {
   --tblr-alert-color: var(--tblr-bitbucket-text);
   --tblr-alert-bg: var(--tblr-bitbucket-bg-subtle);
   --tblr-alert-border-color: var(--tblr-bitbucket-border-subtle);
   --tblr-alert-link-color: var(--tblr-bitbucket-text);
}

.alert-tabler {
   --tblr-alert-color: var(--tblr-tabler-text);
   --tblr-alert-bg: var(--tblr-tabler-bg-subtle);
   --tblr-alert-border-color: var(--tblr-tabler-border-subtle);
   --tblr-alert-link-color: var(--tblr-tabler-text);
}

@keyframes progress-bar-stripes {
   0% {
      background-position-x: 0.5rem;
   }
}
.progress,
.progress-stacked {
   --tblr-progress-height: 0.5rem;
   --tblr-progress-font-size: 0.65625rem;
   --tblr-progress-bg: var(--tblr-border-color);
   --tblr-progress-border-radius: var(--tblr-border-radius);
   --tblr-progress-box-shadow: var(--tblr-box-shadow-inset);
   --tblr-progress-bar-color: #ffffff;
   --tblr-progress-bar-bg: var(--tblr-primary);
   --tblr-progress-bar-transition: width 0.6s ease;
   display: flex;
   height: var(--tblr-progress-height);
   overflow: hidden;
   font-size: var(--tblr-progress-font-size);
   background-color: var(--tblr-progress-bg);
   border-radius: var(--tblr-progress-border-radius);
}

.progress-bar {
   display: flex;
   flex-direction: column;
   justify-content: center;
   overflow: hidden;
   color: var(--tblr-progress-bar-color);
   text-align: center;
   white-space: nowrap;
   background-color: var(--tblr-progress-bar-bg);
   transition: var(--tblr-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
   .progress-bar {
      transition: none;
   }
}

.progress-bar-striped {
   background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
   );
   background-size: var(--tblr-progress-height) var(--tblr-progress-height);
}

.progress-stacked > .progress {
   overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
   width: 100%;
}

.progress-bar-animated {
   animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
   .progress-bar-animated {
      animation: none;
   }
}

.list-group {
   --tblr-list-group-color: var(--tblr-body-color);
   --tblr-list-group-bg: inherit;
   --tblr-list-group-border-color: var(--tblr-border-color);
   --tblr-list-group-border-width: var(--tblr-border-width);
   --tblr-list-group-border-radius: var(--tblr-border-radius);
   --tblr-list-group-item-padding-x: 1.5rem;
   --tblr-list-group-item-padding-y: 1rem;
   --tblr-list-group-action-color: inherit;
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: rgba(var(--tblr-muted-rgb), 0.04);
   --tblr-list-group-action-active-color: var(--tblr-body-color);
   --tblr-list-group-action-active-bg: var(--tblr-secondary-bg);
   --tblr-list-group-disabled-color: var(--tblr-secondary-color);
   --tblr-list-group-disabled-bg: inherit;
   --tblr-list-group-active-color: inherit;
   --tblr-list-group-active-bg: var(--tblr-active-bg);
   --tblr-list-group-active-border-color: var(--tblr-border-color);
   display: flex;
   flex-direction: column;
   padding-left: 0;
   margin-bottom: 0;
   border-radius: var(--tblr-list-group-border-radius);
}

.list-group-numbered {
   list-style-type: none;
   counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
   content: counters(section, ".") ". ";
   counter-increment: section;
}

.list-group-item-action {
   width: 100%;
   color: var(--tblr-list-group-action-color);
   text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
   z-index: 1;
   color: var(--tblr-list-group-action-hover-color);
   text-decoration: none;
   background-color: var(--tblr-list-group-action-hover-bg);
}
.list-group-item-action:active {
   color: var(--tblr-list-group-action-active-color);
   background-color: var(--tblr-list-group-action-active-bg);
}

.list-group-item {
   position: relative;
   display: block;
   padding: var(--tblr-list-group-item-padding-y)
      var(--tblr-list-group-item-padding-x);
   color: var(--tblr-list-group-color);
   background-color: var(--tblr-list-group-bg);
   border: var(--tblr-list-group-border-width) solid
      var(--tblr-list-group-border-color);
}
.list-group-item:first-child {
   border-top-left-radius: inherit;
   border-top-right-radius: inherit;
}
.list-group-item:last-child {
   border-bottom-right-radius: inherit;
   border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
   color: var(--tblr-list-group-disabled-color);
   pointer-events: none;
   background-color: var(--tblr-list-group-disabled-bg);
}
.list-group-item.active {
   z-index: 2;
   color: var(--tblr-list-group-active-color);
   background-color: var(--tblr-list-group-active-bg);
   border-color: var(--tblr-list-group-active-border-color);
}
.list-group-item + .list-group-item {
   border-top-width: 0;
}
.list-group-item + .list-group-item.active {
   margin-top: calc(-1 * var(--tblr-list-group-border-width));
   border-top-width: var(--tblr-list-group-border-width);
}

.list-group-horizontal {
   flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
   border-bottom-left-radius: var(--tblr-list-group-border-radius);
   border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
   border-top-right-radius: var(--tblr-list-group-border-radius);
   border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
   margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
   border-top-width: var(--tblr-list-group-border-width);
   border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
   margin-left: calc(-1 * var(--tblr-list-group-border-width));
   border-left-width: var(--tblr-list-group-border-width);
}

@media (min-width: 576px) {
   .list-group-horizontal-sm {
      flex-direction: row;
   }
   .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--tblr-list-group-border-radius);
      border-top-right-radius: 0;
   }
   .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--tblr-list-group-border-radius);
      border-bottom-left-radius: 0;
   }
   .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0;
   }
   .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--tblr-list-group-border-width);
      border-left-width: 0;
   }
   .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--tblr-list-group-border-width));
      border-left-width: var(--tblr-list-group-border-width);
   }
}
@media (min-width: 768px) {
   .list-group-horizontal-md {
      flex-direction: row;
   }
   .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--tblr-list-group-border-radius);
      border-top-right-radius: 0;
   }
   .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--tblr-list-group-border-radius);
      border-bottom-left-radius: 0;
   }
   .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0;
   }
   .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--tblr-list-group-border-width);
      border-left-width: 0;
   }
   .list-group-horizontal-md > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--tblr-list-group-border-width));
      border-left-width: var(--tblr-list-group-border-width);
   }
}
@media (min-width: 992px) {
   .list-group-horizontal-lg {
      flex-direction: row;
   }
   .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--tblr-list-group-border-radius);
      border-top-right-radius: 0;
   }
   .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--tblr-list-group-border-radius);
      border-bottom-left-radius: 0;
   }
   .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0;
   }
   .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--tblr-list-group-border-width);
      border-left-width: 0;
   }
   .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--tblr-list-group-border-width));
      border-left-width: var(--tblr-list-group-border-width);
   }
}
@media (min-width: 1200px) {
   .list-group-horizontal-xl {
      flex-direction: row;
   }
   .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--tblr-list-group-border-radius);
      border-top-right-radius: 0;
   }
   .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--tblr-list-group-border-radius);
      border-bottom-left-radius: 0;
   }
   .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0;
   }
   .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--tblr-list-group-border-width);
      border-left-width: 0;
   }
   .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--tblr-list-group-border-width));
      border-left-width: var(--tblr-list-group-border-width);
   }
}
@media (min-width: 1400px) {
   .list-group-horizontal-xxl {
      flex-direction: row;
   }
   .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--tblr-list-group-border-radius);
      border-top-right-radius: 0;
   }
   .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--tblr-list-group-border-radius);
      border-bottom-left-radius: 0;
   }
   .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0;
   }
   .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--tblr-list-group-border-width);
      border-left-width: 0;
   }
   .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--tblr-list-group-border-width));
      border-left-width: var(--tblr-list-group-border-width);
   }
}
.list-group-flush {
   border-radius: 0;
}
.list-group-flush > .list-group-item {
   border-width: 0 0 var(--tblr-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
   border-bottom-width: 0;
}

.list-group-item-primary {
   --tblr-list-group-color: var(--tblr-primary-text);
   --tblr-list-group-bg: var(--tblr-primary-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-primary-border-subtle);
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-primary-border-subtle);
}
.list-group-item-primary.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-primary-text);
   --tblr-list-group-active-border-color: var(--tblr-primary-text);
}

.list-group-item-secondary {
   --tblr-list-group-color: var(--tblr-secondary-text);
   --tblr-list-group-bg: var(--tblr-secondary-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-secondary-border-subtle);
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-secondary-border-subtle);
}
.list-group-item-secondary.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-secondary-text);
   --tblr-list-group-active-border-color: var(--tblr-secondary-text);
}

.list-group-item-success {
   --tblr-list-group-color: var(--tblr-success-text);
   --tblr-list-group-bg: var(--tblr-success-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-success-border-subtle);
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-success-border-subtle);
}
.list-group-item-success.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-success-text);
   --tblr-list-group-active-border-color: var(--tblr-success-text);
}

.list-group-item-info {
   --tblr-list-group-color: var(--tblr-info-text);
   --tblr-list-group-bg: var(--tblr-info-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-info-border-subtle);
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-info-border-subtle);
}
.list-group-item-info.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-info-text);
   --tblr-list-group-active-border-color: var(--tblr-info-text);
}

.list-group-item-warning {
   --tblr-list-group-color: var(--tblr-warning-text);
   --tblr-list-group-bg: var(--tblr-warning-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-warning-border-subtle);
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-warning-border-subtle);
}
.list-group-item-warning.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-warning-text);
   --tblr-list-group-active-border-color: var(--tblr-warning-text);
}

.list-group-item-danger {
   --tblr-list-group-color: var(--tblr-danger-text);
   --tblr-list-group-bg: var(--tblr-danger-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-danger-border-subtle);
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-danger-border-subtle);
}
.list-group-item-danger.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-danger-text);
   --tblr-list-group-active-border-color: var(--tblr-danger-text);
}

.list-group-item-light {
   --tblr-list-group-color: var(--tblr-light-text);
   --tblr-list-group-bg: var(--tblr-light-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-light-border-subtle);
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-light-border-subtle);
}
.list-group-item-light.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-light-text);
   --tblr-list-group-active-border-color: var(--tblr-light-text);
}

.list-group-item-dark {
   --tblr-list-group-color: var(--tblr-dark-text);
   --tblr-list-group-bg: var(--tblr-dark-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-dark-border-subtle);
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-dark-border-subtle);
}
.list-group-item-dark.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-dark-text);
   --tblr-list-group-active-border-color: var(--tblr-dark-text);
}

.list-group-item-muted {
   --tblr-list-group-color: var(--tblr-muted-text);
   --tblr-list-group-bg: var(--tblr-muted-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-muted-border-subtle);
}
.list-group-item-muted.list-group-item-action:hover,
.list-group-item-muted.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-muted-border-subtle);
}
.list-group-item-muted.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-muted-text);
   --tblr-list-group-active-border-color: var(--tblr-muted-text);
}

.list-group-item-blue {
   --tblr-list-group-color: var(--tblr-blue-text);
   --tblr-list-group-bg: var(--tblr-blue-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-blue-border-subtle);
}
.list-group-item-blue.list-group-item-action:hover,
.list-group-item-blue.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-blue-border-subtle);
}
.list-group-item-blue.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-blue-text);
   --tblr-list-group-active-border-color: var(--tblr-blue-text);
}

.list-group-item-azure {
   --tblr-list-group-color: var(--tblr-azure-text);
   --tblr-list-group-bg: var(--tblr-azure-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-azure-border-subtle);
}
.list-group-item-azure.list-group-item-action:hover,
.list-group-item-azure.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-azure-border-subtle);
}
.list-group-item-azure.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-azure-text);
   --tblr-list-group-active-border-color: var(--tblr-azure-text);
}

.list-group-item-indigo {
   --tblr-list-group-color: var(--tblr-indigo-text);
   --tblr-list-group-bg: var(--tblr-indigo-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-indigo-border-subtle);
}
.list-group-item-indigo.list-group-item-action:hover,
.list-group-item-indigo.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-indigo-border-subtle);
}
.list-group-item-indigo.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-indigo-text);
   --tblr-list-group-active-border-color: var(--tblr-indigo-text);
}

.list-group-item-purple {
   --tblr-list-group-color: var(--tblr-purple-text);
   --tblr-list-group-bg: var(--tblr-purple-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-purple-border-subtle);
}
.list-group-item-purple.list-group-item-action:hover,
.list-group-item-purple.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-purple-border-subtle);
}
.list-group-item-purple.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-purple-text);
   --tblr-list-group-active-border-color: var(--tblr-purple-text);
}

.list-group-item-pink {
   --tblr-list-group-color: var(--tblr-pink-text);
   --tblr-list-group-bg: var(--tblr-pink-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-pink-border-subtle);
}
.list-group-item-pink.list-group-item-action:hover,
.list-group-item-pink.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-pink-border-subtle);
}
.list-group-item-pink.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-pink-text);
   --tblr-list-group-active-border-color: var(--tblr-pink-text);
}

.list-group-item-red {
   --tblr-list-group-color: var(--tblr-red-text);
   --tblr-list-group-bg: var(--tblr-red-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-red-border-subtle);
}
.list-group-item-red.list-group-item-action:hover,
.list-group-item-red.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-red-border-subtle);
}
.list-group-item-red.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-red-text);
   --tblr-list-group-active-border-color: var(--tblr-red-text);
}

.list-group-item-orange {
   --tblr-list-group-color: var(--tblr-orange-text);
   --tblr-list-group-bg: var(--tblr-orange-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-orange-border-subtle);
}
.list-group-item-orange.list-group-item-action:hover,
.list-group-item-orange.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-orange-border-subtle);
}
.list-group-item-orange.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-orange-text);
   --tblr-list-group-active-border-color: var(--tblr-orange-text);
}

.list-group-item-yellow {
   --tblr-list-group-color: var(--tblr-yellow-text);
   --tblr-list-group-bg: var(--tblr-yellow-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-yellow-border-subtle);
}
.list-group-item-yellow.list-group-item-action:hover,
.list-group-item-yellow.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-yellow-border-subtle);
}
.list-group-item-yellow.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-yellow-text);
   --tblr-list-group-active-border-color: var(--tblr-yellow-text);
}

.list-group-item-lime {
   --tblr-list-group-color: var(--tblr-lime-text);
   --tblr-list-group-bg: var(--tblr-lime-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-lime-border-subtle);
}
.list-group-item-lime.list-group-item-action:hover,
.list-group-item-lime.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-lime-border-subtle);
}
.list-group-item-lime.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-lime-text);
   --tblr-list-group-active-border-color: var(--tblr-lime-text);
}

.list-group-item-green {
   --tblr-list-group-color: var(--tblr-green-text);
   --tblr-list-group-bg: var(--tblr-green-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-green-border-subtle);
}
.list-group-item-green.list-group-item-action:hover,
.list-group-item-green.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-green-border-subtle);
}
.list-group-item-green.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-green-text);
   --tblr-list-group-active-border-color: var(--tblr-green-text);
}

.list-group-item-teal {
   --tblr-list-group-color: var(--tblr-teal-text);
   --tblr-list-group-bg: var(--tblr-teal-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-teal-border-subtle);
}
.list-group-item-teal.list-group-item-action:hover,
.list-group-item-teal.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-teal-border-subtle);
}
.list-group-item-teal.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-teal-text);
   --tblr-list-group-active-border-color: var(--tblr-teal-text);
}

.list-group-item-cyan {
   --tblr-list-group-color: var(--tblr-cyan-text);
   --tblr-list-group-bg: var(--tblr-cyan-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-cyan-border-subtle);
}
.list-group-item-cyan.list-group-item-action:hover,
.list-group-item-cyan.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-cyan-border-subtle);
}
.list-group-item-cyan.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-cyan-text);
   --tblr-list-group-active-border-color: var(--tblr-cyan-text);
}

.list-group-item-facebook {
   --tblr-list-group-color: var(--tblr-facebook-text);
   --tblr-list-group-bg: var(--tblr-facebook-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-facebook-border-subtle);
}
.list-group-item-facebook.list-group-item-action:hover,
.list-group-item-facebook.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-facebook-border-subtle);
}
.list-group-item-facebook.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-facebook-text);
   --tblr-list-group-active-border-color: var(--tblr-facebook-text);
}

.list-group-item-twitter {
   --tblr-list-group-color: var(--tblr-twitter-text);
   --tblr-list-group-bg: var(--tblr-twitter-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-twitter-border-subtle);
}
.list-group-item-twitter.list-group-item-action:hover,
.list-group-item-twitter.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-twitter-border-subtle);
}
.list-group-item-twitter.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-twitter-text);
   --tblr-list-group-active-border-color: var(--tblr-twitter-text);
}

.list-group-item-linkedin {
   --tblr-list-group-color: var(--tblr-linkedin-text);
   --tblr-list-group-bg: var(--tblr-linkedin-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-linkedin-border-subtle);
}
.list-group-item-linkedin.list-group-item-action:hover,
.list-group-item-linkedin.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-linkedin-border-subtle);
}
.list-group-item-linkedin.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-linkedin-text);
   --tblr-list-group-active-border-color: var(--tblr-linkedin-text);
}

.list-group-item-google {
   --tblr-list-group-color: var(--tblr-google-text);
   --tblr-list-group-bg: var(--tblr-google-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-google-border-subtle);
}
.list-group-item-google.list-group-item-action:hover,
.list-group-item-google.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-google-border-subtle);
}
.list-group-item-google.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-google-text);
   --tblr-list-group-active-border-color: var(--tblr-google-text);
}

.list-group-item-youtube {
   --tblr-list-group-color: var(--tblr-youtube-text);
   --tblr-list-group-bg: var(--tblr-youtube-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-youtube-border-subtle);
}
.list-group-item-youtube.list-group-item-action:hover,
.list-group-item-youtube.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-youtube-border-subtle);
}
.list-group-item-youtube.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-youtube-text);
   --tblr-list-group-active-border-color: var(--tblr-youtube-text);
}

.list-group-item-vimeo {
   --tblr-list-group-color: var(--tblr-vimeo-text);
   --tblr-list-group-bg: var(--tblr-vimeo-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-vimeo-border-subtle);
}
.list-group-item-vimeo.list-group-item-action:hover,
.list-group-item-vimeo.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-vimeo-border-subtle);
}
.list-group-item-vimeo.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-vimeo-text);
   --tblr-list-group-active-border-color: var(--tblr-vimeo-text);
}

.list-group-item-dribbble {
   --tblr-list-group-color: var(--tblr-dribbble-text);
   --tblr-list-group-bg: var(--tblr-dribbble-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-dribbble-border-subtle);
}
.list-group-item-dribbble.list-group-item-action:hover,
.list-group-item-dribbble.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-dribbble-border-subtle);
}
.list-group-item-dribbble.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-dribbble-text);
   --tblr-list-group-active-border-color: var(--tblr-dribbble-text);
}

.list-group-item-github {
   --tblr-list-group-color: var(--tblr-github-text);
   --tblr-list-group-bg: var(--tblr-github-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-github-border-subtle);
}
.list-group-item-github.list-group-item-action:hover,
.list-group-item-github.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-github-border-subtle);
}
.list-group-item-github.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-github-text);
   --tblr-list-group-active-border-color: var(--tblr-github-text);
}

.list-group-item-instagram {
   --tblr-list-group-color: var(--tblr-instagram-text);
   --tblr-list-group-bg: var(--tblr-instagram-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-instagram-border-subtle);
}
.list-group-item-instagram.list-group-item-action:hover,
.list-group-item-instagram.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-instagram-border-subtle);
}
.list-group-item-instagram.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-instagram-text);
   --tblr-list-group-active-border-color: var(--tblr-instagram-text);
}

.list-group-item-pinterest {
   --tblr-list-group-color: var(--tblr-pinterest-text);
   --tblr-list-group-bg: var(--tblr-pinterest-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-pinterest-border-subtle);
}
.list-group-item-pinterest.list-group-item-action:hover,
.list-group-item-pinterest.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-pinterest-border-subtle);
}
.list-group-item-pinterest.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-pinterest-text);
   --tblr-list-group-active-border-color: var(--tblr-pinterest-text);
}

.list-group-item-vk {
   --tblr-list-group-color: var(--tblr-vk-text);
   --tblr-list-group-bg: var(--tblr-vk-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-vk-border-subtle);
}
.list-group-item-vk.list-group-item-action:hover,
.list-group-item-vk.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-vk-border-subtle);
}
.list-group-item-vk.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-vk-text);
   --tblr-list-group-active-border-color: var(--tblr-vk-text);
}

.list-group-item-rss {
   --tblr-list-group-color: var(--tblr-rss-text);
   --tblr-list-group-bg: var(--tblr-rss-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-rss-border-subtle);
}
.list-group-item-rss.list-group-item-action:hover,
.list-group-item-rss.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-rss-border-subtle);
}
.list-group-item-rss.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-rss-text);
   --tblr-list-group-active-border-color: var(--tblr-rss-text);
}

.list-group-item-flickr {
   --tblr-list-group-color: var(--tblr-flickr-text);
   --tblr-list-group-bg: var(--tblr-flickr-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-flickr-border-subtle);
}
.list-group-item-flickr.list-group-item-action:hover,
.list-group-item-flickr.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-flickr-border-subtle);
}
.list-group-item-flickr.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-flickr-text);
   --tblr-list-group-active-border-color: var(--tblr-flickr-text);
}

.list-group-item-bitbucket {
   --tblr-list-group-color: var(--tblr-bitbucket-text);
   --tblr-list-group-bg: var(--tblr-bitbucket-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-bitbucket-border-subtle);
}
.list-group-item-bitbucket.list-group-item-action:hover,
.list-group-item-bitbucket.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-bitbucket-border-subtle);
}
.list-group-item-bitbucket.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-bitbucket-text);
   --tblr-list-group-active-border-color: var(--tblr-bitbucket-text);
}

.list-group-item-tabler {
   --tblr-list-group-color: var(--tblr-tabler-text);
   --tblr-list-group-bg: var(--tblr-tabler-bg-subtle);
   --tblr-list-group-border-color: var(--tblr-tabler-border-subtle);
}
.list-group-item-tabler.list-group-item-action:hover,
.list-group-item-tabler.list-group-item-action:focus {
   --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
   --tblr-list-group-action-hover-bg: var(--tblr-tabler-border-subtle);
}
.list-group-item-tabler.list-group-item-action:active {
   --tblr-list-group-active-color: var(--tblr-emphasis-color);
   --tblr-list-group-active-bg: var(--tblr-tabler-text);
   --tblr-list-group-active-border-color: var(--tblr-tabler-text);
}

.btn-close {
   --tblr-btn-close-color: #000000;
   --tblr-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
   --tblr-btn-close-opacity: 0.3;
   --tblr-btn-close-hover-opacity: 0.75;
   --tblr-btn-close-focus-shadow: 0 0 0 0.25rem rgba(32, 107, 196, 0.25);
   --tblr-btn-close-focus-opacity: 1;
   --tblr-btn-close-disabled-opacity: 0.25;
   --tblr-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
   box-sizing: content-box;
   width: 0.75rem;
   height: 0.75rem;
   padding: 0.25em 0.25em;
   color: var(--tblr-btn-close-color);
   background: transparent var(--tblr-btn-close-bg) center/0.75rem auto
      no-repeat;
   border: 0;
   border-radius: 4px;
   opacity: var(--tblr-btn-close-opacity);
}
.btn-close:hover {
   color: var(--tblr-btn-close-color);
   text-decoration: none;
   opacity: var(--tblr-btn-close-hover-opacity);
}
.btn-close:focus {
   outline: 0;
   box-shadow: var(--tblr-btn-close-focus-shadow);
   opacity: var(--tblr-btn-close-focus-opacity);
}
.btn-close:disabled,
.btn-close.disabled {
   pointer-events: none;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   opacity: var(--tblr-btn-close-disabled-opacity);
}

.btn-close-white {
   filter: var(--tblr-btn-close-white-filter);
}

[data-bs-theme="dark"] .btn-close {
   filter: var(--tblr-btn-close-white-filter);
}

.toast {
   --tblr-toast-zindex: 1090;
   --tblr-toast-padding-x: 0.75rem;
   --tblr-toast-padding-y: 0.5rem;
   --tblr-toast-spacing: var(--tblr-content-padding);
   --tblr-toast-max-width: 350px;
   --tblr-toast-font-size: 0.875rem;
   --tblr-toast-color: ;
   --tblr-toast-bg: rgba(var(--tblr-body-bg-rgb), 0.85);
   --tblr-toast-border-width: var(--tblr-border-width);
   --tblr-toast-border-color: var(--tblr-border-color);
   --tblr-toast-border-radius: var(--tblr-border-radius);
   --tblr-toast-box-shadow: var(--tblr-box-shadow);
   --tblr-toast-header-color: var(--tblr-muted);
   --tblr-toast-header-bg: rgba(var(--tblr-body-bg-rgb), 0.85);
   --tblr-toast-header-border-color: var(--tblr-border-color);
   width: var(--tblr-toast-max-width);
   max-width: 100%;
   font-size: var(--tblr-toast-font-size);
   color: var(--tblr-toast-color);
   pointer-events: auto;
   background-color: var(--tblr-toast-bg);
   background-clip: padding-box;
   border: var(--tblr-toast-border-width) solid var(--tblr-toast-border-color);
   box-shadow: var(--tblr-toast-box-shadow);
   border-radius: var(--tblr-toast-border-radius);
}
.toast.showing {
   opacity: 0;
}
.toast:not(.show) {
   display: none;
}

.toast-container {
   --tblr-toast-zindex: 1090;
   position: absolute;
   z-index: var(--tblr-toast-zindex);
   width: -webkit-max-content;
   width: -moz-max-content;
   width: max-content;
   max-width: 100%;
   pointer-events: none;
}
.toast-container > :not(:last-child) {
   margin-bottom: var(--tblr-toast-spacing);
}

.toast-header {
   display: flex;
   align-items: center;
   padding: var(--tblr-toast-padding-y) var(--tblr-toast-padding-x);
   color: var(--tblr-toast-header-color);
   background-color: var(--tblr-toast-header-bg);
   background-clip: padding-box;
   border-bottom: var(--tblr-toast-border-width) solid
      var(--tblr-toast-header-border-color);
   border-top-left-radius: calc(
      var(--tblr-toast-border-radius) - var(--tblr-toast-border-width)
   );
   border-top-right-radius: calc(
      var(--tblr-toast-border-radius) - var(--tblr-toast-border-width)
   );
}
.toast-header .btn-close {
   margin-right: calc(-0.5 * var(--tblr-toast-padding-x));
   margin-left: var(--tblr-toast-padding-x);
}

.toast-body {
   padding: var(--tblr-toast-padding-x);
   word-wrap: break-word;
}

.modal {
   --tblr-modal-zindex: 1055;
   --tblr-modal-width: 540px;
   --tblr-modal-padding: 1.5rem;
   --tblr-modal-margin: 0.5rem;
   --tblr-modal-color: ;
   --tblr-modal-bg: var(--tblr-bg-surface);
   --tblr-modal-border-color: transparent;
   --tblr-modal-border-width: var(--tblr-border-width);
   --tblr-modal-border-radius: var(--tblr-border-radius-lg);
   --tblr-modal-box-shadow: 0 0.125rem 0.25rem
      rgba(var(--tblr-body-color-rgb), 0.075);
   --tblr-modal-inner-border-radius: calc(
      var(--tblr-modal-border-radius) - 1px
   );
   --tblr-modal-header-padding-x: 1.5rem;
   --tblr-modal-header-padding-y: 1.5rem;
   --tblr-modal-header-padding: 1.5rem;
   --tblr-modal-header-border-color: var(--tblr-border-color);
   --tblr-modal-header-border-width: var(--tblr-border-width);
   --tblr-modal-title-line-height: 1.4285714286;
   --tblr-modal-footer-gap: 0.75rem;
   --tblr-modal-footer-bg: ;
   --tblr-modal-footer-border-color: var(--tblr-border-color);
   --tblr-modal-footer-border-width: 0;
   position: fixed;
   // display: none;
   top: 0;
   left: 0;
   z-index: var(--tblr-modal-zindex);
   width: 100%;
   height: 100%;
   overflow-x: hidden;
   overflow-y: auto;
   outline: 0;
}

.modal-dialog {
   position: relative;
   width: auto;
   margin: var(--tblr-modal-margin);
   pointer-events: none;
}
.modal.fade .modal-dialog {
   transition: transform 0.3s ease-out;
   transform: translate(0, -1rem);
}
@media (prefers-reduced-motion: reduce) {
   .modal.fade .modal-dialog {
      transition: none;
   }
}
.modal.show .modal-dialog {
   transform: none;
}
.modal.modal-static .modal-dialog {
   transform: scale(1.02);
}

.modal-dialog-scrollable {
   height: calc(100% - var(--tblr-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
   max-height: 100%;
   overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
   overflow-y: auto;
}

.modal-dialog-centered {
   display: flex;
   align-items: center;
   min-height: calc(100% - var(--tblr-modal-margin) * 2);
}

.modal-content {
   position: relative;
   display: flex;
   flex-direction: column;
   width: 100%;
   color: var(--tblr-modal-color);
   pointer-events: auto;
   background-color: var(--tblr-modal-bg);
   background-clip: padding-box;
   border: var(--tblr-modal-border-width) solid var(--tblr-modal-border-color);
   border-radius: var(--tblr-modal-border-radius);
   outline: 0;
}

.modal-backdrop {
   --tblr-backdrop-zindex: 1050;
   --tblr-backdrop-bg: #1d273b;
   --tblr-backdrop-opacity: 0.24;
   position: fixed;
   top: 0;
   left: 0;
   z-index: var(--tblr-backdrop-zindex);
   width: 100vw;
   height: 100vh;
   background-color: var(--tblr-backdrop-bg);
}
.modal-backdrop.fade {
   opacity: 0;
}
.modal-backdrop.show {
   opacity: var(--tblr-backdrop-opacity);
}

.modal-header .btn-close {
   padding: calc(var(--tblr-modal-header-padding-y) * 0.5)
      calc(var(--tblr-modal-header-padding-x) * 0.5);
   margin: calc(-0.5 * var(--tblr-modal-header-padding-y))
      calc(-0.5 * var(--tblr-modal-header-padding-x))
      calc(-0.5 * var(--tblr-modal-header-padding-y)) auto;
}

.modal-title {
   margin-bottom: 0;
   line-height: var(--tblr-modal-title-line-height);
}

.modal-body {
   position: relative;
   flex: 1 1 auto;
   padding: var(--tblr-modal-padding);
}

.modal-footer {
   display: flex;
   flex-shrink: 0;
   flex-wrap: wrap;
   align-items: center;
   justify-content: flex-end;
   padding: calc(
      var(--tblr-modal-padding) - var(--tblr-modal-footer-gap) * 0.5
   );
   background-color: var(--tblr-modal-footer-bg);
   border-top: var(--tblr-modal-footer-border-width) solid
      var(--tblr-modal-footer-border-color);
   border-bottom-right-radius: var(--tblr-modal-inner-border-radius);
   border-bottom-left-radius: var(--tblr-modal-inner-border-radius);
}
.modal-footer > * {
   margin: calc(var(--tblr-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
   .modal {
      --tblr-modal-margin: 1.75rem;
      --tblr-modal-box-shadow: 0 0.5rem 1rem
         rgba(var(--tblr-body-color-rgb), 0.15);
   }
   .modal-dialog {
      max-width: var(--tblr-modal-width);
      margin-right: auto;
      margin-left: auto;
   }
   .modal-sm {
      --tblr-modal-width: 380px;
   }
}
@media (min-width: 992px) {
   .modal-lg,
   .modal-xl {
      --tblr-modal-width: 860px;
   }
}
@media (min-width: 1200px) {
   .modal-xl {
      --tblr-modal-width: 1140px;
   }
}
.modal-fullscreen {
   width: 100vw;
   max-width: none;
   height: 100%;
   margin: 0;
}
.modal-fullscreen .modal-content {
   height: 100%;
   border: 0;
   border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
   border-radius: 0;
}
.modal-fullscreen .modal-body {
   overflow-y: auto;
}

@media (max-width: 575.98px) {
   .modal-fullscreen-sm-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
   }
   .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
   }
   .modal-fullscreen-sm-down .modal-header,
   .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0;
   }
   .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto;
   }
}
@media (max-width: 767.98px) {
   .modal-fullscreen-md-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
   }
   .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
   }
   .modal-fullscreen-md-down .modal-header,
   .modal-fullscreen-md-down .modal-footer {
      border-radius: 0;
   }
   .modal-fullscreen-md-down .modal-body {
      overflow-y: auto;
   }
}
@media (max-width: 991.98px) {
   .modal-fullscreen-lg-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
   }
   .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
   }
   .modal-fullscreen-lg-down .modal-header,
   .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0;
   }
   .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto;
   }
}
@media (max-width: 1199.98px) {
   .modal-fullscreen-xl-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
   }
   .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
   }
   .modal-fullscreen-xl-down .modal-header,
   .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0;
   }
   .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto;
   }
}
@media (max-width: 1399.98px) {
   .modal-fullscreen-xxl-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
   }
   .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
   }
   .modal-fullscreen-xxl-down .modal-header,
   .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0;
   }
   .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto;
   }
}
.tooltip {
   --tblr-tooltip-zindex: 1080;
   --tblr-tooltip-max-width: 200px;
   --tblr-tooltip-padding-x: 0.5rem;
   --tblr-tooltip-padding-y: 0.25rem;
   --tblr-tooltip-margin: ;
   --tblr-tooltip-font-size: 0.765625rem;
   --tblr-tooltip-color: var(--tblr-light);
   --tblr-tooltip-bg: var(--tblr-bg-surface-dark);
   --tblr-tooltip-border-radius: var(--tblr-border-radius);
   --tblr-tooltip-opacity: 0.9;
   --tblr-tooltip-arrow-width: 0.8rem;
   --tblr-tooltip-arrow-height: 0.4rem;
   z-index: var(--tblr-tooltip-zindex);
   display: block;
   padding: var(--tblr-tooltip-arrow-height);
   margin: var(--tblr-tooltip-margin);
   font-family: var(--tblr-font-sans-serif);
   font-style: normal;
   font-weight: 400;
   line-height: 1.4285714286;
   text-align: left;
   text-align: start;
   text-decoration: none;
   text-shadow: none;
   text-transform: none;
   letter-spacing: normal;
   word-break: normal;
   white-space: normal;
   word-spacing: normal;
   line-break: auto;
   font-size: var(--tblr-tooltip-font-size);
   word-wrap: break-word;
   opacity: 0;
}
.tooltip.show {
   opacity: var(--tblr-tooltip-opacity);
}
.tooltip .tooltip-arrow {
   display: block;
   width: var(--tblr-tooltip-arrow-width);
   height: var(--tblr-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
   position: absolute;
   content: "";
   border-color: transparent;
   border-style: solid;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
   bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
   top: -1px;
   border-width: var(--tblr-tooltip-arrow-height)
      calc(var(--tblr-tooltip-arrow-width) * 0.5) 0;
   border-top-color: var(--tblr-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
   left: 0;
   width: var(--tblr-tooltip-arrow-height);
   height: var(--tblr-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
   right: -1px;
   border-width: calc(var(--tblr-tooltip-arrow-width) * 0.5)
      var(--tblr-tooltip-arrow-height)
      calc(var(--tblr-tooltip-arrow-width) * 0.5) 0;
   border-right-color: var(--tblr-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
   top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
   bottom: -1px;
   border-width: 0 calc(var(--tblr-tooltip-arrow-width) * 0.5)
      var(--tblr-tooltip-arrow-height);
   border-bottom-color: var(--tblr-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
   right: 0;
   width: var(--tblr-tooltip-arrow-height);
   height: var(--tblr-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
   left: -1px;
   border-width: calc(var(--tblr-tooltip-arrow-width) * 0.5) 0
      calc(var(--tblr-tooltip-arrow-width) * 0.5)
      var(--tblr-tooltip-arrow-height);
   border-left-color: var(--tblr-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
   max-width: var(--tblr-tooltip-max-width);
   padding: var(--tblr-tooltip-padding-y) var(--tblr-tooltip-padding-x);
   color: var(--tblr-tooltip-color);
   text-align: center;
   background-color: var(--tblr-tooltip-bg);
   border-radius: var(--tblr-tooltip-border-radius);
}

.popover {
   --tblr-popover-zindex: 1070;
   --tblr-popover-max-width: 276px;
   --tblr-popover-font-size: 0.765625rem;
   --tblr-popover-bg: var(--tblr-bg-surface);
   --tblr-popover-border-width: var(--tblr-border-width);
   --tblr-popover-border-color: var(--tblr-border-color);
   --tblr-popover-border-radius: var(--tblr-border-radius-lg);
   --tblr-popover-inner-border-radius: calc(
      var(--tblr-border-radius-lg) - var(--tblr-border-width)
   );
   --tblr-popover-box-shadow: 0 0.5rem 1rem
      rgba(var(--tblr-body-color-rgb), 0.15);
   --tblr-popover-header-padding-x: 1rem;
   --tblr-popover-header-padding-y: 0.5rem;
   --tblr-popover-header-font-size: 0.875rem;
   --tblr-popover-header-color: ;
   --tblr-popover-header-bg: transparent;
   --tblr-popover-body-padding-x: 1rem;
   --tblr-popover-body-padding-y: 1rem;
   --tblr-popover-body-color: inherit;
   --tblr-popover-arrow-width: 1rem;
   --tblr-popover-arrow-height: 0.5rem;
   --tblr-popover-arrow-border: var(--tblr-popover-border-color);
   z-index: var(--tblr-popover-zindex);
   display: block;
   max-width: var(--tblr-popover-max-width);
   font-family: var(--tblr-font-sans-serif);
   font-style: normal;
   font-weight: 400;
   line-height: 1.4285714286;
   text-align: left;
   text-align: start;
   text-decoration: none;
   text-shadow: none;
   text-transform: none;
   letter-spacing: normal;
   word-break: normal;
   white-space: normal;
   word-spacing: normal;
   line-break: auto;
   font-size: var(--tblr-popover-font-size);
   word-wrap: break-word;
   background-color: var(--tblr-popover-bg);
   background-clip: padding-box;
   border: var(--tblr-popover-border-width) solid
      var(--tblr-popover-border-color);
   border-radius: var(--tblr-popover-border-radius);
}
.popover .popover-arrow {
   display: block;
   width: var(--tblr-popover-arrow-width);
   height: var(--tblr-popover-arrow-height);
}
.popover .popover-arrow::before,
.popover .popover-arrow::after {
   position: absolute;
   display: block;
   content: "";
   border-color: transparent;
   border-style: solid;
   border-width: 0;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
   bottom: calc(
      -1 * (var(--tblr-popover-arrow-height)) - var(--tblr-popover-border-width)
   );
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before,
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
   border-width: var(--tblr-popover-arrow-height)
      calc(var(--tblr-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
   bottom: 0;
   border-top-color: var(--tblr-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
   bottom: var(--tblr-popover-border-width);
   border-top-color: var(--tblr-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
   left: calc(
      -1 * (var(--tblr-popover-arrow-height)) - var(--tblr-popover-border-width)
   );
   width: var(--tblr-popover-arrow-height);
   height: var(--tblr-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
   border-width: calc(var(--tblr-popover-arrow-width) * 0.5)
      var(--tblr-popover-arrow-height)
      calc(var(--tblr-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
   left: 0;
   border-right-color: var(--tblr-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
   left: var(--tblr-popover-border-width);
   border-right-color: var(--tblr-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
   top: calc(
      -1 * (var(--tblr-popover-arrow-height)) - var(--tblr-popover-border-width)
   );
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
   border-width: 0 calc(var(--tblr-popover-arrow-width) * 0.5)
      var(--tblr-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
   top: 0;
   border-bottom-color: var(--tblr-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
   top: var(--tblr-popover-border-width);
   border-bottom-color: var(--tblr-popover-bg);
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
   position: absolute;
   top: 0;
   left: 50%;
   display: block;
   width: var(--tblr-popover-arrow-width);
   margin-left: calc(-0.5 * var(--tblr-popover-arrow-width));
   content: "";
   border-bottom: var(--tblr-popover-border-width) solid
      var(--tblr-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
   right: calc(
      -1 * (var(--tblr-popover-arrow-height)) - var(--tblr-popover-border-width)
   );
   width: var(--tblr-popover-arrow-height);
   height: var(--tblr-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
   border-width: calc(var(--tblr-popover-arrow-width) * 0.5) 0
      calc(var(--tblr-popover-arrow-width) * 0.5)
      var(--tblr-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
   right: 0;
   border-left-color: var(--tblr-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
   right: var(--tblr-popover-border-width);
   border-left-color: var(--tblr-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
   padding: var(--tblr-popover-header-padding-y)
      var(--tblr-popover-header-padding-x);
   margin-bottom: 0;
   font-size: var(--tblr-popover-header-font-size);
   color: var(--tblr-popover-header-color);
   background-color: var(--tblr-popover-header-bg);
   border-bottom: var(--tblr-popover-border-width) solid
      var(--tblr-popover-border-color);
   border-top-left-radius: var(--tblr-popover-inner-border-radius);
   border-top-right-radius: var(--tblr-popover-inner-border-radius);
}
.popover-header:empty {
   display: none;
}

.popover-body {
   padding: var(--tblr-popover-body-padding-y)
      var(--tblr-popover-body-padding-x);
   color: var(--tblr-popover-body-color);
}

.carousel {
   position: relative;
}

.carousel.pointer-event {
   touch-action: pan-y;
}

.carousel-inner {
   position: relative;
   width: 100%;
   overflow: hidden;
}
.carousel-inner::after {
   display: block;
   clear: both;
   content: "";
}

.carousel-item {
   position: relative;
   display: none;
   float: left;
   width: 100%;
   margin-right: -100%;
   -webkit-backface-visibility: hidden;
   backface-visibility: hidden;
   transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
   .carousel-item {
      transition: none;
   }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
   display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
   transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
   transform: translateX(-100%);
}

.carousel-fade .carousel-item {
   opacity: 0;
   transition-property: opacity;
   transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
   z-index: 1;
   opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
   z-index: 0;
   opacity: 0;
   transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
   .carousel-fade .active.carousel-item-start,
   .carousel-fade .active.carousel-item-end {
      transition: none;
   }
}

.carousel-control-prev,
.carousel-control-next {
   position: absolute;
   top: 0;
   bottom: 0;
   z-index: 1;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 15%;
   padding: 0;
   color: #ffffff;
   text-align: center;
   background: none;
   border: 0;
   opacity: 0.5;
   transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
   .carousel-control-prev,
   .carousel-control-next {
      transition: none;
   }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
   color: #ffffff;
   text-decoration: none;
   outline: 0;
   opacity: 0.9;
}

.carousel-control-prev {
   left: 0;
}

.carousel-control-next {
   right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
   display: inline-block;
   width: 1.5rem;
   height: 1.5rem;
   background-repeat: no-repeat;
   background-position: 50%;
   background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='15 18 9 12 15 6'%3e%3c/polyline%3e%3c/svg%3e");
}

.carousel-control-next-icon {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='9 18 15 12 9 6'%3e%3c/polyline%3e%3c/svg%3e");
}

.carousel-indicators {
   position: absolute;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 2;
   display: flex;
   justify-content: center;
   padding: 0;
   margin-right: 15%;
   margin-bottom: 1rem;
   margin-left: 15%;
   list-style: none;
}
.carousel-indicators [data-bs-target] {
   box-sizing: content-box;
   flex: 0 1 auto;
   width: 30px;
   height: 3px;
   padding: 0;
   margin-right: 3px;
   margin-left: 3px;
   text-indent: -999px;
   cursor: pointer;
   background-color: #ffffff;
   background-clip: padding-box;
   border: 0;
   border-top: 10px solid transparent;
   border-bottom: 10px solid transparent;
   opacity: 0.5;
   transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
   .carousel-indicators [data-bs-target] {
      transition: none;
   }
}
.carousel-indicators .active {
   opacity: 1;
}

.carousel-caption {
   position: absolute;
   right: 15%;
   bottom: 1.25rem;
   left: 15%;
   padding-top: 1.25rem;
   padding-bottom: 1.25rem;
   color: #ffffff;
   text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
   filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
   background-color: #000000;
}
.carousel-dark .carousel-caption {
   color: #000000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon {
   filter: invert(1) grayscale(100);
}
[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target] {
   background-color: #000000;
}
[data-bs-theme="dark"] .carousel .carousel-caption {
   color: #000000;
}

.spinner-grow,
.spinner-border {
   display: inline-block;
   width: var(--tblr-spinner-width);
   height: var(--tblr-spinner-height);
   vertical-align: var(--tblr-spinner-vertical-align);
   border-radius: 50%;
   animation: var(--tblr-spinner-animation-speed) linear infinite
      var(--tblr-spinner-animation-name);
}

@keyframes spinner-border {
   to {
      transform: rotate(360deg) /* rtl:ignore */;
   }
}
.spinner-border {
   --tblr-spinner-width: 1.5rem;
   --tblr-spinner-height: 1.5rem;
   --tblr-spinner-vertical-align: -0.125em;
   --tblr-spinner-border-width: 2px;
   --tblr-spinner-animation-speed: 0.75s;
   --tblr-spinner-animation-name: spinner-border;
   border: var(--tblr-spinner-border-width) solid currentcolor;
   border-right-color: transparent;
}

.spinner-border-sm {
   --tblr-spinner-width: 1rem;
   --tblr-spinner-height: 1rem;
   --tblr-spinner-border-width: 1px;
}

@keyframes spinner-grow {
   0% {
      transform: scale(0);
   }
   50% {
      opacity: 1;
      transform: none;
   }
}
.spinner-grow {
   --tblr-spinner-width: 1.5rem;
   --tblr-spinner-height: 1.5rem;
   --tblr-spinner-vertical-align: -0.125em;
   --tblr-spinner-animation-speed: 0.75s;
   --tblr-spinner-animation-name: spinner-grow;
   background-color: currentcolor;
   opacity: 0;
}

.spinner-grow-sm {
   --tblr-spinner-width: 1rem;
   --tblr-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
   .spinner-border,
   .spinner-grow {
      --tblr-spinner-animation-speed: 1.5s;
   }
}
.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
   --tblr-offcanvas-zindex: 1045;
   --tblr-offcanvas-width: 400px;
   --tblr-offcanvas-height: 30vh;
   --tblr-offcanvas-padding-x: 1.5rem;
   --tblr-offcanvas-padding-y: 1.5rem;
   --tblr-offcanvas-color: var(--tblr-body-color);
   --tblr-offcanvas-bg: var(--tblr-bg-surface);
   --tblr-offcanvas-border-width: var(--tblr-border-width);
   --tblr-offcanvas-border-color: var(--tblr-border-color);
   --tblr-offcanvas-box-shadow: 0 0.125rem 0.25rem
      rgba(var(--tblr-body-color-rgb), 0.075);
   --tblr-offcanvas-transition: transform 0.3s ease-in-out;
   --tblr-offcanvas-title-line-height: 1.4285714286;
}

@media (max-width: 575.98px) {
   .offcanvas-sm {
      position: fixed;
      bottom: 0;
      z-index: var(--tblr-offcanvas-zindex);
      display: flex;
      flex-direction: column;
      max-width: 100%;
      color: var(--tblr-offcanvas-color);
      visibility: hidden;
      background-color: var(--tblr-offcanvas-bg);
      background-clip: padding-box;
      outline: 0;
      transition: var(--tblr-offcanvas-transition);
   }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
   .offcanvas-sm {
      transition: none;
   }
}
@media (max-width: 575.98px) {
   .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--tblr-offcanvas-width);
      border-right: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateX(-100%);
   }
}
@media (max-width: 575.98px) {
   .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--tblr-offcanvas-width);
      border-left: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateX(100%);
   }
}
@media (max-width: 575.98px) {
   .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--tblr-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateY(-100%);
   }
}
@media (max-width: 575.98px) {
   .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--tblr-offcanvas-height);
      max-height: 100%;
      border-top: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateY(100%);
   }
}
@media (max-width: 575.98px) {
   .offcanvas-sm.showing,
   .offcanvas-sm.show:not(.hiding) {
      transform: none;
   }
}
@media (max-width: 575.98px) {
   .offcanvas-sm.showing,
   .offcanvas-sm.hiding,
   .offcanvas-sm.show {
      visibility: visible;
   }
}
@media (min-width: 576px) {
   .offcanvas-sm {
      --tblr-offcanvas-height: auto;
      --tblr-offcanvas-border-width: 0;
      background-color: transparent !important;
   }
   .offcanvas-sm .offcanvas-header {
      display: none;
   }
   .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important;
   }
}

@media (max-width: 767.98px) {
   .offcanvas-md {
      position: fixed;
      bottom: 0;
      z-index: var(--tblr-offcanvas-zindex);
      display: flex;
      flex-direction: column;
      max-width: 100%;
      color: var(--tblr-offcanvas-color);
      visibility: hidden;
      background-color: var(--tblr-offcanvas-bg);
      background-clip: padding-box;
      outline: 0;
      transition: var(--tblr-offcanvas-transition);
   }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
   .offcanvas-md {
      transition: none;
   }
}
@media (max-width: 767.98px) {
   .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--tblr-offcanvas-width);
      border-right: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateX(-100%);
   }
}
@media (max-width: 767.98px) {
   .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--tblr-offcanvas-width);
      border-left: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateX(100%);
   }
}
@media (max-width: 767.98px) {
   .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--tblr-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateY(-100%);
   }
}
@media (max-width: 767.98px) {
   .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--tblr-offcanvas-height);
      max-height: 100%;
      border-top: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateY(100%);
   }
}
@media (max-width: 767.98px) {
   .offcanvas-md.showing,
   .offcanvas-md.show:not(.hiding) {
      transform: none;
   }
}
@media (max-width: 767.98px) {
   .offcanvas-md.showing,
   .offcanvas-md.hiding,
   .offcanvas-md.show {
      visibility: visible;
   }
}
@media (min-width: 768px) {
   .offcanvas-md {
      --tblr-offcanvas-height: auto;
      --tblr-offcanvas-border-width: 0;
      background-color: transparent !important;
   }
   .offcanvas-md .offcanvas-header {
      display: none;
   }
   .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important;
   }
}

@media (max-width: 991.98px) {
   .offcanvas-lg {
      position: fixed;
      bottom: 0;
      z-index: var(--tblr-offcanvas-zindex);
      display: flex;
      flex-direction: column;
      max-width: 100%;
      color: var(--tblr-offcanvas-color);
      visibility: hidden;
      background-color: var(--tblr-offcanvas-bg);
      background-clip: padding-box;
      outline: 0;
      transition: var(--tblr-offcanvas-transition);
   }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
   .offcanvas-lg {
      transition: none;
   }
}
@media (max-width: 991.98px) {
   .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--tblr-offcanvas-width);
      border-right: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateX(-100%);
   }
}
@media (max-width: 991.98px) {
   .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--tblr-offcanvas-width);
      border-left: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateX(100%);
   }
}
@media (max-width: 991.98px) {
   .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--tblr-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateY(-100%);
   }
}
@media (max-width: 991.98px) {
   .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--tblr-offcanvas-height);
      max-height: 100%;
      border-top: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateY(100%);
   }
}
@media (max-width: 991.98px) {
   .offcanvas-lg.showing,
   .offcanvas-lg.show:not(.hiding) {
      transform: none;
   }
}
@media (max-width: 991.98px) {
   .offcanvas-lg.showing,
   .offcanvas-lg.hiding,
   .offcanvas-lg.show {
      visibility: visible;
   }
}
@media (min-width: 992px) {
   .offcanvas-lg {
      --tblr-offcanvas-height: auto;
      --tblr-offcanvas-border-width: 0;
      background-color: transparent !important;
   }
   .offcanvas-lg .offcanvas-header {
      display: none;
   }
   .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important;
   }
}

@media (max-width: 1199.98px) {
   .offcanvas-xl {
      position: fixed;
      bottom: 0;
      z-index: var(--tblr-offcanvas-zindex);
      display: flex;
      flex-direction: column;
      max-width: 100%;
      color: var(--tblr-offcanvas-color);
      visibility: hidden;
      background-color: var(--tblr-offcanvas-bg);
      background-clip: padding-box;
      outline: 0;
      transition: var(--tblr-offcanvas-transition);
   }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
   .offcanvas-xl {
      transition: none;
   }
}
@media (max-width: 1199.98px) {
   .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--tblr-offcanvas-width);
      border-right: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateX(-100%);
   }
}
@media (max-width: 1199.98px) {
   .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--tblr-offcanvas-width);
      border-left: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateX(100%);
   }
}
@media (max-width: 1199.98px) {
   .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--tblr-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateY(-100%);
   }
}
@media (max-width: 1199.98px) {
   .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--tblr-offcanvas-height);
      max-height: 100%;
      border-top: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateY(100%);
   }
}
@media (max-width: 1199.98px) {
   .offcanvas-xl.showing,
   .offcanvas-xl.show:not(.hiding) {
      transform: none;
   }
}
@media (max-width: 1199.98px) {
   .offcanvas-xl.showing,
   .offcanvas-xl.hiding,
   .offcanvas-xl.show {
      visibility: visible;
   }
}
@media (min-width: 1200px) {
   .offcanvas-xl {
      --tblr-offcanvas-height: auto;
      --tblr-offcanvas-border-width: 0;
      background-color: transparent !important;
   }
   .offcanvas-xl .offcanvas-header {
      display: none;
   }
   .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important;
   }
}

@media (max-width: 1399.98px) {
   .offcanvas-xxl {
      position: fixed;
      bottom: 0;
      z-index: var(--tblr-offcanvas-zindex);
      display: flex;
      flex-direction: column;
      max-width: 100%;
      color: var(--tblr-offcanvas-color);
      visibility: hidden;
      background-color: var(--tblr-offcanvas-bg);
      background-clip: padding-box;
      outline: 0;
      transition: var(--tblr-offcanvas-transition);
   }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
   .offcanvas-xxl {
      transition: none;
   }
}
@media (max-width: 1399.98px) {
   .offcanvas-xxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--tblr-offcanvas-width);
      border-right: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateX(-100%);
   }
}
@media (max-width: 1399.98px) {
   .offcanvas-xxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--tblr-offcanvas-width);
      border-left: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateX(100%);
   }
}
@media (max-width: 1399.98px) {
   .offcanvas-xxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--tblr-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateY(-100%);
   }
}
@media (max-width: 1399.98px) {
   .offcanvas-xxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--tblr-offcanvas-height);
      max-height: 100%;
      border-top: var(--tblr-offcanvas-border-width) solid
         var(--tblr-offcanvas-border-color);
      transform: translateY(100%);
   }
}
@media (max-width: 1399.98px) {
   .offcanvas-xxl.showing,
   .offcanvas-xxl.show:not(.hiding) {
      transform: none;
   }
}
@media (max-width: 1399.98px) {
   .offcanvas-xxl.showing,
   .offcanvas-xxl.hiding,
   .offcanvas-xxl.show {
      visibility: visible;
   }
}
@media (min-width: 1400px) {
   .offcanvas-xxl {
      --tblr-offcanvas-height: auto;
      --tblr-offcanvas-border-width: 0;
      background-color: transparent !important;
   }
   .offcanvas-xxl .offcanvas-header {
      display: none;
   }
   .offcanvas-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important;
   }
}

.offcanvas {
   position: fixed;
   bottom: 0;
   z-index: var(--tblr-offcanvas-zindex);
   display: flex;
   flex-direction: column;
   max-width: 100%;
   color: var(--tblr-offcanvas-color);
   visibility: hidden;
   background-color: var(--tblr-offcanvas-bg);
   background-clip: padding-box;
   outline: 0;
   transition: var(--tblr-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
   .offcanvas {
      transition: none;
   }
}
.offcanvas.offcanvas-start {
   top: 0;
   left: 0;
   width: var(--tblr-offcanvas-width);
   border-right: var(--tblr-offcanvas-border-width) solid
      var(--tblr-offcanvas-border-color);
   transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
   top: 0;
   right: 0;
   width: var(--tblr-offcanvas-width);
   border-left: var(--tblr-offcanvas-border-width) solid
      var(--tblr-offcanvas-border-color);
   transform: translateX(100%);
}
.offcanvas.offcanvas-top {
   top: 0;
   right: 0;
   left: 0;
   height: var(--tblr-offcanvas-height);
   max-height: 100%;
   border-bottom: var(--tblr-offcanvas-border-width) solid
      var(--tblr-offcanvas-border-color);
   transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
   right: 0;
   left: 0;
   height: var(--tblr-offcanvas-height);
   max-height: 100%;
   border-top: var(--tblr-offcanvas-border-width) solid
      var(--tblr-offcanvas-border-color);
   transform: translateY(100%);
}
.offcanvas.showing,
.offcanvas.show:not(.hiding) {
   transform: none;
}
.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
   visibility: visible;
}

.offcanvas-backdrop {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1040;
   width: 100vw;
   height: 100vh;
   background-color: #1d273b;
}
.offcanvas-backdrop.fade {
   opacity: 0;
}
.offcanvas-backdrop.show {
   opacity: 0.24;
}

.offcanvas-header {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: var(--tblr-offcanvas-padding-y) var(--tblr-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
   padding: calc(var(--tblr-offcanvas-padding-y) * 0.5)
      calc(var(--tblr-offcanvas-padding-x) * 0.5);
   margin-top: calc(-0.5 * var(--tblr-offcanvas-padding-y));
   margin-right: calc(-0.5 * var(--tblr-offcanvas-padding-x));
   margin-bottom: calc(-0.5 * var(--tblr-offcanvas-padding-y));
}

.offcanvas-title {
   margin-bottom: 0;
   line-height: var(--tblr-offcanvas-title-line-height);
}

.offcanvas-body {
   flex-grow: 1;
   padding: var(--tblr-offcanvas-padding-y) var(--tblr-offcanvas-padding-x);
   overflow-y: auto;
}

.placeholder {
   display: inline-block;
   min-height: 1em;
   vertical-align: middle;
   cursor: wait;
   background-color: currentcolor;
   opacity: 0.2;
}
.placeholder.btn::before {
   display: inline-block;
   content: "";
}

.placeholder-xs {
   min-height: 0.6em;
}

.placeholder-sm {
   min-height: 0.8em;
}

.placeholder-lg {
   min-height: 1.2em;
}

.placeholder-glow .placeholder {
   animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
   50% {
      opacity: 0.1;
   }
}
.placeholder-wave {
   -webkit-mask-image: linear-gradient(
      130deg,
      #000000 55%,
      rgba(0, 0, 0, 0.9) 75%,
      #000000 95%
   );
   mask-image: linear-gradient(
      130deg,
      #000000 55%,
      rgba(0, 0, 0, 0.9) 75%,
      #000000 95%
   );
   -webkit-mask-size: 200% 100%;
   mask-size: 200% 100%;
   animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
   100% {
      -webkit-mask-position: -200% 0%;
      mask-position: -200% 0%;
   }
}
.clearfix::after {
   display: block;
   clear: both;
   content: "";
}

.text-bg-primary {
   color: #f8fafc !important;
   background-color: RGBA(32, 107, 196, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-secondary {
   color: #f8fafc !important;
   background-color: RGBA(97, 104, 118, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-success {
   color: #f8fafc !important;
   background-color: RGBA(47, 179, 68, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-info {
   color: #f8fafc !important;
   background-color: RGBA(66, 153, 225, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-warning {
   color: #f8fafc !important;
   background-color: RGBA(247, 103, 7, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-danger {
   color: #f8fafc !important;
   background-color: RGBA(214, 57, 57, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-light {
   color: #1d273b !important;
   background-color: RGBA(248, 250, 252, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-dark {
   color: #f8fafc !important;
   background-color: RGBA(29, 39, 59, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-muted {
   color: #f8fafc !important;
   background-color: RGBA(97, 104, 118, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-blue {
   color: #f8fafc !important;
   background-color: RGBA(32, 107, 196, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-azure {
   color: #f8fafc !important;
   background-color: RGBA(66, 153, 225, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-indigo {
   color: #f8fafc !important;
   background-color: RGBA(66, 99, 235, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-purple {
   color: #f8fafc !important;
   background-color: RGBA(174, 62, 201, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-pink {
   color: #f8fafc !important;
   background-color: RGBA(214, 51, 108, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-red {
   color: #f8fafc !important;
   background-color: RGBA(214, 57, 57, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-orange {
   color: #f8fafc !important;
   background-color: RGBA(247, 103, 7, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-yellow {
   color: #f8fafc !important;
   background-color: RGBA(245, 159, 0, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-lime {
   color: #f8fafc !important;
   background-color: RGBA(116, 184, 22, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-green {
   color: #f8fafc !important;
   background-color: RGBA(47, 179, 68, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-teal {
   color: #f8fafc !important;
   background-color: RGBA(12, 166, 120, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-cyan {
   color: #f8fafc !important;
   background-color: RGBA(23, 162, 184, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-facebook {
   color: #f8fafc !important;
   background-color: RGBA(24, 119, 242, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-twitter {
   color: #f8fafc !important;
   background-color: RGBA(29, 161, 242, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-linkedin {
   color: #f8fafc !important;
   background-color: RGBA(10, 102, 194, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-google {
   color: #f8fafc !important;
   background-color: RGBA(220, 78, 65, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-youtube {
   color: #f8fafc !important;
   background-color: RGBA(255, 0, 0, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-vimeo {
   color: #f8fafc !important;
   background-color: RGBA(26, 183, 234, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-dribbble {
   color: #f8fafc !important;
   background-color: RGBA(234, 76, 137, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-github {
   color: #f8fafc !important;
   background-color: RGBA(24, 23, 23, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-instagram {
   color: #f8fafc !important;
   background-color: RGBA(228, 64, 95, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-pinterest {
   color: #f8fafc !important;
   background-color: RGBA(189, 8, 28, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-vk {
   color: #f8fafc !important;
   background-color: RGBA(99, 131, 168, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-rss {
   color: #f8fafc !important;
   background-color: RGBA(255, 165, 0, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-flickr {
   color: #f8fafc !important;
   background-color: RGBA(0, 99, 220, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-bitbucket {
   color: #f8fafc !important;
   background-color: RGBA(0, 82, 204, var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-tabler {
   color: #f8fafc !important;
   background-color: RGBA(32, 107, 196, var(--tblr-bg-opacity, 1)) !important;
}

.link-primary {
   color: #206bc4 !important;
}
.link-primary:hover,
.link-primary:focus {
   color: #1a569d !important;
}

.link-secondary {
   color: #616876 !important;
}
.link-secondary:hover,
.link-secondary:focus {
   color: #4e535e !important;
}

.link-success {
   color: #2fb344 !important;
}
.link-success:hover,
.link-success:focus {
   color: #268f36 !important;
}

.link-info {
   color: #4299e1 !important;
}
.link-info:hover,
.link-info:focus {
   color: #357ab4 !important;
}

.link-warning {
   color: #f76707 !important;
}
.link-warning:hover,
.link-warning:focus {
   color: #c65206 !important;
}

.link-danger {
   color: #d63939 !important;
}
.link-danger:hover,
.link-danger:focus {
   color: #ab2e2e !important;
}

.link-light {
   color: #f8fafc !important;
}
.link-light:hover,
.link-light:focus {
   color: #f9fbfd !important;
}

.link-dark {
   color: #1d273b !important;
}
.link-dark:hover,
.link-dark:focus {
   color: #171f2f !important;
}

.link-muted {
   color: #616876 !important;
}
.link-muted:hover,
.link-muted:focus {
   color: #4e535e !important;
}

.link-blue {
   color: #206bc4 !important;
}
.link-blue:hover,
.link-blue:focus {
   color: #1a569d !important;
}

.link-azure {
   color: #4299e1 !important;
}
.link-azure:hover,
.link-azure:focus {
   color: #357ab4 !important;
}

.link-indigo {
   color: #4263eb !important;
}
.link-indigo:hover,
.link-indigo:focus {
   color: #354fbc !important;
}

.link-purple {
   color: #ae3ec9 !important;
}
.link-purple:hover,
.link-purple:focus {
   color: #8b32a1 !important;
}

.link-pink {
   color: #d6336c !important;
}
.link-pink:hover,
.link-pink:focus {
   color: #ab2956 !important;
}

.link-red {
   color: #d63939 !important;
}
.link-red:hover,
.link-red:focus {
   color: #ab2e2e !important;
}

.link-orange {
   color: #f76707 !important;
}
.link-orange:hover,
.link-orange:focus {
   color: #c65206 !important;
}

.link-yellow {
   color: #f59f00 !important;
}
.link-yellow:hover,
.link-yellow:focus {
   color: #c47f00 !important;
}

.link-lime {
   color: #74b816 !important;
}
.link-lime:hover,
.link-lime:focus {
   color: #5d9312 !important;
}

.link-green {
   color: #2fb344 !important;
}
.link-green:hover,
.link-green:focus {
   color: #268f36 !important;
}

.link-teal {
   color: #0ca678 !important;
}
.link-teal:hover,
.link-teal:focus {
   color: #0a8560 !important;
}

.link-cyan {
   color: #17a2b8 !important;
}
.link-cyan:hover,
.link-cyan:focus {
   color: #128293 !important;
}

.link-facebook {
   color: #1877f2 !important;
}
.link-facebook:hover,
.link-facebook:focus {
   color: #135fc2 !important;
}

.link-twitter {
   color: #1da1f2 !important;
}
.link-twitter:hover,
.link-twitter:focus {
   color: #1781c2 !important;
}

.link-linkedin {
   color: #0a66c2 !important;
}
.link-linkedin:hover,
.link-linkedin:focus {
   color: #08529b !important;
}

.link-google {
   color: #dc4e41 !important;
}
.link-google:hover,
.link-google:focus {
   color: #b03e34 !important;
}

.link-youtube {
   color: #ff0000 !important;
}
.link-youtube:hover,
.link-youtube:focus {
   color: #cc0000 !important;
}

.link-vimeo {
   color: #1ab7ea !important;
}
.link-vimeo:hover,
.link-vimeo:focus {
   color: #1592bb !important;
}

.link-dribbble {
   color: #ea4c89 !important;
}
.link-dribbble:hover,
.link-dribbble:focus {
   color: #bb3d6e !important;
}

.link-github {
   color: #181717 !important;
}
.link-github:hover,
.link-github:focus {
   color: #131212 !important;
}

.link-instagram {
   color: #e4405f !important;
}
.link-instagram:hover,
.link-instagram:focus {
   color: #b6334c !important;
}

.link-pinterest {
   color: #bd081c !important;
}
.link-pinterest:hover,
.link-pinterest:focus {
   color: #970616 !important;
}

.link-vk {
   color: #6383a8 !important;
}
.link-vk:hover,
.link-vk:focus {
   color: #4f6986 !important;
}

.link-rss {
   color: #ffa500 !important;
}
.link-rss:hover,
.link-rss:focus {
   color: #cc8400 !important;
}

.link-flickr {
   color: #0063dc !important;
}
.link-flickr:hover,
.link-flickr:focus {
   color: #004fb0 !important;
}

.link-bitbucket {
   color: #0052cc !important;
}
.link-bitbucket:hover,
.link-bitbucket:focus {
   color: #0042a3 !important;
}

.link-tabler {
   color: #206bc4 !important;
}
.link-tabler:hover,
.link-tabler:focus {
   color: #1a569d !important;
}

.ratio {
   position: relative;
   width: 100%;
}
.ratio::before {
   display: block;
   padding-top: var(--tblr-aspect-ratio);
   content: "";
}
.ratio > * {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

.ratio-1x1 {
   --tblr-aspect-ratio: 100%;
}

.ratio-2x1 {
   --tblr-aspect-ratio: 50%;
}

.ratio-1x2 {
   --tblr-aspect-ratio: 200%;
}

.ratio-3x1 {
   --tblr-aspect-ratio: 33.3333333333%;
}

.ratio-1x3 {
   --tblr-aspect-ratio: 300%;
}

.ratio-4x3 {
   --tblr-aspect-ratio: 75%;
}

.ratio-3x4 {
   --tblr-aspect-ratio: 133.3333333333%;
}

.ratio-16x9 {
   --tblr-aspect-ratio: 56.25%;
}

.ratio-9x16 {
   --tblr-aspect-ratio: 177.7777777778%;
}

.ratio-21x9 {
   --tblr-aspect-ratio: 63%;
}

.ratio-9x21 {
   --tblr-aspect-ratio: 233.3333333333%;
}

.fixed-top {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   z-index: 1030;
}

.fixed-bottom {
   position: fixed;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 1030;
}

.sticky-top {
   position: -webkit-sticky;
   position: sticky;
   top: 0;
   z-index: 1020;
}

.sticky-bottom {
   position: -webkit-sticky;
   position: sticky;
   bottom: 0;
   z-index: 1020;
}

@media (min-width: 576px) {
   .sticky-sm-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
   }
   .sticky-sm-bottom {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      z-index: 1020;
   }
}
@media (min-width: 768px) {
   .sticky-md-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
   }
   .sticky-md-bottom {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      z-index: 1020;
   }
}
@media (min-width: 992px) {
   .sticky-lg-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
   }
   .sticky-lg-bottom {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      z-index: 1020;
   }
}
@media (min-width: 1200px) {
   .sticky-xl-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
   }
   .sticky-xl-bottom {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      z-index: 1020;
   }
}
@media (min-width: 1400px) {
   .sticky-xxl-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
   }
   .sticky-xxl-bottom {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      z-index: 1020;
   }
}
.hstack {
   display: flex;
   flex-direction: row;
   align-items: center;
   align-self: stretch;
}

.vstack {
   display: flex;
   flex: 1 1 auto;
   flex-direction: column;
   align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
   position: absolute !important;
   width: 1px !important;
   height: 1px !important;
   padding: 0 !important;
   margin: -1px !important;
   overflow: hidden !important;
   clip: rect(0, 0, 0, 0) !important;
   white-space: nowrap !important;
   border: 0 !important;
}

.stretched-link::after {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 1;
   content: "";
}

.text-truncate {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.vr {
   display: inline-block;
   align-self: stretch;
   width: 1px;
   min-height: 1em;
   background-color: currentcolor;
   opacity: 0.16;
}

.align-baseline {
   vertical-align: baseline !important;
}

.align-top {
   vertical-align: top !important;
}

.align-middle {
   vertical-align: middle !important;
}

.align-bottom {
   vertical-align: bottom !important;
}

.align-text-bottom {
   vertical-align: text-bottom !important;
}

.align-text-top {
   vertical-align: text-top !important;
}

.float-start {
   float: left !important;
}

.float-end {
   float: right !important;
}

.float-none {
   float: none !important;
}

.object-fit-contain {
   -o-object-fit: contain !important;
   object-fit: contain !important;
}

.object-fit-cover {
   -o-object-fit: cover !important;
   object-fit: cover !important;
}

.object-fit-fill {
   -o-object-fit: fill !important;
   object-fit: fill !important;
}

.object-fit-scale {
   -o-object-fit: scale-down !important;
   object-fit: scale-down !important;
}

.object-fit-none {
   -o-object-fit: none !important;
   object-fit: none !important;
}

.opacity-0 {
   opacity: 0 !important;
}

.opacity-25 {
   opacity: 0.25 !important;
}

.opacity-50 {
   opacity: 0.5 !important;
}

.opacity-75 {
   opacity: 0.75 !important;
}

.opacity-100 {
   opacity: 1 !important;
}

.overflow-auto {
   overflow: auto !important;
}

.overflow-hidden {
   overflow: hidden !important;
}

.overflow-visible {
   overflow: visible !important;
}

.overflow-scroll {
   overflow: scroll !important;
}

.overflow-x-auto {
   overflow-x: auto !important;
}

.overflow-x-hidden {
   overflow-x: hidden !important;
}

.overflow-x-visible {
   overflow-x: visible !important;
}

.overflow-x-scroll {
   overflow-x: scroll !important;
}

.overflow-y-auto {
   overflow-y: auto !important;
}

.overflow-y-hidden {
   overflow-y: hidden !important;
}

.overflow-y-visible {
   overflow-y: visible !important;
}

.overflow-y-scroll {
   overflow-y: scroll !important;
}

.d-inline {
   display: inline !important;
}

.d-inline-block {
   display: inline-block !important;
}

.d-block {
   display: block !important;
}

.d-grid {
   display: grid !important;
}

.d-table {
   display: table !important;
}

.d-table-row {
   display: table-row !important;
}

.d-table-cell {
   display: table-cell !important;
}

.d-flex {
   display: flex !important;
}

.d-inline-flex {
   display: inline-flex !important;
}

.d-none {
   display: none !important;
}

.shadow {
   box-shadow: 0 0.5rem 1rem rgba(var(--tblr-body-color-rgb), 0.15) !important;
}

.shadow-sm {
   box-shadow: 0 0.125rem 0.25rem rgba(var(--tblr-body-color-rgb), 0.075) !important;
}

.shadow-lg {
   box-shadow: 0 1rem 3rem rgba(var(--tblr-body-color-rgb), 0.175) !important;
}

.shadow-none {
   box-shadow: none !important;
}

.position-static {
   position: static !important;
}

.position-relative {
   position: relative !important;
}

.position-absolute {
   position: absolute !important;
}

.position-fixed {
   position: fixed !important;
}

.position-sticky {
   position: -webkit-sticky !important;
   position: sticky !important;
}

.top-0 {
   top: 0 !important;
}

.top-50 {
   top: 50% !important;
}

.top-100 {
   top: 100% !important;
}

.bottom-0 {
   bottom: 0 !important;
}

.bottom-50 {
   bottom: 50% !important;
}

.bottom-100 {
   bottom: 100% !important;
}

.start-0 {
   left: 0 !important;
}

.start-50 {
   left: 50% !important;
}

.start-100 {
   left: 100% !important;
}

.end-0 {
   right: 0 !important;
}

.end-50 {
   right: 50% !important;
}

.end-100 {
   right: 100% !important;
}

.translate-middle {
   transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
   transform: translateX(-50%) !important;
}

.translate-middle-y {
   transform: translateY(-50%) !important;
}

.border {
   border: 1px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
}

.border-wide {
   border: 2px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
}

.border-0 {
   border: 0 !important;
}

.border-top {
   border-top: 1px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
}

.border-top-wide {
   border-top: 2px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
}

.border-top-0 {
   border-top: 0 !important;
}

.border-end {
   border-right: 1px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
}

.border-end-wide {
   border-right: 2px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
}

.border-end-0 {
   border-right: 0 !important;
}

.border-bottom {
   border-bottom: 1px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
}

.border-bottom-wide {
   border-bottom: 2px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
}

.border-bottom-0 {
   border-bottom: 0 !important;
}

.border-start {
   border-left: 1px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
}

.border-start-wide {
   border-left: 2px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
}

.border-start-0 {
   border-left: 0 !important;
}

.border-primary {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-primary-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-secondary {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-secondary-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-success {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-success-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-info {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-info-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-warning {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-warning-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-danger {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-danger-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-light {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-light-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-dark {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-dark-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-muted {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-muted-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-blue {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-blue-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-azure {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-azure-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-indigo {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-indigo-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-purple {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-purple-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-pink {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-pink-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-red {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-red-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-orange {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-orange-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-yellow {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-yellow-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-lime {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-lime-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-green {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-green-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-teal {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-teal-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-cyan {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-cyan-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-facebook {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-facebook-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-twitter {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-twitter-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-linkedin {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-linkedin-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-google {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-google-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-youtube {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-youtube-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-vimeo {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-vimeo-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-dribbble {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-dribbble-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-github {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-github-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-instagram {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-instagram-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-pinterest {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-pinterest-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-vk {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-vk-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-rss {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-rss-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-flickr {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-flickr-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-bitbucket {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-bitbucket-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-tabler {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-tabler-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-white {
   --tblr-border-opacity: 1;
   border-color: rgba(
      var(--tblr-white-rgb),
      var(--tblr-border-opacity)
   ) !important;
}

.border-primary-subtle {
   border-color: var(--tblr-primary-border-subtle) !important;
}

.border-secondary-subtle {
   border-color: var(--tblr-secondary-border-subtle) !important;
}

.border-success-subtle {
   border-color: var(--tblr-success-border-subtle) !important;
}

.border-info-subtle {
   border-color: var(--tblr-info-border-subtle) !important;
}

.border-warning-subtle {
   border-color: var(--tblr-warning-border-subtle) !important;
}

.border-danger-subtle {
   border-color: var(--tblr-danger-border-subtle) !important;
}

.border-light-subtle {
   border-color: var(--tblr-light-border-subtle) !important;
}

.border-dark-subtle {
   border-color: var(--tblr-dark-border-subtle) !important;
}

.border-1 {
   --tblr-border-width: 1px;
}

.border-2 {
   --tblr-border-width: 2px;
}

.border-3 {
   --tblr-border-width: 3px;
}

.border-4 {
   --tblr-border-width: 4px;
}

.border-5 {
   --tblr-border-width: 5px;
}

.border-opacity-10 {
   --tblr-border-opacity: 0.1;
}

.border-opacity-25 {
   --tblr-border-opacity: 0.25;
}

.border-opacity-50 {
   --tblr-border-opacity: 0.5;
}

.border-opacity-75 {
   --tblr-border-opacity: 0.75;
}

.border-opacity-100 {
   --tblr-border-opacity: 1;
}

.w-0 {
   width: 0 !important;
}

.w-1 {
   width: 0.25rem !important;
}

.w-2 {
   width: 0.5rem !important;
}

.w-3 {
   width: 1rem !important;
}

.w-4 {
   width: 2rem !important;
}

.w-5 {
   width: 4rem !important;
}

.w-25 {
   width: 25% !important;
}

.w-33 {
   width: 33.33333% !important;
}

.w-50 {
   width: 50% !important;
}

.w-66 {
   width: 66.66666% !important;
}

.w-75 {
   width: 75% !important;
}

.w-100 {
   width: 100% !important;
}

.w-auto {
   width: auto !important;
}

.mw-100 {
   max-width: 100% !important;
}

.vw-100 {
   width: 100vw !important;
}

.min-vw-100 {
   min-width: 100vw !important;
}

.h-0 {
   height: 0 !important;
}

.h-1 {
   height: 0.25rem !important;
}

.h-2 {
   height: 0.5rem !important;
}

.h-3 {
   height: 1rem !important;
}

.h-4 {
   height: 2rem !important;
}

.h-5 {
   height: 4rem !important;
}

.h-25 {
   height: 25% !important;
}

.h-33 {
   height: 33.33333% !important;
}

.h-50 {
   height: 50% !important;
}

.h-66 {
   height: 66.66666% !important;
}

.h-75 {
   height: 75% !important;
}

.h-100 {
   height: 100% !important;
}

.h-auto {
   height: auto !important;
}

.mh-100 {
   max-height: 100% !important;
}

.vh-100 {
   height: 100vh !important;
}

.min-vh-100 {
   min-height: 100vh !important;
}

.flex-fill {
   flex: 1 1 auto !important;
}

.flex-row {
   flex-direction: row !important;
}

.flex-column {
   flex-direction: column !important;
}

.flex-row-reverse {
   flex-direction: row-reverse !important;
}

.flex-column-reverse {
   flex-direction: column-reverse !important;
}

.flex-grow-0 {
   flex-grow: 0 !important;
}

.flex-grow-1 {
   flex-grow: 1 !important;
}

.flex-shrink-0 {
   flex-shrink: 0 !important;
}

.flex-shrink-1 {
   flex-shrink: 1 !important;
}

.flex-wrap {
   flex-wrap: wrap !important;
}

.flex-nowrap {
   flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
   flex-wrap: wrap-reverse !important;
}

.justify-content-start {
   justify-content: flex-start !important;
}

.justify-content-end {
   justify-content: flex-end !important;
}

.justify-content-center {
   justify-content: center !important;
}

.justify-content-between {
   justify-content: space-between !important;
}

.justify-content-around {
   justify-content: space-around !important;
}

.justify-content-evenly {
   justify-content: space-evenly !important;
}

.align-items-start {
   align-items: flex-start !important;
}

.align-items-end {
   align-items: flex-end !important;
}

.align-items-center {
   align-items: center !important;
}

.align-items-baseline {
   align-items: baseline !important;
}

.align-items-stretch {
   align-items: stretch !important;
}

.align-content-start {
   align-content: flex-start !important;
}

.align-content-end {
   align-content: flex-end !important;
}

.align-content-center {
   align-content: center !important;
}

.align-content-between {
   align-content: space-between !important;
}

.align-content-around {
   align-content: space-around !important;
}

.align-content-stretch {
   align-content: stretch !important;
}

.align-self-auto {
   align-self: auto !important;
}

.align-self-start {
   align-self: flex-start !important;
}

.align-self-end {
   align-self: flex-end !important;
}

.align-self-center {
   align-self: center !important;
}

.align-self-baseline {
   align-self: baseline !important;
}

.align-self-stretch {
   align-self: stretch !important;
}

.order-first {
   order: -1 !important;
}

.order-0 {
   order: 0 !important;
}

.order-1 {
   order: 1 !important;
}

.order-2 {
   order: 2 !important;
}

.order-3 {
   order: 3 !important;
}

.order-4 {
   order: 4 !important;
}

.order-5 {
   order: 5 !important;
}

.order-last {
   order: 6 !important;
}

.m-0 {
   margin: 0 !important;
}

.m-1 {
   margin: 0.25rem !important;
}

.m-2 {
   margin: 0.5rem !important;
}

.m-3 {
   margin: 1rem !important;
}

.m-4 {
   margin: 2rem !important;
}

.m-5 {
   margin: 4rem !important;
}

.m-auto {
   margin: auto !important;
}

.mx-0 {
   margin-right: 0 !important;
   margin-left: 0 !important;
}

.mx-1 {
   margin-right: 0.25rem !important;
   margin-left: 0.25rem !important;
}

.mx-2 {
   margin-right: 0.5rem !important;
   margin-left: 0.5rem !important;
}

.mx-3 {
   margin-right: 1rem !important;
   margin-left: 1rem !important;
}

.mx-4 {
   margin-right: 2rem !important;
   margin-left: 2rem !important;
}

.mx-5 {
   margin-right: 4rem !important;
   margin-left: 4rem !important;
}

.mx-auto {
   margin-right: auto !important;
   margin-left: auto !important;
}

.my-0 {
   margin-top: 0 !important;
   margin-bottom: 0 !important;
}

.my-1 {
   margin-top: 0.25rem !important;
   margin-bottom: 0.25rem !important;
}

.my-2 {
   margin-top: 0.5rem !important;
   margin-bottom: 0.5rem !important;
}

.my-3 {
   margin-top: 1rem !important;
   margin-bottom: 1rem !important;
}

.my-4 {
   margin-top: 2rem !important;
   margin-bottom: 2rem !important;
}

.my-5 {
   margin-top: 4rem !important;
   margin-bottom: 4rem !important;
}

.my-auto {
   margin-top: auto !important;
   margin-bottom: auto !important;
}

.mt-0 {
   margin-top: 0 !important;
}

.mt-1 {
   margin-top: 0.25rem !important;
}

.mt-2 {
   margin-top: 0.5rem !important;
}

.mt-3 {
   margin-top: 1rem !important;
}

.mt-4 {
   margin-top: 2rem !important;
}

.mt-5 {
   margin-top: 4rem !important;
}

.mt-auto {
   margin-top: auto !important;
}

.me-0 {
   margin-right: 0 !important;
}

.me-1 {
   margin-right: 0.25rem !important;
}

.me-2 {
   margin-right: 0.5rem !important;
}

.me-3 {
   margin-right: 1rem !important;
}

.me-4 {
   margin-right: 2rem !important;
}

.me-5 {
   margin-right: 4rem !important;
}

.me-auto {
   margin-right: auto !important;
}

.mb-0 {
   margin-bottom: 0 !important;
}

.mb-1 {
   margin-bottom: 0.25rem !important;
}

.mb-2 {
   margin-bottom: 0.5rem !important;
}

.mb-3 {
   margin-bottom: 1rem !important;
}

.mb-4 {
   margin-bottom: 2rem !important;
}

.mb-5 {
   margin-bottom: 4rem !important;
}

.mb-auto {
   margin-bottom: auto !important;
}

.ms-0 {
   margin-left: 0 !important;
}

.ms-1 {
   margin-left: 0.25rem !important;
}

.ms-2 {
   margin-left: 0.5rem !important;
}

.ms-3 {
   margin-left: 1rem !important;
}

.ms-4 {
   margin-left: 2rem !important;
}

.ms-5 {
   margin-left: 4rem !important;
}

.ms-auto {
   margin-left: auto !important;
}

.p-0 {
   padding: 0 !important;
}

.p-1 {
   padding: 0.25rem !important;
}

.p-2 {
   padding: 0.5rem !important;
}

.p-3 {
   padding: 1rem !important;
}

.p-4 {
   padding: 2rem !important;
}

.p-5 {
   padding: 4rem !important;
}

.px-0 {
   padding-right: 0 !important;
   padding-left: 0 !important;
}

.px-1 {
   padding-right: 0.25rem !important;
   padding-left: 0.25rem !important;
}

.px-2 {
   padding-right: 0.5rem !important;
   padding-left: 0.5rem !important;
}

.px-3 {
   padding-right: 1rem !important;
   padding-left: 1rem !important;
}

.px-4 {
   padding-right: 2rem !important;
   padding-left: 2rem !important;
}

.px-5 {
   padding-right: 4rem !important;
   padding-left: 4rem !important;
}

.py-0 {
   padding-top: 0 !important;
   padding-bottom: 0 !important;
}

.py-1 {
   padding-top: 0.25rem !important;
   padding-bottom: 0.25rem !important;
}

.py-2 {
   padding-top: 0.5rem !important;
   padding-bottom: 0.5rem !important;
}

.py-3 {
   padding-top: 1rem !important;
   padding-bottom: 1rem !important;
}

.py-4 {
   padding-top: 2rem !important;
   padding-bottom: 2rem !important;
}

.py-5 {
   padding-top: 4rem !important;
   padding-bottom: 4rem !important;
}

.pt-0 {
   padding-top: 0 !important;
}

.pt-1 {
   padding-top: 0.25rem !important;
}

.pt-2 {
   padding-top: 0.5rem !important;
}

.pt-3 {
   padding-top: 1rem !important;
}

.pt-4 {
   padding-top: 2rem !important;
}

.pt-5 {
   padding-top: 4rem !important;
}

.pe-0 {
   padding-right: 0 !important;
}

.pe-1 {
   padding-right: 0.25rem !important;
}

.pe-2 {
   padding-right: 0.5rem !important;
}

.pe-3 {
   padding-right: 1rem !important;
}

.pe-4 {
   padding-right: 2rem !important;
}

.pe-5 {
   padding-right: 4rem !important;
}

.pb-0 {
   padding-bottom: 0 !important;
}

.pb-1 {
   padding-bottom: 0.25rem !important;
}

.pb-2 {
   padding-bottom: 0.5rem !important;
}

.pb-3 {
   padding-bottom: 1rem !important;
}

.pb-4 {
   padding-bottom: 2rem !important;
}

.pb-5 {
   padding-bottom: 4rem !important;
}

.ps-0 {
   padding-left: 0 !important;
}

.ps-1 {
   padding-left: 0.25rem !important;
}

.ps-2 {
   padding-left: 0.5rem !important;
}

.ps-3 {
   padding-left: 1rem !important;
}

.ps-4 {
   padding-left: 2rem !important;
}

.ps-5 {
   padding-left: 4rem !important;
}

.gap-0 {
   gap: 0 !important;
}

.gap-1 {
   gap: 0.25rem !important;
}

.gap-2 {
   gap: 0.5rem !important;
}

.gap-3 {
   gap: 1rem !important;
}

.gap-4 {
   gap: 2rem !important;
}

.gap-5 {
   gap: 4rem !important;
}

.row-gap-0 {
   row-gap: 0 !important;
}

.row-gap-1 {
   row-gap: 0.25rem !important;
}

.row-gap-2 {
   row-gap: 0.5rem !important;
}

.row-gap-3 {
   row-gap: 1rem !important;
}

.row-gap-4 {
   row-gap: 2rem !important;
}

.row-gap-5 {
   row-gap: 4rem !important;
}

.column-gap-0 {
   -moz-column-gap: 0 !important;
   column-gap: 0 !important;
}

.column-gap-1 {
   -moz-column-gap: 0.25rem !important;
   column-gap: 0.25rem !important;
}

.column-gap-2 {
   -moz-column-gap: 0.5rem !important;
   column-gap: 0.5rem !important;
}

.column-gap-3 {
   -moz-column-gap: 1rem !important;
   column-gap: 1rem !important;
}

.column-gap-4 {
   -moz-column-gap: 2rem !important;
   column-gap: 2rem !important;
}

.column-gap-5 {
   -moz-column-gap: 4rem !important;
   column-gap: 4rem !important;
}

.font-monospace {
   font-family: var(--tblr-font-monospace) !important;
}

.fs-1 {
   font-size: 1.5rem !important;
}

.fs-2 {
   font-size: 1.25rem !important;
}

.fs-3 {
   font-size: 1rem !important;
}

.fs-4 {
   font-size: 0.875rem !important;
}

.fs-5 {
   font-size: 0.75rem !important;
}

.fs-6 {
   font-size: 0.625rem !important;
}

.fst-italic {
   font-style: italic !important;
}

.fst-normal {
   font-style: normal !important;
}

.fw-lighter {
   font-weight: lighter !important;
}

.fw-light {
   font-weight: 300 !important;
}

.fw-normal {
   font-weight: 400 !important;
}

.fw-medium {
   font-weight: 500 !important;
}

.fw-semibold {
   font-weight: 600 !important;
}

.fw-bold {
   font-weight: 600 !important;
}

.fw-bolder {
   font-weight: bolder !important;
}

.lh-1 {
   line-height: 1 !important;
}

.lh-sm {
   line-height: 1.1428571429 !important;
}

.lh-base {
   line-height: 1.4285714286 !important;
}

.lh-lg {
   line-height: 1.7142857143 !important;
}

.text-start {
   text-align: left !important;
}

.text-end {
   text-align: right !important;
}

.text-center {
   text-align: center !important;
}

.text-decoration-none {
   text-decoration: none !important;
}

.text-decoration-underline {
   text-decoration: underline !important;
}

.text-decoration-line-through {
   text-decoration: line-through !important;
}

.text-lowercase {
   text-transform: lowercase !important;
}

.text-uppercase {
   text-transform: uppercase !important;
}

.text-capitalize {
   text-transform: capitalize !important;
}

.text-wrap {
   white-space: normal !important;
}

.text-nowrap {
   white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
   word-wrap: break-word !important;
   word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-primary-rgb), var(--tblr-text-opacity)) !important;
}

.text-secondary {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-secondary-rgb), var(--tblr-text-opacity)) !important;
}

.text-success {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-success-rgb), var(--tblr-text-opacity)) !important;
}

.text-info {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-info-rgb), var(--tblr-text-opacity)) !important;
}

.text-warning {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-warning-rgb), var(--tblr-text-opacity)) !important;
}

.text-danger {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-danger-rgb), var(--tblr-text-opacity)) !important;
}

.text-light {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-light-rgb), var(--tblr-text-opacity)) !important;
}

.text-dark {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-dark-rgb), var(--tblr-text-opacity)) !important;
}

.text-muted {
   --tblr-text-opacity: 1;
   color: #616876 !important;
}

.text-blue {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-blue-rgb), var(--tblr-text-opacity)) !important;
}

.text-azure {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-azure-rgb), var(--tblr-text-opacity)) !important;
}

.text-indigo {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-indigo-rgb), var(--tblr-text-opacity)) !important;
}

.text-purple {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-purple-rgb), var(--tblr-text-opacity)) !important;
}

.text-pink {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-pink-rgb), var(--tblr-text-opacity)) !important;
}

.text-red {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-red-rgb), var(--tblr-text-opacity)) !important;
}

.text-orange {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-orange-rgb), var(--tblr-text-opacity)) !important;
}

.text-yellow {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-yellow-rgb), var(--tblr-text-opacity)) !important;
}

.text-lime {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-lime-rgb), var(--tblr-text-opacity)) !important;
}

.text-green {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-green-rgb), var(--tblr-text-opacity)) !important;
}

.text-teal {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-teal-rgb), var(--tblr-text-opacity)) !important;
}

.text-cyan {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-cyan-rgb), var(--tblr-text-opacity)) !important;
}

.text-facebook {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-facebook-rgb), var(--tblr-text-opacity)) !important;
}

.text-twitter {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-twitter-rgb), var(--tblr-text-opacity)) !important;
}

.text-linkedin {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-linkedin-rgb), var(--tblr-text-opacity)) !important;
}

.text-google {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-google-rgb), var(--tblr-text-opacity)) !important;
}

.text-youtube {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-youtube-rgb), var(--tblr-text-opacity)) !important;
}

.text-vimeo {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-vimeo-rgb), var(--tblr-text-opacity)) !important;
}

.text-dribbble {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-dribbble-rgb), var(--tblr-text-opacity)) !important;
}

.text-github {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-github-rgb), var(--tblr-text-opacity)) !important;
}

.text-instagram {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-instagram-rgb), var(--tblr-text-opacity)) !important;
}

.text-pinterest {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-pinterest-rgb), var(--tblr-text-opacity)) !important;
}

.text-vk {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-vk-rgb), var(--tblr-text-opacity)) !important;
}

.text-rss {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-rss-rgb), var(--tblr-text-opacity)) !important;
}

.text-flickr {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-flickr-rgb), var(--tblr-text-opacity)) !important;
}

.text-bitbucket {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-bitbucket-rgb), var(--tblr-text-opacity)) !important;
}

.text-tabler {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-tabler-rgb), var(--tblr-text-opacity)) !important;
}

.text-black {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-black-rgb), var(--tblr-text-opacity)) !important;
}

.text-white {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-white-rgb), var(--tblr-text-opacity)) !important;
}

.text-body {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-body-color-rgb), var(--tblr-text-opacity)) !important;
}

.text-black-50 {
   --tblr-text-opacity: 1;
   color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
   --tblr-text-opacity: 1;
   color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
   --tblr-text-opacity: 1;
   color: var(--tblr-secondary-color) !important;
}

.text-body-tertiary {
   --tblr-text-opacity: 1;
   color: var(--tblr-tertiary-color) !important;
}

.text-body-emphasis {
   --tblr-text-opacity: 1;
   color: var(--tblr-emphasis-color) !important;
}

.text-reset {
   --tblr-text-opacity: 1;
   color: inherit !important;
}

.text-opacity-25 {
   --tblr-text-opacity: 0.25;
}

.text-opacity-50 {
   --tblr-text-opacity: 0.5;
}

.text-opacity-75 {
   --tblr-text-opacity: 0.75;
}

.text-opacity-100 {
   --tblr-text-opacity: 1;
}

.text-primary-emphasis {
   color: var(--tblr-primary-text) !important;
}

.text-secondary-emphasis {
   color: var(--tblr-secondary-text) !important;
}

.text-success-emphasis {
   color: var(--tblr-success-text) !important;
}

.text-info-emphasis {
   color: var(--tblr-info-text) !important;
}

.text-warning-emphasis {
   color: var(--tblr-warning-text) !important;
}

.text-danger-emphasis {
   color: var(--tblr-danger-text) !important;
}

.text-light-emphasis {
   color: var(--tblr-light-text) !important;
}

.text-dark-emphasis {
   color: var(--tblr-dark-text) !important;
}

.bg-primary {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-primary-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-secondary {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-secondary-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-success {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-success-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-info {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-info-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-warning {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-warning-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-danger {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-danger-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-light {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-light-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-dark {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-dark-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-muted {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-muted-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-blue {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-blue-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-azure {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-azure-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-indigo {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-indigo-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-purple {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-purple-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-pink {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-pink-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-red {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-red-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-orange {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-orange-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-yellow {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-yellow-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-lime {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-lime-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-green {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-green-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-teal {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-teal-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-cyan {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-cyan-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-facebook {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-facebook-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-twitter {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-twitter-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-linkedin {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-linkedin-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-google {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-google-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-youtube {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-youtube-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-vimeo {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-vimeo-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-dribbble {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-dribbble-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-github {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-github-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-instagram {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-instagram-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-pinterest {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-pinterest-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-vk {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-vk-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-rss {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-rss-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-flickr {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-flickr-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-bitbucket {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-bitbucket-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-tabler {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-tabler-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-black {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-black-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-white {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-white-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-body {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-body-bg-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-transparent {
   --tblr-bg-opacity: 1;
   background-color: transparent !important;
}

.bg-body-secondary {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-secondary-bg-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-body-tertiary {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-tertiary-bg-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-body-emphasis {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-emphasis-bg-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-opacity-10 {
   --tblr-bg-opacity: 0.1;
}

.bg-opacity-25 {
   --tblr-bg-opacity: 0.25;
}

.bg-opacity-50 {
   --tblr-bg-opacity: 0.5;
}

.bg-opacity-75 {
   --tblr-bg-opacity: 0.75;
}

.bg-opacity-100 {
   --tblr-bg-opacity: 1;
}

.bg-primary-subtle {
   background-color: var(--tblr-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
   background-color: var(--tblr-secondary-bg-subtle) !important;
}

.bg-success-subtle {
   background-color: var(--tblr-success-bg-subtle) !important;
}

.bg-info-subtle {
   background-color: var(--tblr-info-bg-subtle) !important;
}

.bg-warning-subtle {
   background-color: var(--tblr-warning-bg-subtle) !important;
}

.bg-danger-subtle {
   background-color: var(--tblr-danger-bg-subtle) !important;
}

.bg-light-subtle {
   background-color: var(--tblr-light-bg-subtle) !important;
}

.bg-dark-subtle {
   background-color: var(--tblr-dark-bg-subtle) !important;
}

.bg-gradient {
   background-image: var(--tblr-gradient) !important;
}

.user-select-all {
   -webkit-user-select: all !important;
   -moz-user-select: all !important;
   user-select: all !important;
}

.user-select-auto {
   -webkit-user-select: auto !important;
   -moz-user-select: auto !important;
   -ms-user-select: auto !important;
   user-select: auto !important;
}

.user-select-none {
   -webkit-user-select: none !important;
   -moz-user-select: none !important;
   -ms-user-select: none !important;
   user-select: none !important;
}

.pe-none {
   pointer-events: none !important;
}

.pe-auto {
   pointer-events: auto !important;
}

.rounded {
   border-radius: var(--tblr-border-radius) !important;
}

.rounded-0 {
   border-radius: 0 !important;
}

.rounded-1 {
   border-radius: var(--tblr-border-radius-sm) !important;
}

.rounded-2 {
   border-radius: var(--tblr-border-radius) !important;
}

.rounded-3 {
   border-radius: var(--tblr-border-radius-lg) !important;
}

.rounded-4 {
   border-radius: var(--tblr-border-radius-xl) !important;
}

.rounded-5 {
   border-radius: var(--tblr-border-radius-2xl) !important;
}

.rounded-circle {
   border-radius: 50% !important;
}

.rounded-pill {
   border-radius: var(--tblr-border-radius-pill) !important;
}

.rounded-top {
   border-top-left-radius: var(--tblr-border-radius) !important;
   border-top-right-radius: var(--tblr-border-radius) !important;
}

.rounded-top-0 {
   border-top-left-radius: 0 !important;
   border-top-right-radius: 0 !important;
}

.rounded-top-1 {
   border-top-left-radius: var(--tblr-border-radius-sm) !important;
   border-top-right-radius: var(--tblr-border-radius-sm) !important;
}

.rounded-top-2 {
   border-top-left-radius: var(--tblr-border-radius) !important;
   border-top-right-radius: var(--tblr-border-radius) !important;
}

.rounded-top-3 {
   border-top-left-radius: var(--tblr-border-radius-lg) !important;
   border-top-right-radius: var(--tblr-border-radius-lg) !important;
}

.rounded-top-4 {
   border-top-left-radius: var(--tblr-border-radius-xl) !important;
   border-top-right-radius: var(--tblr-border-radius-xl) !important;
}

.rounded-top-5 {
   border-top-left-radius: var(--tblr-border-radius-2xl) !important;
   border-top-right-radius: var(--tblr-border-radius-2xl) !important;
}

.rounded-top-circle {
   border-top-left-radius: 50% !important;
   border-top-right-radius: 50% !important;
}

.rounded-top-pill {
   border-top-left-radius: var(--tblr-border-radius-pill) !important;
   border-top-right-radius: var(--tblr-border-radius-pill) !important;
}

.rounded-end {
   border-top-right-radius: var(--tblr-border-radius) !important;
   border-bottom-right-radius: var(--tblr-border-radius) !important;
}

.rounded-end-0 {
   border-top-right-radius: 0 !important;
   border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
   border-top-right-radius: var(--tblr-border-radius-sm) !important;
   border-bottom-right-radius: var(--tblr-border-radius-sm) !important;
}

.rounded-end-2 {
   border-top-right-radius: var(--tblr-border-radius) !important;
   border-bottom-right-radius: var(--tblr-border-radius) !important;
}

.rounded-end-3 {
   border-top-right-radius: var(--tblr-border-radius-lg) !important;
   border-bottom-right-radius: var(--tblr-border-radius-lg) !important;
}

.rounded-end-4 {
   border-top-right-radius: var(--tblr-border-radius-xl) !important;
   border-bottom-right-radius: var(--tblr-border-radius-xl) !important;
}

.rounded-end-5 {
   border-top-right-radius: var(--tblr-border-radius-2xl) !important;
   border-bottom-right-radius: var(--tblr-border-radius-2xl) !important;
}

.rounded-end-circle {
   border-top-right-radius: 50% !important;
   border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
   border-top-right-radius: var(--tblr-border-radius-pill) !important;
   border-bottom-right-radius: var(--tblr-border-radius-pill) !important;
}

.rounded-bottom {
   border-bottom-right-radius: var(--tblr-border-radius) !important;
   border-bottom-left-radius: var(--tblr-border-radius) !important;
}

.rounded-bottom-0 {
   border-bottom-right-radius: 0 !important;
   border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
   border-bottom-right-radius: var(--tblr-border-radius-sm) !important;
   border-bottom-left-radius: var(--tblr-border-radius-sm) !important;
}

.rounded-bottom-2 {
   border-bottom-right-radius: var(--tblr-border-radius) !important;
   border-bottom-left-radius: var(--tblr-border-radius) !important;
}

.rounded-bottom-3 {
   border-bottom-right-radius: var(--tblr-border-radius-lg) !important;
   border-bottom-left-radius: var(--tblr-border-radius-lg) !important;
}

.rounded-bottom-4 {
   border-bottom-right-radius: var(--tblr-border-radius-xl) !important;
   border-bottom-left-radius: var(--tblr-border-radius-xl) !important;
}

.rounded-bottom-5 {
   border-bottom-right-radius: var(--tblr-border-radius-2xl) !important;
   border-bottom-left-radius: var(--tblr-border-radius-2xl) !important;
}

.rounded-bottom-circle {
   border-bottom-right-radius: 50% !important;
   border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
   border-bottom-right-radius: var(--tblr-border-radius-pill) !important;
   border-bottom-left-radius: var(--tblr-border-radius-pill) !important;
}

.rounded-start {
   border-bottom-left-radius: var(--tblr-border-radius) !important;
   border-top-left-radius: var(--tblr-border-radius) !important;
}

.rounded-start-0 {
   border-bottom-left-radius: 0 !important;
   border-top-left-radius: 0 !important;
}

.rounded-start-1 {
   border-bottom-left-radius: var(--tblr-border-radius-sm) !important;
   border-top-left-radius: var(--tblr-border-radius-sm) !important;
}

.rounded-start-2 {
   border-bottom-left-radius: var(--tblr-border-radius) !important;
   border-top-left-radius: var(--tblr-border-radius) !important;
}

.rounded-start-3 {
   border-bottom-left-radius: var(--tblr-border-radius-lg) !important;
   border-top-left-radius: var(--tblr-border-radius-lg) !important;
}

.rounded-start-4 {
   border-bottom-left-radius: var(--tblr-border-radius-xl) !important;
   border-top-left-radius: var(--tblr-border-radius-xl) !important;
}

.rounded-start-5 {
   border-bottom-left-radius: var(--tblr-border-radius-2xl) !important;
   border-top-left-radius: var(--tblr-border-radius-2xl) !important;
}

.rounded-start-circle {
   border-bottom-left-radius: 50% !important;
   border-top-left-radius: 50% !important;
}

.rounded-start-pill {
   border-bottom-left-radius: var(--tblr-border-radius-pill) !important;
   border-top-left-radius: var(--tblr-border-radius-pill) !important;
}

.visible {
   visibility: visible !important;
}

.invisible {
   visibility: hidden !important;
}

.z-n1 {
   z-index: -1 !important;
}

.z-0 {
   z-index: 0 !important;
}

.z-1 {
   z-index: 1 !important;
}

.z-2 {
   z-index: 2 !important;
}

.z-3 {
   z-index: 3 !important;
}

.object-contain {
   -o-object-fit: contain !important;
   object-fit: contain !important;
}

.object-cover {
   -o-object-fit: cover !important;
   object-fit: cover !important;
}

.object-fill {
   -o-object-fit: fill !important;
   object-fit: fill !important;
}

.object-scale-down {
   -o-object-fit: scale-down !important;
   object-fit: scale-down !important;
}

.object-none {
   -o-object-fit: none !important;
   object-fit: none !important;
}

.tracking-tight {
   letter-spacing: -0.05em !important;
}

.tracking-normal {
   letter-spacing: 0 !important;
}

.tracking-wide {
   letter-spacing: 0.05em !important;
}

.cursor-auto {
   cursor: auto !important;
}

.cursor-pointer {
   cursor: pointer !important;
}

.cursor-move {
   cursor: move !important;
}

.cursor-not-allowed {
   cursor: not-allowed !important;
}

.cursor-zoom-in {
   cursor: zoom-in !important;
}

.cursor-zoom-out {
   cursor: zoom-out !important;
}

.cursor-default {
   cursor: default !important;
}

.cursor-none {
   cursor: none !important;
}

.cursor-help {
   cursor: help !important;
}

.cursor-progress {
   cursor: progress !important;
}

.cursor-wait {
   cursor: wait !important;
}

.cursor-text {
   cursor: text !important;
}

.cursor-v-text {
   cursor: vertical-text !important;
}

.cursor-grab {
   cursor: -webkit-grab !important;
   cursor: grab !important;
}

.cursor-grabbing {
   cursor: -webkit-grabbing !important;
   cursor: grabbing !important;
}

.border-x {
   border-left: 1px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
   border-right: 1px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
}

.border-x-wide {
   border-left: 2px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
   border-right: 2px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
}

.border-x-0 {
   border-left: 0 !important;
   border-right: 0 !important;
}

.border-y {
   border-top: 1px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
   border-bottom: 1px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
}

.border-y-wide {
   border-top: 2px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
   border-bottom: 2px var(--tblr-border-style) rgba(97, 104, 118, 0.16) !important;
}

.border-y-0 {
   border-top: 0 !important;
   border-bottom: 0 !important;
}

.columns-2 {
   -moz-columns: 2 !important;
   columns: 2 !important;
}

.columns-3 {
   -moz-columns: 3 !important;
   columns: 3 !important;
}

.columns-4 {
   -moz-columns: 4 !important;
   columns: 4 !important;
}

@media (min-width: 576px) {
   .float-sm-start {
      float: left !important;
   }
   .float-sm-end {
      float: right !important;
   }
   .float-sm-none {
      float: none !important;
   }
   .object-fit-sm-contain {
      -o-object-fit: contain !important;
      object-fit: contain !important;
   }
   .object-fit-sm-cover {
      -o-object-fit: cover !important;
      object-fit: cover !important;
   }
   .object-fit-sm-fill {
      -o-object-fit: fill !important;
      object-fit: fill !important;
   }
   .object-fit-sm-scale {
      -o-object-fit: scale-down !important;
      object-fit: scale-down !important;
   }
   .object-fit-sm-none {
      -o-object-fit: none !important;
      object-fit: none !important;
   }
   .d-sm-inline {
      display: inline !important;
   }
   .d-sm-inline-block {
      display: inline-block !important;
   }
   .d-sm-block {
      display: block !important;
   }
   .d-sm-grid {
      display: grid !important;
   }
   .d-sm-table {
      display: table !important;
   }
   .d-sm-table-row {
      display: table-row !important;
   }
   .d-sm-table-cell {
      display: table-cell !important;
   }
   .d-sm-flex {
      display: flex !important;
   }
   .d-sm-inline-flex {
      display: inline-flex !important;
   }
   .d-sm-none {
      display: none !important;
   }
   .flex-sm-fill {
      flex: 1 1 auto !important;
   }
   .flex-sm-row {
      flex-direction: row !important;
   }
   .flex-sm-column {
      flex-direction: column !important;
   }
   .flex-sm-row-reverse {
      flex-direction: row-reverse !important;
   }
   .flex-sm-column-reverse {
      flex-direction: column-reverse !important;
   }
   .flex-sm-grow-0 {
      flex-grow: 0 !important;
   }
   .flex-sm-grow-1 {
      flex-grow: 1 !important;
   }
   .flex-sm-shrink-0 {
      flex-shrink: 0 !important;
   }
   .flex-sm-shrink-1 {
      flex-shrink: 1 !important;
   }
   .flex-sm-wrap {
      flex-wrap: wrap !important;
   }
   .flex-sm-nowrap {
      flex-wrap: nowrap !important;
   }
   .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important;
   }
   .justify-content-sm-start {
      justify-content: flex-start !important;
   }
   .justify-content-sm-end {
      justify-content: flex-end !important;
   }
   .justify-content-sm-center {
      justify-content: center !important;
   }
   .justify-content-sm-between {
      justify-content: space-between !important;
   }
   .justify-content-sm-around {
      justify-content: space-around !important;
   }
   .justify-content-sm-evenly {
      justify-content: space-evenly !important;
   }
   .align-items-sm-start {
      align-items: flex-start !important;
   }
   .align-items-sm-end {
      align-items: flex-end !important;
   }
   .align-items-sm-center {
      align-items: center !important;
   }
   .align-items-sm-baseline {
      align-items: baseline !important;
   }
   .align-items-sm-stretch {
      align-items: stretch !important;
   }
   .align-content-sm-start {
      align-content: flex-start !important;
   }
   .align-content-sm-end {
      align-content: flex-end !important;
   }
   .align-content-sm-center {
      align-content: center !important;
   }
   .align-content-sm-between {
      align-content: space-between !important;
   }
   .align-content-sm-around {
      align-content: space-around !important;
   }
   .align-content-sm-stretch {
      align-content: stretch !important;
   }
   .align-self-sm-auto {
      align-self: auto !important;
   }
   .align-self-sm-start {
      align-self: flex-start !important;
   }
   .align-self-sm-end {
      align-self: flex-end !important;
   }
   .align-self-sm-center {
      align-self: center !important;
   }
   .align-self-sm-baseline {
      align-self: baseline !important;
   }
   .align-self-sm-stretch {
      align-self: stretch !important;
   }
   .order-sm-first {
      order: -1 !important;
   }
   .order-sm-0 {
      order: 0 !important;
   }
   .order-sm-1 {
      order: 1 !important;
   }
   .order-sm-2 {
      order: 2 !important;
   }
   .order-sm-3 {
      order: 3 !important;
   }
   .order-sm-4 {
      order: 4 !important;
   }
   .order-sm-5 {
      order: 5 !important;
   }
   .order-sm-last {
      order: 6 !important;
   }
   .m-sm-0 {
      margin: 0 !important;
   }
   .m-sm-1 {
      margin: 0.25rem !important;
   }
   .m-sm-2 {
      margin: 0.5rem !important;
   }
   .m-sm-3 {
      margin: 1rem !important;
   }
   .m-sm-4 {
      margin: 2rem !important;
   }
   .m-sm-5 {
      margin: 4rem !important;
   }
   .m-sm-auto {
      margin: auto !important;
   }
   .mx-sm-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
   }
   .mx-sm-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
   }
   .mx-sm-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
   }
   .mx-sm-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
   }
   .mx-sm-4 {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
   }
   .mx-sm-5 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
   }
   .mx-sm-auto {
      margin-right: auto !important;
      margin-left: auto !important;
   }
   .my-sm-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
   }
   .my-sm-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
   }
   .my-sm-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
   }
   .my-sm-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
   }
   .my-sm-4 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
   }
   .my-sm-5 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
   }
   .my-sm-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
   }
   .mt-sm-0 {
      margin-top: 0 !important;
   }
   .mt-sm-1 {
      margin-top: 0.25rem !important;
   }
   .mt-sm-2 {
      margin-top: 0.5rem !important;
   }
   .mt-sm-3 {
      margin-top: 1rem !important;
   }
   .mt-sm-4 {
      margin-top: 2rem !important;
   }
   .mt-sm-5 {
      margin-top: 4rem !important;
   }
   .mt-sm-auto {
      margin-top: auto !important;
   }
   .me-sm-0 {
      margin-right: 0 !important;
   }
   .me-sm-1 {
      margin-right: 0.25rem !important;
   }
   .me-sm-2 {
      margin-right: 0.5rem !important;
   }
   .me-sm-3 {
      margin-right: 1rem !important;
   }
   .me-sm-4 {
      margin-right: 2rem !important;
   }
   .me-sm-5 {
      margin-right: 4rem !important;
   }
   .me-sm-auto {
      margin-right: auto !important;
   }
   .mb-sm-0 {
      margin-bottom: 0 !important;
   }
   .mb-sm-1 {
      margin-bottom: 0.25rem !important;
   }
   .mb-sm-2 {
      margin-bottom: 0.5rem !important;
   }
   .mb-sm-3 {
      margin-bottom: 1rem !important;
   }
   .mb-sm-4 {
      margin-bottom: 2rem !important;
   }
   .mb-sm-5 {
      margin-bottom: 4rem !important;
   }
   .mb-sm-auto {
      margin-bottom: auto !important;
   }
   .ms-sm-0 {
      margin-left: 0 !important;
   }
   .ms-sm-1 {
      margin-left: 0.25rem !important;
   }
   .ms-sm-2 {
      margin-left: 0.5rem !important;
   }
   .ms-sm-3 {
      margin-left: 1rem !important;
   }
   .ms-sm-4 {
      margin-left: 2rem !important;
   }
   .ms-sm-5 {
      margin-left: 4rem !important;
   }
   .ms-sm-auto {
      margin-left: auto !important;
   }
   .p-sm-0 {
      padding: 0 !important;
   }
   .p-sm-1 {
      padding: 0.25rem !important;
   }
   .p-sm-2 {
      padding: 0.5rem !important;
   }
   .p-sm-3 {
      padding: 1rem !important;
   }
   .p-sm-4 {
      padding: 2rem !important;
   }
   .p-sm-5 {
      padding: 4rem !important;
   }
   .px-sm-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
   }
   .px-sm-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
   }
   .px-sm-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
   }
   .px-sm-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
   }
   .px-sm-4 {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
   }
   .px-sm-5 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
   }
   .py-sm-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
   }
   .py-sm-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
   }
   .py-sm-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
   }
   .py-sm-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
   }
   .py-sm-4 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
   }
   .py-sm-5 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
   }
   .pt-sm-0 {
      padding-top: 0 !important;
   }
   .pt-sm-1 {
      padding-top: 0.25rem !important;
   }
   .pt-sm-2 {
      padding-top: 0.5rem !important;
   }
   .pt-sm-3 {
      padding-top: 1rem !important;
   }
   .pt-sm-4 {
      padding-top: 2rem !important;
   }
   .pt-sm-5 {
      padding-top: 4rem !important;
   }
   .pe-sm-0 {
      padding-right: 0 !important;
   }
   .pe-sm-1 {
      padding-right: 0.25rem !important;
   }
   .pe-sm-2 {
      padding-right: 0.5rem !important;
   }
   .pe-sm-3 {
      padding-right: 1rem !important;
   }
   .pe-sm-4 {
      padding-right: 2rem !important;
   }
   .pe-sm-5 {
      padding-right: 4rem !important;
   }
   .pb-sm-0 {
      padding-bottom: 0 !important;
   }
   .pb-sm-1 {
      padding-bottom: 0.25rem !important;
   }
   .pb-sm-2 {
      padding-bottom: 0.5rem !important;
   }
   .pb-sm-3 {
      padding-bottom: 1rem !important;
   }
   .pb-sm-4 {
      padding-bottom: 2rem !important;
   }
   .pb-sm-5 {
      padding-bottom: 4rem !important;
   }
   .ps-sm-0 {
      padding-left: 0 !important;
   }
   .ps-sm-1 {
      padding-left: 0.25rem !important;
   }
   .ps-sm-2 {
      padding-left: 0.5rem !important;
   }
   .ps-sm-3 {
      padding-left: 1rem !important;
   }
   .ps-sm-4 {
      padding-left: 2rem !important;
   }
   .ps-sm-5 {
      padding-left: 4rem !important;
   }
   .gap-sm-0 {
      gap: 0 !important;
   }
   .gap-sm-1 {
      gap: 0.25rem !important;
   }
   .gap-sm-2 {
      gap: 0.5rem !important;
   }
   .gap-sm-3 {
      gap: 1rem !important;
   }
   .gap-sm-4 {
      gap: 2rem !important;
   }
   .gap-sm-5 {
      gap: 4rem !important;
   }
   .row-gap-sm-0 {
      row-gap: 0 !important;
   }
   .row-gap-sm-1 {
      row-gap: 0.25rem !important;
   }
   .row-gap-sm-2 {
      row-gap: 0.5rem !important;
   }
   .row-gap-sm-3 {
      row-gap: 1rem !important;
   }
   .row-gap-sm-4 {
      row-gap: 2rem !important;
   }
   .row-gap-sm-5 {
      row-gap: 4rem !important;
   }
   .column-gap-sm-0 {
      -moz-column-gap: 0 !important;
      column-gap: 0 !important;
   }
   .column-gap-sm-1 {
      -moz-column-gap: 0.25rem !important;
      column-gap: 0.25rem !important;
   }
   .column-gap-sm-2 {
      -moz-column-gap: 0.5rem !important;
      column-gap: 0.5rem !important;
   }
   .column-gap-sm-3 {
      -moz-column-gap: 1rem !important;
      column-gap: 1rem !important;
   }
   .column-gap-sm-4 {
      -moz-column-gap: 2rem !important;
      column-gap: 2rem !important;
   }
   .column-gap-sm-5 {
      -moz-column-gap: 4rem !important;
      column-gap: 4rem !important;
   }
   .text-sm-start {
      text-align: left !important;
   }
   .text-sm-end {
      text-align: right !important;
   }
   .text-sm-center {
      text-align: center !important;
   }
   .columns-sm-2 {
      -moz-columns: 2 !important;
      columns: 2 !important;
   }
   .columns-sm-3 {
      -moz-columns: 3 !important;
      columns: 3 !important;
   }
   .columns-sm-4 {
      -moz-columns: 4 !important;
      columns: 4 !important;
   }
}
@media (min-width: 768px) {
   .float-md-start {
      float: left !important;
   }
   .float-md-end {
      float: right !important;
   }
   .float-md-none {
      float: none !important;
   }
   .object-fit-md-contain {
      -o-object-fit: contain !important;
      object-fit: contain !important;
   }
   .object-fit-md-cover {
      -o-object-fit: cover !important;
      object-fit: cover !important;
   }
   .object-fit-md-fill {
      -o-object-fit: fill !important;
      object-fit: fill !important;
   }
   .object-fit-md-scale {
      -o-object-fit: scale-down !important;
      object-fit: scale-down !important;
   }
   .object-fit-md-none {
      -o-object-fit: none !important;
      object-fit: none !important;
   }
   .d-md-inline {
      display: inline !important;
   }
   .d-md-inline-block {
      display: inline-block !important;
   }
   .d-md-block {
      display: block !important;
   }
   .d-md-grid {
      display: grid !important;
   }
   .d-md-table {
      display: table !important;
   }
   .d-md-table-row {
      display: table-row !important;
   }
   .d-md-table-cell {
      display: table-cell !important;
   }
   .d-md-flex {
      display: flex !important;
   }
   .d-md-inline-flex {
      display: inline-flex !important;
   }
   .d-md-none {
      display: none !important;
   }
   .flex-md-fill {
      flex: 1 1 auto !important;
   }
   .flex-md-row {
      flex-direction: row !important;
   }
   .flex-md-column {
      flex-direction: column !important;
   }
   .flex-md-row-reverse {
      flex-direction: row-reverse !important;
   }
   .flex-md-column-reverse {
      flex-direction: column-reverse !important;
   }
   .flex-md-grow-0 {
      flex-grow: 0 !important;
   }
   .flex-md-grow-1 {
      flex-grow: 1 !important;
   }
   .flex-md-shrink-0 {
      flex-shrink: 0 !important;
   }
   .flex-md-shrink-1 {
      flex-shrink: 1 !important;
   }
   .flex-md-wrap {
      flex-wrap: wrap !important;
   }
   .flex-md-nowrap {
      flex-wrap: nowrap !important;
   }
   .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important;
   }
   .justify-content-md-start {
      justify-content: flex-start !important;
   }
   .justify-content-md-end {
      justify-content: flex-end !important;
   }
   .justify-content-md-center {
      justify-content: center !important;
   }
   .justify-content-md-between {
      justify-content: space-between !important;
   }
   .justify-content-md-around {
      justify-content: space-around !important;
   }
   .justify-content-md-evenly {
      justify-content: space-evenly !important;
   }
   .align-items-md-start {
      align-items: flex-start !important;
   }
   .align-items-md-end {
      align-items: flex-end !important;
   }
   .align-items-md-center {
      align-items: center !important;
   }
   .align-items-md-baseline {
      align-items: baseline !important;
   }
   .align-items-md-stretch {
      align-items: stretch !important;
   }
   .align-content-md-start {
      align-content: flex-start !important;
   }
   .align-content-md-end {
      align-content: flex-end !important;
   }
   .align-content-md-center {
      align-content: center !important;
   }
   .align-content-md-between {
      align-content: space-between !important;
   }
   .align-content-md-around {
      align-content: space-around !important;
   }
   .align-content-md-stretch {
      align-content: stretch !important;
   }
   .align-self-md-auto {
      align-self: auto !important;
   }
   .align-self-md-start {
      align-self: flex-start !important;
   }
   .align-self-md-end {
      align-self: flex-end !important;
   }
   .align-self-md-center {
      align-self: center !important;
   }
   .align-self-md-baseline {
      align-self: baseline !important;
   }
   .align-self-md-stretch {
      align-self: stretch !important;
   }
   .order-md-first {
      order: -1 !important;
   }
   .order-md-0 {
      order: 0 !important;
   }
   .order-md-1 {
      order: 1 !important;
   }
   .order-md-2 {
      order: 2 !important;
   }
   .order-md-3 {
      order: 3 !important;
   }
   .order-md-4 {
      order: 4 !important;
   }
   .order-md-5 {
      order: 5 !important;
   }
   .order-md-last {
      order: 6 !important;
   }
   .m-md-0 {
      margin: 0 !important;
   }
   .m-md-1 {
      margin: 0.25rem !important;
   }
   .m-md-2 {
      margin: 0.5rem !important;
   }
   .m-md-3 {
      margin: 1rem !important;
   }
   .m-md-4 {
      margin: 2rem !important;
   }
   .m-md-5 {
      margin: 4rem !important;
   }
   .m-md-auto {
      margin: auto !important;
   }
   .mx-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
   }
   .mx-md-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
   }
   .mx-md-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
   }
   .mx-md-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
   }
   .mx-md-4 {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
   }
   .mx-md-5 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
   }
   .mx-md-auto {
      margin-right: auto !important;
      margin-left: auto !important;
   }
   .my-md-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
   }
   .my-md-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
   }
   .my-md-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
   }
   .my-md-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
   }
   .my-md-4 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
   }
   .my-md-5 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
   }
   .my-md-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
   }
   .mt-md-0 {
      margin-top: 0 !important;
   }
   .mt-md-1 {
      margin-top: 0.25rem !important;
   }
   .mt-md-2 {
      margin-top: 0.5rem !important;
   }
   .mt-md-3 {
      margin-top: 1rem !important;
   }
   .mt-md-4 {
      margin-top: 2rem !important;
   }
   .mt-md-5 {
      margin-top: 4rem !important;
   }
   .mt-md-auto {
      margin-top: auto !important;
   }
   .me-md-0 {
      margin-right: 0 !important;
   }
   .me-md-1 {
      margin-right: 0.25rem !important;
   }
   .me-md-2 {
      margin-right: 0.5rem !important;
   }
   .me-md-3 {
      margin-right: 1rem !important;
   }
   .me-md-4 {
      margin-right: 2rem !important;
   }
   .me-md-5 {
      margin-right: 4rem !important;
   }
   .me-md-auto {
      margin-right: auto !important;
   }
   .mb-md-0 {
      margin-bottom: 0 !important;
   }
   .mb-md-1 {
      margin-bottom: 0.25rem !important;
   }
   .mb-md-2 {
      margin-bottom: 0.5rem !important;
   }
   .mb-md-3 {
      margin-bottom: 1rem !important;
   }
   .mb-md-4 {
      margin-bottom: 2rem !important;
   }
   .mb-md-5 {
      margin-bottom: 4rem !important;
   }
   .mb-md-auto {
      margin-bottom: auto !important;
   }
   .ms-md-0 {
      margin-left: 0 !important;
   }
   .ms-md-1 {
      margin-left: 0.25rem !important;
   }
   .ms-md-2 {
      margin-left: 0.5rem !important;
   }
   .ms-md-3 {
      margin-left: 1rem !important;
   }
   .ms-md-4 {
      margin-left: 2rem !important;
   }
   .ms-md-5 {
      margin-left: 4rem !important;
   }
   .ms-md-auto {
      margin-left: auto !important;
   }
   .p-md-0 {
      padding: 0 !important;
   }
   .p-md-1 {
      padding: 0.25rem !important;
   }
   .p-md-2 {
      padding: 0.5rem !important;
   }
   .p-md-3 {
      padding: 1rem !important;
   }
   .p-md-4 {
      padding: 2rem !important;
   }
   .p-md-5 {
      padding: 4rem !important;
   }
   .px-md-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
   }
   .px-md-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
   }
   .px-md-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
   }
   .px-md-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
   }
   .px-md-4 {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
   }
   .px-md-5 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
   }
   .py-md-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
   }
   .py-md-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
   }
   .py-md-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
   }
   .py-md-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
   }
   .py-md-4 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
   }
   .py-md-5 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
   }
   .pt-md-0 {
      padding-top: 0 !important;
   }
   .pt-md-1 {
      padding-top: 0.25rem !important;
   }
   .pt-md-2 {
      padding-top: 0.5rem !important;
   }
   .pt-md-3 {
      padding-top: 1rem !important;
   }
   .pt-md-4 {
      padding-top: 2rem !important;
   }
   .pt-md-5 {
      padding-top: 4rem !important;
   }
   .pe-md-0 {
      padding-right: 0 !important;
   }
   .pe-md-1 {
      padding-right: 0.25rem !important;
   }
   .pe-md-2 {
      padding-right: 0.5rem !important;
   }
   .pe-md-3 {
      padding-right: 1rem !important;
   }
   .pe-md-4 {
      padding-right: 2rem !important;
   }
   .pe-md-5 {
      padding-right: 4rem !important;
   }
   .pb-md-0 {
      padding-bottom: 0 !important;
   }
   .pb-md-1 {
      padding-bottom: 0.25rem !important;
   }
   .pb-md-2 {
      padding-bottom: 0.5rem !important;
   }
   .pb-md-3 {
      padding-bottom: 1rem !important;
   }
   .pb-md-4 {
      padding-bottom: 2rem !important;
   }
   .pb-md-5 {
      padding-bottom: 4rem !important;
   }
   .ps-md-0 {
      padding-left: 0 !important;
   }
   .ps-md-1 {
      padding-left: 0.25rem !important;
   }
   .ps-md-2 {
      padding-left: 0.5rem !important;
   }
   .ps-md-3 {
      padding-left: 1rem !important;
   }
   .ps-md-4 {
      padding-left: 2rem !important;
   }
   .ps-md-5 {
      padding-left: 4rem !important;
   }
   .gap-md-0 {
      gap: 0 !important;
   }
   .gap-md-1 {
      gap: 0.25rem !important;
   }
   .gap-md-2 {
      gap: 0.5rem !important;
   }
   .gap-md-3 {
      gap: 1rem !important;
   }
   .gap-md-4 {
      gap: 2rem !important;
   }
   .gap-md-5 {
      gap: 4rem !important;
   }
   .row-gap-md-0 {
      row-gap: 0 !important;
   }
   .row-gap-md-1 {
      row-gap: 0.25rem !important;
   }
   .row-gap-md-2 {
      row-gap: 0.5rem !important;
   }
   .row-gap-md-3 {
      row-gap: 1rem !important;
   }
   .row-gap-md-4 {
      row-gap: 2rem !important;
   }
   .row-gap-md-5 {
      row-gap: 4rem !important;
   }
   .column-gap-md-0 {
      -moz-column-gap: 0 !important;
      column-gap: 0 !important;
   }
   .column-gap-md-1 {
      -moz-column-gap: 0.25rem !important;
      column-gap: 0.25rem !important;
   }
   .column-gap-md-2 {
      -moz-column-gap: 0.5rem !important;
      column-gap: 0.5rem !important;
   }
   .column-gap-md-3 {
      -moz-column-gap: 1rem !important;
      column-gap: 1rem !important;
   }
   .column-gap-md-4 {
      -moz-column-gap: 2rem !important;
      column-gap: 2rem !important;
   }
   .column-gap-md-5 {
      -moz-column-gap: 4rem !important;
      column-gap: 4rem !important;
   }
   .text-md-start {
      text-align: left !important;
   }
   .text-md-end {
      text-align: right !important;
   }
   .text-md-center {
      text-align: center !important;
   }
   .columns-md-2 {
      -moz-columns: 2 !important;
      columns: 2 !important;
   }
   .columns-md-3 {
      -moz-columns: 3 !important;
      columns: 3 !important;
   }
   .columns-md-4 {
      -moz-columns: 4 !important;
      columns: 4 !important;
   }
}
@media (min-width: 992px) {
   .float-lg-start {
      float: left !important;
   }
   .float-lg-end {
      float: right !important;
   }
   .float-lg-none {
      float: none !important;
   }
   .object-fit-lg-contain {
      -o-object-fit: contain !important;
      object-fit: contain !important;
   }
   .object-fit-lg-cover {
      -o-object-fit: cover !important;
      object-fit: cover !important;
   }
   .object-fit-lg-fill {
      -o-object-fit: fill !important;
      object-fit: fill !important;
   }
   .object-fit-lg-scale {
      -o-object-fit: scale-down !important;
      object-fit: scale-down !important;
   }
   .object-fit-lg-none {
      -o-object-fit: none !important;
      object-fit: none !important;
   }
   .d-lg-inline {
      display: inline !important;
   }
   .d-lg-inline-block {
      display: inline-block !important;
   }
   .d-lg-block {
      display: block !important;
   }
   .d-lg-grid {
      display: grid !important;
   }
   .d-lg-table {
      display: table !important;
   }
   .d-lg-table-row {
      display: table-row !important;
   }
   .d-lg-table-cell {
      display: table-cell !important;
   }
   .d-lg-flex {
      display: flex !important;
   }
   .d-lg-inline-flex {
      display: inline-flex !important;
   }
   .d-lg-none {
      display: none !important;
   }
   .flex-lg-fill {
      flex: 1 1 auto !important;
   }
   .flex-lg-row {
      flex-direction: row !important;
   }
   .flex-lg-column {
      flex-direction: column !important;
   }
   .flex-lg-row-reverse {
      flex-direction: row-reverse !important;
   }
   .flex-lg-column-reverse {
      flex-direction: column-reverse !important;
   }
   .flex-lg-grow-0 {
      flex-grow: 0 !important;
   }
   .flex-lg-grow-1 {
      flex-grow: 1 !important;
   }
   .flex-lg-shrink-0 {
      flex-shrink: 0 !important;
   }
   .flex-lg-shrink-1 {
      flex-shrink: 1 !important;
   }
   .flex-lg-wrap {
      flex-wrap: wrap !important;
   }
   .flex-lg-nowrap {
      flex-wrap: nowrap !important;
   }
   .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important;
   }
   .justify-content-lg-start {
      justify-content: flex-start !important;
   }
   .justify-content-lg-end {
      justify-content: flex-end !important;
   }
   .justify-content-lg-center {
      justify-content: center !important;
   }
   .justify-content-lg-between {
      justify-content: space-between !important;
   }
   .justify-content-lg-around {
      justify-content: space-around !important;
   }
   .justify-content-lg-evenly {
      justify-content: space-evenly !important;
   }
   .align-items-lg-start {
      align-items: flex-start !important;
   }
   .align-items-lg-end {
      align-items: flex-end !important;
   }
   .align-items-lg-center {
      align-items: center !important;
   }
   .align-items-lg-baseline {
      align-items: baseline !important;
   }
   .align-items-lg-stretch {
      align-items: stretch !important;
   }
   .align-content-lg-start {
      align-content: flex-start !important;
   }
   .align-content-lg-end {
      align-content: flex-end !important;
   }
   .align-content-lg-center {
      align-content: center !important;
   }
   .align-content-lg-between {
      align-content: space-between !important;
   }
   .align-content-lg-around {
      align-content: space-around !important;
   }
   .align-content-lg-stretch {
      align-content: stretch !important;
   }
   .align-self-lg-auto {
      align-self: auto !important;
   }
   .align-self-lg-start {
      align-self: flex-start !important;
   }
   .align-self-lg-end {
      align-self: flex-end !important;
   }
   .align-self-lg-center {
      align-self: center !important;
   }
   .align-self-lg-baseline {
      align-self: baseline !important;
   }
   .align-self-lg-stretch {
      align-self: stretch !important;
   }
   .order-lg-first {
      order: -1 !important;
   }
   .order-lg-0 {
      order: 0 !important;
   }
   .order-lg-1 {
      order: 1 !important;
   }
   .order-lg-2 {
      order: 2 !important;
   }
   .order-lg-3 {
      order: 3 !important;
   }
   .order-lg-4 {
      order: 4 !important;
   }
   .order-lg-5 {
      order: 5 !important;
   }
   .order-lg-last {
      order: 6 !important;
   }
   .m-lg-0 {
      margin: 0 !important;
   }
   .m-lg-1 {
      margin: 0.25rem !important;
   }
   .m-lg-2 {
      margin: 0.5rem !important;
   }
   .m-lg-3 {
      margin: 1rem !important;
   }
   .m-lg-4 {
      margin: 2rem !important;
   }
   .m-lg-5 {
      margin: 4rem !important;
   }
   .m-lg-auto {
      margin: auto !important;
   }
   .mx-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
   }
   .mx-lg-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
   }
   .mx-lg-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
   }
   .mx-lg-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
   }
   .mx-lg-4 {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
   }
   .mx-lg-5 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
   }
   .mx-lg-auto {
      margin-right: auto !important;
      margin-left: auto !important;
   }
   .my-lg-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
   }
   .my-lg-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
   }
   .my-lg-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
   }
   .my-lg-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
   }
   .my-lg-4 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
   }
   .my-lg-5 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
   }
   .my-lg-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
   }
   .mt-lg-0 {
      margin-top: 0 !important;
   }
   .mt-lg-1 {
      margin-top: 0.25rem !important;
   }
   .mt-lg-2 {
      margin-top: 0.5rem !important;
   }
   .mt-lg-3 {
      margin-top: 1rem !important;
   }
   .mt-lg-4 {
      margin-top: 2rem !important;
   }
   .mt-lg-5 {
      margin-top: 4rem !important;
   }
   .mt-lg-auto {
      margin-top: auto !important;
   }
   .me-lg-0 {
      margin-right: 0 !important;
   }
   .me-lg-1 {
      margin-right: 0.25rem !important;
   }
   .me-lg-2 {
      margin-right: 0.5rem !important;
   }
   .me-lg-3 {
      margin-right: 1rem !important;
   }
   .me-lg-4 {
      margin-right: 2rem !important;
   }
   .me-lg-5 {
      margin-right: 4rem !important;
   }
   .me-lg-auto {
      margin-right: auto !important;
   }
   .mb-lg-0 {
      margin-bottom: 0 !important;
   }
   .mb-lg-1 {
      margin-bottom: 0.25rem !important;
   }
   .mb-lg-2 {
      margin-bottom: 0.5rem !important;
   }
   .mb-lg-3 {
      margin-bottom: 1rem !important;
   }
   .mb-lg-4 {
      margin-bottom: 2rem !important;
   }
   .mb-lg-5 {
      margin-bottom: 4rem !important;
   }
   .mb-lg-auto {
      margin-bottom: auto !important;
   }
   .ms-lg-0 {
      margin-left: 0 !important;
   }
   .ms-lg-1 {
      margin-left: 0.25rem !important;
   }
   .ms-lg-2 {
      margin-left: 0.5rem !important;
   }
   .ms-lg-3 {
      margin-left: 1rem !important;
   }
   .ms-lg-4 {
      margin-left: 2rem !important;
   }
   .ms-lg-5 {
      margin-left: 4rem !important;
   }
   .ms-lg-auto {
      margin-left: auto !important;
   }
   .p-lg-0 {
      padding: 0 !important;
   }
   .p-lg-1 {
      padding: 0.25rem !important;
   }
   .p-lg-2 {
      padding: 0.5rem !important;
   }
   .p-lg-3 {
      padding: 1rem !important;
   }
   .p-lg-4 {
      padding: 2rem !important;
   }
   .p-lg-5 {
      padding: 4rem !important;
   }
   .px-lg-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
   }
   .px-lg-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
   }
   .px-lg-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
   }
   .px-lg-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
   }
   .px-lg-4 {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
   }
   .px-lg-5 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
   }
   .py-lg-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
   }
   .py-lg-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
   }
   .py-lg-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
   }
   .py-lg-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
   }
   .py-lg-4 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
   }
   .py-lg-5 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
   }
   .pt-lg-0 {
      padding-top: 0 !important;
   }
   .pt-lg-1 {
      padding-top: 0.25rem !important;
   }
   .pt-lg-2 {
      padding-top: 0.5rem !important;
   }
   .pt-lg-3 {
      padding-top: 1rem !important;
   }
   .pt-lg-4 {
      padding-top: 2rem !important;
   }
   .pt-lg-5 {
      padding-top: 4rem !important;
   }
   .pe-lg-0 {
      padding-right: 0 !important;
   }
   .pe-lg-1 {
      padding-right: 0.25rem !important;
   }
   .pe-lg-2 {
      padding-right: 0.5rem !important;
   }
   .pe-lg-3 {
      padding-right: 1rem !important;
   }
   .pe-lg-4 {
      padding-right: 2rem !important;
   }
   .pe-lg-5 {
      padding-right: 4rem !important;
   }
   .pb-lg-0 {
      padding-bottom: 0 !important;
   }
   .pb-lg-1 {
      padding-bottom: 0.25rem !important;
   }
   .pb-lg-2 {
      padding-bottom: 0.5rem !important;
   }
   .pb-lg-3 {
      padding-bottom: 1rem !important;
   }
   .pb-lg-4 {
      padding-bottom: 2rem !important;
   }
   .pb-lg-5 {
      padding-bottom: 4rem !important;
   }
   .ps-lg-0 {
      padding-left: 0 !important;
   }
   .ps-lg-1 {
      padding-left: 0.25rem !important;
   }
   .ps-lg-2 {
      padding-left: 0.5rem !important;
   }
   .ps-lg-3 {
      padding-left: 1rem !important;
   }
   .ps-lg-4 {
      padding-left: 2rem !important;
   }
   .ps-lg-5 {
      padding-left: 4rem !important;
   }
   .gap-lg-0 {
      gap: 0 !important;
   }
   .gap-lg-1 {
      gap: 0.25rem !important;
   }
   .gap-lg-2 {
      gap: 0.5rem !important;
   }
   .gap-lg-3 {
      gap: 1rem !important;
   }
   .gap-lg-4 {
      gap: 2rem !important;
   }
   .gap-lg-5 {
      gap: 4rem !important;
   }
   .row-gap-lg-0 {
      row-gap: 0 !important;
   }
   .row-gap-lg-1 {
      row-gap: 0.25rem !important;
   }
   .row-gap-lg-2 {
      row-gap: 0.5rem !important;
   }
   .row-gap-lg-3 {
      row-gap: 1rem !important;
   }
   .row-gap-lg-4 {
      row-gap: 2rem !important;
   }
   .row-gap-lg-5 {
      row-gap: 4rem !important;
   }
   .column-gap-lg-0 {
      -moz-column-gap: 0 !important;
      column-gap: 0 !important;
   }
   .column-gap-lg-1 {
      -moz-column-gap: 0.25rem !important;
      column-gap: 0.25rem !important;
   }
   .column-gap-lg-2 {
      -moz-column-gap: 0.5rem !important;
      column-gap: 0.5rem !important;
   }
   .column-gap-lg-3 {
      -moz-column-gap: 1rem !important;
      column-gap: 1rem !important;
   }
   .column-gap-lg-4 {
      -moz-column-gap: 2rem !important;
      column-gap: 2rem !important;
   }
   .column-gap-lg-5 {
      -moz-column-gap: 4rem !important;
      column-gap: 4rem !important;
   }
   .text-lg-start {
      text-align: left !important;
   }
   .text-lg-end {
      text-align: right !important;
   }
   .text-lg-center {
      text-align: center !important;
   }
   .columns-lg-2 {
      -moz-columns: 2 !important;
      columns: 2 !important;
   }
   .columns-lg-3 {
      -moz-columns: 3 !important;
      columns: 3 !important;
   }
   .columns-lg-4 {
      -moz-columns: 4 !important;
      columns: 4 !important;
   }
}
@media (min-width: 1200px) {
   .float-xl-start {
      float: left !important;
   }
   .float-xl-end {
      float: right !important;
   }
   .float-xl-none {
      float: none !important;
   }
   .object-fit-xl-contain {
      -o-object-fit: contain !important;
      object-fit: contain !important;
   }
   .object-fit-xl-cover {
      -o-object-fit: cover !important;
      object-fit: cover !important;
   }
   .object-fit-xl-fill {
      -o-object-fit: fill !important;
      object-fit: fill !important;
   }
   .object-fit-xl-scale {
      -o-object-fit: scale-down !important;
      object-fit: scale-down !important;
   }
   .object-fit-xl-none {
      -o-object-fit: none !important;
      object-fit: none !important;
   }
   .d-xl-inline {
      display: inline !important;
   }
   .d-xl-inline-block {
      display: inline-block !important;
   }
   .d-xl-block {
      display: block !important;
   }
   .d-xl-grid {
      display: grid !important;
   }
   .d-xl-table {
      display: table !important;
   }
   .d-xl-table-row {
      display: table-row !important;
   }
   .d-xl-table-cell {
      display: table-cell !important;
   }
   .d-xl-flex {
      display: flex !important;
   }
   .d-xl-inline-flex {
      display: inline-flex !important;
   }
   .d-xl-none {
      display: none !important;
   }
   .flex-xl-fill {
      flex: 1 1 auto !important;
   }
   .flex-xl-row {
      flex-direction: row !important;
   }
   .flex-xl-column {
      flex-direction: column !important;
   }
   .flex-xl-row-reverse {
      flex-direction: row-reverse !important;
   }
   .flex-xl-column-reverse {
      flex-direction: column-reverse !important;
   }
   .flex-xl-grow-0 {
      flex-grow: 0 !important;
   }
   .flex-xl-grow-1 {
      flex-grow: 1 !important;
   }
   .flex-xl-shrink-0 {
      flex-shrink: 0 !important;
   }
   .flex-xl-shrink-1 {
      flex-shrink: 1 !important;
   }
   .flex-xl-wrap {
      flex-wrap: wrap !important;
   }
   .flex-xl-nowrap {
      flex-wrap: nowrap !important;
   }
   .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
   }
   .justify-content-xl-start {
      justify-content: flex-start !important;
   }
   .justify-content-xl-end {
      justify-content: flex-end !important;
   }
   .justify-content-xl-center {
      justify-content: center !important;
   }
   .justify-content-xl-between {
      justify-content: space-between !important;
   }
   .justify-content-xl-around {
      justify-content: space-around !important;
   }
   .justify-content-xl-evenly {
      justify-content: space-evenly !important;
   }
   .align-items-xl-start {
      align-items: flex-start !important;
   }
   .align-items-xl-end {
      align-items: flex-end !important;
   }
   .align-items-xl-center {
      align-items: center !important;
   }
   .align-items-xl-baseline {
      align-items: baseline !important;
   }
   .align-items-xl-stretch {
      align-items: stretch !important;
   }
   .align-content-xl-start {
      align-content: flex-start !important;
   }
   .align-content-xl-end {
      align-content: flex-end !important;
   }
   .align-content-xl-center {
      align-content: center !important;
   }
   .align-content-xl-between {
      align-content: space-between !important;
   }
   .align-content-xl-around {
      align-content: space-around !important;
   }
   .align-content-xl-stretch {
      align-content: stretch !important;
   }
   .align-self-xl-auto {
      align-self: auto !important;
   }
   .align-self-xl-start {
      align-self: flex-start !important;
   }
   .align-self-xl-end {
      align-self: flex-end !important;
   }
   .align-self-xl-center {
      align-self: center !important;
   }
   .align-self-xl-baseline {
      align-self: baseline !important;
   }
   .align-self-xl-stretch {
      align-self: stretch !important;
   }
   .order-xl-first {
      order: -1 !important;
   }
   .order-xl-0 {
      order: 0 !important;
   }
   .order-xl-1 {
      order: 1 !important;
   }
   .order-xl-2 {
      order: 2 !important;
   }
   .order-xl-3 {
      order: 3 !important;
   }
   .order-xl-4 {
      order: 4 !important;
   }
   .order-xl-5 {
      order: 5 !important;
   }
   .order-xl-last {
      order: 6 !important;
   }
   .m-xl-0 {
      margin: 0 !important;
   }
   .m-xl-1 {
      margin: 0.25rem !important;
   }
   .m-xl-2 {
      margin: 0.5rem !important;
   }
   .m-xl-3 {
      margin: 1rem !important;
   }
   .m-xl-4 {
      margin: 2rem !important;
   }
   .m-xl-5 {
      margin: 4rem !important;
   }
   .m-xl-auto {
      margin: auto !important;
   }
   .mx-xl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
   }
   .mx-xl-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
   }
   .mx-xl-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
   }
   .mx-xl-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
   }
   .mx-xl-4 {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
   }
   .mx-xl-5 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
   }
   .mx-xl-auto {
      margin-right: auto !important;
      margin-left: auto !important;
   }
   .my-xl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
   }
   .my-xl-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
   }
   .my-xl-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
   }
   .my-xl-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
   }
   .my-xl-4 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
   }
   .my-xl-5 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
   }
   .my-xl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
   }
   .mt-xl-0 {
      margin-top: 0 !important;
   }
   .mt-xl-1 {
      margin-top: 0.25rem !important;
   }
   .mt-xl-2 {
      margin-top: 0.5rem !important;
   }
   .mt-xl-3 {
      margin-top: 1rem !important;
   }
   .mt-xl-4 {
      margin-top: 2rem !important;
   }
   .mt-xl-5 {
      margin-top: 4rem !important;
   }
   .mt-xl-auto {
      margin-top: auto !important;
   }
   .me-xl-0 {
      margin-right: 0 !important;
   }
   .me-xl-1 {
      margin-right: 0.25rem !important;
   }
   .me-xl-2 {
      margin-right: 0.5rem !important;
   }
   .me-xl-3 {
      margin-right: 1rem !important;
   }
   .me-xl-4 {
      margin-right: 2rem !important;
   }
   .me-xl-5 {
      margin-right: 4rem !important;
   }
   .me-xl-auto {
      margin-right: auto !important;
   }
   .mb-xl-0 {
      margin-bottom: 0 !important;
   }
   .mb-xl-1 {
      margin-bottom: 0.25rem !important;
   }
   .mb-xl-2 {
      margin-bottom: 0.5rem !important;
   }
   .mb-xl-3 {
      margin-bottom: 1rem !important;
   }
   .mb-xl-4 {
      margin-bottom: 2rem !important;
   }
   .mb-xl-5 {
      margin-bottom: 4rem !important;
   }
   .mb-xl-auto {
      margin-bottom: auto !important;
   }
   .ms-xl-0 {
      margin-left: 0 !important;
   }
   .ms-xl-1 {
      margin-left: 0.25rem !important;
   }
   .ms-xl-2 {
      margin-left: 0.5rem !important;
   }
   .ms-xl-3 {
      margin-left: 1rem !important;
   }
   .ms-xl-4 {
      margin-left: 2rem !important;
   }
   .ms-xl-5 {
      margin-left: 4rem !important;
   }
   .ms-xl-auto {
      margin-left: auto !important;
   }
   .p-xl-0 {
      padding: 0 !important;
   }
   .p-xl-1 {
      padding: 0.25rem !important;
   }
   .p-xl-2 {
      padding: 0.5rem !important;
   }
   .p-xl-3 {
      padding: 1rem !important;
   }
   .p-xl-4 {
      padding: 2rem !important;
   }
   .p-xl-5 {
      padding: 4rem !important;
   }
   .px-xl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
   }
   .px-xl-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
   }
   .px-xl-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
   }
   .px-xl-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
   }
   .px-xl-4 {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
   }
   .px-xl-5 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
   }
   .py-xl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
   }
   .py-xl-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
   }
   .py-xl-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
   }
   .py-xl-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
   }
   .py-xl-4 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
   }
   .py-xl-5 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
   }
   .pt-xl-0 {
      padding-top: 0 !important;
   }
   .pt-xl-1 {
      padding-top: 0.25rem !important;
   }
   .pt-xl-2 {
      padding-top: 0.5rem !important;
   }
   .pt-xl-3 {
      padding-top: 1rem !important;
   }
   .pt-xl-4 {
      padding-top: 2rem !important;
   }
   .pt-xl-5 {
      padding-top: 4rem !important;
   }
   .pe-xl-0 {
      padding-right: 0 !important;
   }
   .pe-xl-1 {
      padding-right: 0.25rem !important;
   }
   .pe-xl-2 {
      padding-right: 0.5rem !important;
   }
   .pe-xl-3 {
      padding-right: 1rem !important;
   }
   .pe-xl-4 {
      padding-right: 2rem !important;
   }
   .pe-xl-5 {
      padding-right: 4rem !important;
   }
   .pb-xl-0 {
      padding-bottom: 0 !important;
   }
   .pb-xl-1 {
      padding-bottom: 0.25rem !important;
   }
   .pb-xl-2 {
      padding-bottom: 0.5rem !important;
   }
   .pb-xl-3 {
      padding-bottom: 1rem !important;
   }
   .pb-xl-4 {
      padding-bottom: 2rem !important;
   }
   .pb-xl-5 {
      padding-bottom: 4rem !important;
   }
   .ps-xl-0 {
      padding-left: 0 !important;
   }
   .ps-xl-1 {
      padding-left: 0.25rem !important;
   }
   .ps-xl-2 {
      padding-left: 0.5rem !important;
   }
   .ps-xl-3 {
      padding-left: 1rem !important;
   }
   .ps-xl-4 {
      padding-left: 2rem !important;
   }
   .ps-xl-5 {
      padding-left: 4rem !important;
   }
   .gap-xl-0 {
      gap: 0 !important;
   }
   .gap-xl-1 {
      gap: 0.25rem !important;
   }
   .gap-xl-2 {
      gap: 0.5rem !important;
   }
   .gap-xl-3 {
      gap: 1rem !important;
   }
   .gap-xl-4 {
      gap: 2rem !important;
   }
   .gap-xl-5 {
      gap: 4rem !important;
   }
   .row-gap-xl-0 {
      row-gap: 0 !important;
   }
   .row-gap-xl-1 {
      row-gap: 0.25rem !important;
   }
   .row-gap-xl-2 {
      row-gap: 0.5rem !important;
   }
   .row-gap-xl-3 {
      row-gap: 1rem !important;
   }
   .row-gap-xl-4 {
      row-gap: 2rem !important;
   }
   .row-gap-xl-5 {
      row-gap: 4rem !important;
   }
   .column-gap-xl-0 {
      -moz-column-gap: 0 !important;
      column-gap: 0 !important;
   }
   .column-gap-xl-1 {
      -moz-column-gap: 0.25rem !important;
      column-gap: 0.25rem !important;
   }
   .column-gap-xl-2 {
      -moz-column-gap: 0.5rem !important;
      column-gap: 0.5rem !important;
   }
   .column-gap-xl-3 {
      -moz-column-gap: 1rem !important;
      column-gap: 1rem !important;
   }
   .column-gap-xl-4 {
      -moz-column-gap: 2rem !important;
      column-gap: 2rem !important;
   }
   .column-gap-xl-5 {
      -moz-column-gap: 4rem !important;
      column-gap: 4rem !important;
   }
   .text-xl-start {
      text-align: left !important;
   }
   .text-xl-end {
      text-align: right !important;
   }
   .text-xl-center {
      text-align: center !important;
   }
   .columns-xl-2 {
      -moz-columns: 2 !important;
      columns: 2 !important;
   }
   .columns-xl-3 {
      -moz-columns: 3 !important;
      columns: 3 !important;
   }
   .columns-xl-4 {
      -moz-columns: 4 !important;
      columns: 4 !important;
   }
}
@media (min-width: 1400px) {
   .float-xxl-start {
      float: left !important;
   }
   .float-xxl-end {
      float: right !important;
   }
   .float-xxl-none {
      float: none !important;
   }
   .object-fit-xxl-contain {
      -o-object-fit: contain !important;
      object-fit: contain !important;
   }
   .object-fit-xxl-cover {
      -o-object-fit: cover !important;
      object-fit: cover !important;
   }
   .object-fit-xxl-fill {
      -o-object-fit: fill !important;
      object-fit: fill !important;
   }
   .object-fit-xxl-scale {
      -o-object-fit: scale-down !important;
      object-fit: scale-down !important;
   }
   .object-fit-xxl-none {
      -o-object-fit: none !important;
      object-fit: none !important;
   }
   .d-xxl-inline {
      display: inline !important;
   }
   .d-xxl-inline-block {
      display: inline-block !important;
   }
   .d-xxl-block {
      display: block !important;
   }
   .d-xxl-grid {
      display: grid !important;
   }
   .d-xxl-table {
      display: table !important;
   }
   .d-xxl-table-row {
      display: table-row !important;
   }
   .d-xxl-table-cell {
      display: table-cell !important;
   }
   .d-xxl-flex {
      display: flex !important;
   }
   .d-xxl-inline-flex {
      display: inline-flex !important;
   }
   .d-xxl-none {
      display: none !important;
   }
   .flex-xxl-fill {
      flex: 1 1 auto !important;
   }
   .flex-xxl-row {
      flex-direction: row !important;
   }
   .flex-xxl-column {
      flex-direction: column !important;
   }
   .flex-xxl-row-reverse {
      flex-direction: row-reverse !important;
   }
   .flex-xxl-column-reverse {
      flex-direction: column-reverse !important;
   }
   .flex-xxl-grow-0 {
      flex-grow: 0 !important;
   }
   .flex-xxl-grow-1 {
      flex-grow: 1 !important;
   }
   .flex-xxl-shrink-0 {
      flex-shrink: 0 !important;
   }
   .flex-xxl-shrink-1 {
      flex-shrink: 1 !important;
   }
   .flex-xxl-wrap {
      flex-wrap: wrap !important;
   }
   .flex-xxl-nowrap {
      flex-wrap: nowrap !important;
   }
   .flex-xxl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
   }
   .justify-content-xxl-start {
      justify-content: flex-start !important;
   }
   .justify-content-xxl-end {
      justify-content: flex-end !important;
   }
   .justify-content-xxl-center {
      justify-content: center !important;
   }
   .justify-content-xxl-between {
      justify-content: space-between !important;
   }
   .justify-content-xxl-around {
      justify-content: space-around !important;
   }
   .justify-content-xxl-evenly {
      justify-content: space-evenly !important;
   }
   .align-items-xxl-start {
      align-items: flex-start !important;
   }
   .align-items-xxl-end {
      align-items: flex-end !important;
   }
   .align-items-xxl-center {
      align-items: center !important;
   }
   .align-items-xxl-baseline {
      align-items: baseline !important;
   }
   .align-items-xxl-stretch {
      align-items: stretch !important;
   }
   .align-content-xxl-start {
      align-content: flex-start !important;
   }
   .align-content-xxl-end {
      align-content: flex-end !important;
   }
   .align-content-xxl-center {
      align-content: center !important;
   }
   .align-content-xxl-between {
      align-content: space-between !important;
   }
   .align-content-xxl-around {
      align-content: space-around !important;
   }
   .align-content-xxl-stretch {
      align-content: stretch !important;
   }
   .align-self-xxl-auto {
      align-self: auto !important;
   }
   .align-self-xxl-start {
      align-self: flex-start !important;
   }
   .align-self-xxl-end {
      align-self: flex-end !important;
   }
   .align-self-xxl-center {
      align-self: center !important;
   }
   .align-self-xxl-baseline {
      align-self: baseline !important;
   }
   .align-self-xxl-stretch {
      align-self: stretch !important;
   }
   .order-xxl-first {
      order: -1 !important;
   }
   .order-xxl-0 {
      order: 0 !important;
   }
   .order-xxl-1 {
      order: 1 !important;
   }
   .order-xxl-2 {
      order: 2 !important;
   }
   .order-xxl-3 {
      order: 3 !important;
   }
   .order-xxl-4 {
      order: 4 !important;
   }
   .order-xxl-5 {
      order: 5 !important;
   }
   .order-xxl-last {
      order: 6 !important;
   }
   .m-xxl-0 {
      margin: 0 !important;
   }
   .m-xxl-1 {
      margin: 0.25rem !important;
   }
   .m-xxl-2 {
      margin: 0.5rem !important;
   }
   .m-xxl-3 {
      margin: 1rem !important;
   }
   .m-xxl-4 {
      margin: 2rem !important;
   }
   .m-xxl-5 {
      margin: 4rem !important;
   }
   .m-xxl-auto {
      margin: auto !important;
   }
   .mx-xxl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
   }
   .mx-xxl-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
   }
   .mx-xxl-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
   }
   .mx-xxl-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
   }
   .mx-xxl-4 {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
   }
   .mx-xxl-5 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
   }
   .mx-xxl-auto {
      margin-right: auto !important;
      margin-left: auto !important;
   }
   .my-xxl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
   }
   .my-xxl-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
   }
   .my-xxl-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
   }
   .my-xxl-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
   }
   .my-xxl-4 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
   }
   .my-xxl-5 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
   }
   .my-xxl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
   }
   .mt-xxl-0 {
      margin-top: 0 !important;
   }
   .mt-xxl-1 {
      margin-top: 0.25rem !important;
   }
   .mt-xxl-2 {
      margin-top: 0.5rem !important;
   }
   .mt-xxl-3 {
      margin-top: 1rem !important;
   }
   .mt-xxl-4 {
      margin-top: 2rem !important;
   }
   .mt-xxl-5 {
      margin-top: 4rem !important;
   }
   .mt-xxl-auto {
      margin-top: auto !important;
   }
   .me-xxl-0 {
      margin-right: 0 !important;
   }
   .me-xxl-1 {
      margin-right: 0.25rem !important;
   }
   .me-xxl-2 {
      margin-right: 0.5rem !important;
   }
   .me-xxl-3 {
      margin-right: 1rem !important;
   }
   .me-xxl-4 {
      margin-right: 2rem !important;
   }
   .me-xxl-5 {
      margin-right: 4rem !important;
   }
   .me-xxl-auto {
      margin-right: auto !important;
   }
   .mb-xxl-0 {
      margin-bottom: 0 !important;
   }
   .mb-xxl-1 {
      margin-bottom: 0.25rem !important;
   }
   .mb-xxl-2 {
      margin-bottom: 0.5rem !important;
   }
   .mb-xxl-3 {
      margin-bottom: 1rem !important;
   }
   .mb-xxl-4 {
      margin-bottom: 2rem !important;
   }
   .mb-xxl-5 {
      margin-bottom: 4rem !important;
   }
   .mb-xxl-auto {
      margin-bottom: auto !important;
   }
   .ms-xxl-0 {
      margin-left: 0 !important;
   }
   .ms-xxl-1 {
      margin-left: 0.25rem !important;
   }
   .ms-xxl-2 {
      margin-left: 0.5rem !important;
   }
   .ms-xxl-3 {
      margin-left: 1rem !important;
   }
   .ms-xxl-4 {
      margin-left: 2rem !important;
   }
   .ms-xxl-5 {
      margin-left: 4rem !important;
   }
   .ms-xxl-auto {
      margin-left: auto !important;
   }
   .p-xxl-0 {
      padding: 0 !important;
   }
   .p-xxl-1 {
      padding: 0.25rem !important;
   }
   .p-xxl-2 {
      padding: 0.5rem !important;
   }
   .p-xxl-3 {
      padding: 1rem !important;
   }
   .p-xxl-4 {
      padding: 2rem !important;
   }
   .p-xxl-5 {
      padding: 4rem !important;
   }
   .px-xxl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
   }
   .px-xxl-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
   }
   .px-xxl-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
   }
   .px-xxl-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
   }
   .px-xxl-4 {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
   }
   .px-xxl-5 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
   }
   .py-xxl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
   }
   .py-xxl-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
   }
   .py-xxl-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
   }
   .py-xxl-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
   }
   .py-xxl-4 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
   }
   .py-xxl-5 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
   }
   .pt-xxl-0 {
      padding-top: 0 !important;
   }
   .pt-xxl-1 {
      padding-top: 0.25rem !important;
   }
   .pt-xxl-2 {
      padding-top: 0.5rem !important;
   }
   .pt-xxl-3 {
      padding-top: 1rem !important;
   }
   .pt-xxl-4 {
      padding-top: 2rem !important;
   }
   .pt-xxl-5 {
      padding-top: 4rem !important;
   }
   .pe-xxl-0 {
      padding-right: 0 !important;
   }
   .pe-xxl-1 {
      padding-right: 0.25rem !important;
   }
   .pe-xxl-2 {
      padding-right: 0.5rem !important;
   }
   .pe-xxl-3 {
      padding-right: 1rem !important;
   }
   .pe-xxl-4 {
      padding-right: 2rem !important;
   }
   .pe-xxl-5 {
      padding-right: 4rem !important;
   }
   .pb-xxl-0 {
      padding-bottom: 0 !important;
   }
   .pb-xxl-1 {
      padding-bottom: 0.25rem !important;
   }
   .pb-xxl-2 {
      padding-bottom: 0.5rem !important;
   }
   .pb-xxl-3 {
      padding-bottom: 1rem !important;
   }
   .pb-xxl-4 {
      padding-bottom: 2rem !important;
   }
   .pb-xxl-5 {
      padding-bottom: 4rem !important;
   }
   .ps-xxl-0 {
      padding-left: 0 !important;
   }
   .ps-xxl-1 {
      padding-left: 0.25rem !important;
   }
   .ps-xxl-2 {
      padding-left: 0.5rem !important;
   }
   .ps-xxl-3 {
      padding-left: 1rem !important;
   }
   .ps-xxl-4 {
      padding-left: 2rem !important;
   }
   .ps-xxl-5 {
      padding-left: 4rem !important;
   }
   .gap-xxl-0 {
      gap: 0 !important;
   }
   .gap-xxl-1 {
      gap: 0.25rem !important;
   }
   .gap-xxl-2 {
      gap: 0.5rem !important;
   }
   .gap-xxl-3 {
      gap: 1rem !important;
   }
   .gap-xxl-4 {
      gap: 2rem !important;
   }
   .gap-xxl-5 {
      gap: 4rem !important;
   }
   .row-gap-xxl-0 {
      row-gap: 0 !important;
   }
   .row-gap-xxl-1 {
      row-gap: 0.25rem !important;
   }
   .row-gap-xxl-2 {
      row-gap: 0.5rem !important;
   }
   .row-gap-xxl-3 {
      row-gap: 1rem !important;
   }
   .row-gap-xxl-4 {
      row-gap: 2rem !important;
   }
   .row-gap-xxl-5 {
      row-gap: 4rem !important;
   }
   .column-gap-xxl-0 {
      -moz-column-gap: 0 !important;
      column-gap: 0 !important;
   }
   .column-gap-xxl-1 {
      -moz-column-gap: 0.25rem !important;
      column-gap: 0.25rem !important;
   }
   .column-gap-xxl-2 {
      -moz-column-gap: 0.5rem !important;
      column-gap: 0.5rem !important;
   }
   .column-gap-xxl-3 {
      -moz-column-gap: 1rem !important;
      column-gap: 1rem !important;
   }
   .column-gap-xxl-4 {
      -moz-column-gap: 2rem !important;
      column-gap: 2rem !important;
   }
   .column-gap-xxl-5 {
      -moz-column-gap: 4rem !important;
      column-gap: 4rem !important;
   }
   .text-xxl-start {
      text-align: left !important;
   }
   .text-xxl-end {
      text-align: right !important;
   }
   .text-xxl-center {
      text-align: center !important;
   }
   .columns-xxl-2 {
      -moz-columns: 2 !important;
      columns: 2 !important;
   }
   .columns-xxl-3 {
      -moz-columns: 3 !important;
      columns: 3 !important;
   }
   .columns-xxl-4 {
      -moz-columns: 4 !important;
      columns: 4 !important;
   }
}
@media print {
   .d-print-inline {
      display: inline !important;
   }
   .d-print-inline-block {
      display: inline-block !important;
   }
   .d-print-block {
      display: block !important;
   }
   .d-print-grid {
      display: grid !important;
   }
   .d-print-table {
      display: table !important;
   }
   .d-print-table-row {
      display: table-row !important;
   }
   .d-print-table-cell {
      display: table-cell !important;
   }
   .d-print-flex {
      display: flex !important;
   }
   .d-print-inline-flex {
      display: inline-flex !important;
   }
   .d-print-none {
      display: none !important;
   }
}
:root,
:host {
   font-size: 16px;
   height: 100%;
   --tblr-primary: #18a0fb;
   --tblr-primary-fg: var(--tblr-light);
   --tblr-primary-darken: #1d60b0;
   --tblr-primary-rgb: 32, 107, 196;
   --tblr-secondary: #616876;
   --tblr-secondary-fg: var(--tblr-light);
   --tblr-secondary-darken: #575e6a;
   --tblr-secondary-rgb: 97, 104, 118;
   --tblr-success: #2fb344;
   --tblr-success-fg: var(--tblr-light);
   --tblr-success-darken: #2aa13d;
   --tblr-success-rgb: 47, 179, 68;
   --tblr-info: #4299e1;
   --tblr-info-fg: var(--tblr-light);
   --tblr-info-darken: #3b8acb;
   --tblr-info-rgb: 66, 153, 225;
   --tblr-warning: #f76707;
   --tblr-warning-fg: var(--tblr-light);
   --tblr-warning-darken: #de5d06;
   --tblr-warning-rgb: 247, 103, 7;
   --tblr-danger: #d63939;
   --tblr-danger-fg: var(--tblr-light);
   --tblr-danger-darken: #c13333;
   --tblr-danger-rgb: 214, 57, 57;
   --tblr-light: #f8fafc;
   --tblr-light-fg: var(--tblr-dark);
   --tblr-light-darken: #dfe1e3;
   --tblr-light-rgb: 248, 250, 252;
   --tblr-dark: #1d273b;
   --tblr-dark-fg: var(--tblr-light);
   --tblr-dark-darken: #1a2335;
   --tblr-dark-rgb: 29, 39, 59;
   --tblr-muted: #616876;
   --tblr-muted-fg: var(--tblr-light);
   --tblr-muted-darken: #575e6a;
   --tblr-muted-rgb: 97, 104, 118;
   --tblr-blue: #206bc4;
   --tblr-blue-fg: var(--tblr-light);
   --tblr-blue-darken: #1d60b0;
   --tblr-blue-rgb: 32, 107, 196;
   --tblr-azure: #4299e1;
   --tblr-azure-fg: var(--tblr-light);
   --tblr-azure-darken: #3b8acb;
   --tblr-azure-rgb: 66, 153, 225;
   --tblr-indigo: #4263eb;
   --tblr-indigo-fg: var(--tblr-light);
   --tblr-indigo-darken: #3b59d4;
   --tblr-indigo-rgb: 66, 99, 235;
   --tblr-purple: #ae3ec9;
   --tblr-purple-fg: var(--tblr-light);
   --tblr-purple-darken: #9d38b5;
   --tblr-purple-rgb: 174, 62, 201;
   --tblr-pink: #d6336c;
   --tblr-pink-fg: var(--tblr-light);
   --tblr-pink-darken: #c12e61;
   --tblr-pink-rgb: 214, 51, 108;
   --tblr-red: #d63939;
   --tblr-red-fg: var(--tblr-light);
   --tblr-red-darken: #c13333;
   --tblr-red-rgb: 214, 57, 57;
   --tblr-orange: #f76707;
   --tblr-orange-fg: var(--tblr-light);
   --tblr-orange-darken: #de5d06;
   --tblr-orange-rgb: 247, 103, 7;
   --tblr-yellow: #f59f00;
   --tblr-yellow-fg: var(--tblr-light);
   --tblr-yellow-darken: #dd8f00;
   --tblr-yellow-rgb: 245, 159, 0;
   --tblr-lime: #74b816;
   --tblr-lime-fg: var(--tblr-light);
   --tblr-lime-darken: #68a614;
   --tblr-lime-rgb: 116, 184, 22;
   --tblr-green: #2fb344;
   --tblr-green-fg: var(--tblr-light);
   --tblr-green-darken: #2aa13d;
   --tblr-green-rgb: 47, 179, 68;
   --tblr-teal: #0ca678;
   --tblr-teal-fg: var(--tblr-light);
   --tblr-teal-darken: #0b956c;
   --tblr-teal-rgb: 12, 166, 120;
   --tblr-cyan: #17a2b8;
   --tblr-cyan-fg: var(--tblr-light);
   --tblr-cyan-darken: #1592a6;
   --tblr-cyan-rgb: 23, 162, 184;
   --tblr-facebook: #1877f2;
   --tblr-facebook-fg: var(--tblr-light);
   --tblr-facebook-darken: #166bda;
   --tblr-facebook-rgb: 24, 119, 242;
   --tblr-twitter: #1da1f2;
   --tblr-twitter-fg: var(--tblr-light);
   --tblr-twitter-darken: #1a91da;
   --tblr-twitter-rgb: 29, 161, 242;
   --tblr-linkedin: #0a66c2;
   --tblr-linkedin-fg: var(--tblr-light);
   --tblr-linkedin-darken: #095caf;
   --tblr-linkedin-rgb: 10, 102, 194;
   --tblr-google: #dc4e41;
   --tblr-google-fg: var(--tblr-light);
   --tblr-google-darken: #c6463b;
   --tblr-google-rgb: 220, 78, 65;
   --tblr-youtube: #ff0000;
   --tblr-youtube-fg: var(--tblr-light);
   --tblr-youtube-darken: #e60000;
   --tblr-youtube-rgb: 255, 0, 0;
   --tblr-vimeo: #1ab7ea;
   --tblr-vimeo-fg: var(--tblr-light);
   --tblr-vimeo-darken: #17a5d3;
   --tblr-vimeo-rgb: 26, 183, 234;
   --tblr-dribbble: #ea4c89;
   --tblr-dribbble-fg: var(--tblr-light);
   --tblr-dribbble-darken: #d3447b;
   --tblr-dribbble-rgb: 234, 76, 137;
   --tblr-github: #181717;
   --tblr-github-fg: var(--tblr-light);
   --tblr-github-darken: #161515;
   --tblr-github-rgb: 24, 23, 23;
   --tblr-instagram: #e4405f;
   --tblr-instagram-fg: var(--tblr-light);
   --tblr-instagram-darken: #cd3a56;
   --tblr-instagram-rgb: 228, 64, 95;
   --tblr-pinterest: #bd081c;
   --tblr-pinterest-fg: var(--tblr-light);
   --tblr-pinterest-darken: #aa0719;
   --tblr-pinterest-rgb: 189, 8, 28;
   --tblr-vk: #6383a8;
   --tblr-vk-fg: var(--tblr-light);
   --tblr-vk-darken: #597697;
   --tblr-vk-rgb: 99, 131, 168;
   --tblr-rss: #ffa500;
   --tblr-rss-fg: var(--tblr-light);
   --tblr-rss-darken: #e69500;
   --tblr-rss-rgb: 255, 165, 0;
   --tblr-flickr: #0063dc;
   --tblr-flickr-fg: var(--tblr-light);
   --tblr-flickr-darken: #0059c6;
   --tblr-flickr-rgb: 0, 99, 220;
   --tblr-bitbucket: #0052cc;
   --tblr-bitbucket-fg: var(--tblr-light);
   --tblr-bitbucket-darken: #004ab8;
   --tblr-bitbucket-rgb: 0, 82, 204;
   --tblr-tabler: #206bc4;
   --tblr-tabler-fg: var(--tblr-light);
   --tblr-tabler-darken: #1d60b0;
   --tblr-tabler-rgb: 32, 107, 196;
   --tblr-gray-50: #f8fafc;
   --tblr-gray-50-fg: var(--tblr-dark);
   --tblr-gray-50-darken: #dfe1e3;
   --tblr-gray-50-rgb: 248, 250, 252;
   --tblr-gray-100: #f1f5f9;
   --tblr-gray-100-fg: var(--tblr-dark);
   --tblr-gray-100-darken: #d9dde0;
   --tblr-gray-100-rgb: 241, 245, 249;
   --tblr-gray-200: #e2e8f0;
   --tblr-gray-200-fg: var(--tblr-dark);
   --tblr-gray-200-darken: #cbd1d8;
   --tblr-gray-200-rgb: 226, 232, 240;
   --tblr-gray-300: #c8d3e1;
   --tblr-gray-300-fg: var(--tblr-dark);
   --tblr-gray-300-darken: #b4becb;
   --tblr-gray-300-rgb: 200, 211, 225;
   --tblr-gray-400: #9ba9be;
   --tblr-gray-400-fg: var(--tblr-light);
   --tblr-gray-400-darken: #8c98ab;
   --tblr-gray-400-rgb: 155, 169, 190;
   --tblr-gray-500: #6c7a91;
   --tblr-gray-500-fg: var(--tblr-light);
   --tblr-gray-500-darken: #616e83;
   --tblr-gray-500-rgb: 108, 122, 145;
   --tblr-gray-600: #49566c;
   --tblr-gray-600-fg: var(--tblr-light);
   --tblr-gray-600-darken: #424d61;
   --tblr-gray-600-rgb: 73, 86, 108;
   --tblr-gray-700: #313c52;
   --tblr-gray-700-fg: var(--tblr-light);
   --tblr-gray-700-darken: #2c364a;
   --tblr-gray-700-rgb: 49, 60, 82;
   --tblr-gray-800: #1d273b;
   --tblr-gray-800-fg: var(--tblr-light);
   --tblr-gray-800-darken: #1a2335;
   --tblr-gray-800-rgb: 29, 39, 59;
   --tblr-gray-900: #0f172a;
   --tblr-gray-900-fg: var(--tblr-light);
   --tblr-gray-900-darken: #0e1526;
   --tblr-gray-900-rgb: 15, 23, 42;
   --tblr-bg-surface: var(--tblr-white);
   --tblr-bg-surface-secondary: var(--tblr-gray-100);
   --tblr-bg-surface-tertiary: var(--tblr-gray-50);
   --tblr-bg-surface-dark: var(--tblr-dark);
   --tblr-bg-forms: var(--tblr-bg-surface);
   --tblr-border-color: #e6e7e9;
   --tblr-border-color-light: #f2f3f4;
   --tblr-border-color-active: #b3b7bd;
   --tblr-icon-color: var(--tblr-gray-500);
   --tblr-active-bg: rgba(var(--tblr-primary-rgb), 0.04);
   --tblr-disabled-bg: var(--tblr-bg-surface-secondary);
   --tblr-disabled-color: var(--tblr-gray-300);
   --tblr-code-color: var(--tblr-gray-600);
   --tblr-code-bg: var(--tblr-bg-surface-secondary);
   --tblr-content-padding: 1.25rem;
   --tblr-dark-mode-border-color: #243049;
   --tblr-dark-mode-border-color-light: #243049;
   --tblr-dark-mode-border-color-active: #314264;
   --tblr-font-weight-light: 300;
   --tblr-font-weight-normal: 400;
   --tblr-font-weight-medium: 500;
   --tblr-font-weight-bold: 600;
   --tblr-font-weight-headings: var(--tblr-font-weight-bold);
   --tblr-font-size-h1: 1.5rem;
   --tblr-font-size-h2: 1.25rem;
   --tblr-font-size-h3: 1rem;
   --tblr-font-size-h4: 0.875rem;
   --tblr-font-size-h5: 0.75rem;
   --tblr-font-size-h6: 0.625rem;
   --tblr-line-height-h1: 2rem;
   --tblr-line-height-h2: 1.75rem;
   --tblr-line-height-h3: 1.5rem;
   --tblr-line-height-h4: 1.25rem;
   --tblr-line-height-h5: 1rem;
   --tblr-line-height-h6: 1rem;
   --tblr-shadow: rgba(var(--tblr-body-color-rgb), 0.04) 0 2px 4px 0;
   --tblr-shadow-transparent: 0 0 0 0 transparent;
   --tblr-shadow-button: 0 1px 0 rgba(var(--tblr-body-color-rgb), 0.04);
   --tblr-shadow-button-inset: inset 0 -1px 0 rgba(var(--tblr-body-color-rgb), 0.2);
   --tblr-shadow-card: 0 0 4px rgba(var(--tblr-body-color-rgb), 0.04);
   --tblr-shadow-card-hover: rgba(var(--tblr-body-color-rgb), 0.16) 0 2px 16px 0;
}

@keyframes pulse {
   from {
      opacity: 1;
      transform: scale3d(0.8, 0.8, 0.8);
   }
   50% {
      transform: scale3d(1, 1, 1);
      opacity: 1;
   }
   to {
      opacity: 1;
      transform: scale3d(0.8, 0.8, 0.8);
   }
}
@keyframes tada {
   0% {
      transform: scale3d(1, 1, 1);
   }
   10%,
   5% {
      transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -5deg);
   }
   15%,
   25%,
   35%,
   45% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 5deg);
   }
   20%,
   30%,
   40% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -5deg);
   }
   50% {
      transform: scale3d(1, 1, 1);
   }
}
@keyframes rotate-360 {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}
@keyframes blink {
   from {
      opacity: 0;
   }
   50% {
      opacity: 1;
   }
   to {
      opacity: 0;
   }
}
body {
   overflow-y: scroll;
   letter-spacing: 0;
   touch-action: manipulation;
   text-rendering: optimizeLegibility;
   font-feature-settings: "liga" 0;
   position: relative;
   min-height: 100%;
   height: 100%;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}
@media print {
   body {
      background: transparent;
   }
}

* {
   scrollbar-color: rgba(
         var(--tblr-scrollbar-color, var(--tblr-body-color-rgb)),
         0.16
      )
      transparent;
}

*::-webkit-scrollbar {
   width: 1rem;
   height: 1rem;
   -webkit-transition: background 0.3s;
   transition: background 0.3s;
}
@media (prefers-reduced-motion: reduce) {
   *::-webkit-scrollbar {
      -webkit-transition: none;
      transition: none;
   }
}

*::-webkit-scrollbar-thumb {
   border-radius: 1rem;
   border: 5px solid transparent;
   box-shadow: inset 0 0 0 1rem
      rgba(var(--tblr-scrollbar-color, var(--tblr-body-color-rgb)), 0.16);
}

*::-webkit-scrollbar-track {
   background: transparent;
}

*:hover::-webkit-scrollbar-thumb {
   box-shadow: inset 0 0 0 1rem
      rgba(var(--tblr-scrollbar-color, var(--tblr-body-color-rgb)), 0.32);
}

*::-webkit-scrollbar-corner {
   background: transparent;
}

.layout-fluid .container,
.layout-fluid [class^="container-"],
.layout-fluid [class*=" container-"] {
   max-width: 100%;
}

.layout-boxed {
   --tblr-theme-boxed-border-radius: 0;
   --tblr-theme-boxed-width: 1320px;
}
@media (min-width: 768px) {
   .layout-boxed {
      background: #1d273b
         linear-gradient(to right, rgba(255, 255, 255, 0.1), transparent) fixed;
      padding: 1rem;
      --tblr-theme-boxed-border-radius: 4px;
   }
}
.layout-boxed .page {
   margin: 0 auto;
   max-width: var(--tblr-theme-boxed-width);
   border-radius: var(--tblr-theme-boxed-border-radius);
   color: var(--tblr-body-color);
}
@media (min-width: 768px) {
   .layout-boxed .page {
      border: var(--tblr-border-width) var(--tblr-border-style)
         var(--tblr-border-color);
      background: var(--tblr-body-bg);
   }
}
.layout-boxed .page > .navbar:first-child {
   border-top-left-radius: var(--tblr-theme-boxed-border-radius);
   border-top-right-radius: var(--tblr-theme-boxed-border-radius);
}

/**
Navbar
 */
.navbar {
   --tblr-navbar-border-width: var(--tblr-border-width);
   --tblr-navbar-active-border-color: var(--tblr-primary);
   --tblr-navbar-active-bg: rgba(0, 0, 0, 0.06);
   --tblr-navbar-bg: transparent;
   align-items: stretch;
   min-height: 3.5rem;
   box-shadow: inset 0 calc(-1 * var(--tblr-navbar-border-width)) 0 0
      var(--tblr-navbar-border-color);
   background: var(--tblr-navbar-bg);
   --tblr-navbar-active-bg: rgba(0, 0, 0, 0.06);
   --tblr-navbar-bg: transparent;
   color: var(--tblr-body-color);
}
.navbar-collapse .navbar {
   flex-grow: 1;
}
.navbar.collapsing {
   min-height: 0;
}
.navbar .navbar-brand {
   color: var(--tblr-body-color);
}
.navbar .navbar-brand:hover,
.navbar .navbar-brand:focus {
   color: var(--tblr-body-color);
   opacity: 0.8;
}
.navbar .navbar-nav .nav-link {
   color: var(--tblr-body-color);
}
.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link:focus {
   color: var(--tblr-body-color) color;
}
.navbar .navbar-nav .nav-link.disabled {
   color: var(--tblr-disabled-color);
}
.navbar .navbar-nav .show > .nav-link,
.navbar .navbar-nav .active > .nav-link,
.navbar .navbar-nav .nav-link.show,
.navbar .navbar-nav .nav-link.active {
   color: var(--tblr-body-color) color;
}
.navbar .navbar-toggler {
   color: var(--tblr-body-color);
   border-color: transparent;
}
.navbar .navbar-text {
   color: var(--tblr-body-color);
}
.navbar .navbar-text a,
.navbar .navbar-text a:hover,
.navbar .navbar-text a:focus {
   color: var(--tblr-body-color);
}
@media not print {
   .theme-dark .navbar {
      --tblr-navbar-border-color: #243049;
      --tblr-navbar-bg: #1d273b;
      --tblr-scrollbar-color: var(--tblr-white-rgb);
      --tblr-navbar-active-bg: rgba(255, 255, 255, 0.06);
      --tblr-navbar-bg: #1d273b;
      color: rgba(255, 255, 255, 0.7);
   }
   .theme-dark .navbar .navbar-brand {
      color: #ffffff;
   }
   .theme-dark .navbar .navbar-brand:hover,
   .theme-dark .navbar .navbar-brand:focus {
      color: #ffffff;
      opacity: 0.8;
   }
   .theme-dark .navbar .navbar-nav .nav-link {
      color: rgba(255, 255, 255, 0.7);
   }
   .theme-dark .navbar .navbar-nav .nav-link:hover,
   .theme-dark .navbar .navbar-nav .nav-link:focus {
      color: #ffffff;
   }
   .theme-dark .navbar .navbar-nav .nav-link.disabled {
      color: var(--tblr-disabled-color);
   }
   .theme-dark .navbar .navbar-nav .show > .nav-link,
   .theme-dark .navbar .navbar-nav .active > .nav-link,
   .theme-dark .navbar .navbar-nav .nav-link.show,
   .theme-dark .navbar .navbar-nav .nav-link.active {
      color: #ffffff;
   }
   .theme-dark .navbar .navbar-toggler {
      color: #ffffff;
      border-color: transparent;
   }
   .theme-dark .navbar .navbar-text {
      color: rgba(255, 255, 255, 0.7);
   }
   .theme-dark .navbar .navbar-text a,
   .theme-dark .navbar .navbar-text a:hover,
   .theme-dark .navbar .navbar-text a:focus {
      color: rgba(255, 255, 255, 0.7);
   }
}
@media not print {
   @media (prefers-color-scheme: dark) {
      .theme-dark-auto .navbar {
         --tblr-navbar-border-color: #243049;
         --tblr-navbar-bg: #1d273b;
         --tblr-scrollbar-color: var(--tblr-white-rgb);
         --tblr-navbar-active-bg: rgba(255, 255, 255, 0.06);
         --tblr-navbar-bg: #1d273b;
         color: rgba(255, 255, 255, 0.7);
      }
      .theme-dark-auto .navbar .navbar-brand {
         color: #ffffff;
      }
      .theme-dark-auto .navbar .navbar-brand:hover,
      .theme-dark-auto .navbar .navbar-brand:focus {
         color: #ffffff;
         opacity: 0.8;
      }
      .theme-dark-auto .navbar .navbar-nav .nav-link {
         color: rgba(255, 255, 255, 0.7);
      }
      .theme-dark-auto .navbar .navbar-nav .nav-link:hover,
      .theme-dark-auto .navbar .navbar-nav .nav-link:focus {
         color: #ffffff;
      }
      .theme-dark-auto .navbar .navbar-nav .nav-link.disabled {
         color: var(--tblr-disabled-color);
      }
      .theme-dark-auto .navbar .navbar-nav .show > .nav-link,
      .theme-dark-auto .navbar .navbar-nav .active > .nav-link,
      .theme-dark-auto .navbar .navbar-nav .nav-link.show,
      .theme-dark-auto .navbar .navbar-nav .nav-link.active {
         color: #ffffff;
      }
      .theme-dark-auto .navbar .navbar-toggler {
         color: #ffffff;
         border-color: transparent;
      }
      .theme-dark-auto .navbar .navbar-text {
         color: rgba(255, 255, 255, 0.7);
      }
      .theme-dark-auto .navbar .navbar-text a,
      .theme-dark-auto .navbar .navbar-text a:hover,
      .theme-dark-auto .navbar .navbar-text a:focus {
         color: rgba(255, 255, 255, 0.7);
      }
   }
}
.navbar .dropdown-menu {
   position: absolute;
   z-index: 1030;
}
.navbar .navbar-nav {
   min-height: 3rem;
}
.navbar .navbar-nav .nav-link {
   position: relative;
   min-width: 2rem;
   min-height: 2rem;
   justify-content: center;
   border-radius: var(--tblr-border-radius);
}
.navbar .navbar-nav .nav-link .badge {
   position: absolute;
   top: 0.375rem;
   right: 0.375rem;
   transform: translate(50%, -50%);
}

.navbar-nav {
   margin: 0;
   padding: 0;
}

@media (max-width: 575.98px) {
   .navbar-expand-sm .navbar-collapse {
      flex-direction: column;
   }
   .navbar-expand-sm .navbar-collapse [class^="container"] {
      flex-direction: column;
      align-items: stretch;
      padding: 0;
   }
   .navbar-expand-sm .navbar-collapse .navbar-nav {
      margin-left: 0;
      margin-right: 0;
   }
   .navbar-expand-sm .navbar-collapse .navbar-nav .nav-link {
      padding: 0.5rem calc(var(--tblr-content-padding) / 2);
      justify-content: flex-start;
   }
   .navbar-expand-sm .navbar-collapse .dropdown-menu-columns {
      flex-direction: column;
   }
   .navbar-expand-sm .navbar-collapse .dropdown-menu {
      padding: 0;
      background: transparent;
      position: static;
      color: inherit;
      box-shadow: none;
      border: none;
      min-width: 0;
      margin: 0;
   }
   .navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-item {
      min-width: 0;
      display: flex;
      width: auto;
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 1.75rem);
      color: inherit;
   }
   .navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-item.active,
   .navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-item:active {
      background: var(--tblr-navbar-active-bg);
   }
   .navbar-expand-sm
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 3.25rem);
   }
   .navbar-expand-sm
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 4.75rem);
   }
   .navbar-expand-sm .navbar-collapse .dropdown-toggle:after {
      margin-left: auto;
   }
   .navbar-expand-sm .navbar-collapse .nav-item.active:after {
      border-bottom-width: 0;
      border-left-width: 3px;
      right: auto;
      top: 0;
      bottom: 0;
   }
}
@media (min-width: 576px) {
   .navbar-expand-sm .navbar-collapse {
      width: auto;
      flex: 1 1 auto;
   }
   .navbar-expand-sm.navbar-light .nav-item.active,
   .navbar-expand-sm .navbar-light .nav-item.active {
      position: relative;
   }
   .navbar-expand-sm.navbar-light .nav-item.active:after,
   .navbar-expand-sm .navbar-light .nav-item.active:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -0.25rem;
      border: 0 var(--tblr-border-style) var(--tblr-navbar-active-border-color);
      border-bottom-width: 2px;
   }
   .navbar-expand-sm.navbar-vertical {
      box-shadow: inset calc(-1 * var(--tblr-navbar-border-width)) 0 0 0
         var(--tblr-navbar-border-color);
   }
   .navbar-expand-sm.navbar-vertical.navbar-right {
      box-shadow: inset calc(1 * var(--tblr-navbar-border-width)) 0 0 0
         var(--tblr-navbar-border-color);
   }
   .navbar-expand-sm.navbar-vertical ~ .navbar,
   .navbar-expand-sm.navbar-vertical ~ .page-wrapper {
      margin-left: 15rem;
   }
   .navbar-expand-sm.navbar-vertical.navbar-right ~ .navbar,
   .navbar-expand-sm.navbar-vertical.navbar-right ~ .page-wrapper {
      margin-left: 0;
      margin-right: 15rem;
   }
}
@media (max-width: 767.98px) {
   .navbar-expand-md .navbar-collapse {
      flex-direction: column;
   }
   .navbar-expand-md .navbar-collapse [class^="container"] {
      flex-direction: column;
      align-items: stretch;
      padding: 0;
   }
   .navbar-expand-md .navbar-collapse .navbar-nav {
      margin-left: 0;
      margin-right: 0;
   }
   .navbar-expand-md .navbar-collapse .navbar-nav .nav-link {
      padding: 0.5rem calc(var(--tblr-content-padding) / 2);
      justify-content: flex-start;
   }
   .navbar-expand-md .navbar-collapse .dropdown-menu-columns {
      flex-direction: column;
   }
   .navbar-expand-md .navbar-collapse .dropdown-menu {
      padding: 0;
      background: transparent;
      position: static;
      color: inherit;
      box-shadow: none;
      border: none;
      min-width: 0;
      margin: 0;
   }
   .navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-item {
      min-width: 0;
      display: flex;
      width: auto;
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 1.75rem);
      color: inherit;
   }
   .navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-item.active,
   .navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-item:active {
      background: var(--tblr-navbar-active-bg);
   }
   .navbar-expand-md
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 3.25rem);
   }
   .navbar-expand-md
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 4.75rem);
   }
   .navbar-expand-md .navbar-collapse .dropdown-toggle:after {
      margin-left: auto;
   }
   .navbar-expand-md .navbar-collapse .nav-item.active:after {
      border-bottom-width: 0;
      border-left-width: 3px;
      right: auto;
      top: 0;
      bottom: 0;
   }
}
@media (min-width: 768px) {
   .navbar-expand-md .navbar-collapse {
      width: auto;
      flex: 1 1 auto;
   }
   .navbar-expand-md.navbar-light .nav-item.active,
   .navbar-expand-md .navbar-light .nav-item.active {
      position: relative;
   }
   .navbar-expand-md.navbar-light .nav-item.active:after,
   .navbar-expand-md .navbar-light .nav-item.active:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -0.25rem;
      border: 0 var(--tblr-border-style) var(--tblr-navbar-active-border-color);
      border-bottom-width: 2px;
   }
   .navbar-expand-md.navbar-vertical {
      box-shadow: inset calc(-1 * var(--tblr-navbar-border-width)) 0 0 0
         var(--tblr-navbar-border-color);
   }
   .navbar-expand-md.navbar-vertical.navbar-right {
      box-shadow: inset calc(1 * var(--tblr-navbar-border-width)) 0 0 0
         var(--tblr-navbar-border-color);
   }
   .navbar-expand-md.navbar-vertical ~ .navbar,
   .navbar-expand-md.navbar-vertical ~ .page-wrapper {
      margin-left: 15rem;
   }
   .navbar-expand-md.navbar-vertical.navbar-right ~ .navbar,
   .navbar-expand-md.navbar-vertical.navbar-right ~ .page-wrapper {
      margin-left: 0;
      margin-right: 15rem;
   }
}
@media (max-width: 991.98px) {
   .navbar-expand-lg .navbar-collapse {
      flex-direction: column;
   }
   .navbar-expand-lg .navbar-collapse [class^="container"] {
      flex-direction: column;
      align-items: stretch;
      padding: 0;
   }
   .navbar-expand-lg .navbar-collapse .navbar-nav {
      margin-left: 0;
      margin-right: 0;
   }
   .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link {
      padding: 0.5rem calc(var(--tblr-content-padding) / 2);
      justify-content: flex-start;
   }
   .navbar-expand-lg .navbar-collapse .dropdown-menu-columns {
      flex-direction: column;
   }
   .navbar-expand-lg .navbar-collapse .dropdown-menu {
      padding: 0;
      background: transparent;
      position: static;
      color: inherit;
      box-shadow: none;
      border: none;
      min-width: 0;
      margin: 0;
   }
   .navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-item {
      min-width: 0;
      display: flex;
      width: auto;
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 1.75rem);
      color: inherit;
   }
   .navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-item.active,
   .navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-item:active {
      background: var(--tblr-navbar-active-bg);
   }
   .navbar-expand-lg
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 3.25rem);
   }
   .navbar-expand-lg
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 4.75rem);
   }
   .navbar-expand-lg .navbar-collapse .dropdown-toggle:after {
      margin-left: auto;
   }
   .navbar-expand-lg .navbar-collapse .nav-item.active:after {
      border-bottom-width: 0;
      border-left-width: 3px;
      right: auto;
      top: 0;
      bottom: 0;
   }
}
@media (min-width: 992px) {
   .navbar-expand-lg .navbar-collapse {
      width: auto;
      flex: 1 1 auto;
   }
   .navbar-expand-lg.navbar-light .nav-item.active,
   .navbar-expand-lg .navbar-light .nav-item.active {
      position: relative;
   }
   .navbar-expand-lg.navbar-light .nav-item.active:after,
   .navbar-expand-lg .navbar-light .nav-item.active:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -0.25rem;
      border: 0 var(--tblr-border-style) var(--tblr-navbar-active-border-color);
      border-bottom-width: 2px;
   }
   .navbar-expand-lg.navbar-vertical {
      box-shadow: inset calc(-1 * var(--tblr-navbar-border-width)) 0 0 0
         var(--tblr-navbar-border-color);
   }
   .navbar-expand-lg.navbar-vertical.navbar-right {
      box-shadow: inset calc(1 * var(--tblr-navbar-border-width)) 0 0 0
         var(--tblr-navbar-border-color);
   }
   .navbar-expand-lg.navbar-vertical ~ .navbar,
   .navbar-expand-lg.navbar-vertical ~ .page-wrapper {
      margin-left: 15rem;
   }
   .navbar-expand-lg.navbar-vertical.navbar-right ~ .navbar,
   .navbar-expand-lg.navbar-vertical.navbar-right ~ .page-wrapper {
      margin-left: 0;
      margin-right: 15rem;
   }
}
@media (max-width: 1199.98px) {
   .navbar-expand-xl .navbar-collapse {
      flex-direction: column;
   }
   .navbar-expand-xl .navbar-collapse [class^="container"] {
      flex-direction: column;
      align-items: stretch;
      padding: 0;
   }
   .navbar-expand-xl .navbar-collapse .navbar-nav {
      margin-left: 0;
      margin-right: 0;
   }
   .navbar-expand-xl .navbar-collapse .navbar-nav .nav-link {
      padding: 0.5rem calc(var(--tblr-content-padding) / 2);
      justify-content: flex-start;
   }
   .navbar-expand-xl .navbar-collapse .dropdown-menu-columns {
      flex-direction: column;
   }
   .navbar-expand-xl .navbar-collapse .dropdown-menu {
      padding: 0;
      background: transparent;
      position: static;
      color: inherit;
      box-shadow: none;
      border: none;
      min-width: 0;
      margin: 0;
   }
   .navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-item {
      min-width: 0;
      display: flex;
      width: auto;
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 1.75rem);
      color: inherit;
   }
   .navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-item.active,
   .navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-item:active {
      background: var(--tblr-navbar-active-bg);
   }
   .navbar-expand-xl
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 3.25rem);
   }
   .navbar-expand-xl
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 4.75rem);
   }
   .navbar-expand-xl .navbar-collapse .dropdown-toggle:after {
      margin-left: auto;
   }
   .navbar-expand-xl .navbar-collapse .nav-item.active:after {
      border-bottom-width: 0;
      border-left-width: 3px;
      right: auto;
      top: 0;
      bottom: 0;
   }
}
@media (min-width: 1200px) {
   .navbar-expand-xl .navbar-collapse {
      width: auto;
      flex: 1 1 auto;
   }
   .navbar-expand-xl.navbar-light .nav-item.active,
   .navbar-expand-xl .navbar-light .nav-item.active {
      position: relative;
   }
   .navbar-expand-xl.navbar-light .nav-item.active:after,
   .navbar-expand-xl .navbar-light .nav-item.active:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -0.25rem;
      border: 0 var(--tblr-border-style) var(--tblr-navbar-active-border-color);
      border-bottom-width: 2px;
   }
   .navbar-expand-xl.navbar-vertical {
      box-shadow: inset calc(-1 * var(--tblr-navbar-border-width)) 0 0 0
         var(--tblr-navbar-border-color);
   }
   .navbar-expand-xl.navbar-vertical.navbar-right {
      box-shadow: inset calc(1 * var(--tblr-navbar-border-width)) 0 0 0
         var(--tblr-navbar-border-color);
   }
   .navbar-expand-xl.navbar-vertical ~ .navbar,
   .navbar-expand-xl.navbar-vertical ~ .page-wrapper {
      margin-left: 15rem;
   }
   .navbar-expand-xl.navbar-vertical.navbar-right ~ .navbar,
   .navbar-expand-xl.navbar-vertical.navbar-right ~ .page-wrapper {
      margin-left: 0;
      margin-right: 15rem;
   }
}
@media (max-width: 1399.98px) {
   .navbar-expand-xxl .navbar-collapse {
      flex-direction: column;
   }
   .navbar-expand-xxl .navbar-collapse [class^="container"] {
      flex-direction: column;
      align-items: stretch;
      padding: 0;
   }
   .navbar-expand-xxl .navbar-collapse .navbar-nav {
      margin-left: 0;
      margin-right: 0;
   }
   .navbar-expand-xxl .navbar-collapse .navbar-nav .nav-link {
      padding: 0.5rem calc(var(--tblr-content-padding) / 2);
      justify-content: flex-start;
   }
   .navbar-expand-xxl .navbar-collapse .dropdown-menu-columns {
      flex-direction: column;
   }
   .navbar-expand-xxl .navbar-collapse .dropdown-menu {
      padding: 0;
      background: transparent;
      position: static;
      color: inherit;
      box-shadow: none;
      border: none;
      min-width: 0;
      margin: 0;
   }
   .navbar-expand-xxl .navbar-collapse .dropdown-menu .dropdown-item {
      min-width: 0;
      display: flex;
      width: auto;
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 1.75rem);
      color: inherit;
   }
   .navbar-expand-xxl .navbar-collapse .dropdown-menu .dropdown-item.active,
   .navbar-expand-xxl .navbar-collapse .dropdown-menu .dropdown-item:active {
      background: var(--tblr-navbar-active-bg);
   }
   .navbar-expand-xxl
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 3.25rem);
   }
   .navbar-expand-xxl
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 4.75rem);
   }
   .navbar-expand-xxl .navbar-collapse .dropdown-toggle:after {
      margin-left: auto;
   }
   .navbar-expand-xxl .navbar-collapse .nav-item.active:after {
      border-bottom-width: 0;
      border-left-width: 3px;
      right: auto;
      top: 0;
      bottom: 0;
   }
}
@media (min-width: 1400px) {
   .navbar-expand-xxl .navbar-collapse {
      width: auto;
      flex: 1 1 auto;
   }
   .navbar-expand-xxl.navbar-light .nav-item.active,
   .navbar-expand-xxl .navbar-light .nav-item.active {
      position: relative;
   }
   .navbar-expand-xxl.navbar-light .nav-item.active:after,
   .navbar-expand-xxl .navbar-light .nav-item.active:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -0.25rem;
      border: 0 var(--tblr-border-style) var(--tblr-navbar-active-border-color);
      border-bottom-width: 2px;
   }
   .navbar-expand-xxl.navbar-vertical {
      box-shadow: inset calc(-1 * var(--tblr-navbar-border-width)) 0 0 0
         var(--tblr-navbar-border-color);
   }
   .navbar-expand-xxl.navbar-vertical.navbar-right {
      box-shadow: inset calc(1 * var(--tblr-navbar-border-width)) 0 0 0
         var(--tblr-navbar-border-color);
   }
   .navbar-expand-xxl.navbar-vertical ~ .navbar,
   .navbar-expand-xxl.navbar-vertical ~ .page-wrapper {
      margin-left: 15rem;
   }
   .navbar-expand-xxl.navbar-vertical.navbar-right ~ .navbar,
   .navbar-expand-xxl.navbar-vertical.navbar-right ~ .page-wrapper {
      margin-left: 0;
      margin-right: 15rem;
   }
}
.navbar-expand .navbar-collapse {
   flex-direction: column;
}
.navbar-expand .navbar-collapse [class^="container"] {
   flex-direction: column;
   align-items: stretch;
   padding: 0;
}
.navbar-expand .navbar-collapse .navbar-nav {
   margin-left: 0;
   margin-right: 0;
}
.navbar-expand .navbar-collapse .navbar-nav .nav-link {
   padding: 0.5rem calc(var(--tblr-content-padding) / 2);
   justify-content: flex-start;
}
.navbar-expand .navbar-collapse .dropdown-menu-columns {
   flex-direction: column;
}
.navbar-expand .navbar-collapse .dropdown-menu {
   padding: 0;
   background: transparent;
   position: static;
   color: inherit;
   box-shadow: none;
   border: none;
   min-width: 0;
   margin: 0;
}
.navbar-expand .navbar-collapse .dropdown-menu .dropdown-item {
   min-width: 0;
   display: flex;
   width: auto;
   padding-left: calc(calc(var(--tblr-content-padding) / 2) + 1.75rem);
   color: inherit;
}
.navbar-expand .navbar-collapse .dropdown-menu .dropdown-item.active,
.navbar-expand .navbar-collapse .dropdown-menu .dropdown-item:active {
   background: var(--tblr-navbar-active-bg);
}
.navbar-expand .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-item {
   padding-left: calc(calc(var(--tblr-content-padding) / 2) + 3.25rem);
}
.navbar-expand
   .navbar-collapse
   .dropdown-menu
   .dropdown-menu
   .dropdown-menu
   .dropdown-item {
   padding-left: calc(calc(var(--tblr-content-padding) / 2) + 4.75rem);
}
.navbar-expand .navbar-collapse .dropdown-toggle:after {
   margin-left: auto;
}
.navbar-expand .navbar-collapse .nav-item.active:after {
   border-bottom-width: 0;
   border-left-width: 3px;
   right: auto;
   top: 0;
   bottom: 0;
}
.navbar-expand .navbar-collapse {
   width: auto;
   flex: 1 1 auto;
}
.navbar-expand.navbar-light .nav-item.active,
.navbar-expand .navbar-light .nav-item.active {
   position: relative;
}
.navbar-expand.navbar-light .nav-item.active:after,
.navbar-expand .navbar-light .nav-item.active:after {
   content: "";
   position: absolute;
   left: 0;
   right: 0;
   bottom: -0.25rem;
   border: 0 var(--tblr-border-style) var(--tblr-navbar-active-border-color);
   border-bottom-width: 2px;
}
.navbar-expand.navbar-vertical {
   box-shadow: inset calc(-1 * var(--tblr-navbar-border-width)) 0 0 0
      var(--tblr-navbar-border-color);
}
.navbar-expand.navbar-vertical.navbar-right {
   box-shadow: inset calc(1 * var(--tblr-navbar-border-width)) 0 0 0
      var(--tblr-navbar-border-color);
}
.navbar-expand.navbar-vertical ~ .navbar,
.navbar-expand.navbar-vertical ~ .page-wrapper {
   margin-left: 15rem;
}
.navbar-expand.navbar-vertical.navbar-right ~ .navbar,
.navbar-expand.navbar-vertical.navbar-right ~ .page-wrapper {
   margin-left: 0;
   margin-right: 15rem;
}

/**
Navbar brand
 */
.navbar-brand {
   display: inline-flex;
   align-items: center;
   font-weight: var(--tblr-font-weight-bold);
   margin: 0;
   line-height: 1;
}

.navbar-brand-image {
   height: 2rem;
   width: auto;
}

/**
Navbar toggler
 */
.navbar-toggler {
   border: 0;
   width: 2rem;
   height: 2rem;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
}

.navbar-toggler-icon {
   height: 2px;
   width: 1.25em;
   background: currentColor;
   border-radius: 10px;
   transition: top 0.2s 0.2s, bottom 0.2s 0.2s, transform 0.2s, opacity 0s 0.2s;
   position: relative;
}
@media (prefers-reduced-motion: reduce) {
   .navbar-toggler-icon {
      transition: none;
   }
}
.navbar-toggler-icon:before,
.navbar-toggler-icon:after {
   content: "";
   display: block;
   height: inherit;
   width: inherit;
   border-radius: inherit;
   background: inherit;
   position: absolute;
   left: 0;
   transition: inherit;
}
@media (prefers-reduced-motion: reduce) {
   .navbar-toggler-icon:before,
   .navbar-toggler-icon:after {
      transition: none;
   }
}
.navbar-toggler-icon:before {
   top: -0.45em;
}
.navbar-toggler-icon:after {
   bottom: -0.45em;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
   transform: rotate(45deg);
   transition: top 0.3s, bottom 0.3s, transform 0.3s 0.3s, opacity 0s 0.3s;
}
@media (prefers-reduced-motion: reduce) {
   .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
      transition: none;
   }
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
   top: 0;
   transform: rotate(-90deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
   bottom: 0;
   opacity: 0;
}

/**
Navbar light
 */
.navbar-light {
   --tblr-navbar-border-color: var(--tblr-border-color);
   --tblr-navbar-bg: var(--tblr-bg-surface);
}

/**
Navbar dark
 */
.navbar-dark {
   --tblr-navbar-border-color: #243049;
   --tblr-navbar-bg: #1d273b;
   --tblr-scrollbar-color: var(--tblr-white-rgb);
   --tblr-navbar-active-bg: rgba(255, 255, 255, 0.06);
   --tblr-navbar-bg: #1d273b;
   color: rgba(255, 255, 255, 0.7);
}
.navbar-dark .navbar-brand {
   color: #ffffff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
   color: #ffffff;
   opacity: 0.8;
}
.navbar-dark .navbar-nav .nav-link {
   color: rgba(255, 255, 255, 0.7);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
   color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link.disabled {
   color: var(--tblr-disabled-color);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
   color: #ffffff;
}
.navbar-dark .navbar-toggler {
   color: #ffffff;
   border-color: transparent;
}
.navbar-dark .navbar-text {
   color: rgba(255, 255, 255, 0.7);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
   color: rgba(255, 255, 255, 0.7);
}

/**
Navbar transparent
 */
.navbar-transparent {
   --tblr-navbar-border-color: transparent !important;
   background: transparent !important;
}

/**
Navbar nav
 */
.navbar-nav {
   align-items: stretch;
}
.navbar-nav .nav-item {
   display: flex;
   flex-direction: column;
   justify-content: center;
}

/**
Navbar side
 */
.navbar-side {
   margin: 0;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-around;
}

/**
Navbar vertical
 */
@media (min-width: 576px) {
   .navbar-vertical.navbar-expand-sm {
      width: 15rem;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1030;
      align-items: flex-start;
      transition: transform 0.3s;
      overflow-y: scroll;
      padding: 0;
   }
}
@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
   .navbar-vertical.navbar-expand-sm {
      transition: none;
   }
}
@media (min-width: 576px) {
   .navbar-vertical.navbar-expand-sm.navbar-right {
      left: auto;
      right: 0;
   }
}
@media (min-width: 576px) {
   .navbar-vertical.navbar-expand-sm .navbar-brand {
      padding: 0.75rem 0;
      justify-content: center;
   }
}
@media (min-width: 576px) {
   .navbar-vertical.navbar-expand-sm .navbar-collapse {
      align-items: stretch;
   }
}
@media (min-width: 576px) {
   .navbar-vertical.navbar-expand-sm .navbar-nav {
      flex-direction: column;
      flex-grow: 1;
      min-height: auto;
   }
   .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
   }
}
@media (min-width: 576px) {
   .navbar-vertical.navbar-expand-sm > [class^="container"] {
      flex-direction: column;
      align-items: stretch;
      min-height: 100%;
      justify-content: flex-start;
      padding: 0;
   }
}
@media (min-width: 576px) {
   .navbar-vertical.navbar-expand-sm ~ .page {
      padding-left: 15rem;
   }
   .navbar-vertical.navbar-expand-sm ~ .page [class^="container"] {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
   }
}
@media (min-width: 576px) {
   .navbar-vertical.navbar-expand-sm.navbar-right ~ .page {
      padding-left: 0;
      padding-right: 15rem;
   }
}
@media (min-width: 576px) {
   .navbar-vertical.navbar-expand-sm .navbar-collapse {
      flex-direction: column;
   }
   .navbar-vertical.navbar-expand-sm .navbar-collapse [class^="container"] {
      flex-direction: column;
      align-items: stretch;
      padding: 0;
   }
   .navbar-vertical.navbar-expand-sm .navbar-collapse .navbar-nav {
      margin-left: 0;
      margin-right: 0;
   }
   .navbar-vertical.navbar-expand-sm .navbar-collapse .navbar-nav .nav-link {
      padding: 0.5rem calc(var(--tblr-content-padding) / 2);
      justify-content: flex-start;
   }
   .navbar-vertical.navbar-expand-sm .navbar-collapse .dropdown-menu-columns {
      flex-direction: column;
   }
   .navbar-vertical.navbar-expand-sm .navbar-collapse .dropdown-menu {
      padding: 0;
      background: transparent;
      position: static;
      color: inherit;
      box-shadow: none;
      border: none;
      min-width: 0;
      margin: 0;
   }
   .navbar-vertical.navbar-expand-sm
      .navbar-collapse
      .dropdown-menu
      .dropdown-item {
      min-width: 0;
      display: flex;
      width: auto;
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 1.75rem);
      color: inherit;
   }
   .navbar-vertical.navbar-expand-sm
      .navbar-collapse
      .dropdown-menu
      .dropdown-item.active,
   .navbar-vertical.navbar-expand-sm
      .navbar-collapse
      .dropdown-menu
      .dropdown-item:active {
      background: var(--tblr-navbar-active-bg);
   }
   .navbar-vertical.navbar-expand-sm
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 3.25rem);
   }
   .navbar-vertical.navbar-expand-sm
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 4.75rem);
   }
   .navbar-vertical.navbar-expand-sm .navbar-collapse .dropdown-toggle:after {
      margin-left: auto;
   }
   .navbar-vertical.navbar-expand-sm .navbar-collapse .nav-item.active:after {
      border-bottom-width: 0;
      border-left-width: 3px;
      right: auto;
      top: 0;
      bottom: 0;
   }
}
@media (min-width: 768px) {
   .navbar-vertical.navbar-expand-md {
      width: 15rem;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1030;
      align-items: flex-start;
      transition: transform 0.3s;
      overflow-y: scroll;
      padding: 0;
   }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
   .navbar-vertical.navbar-expand-md {
      transition: none;
   }
}
@media (min-width: 768px) {
   .navbar-vertical.navbar-expand-md.navbar-right {
      left: auto;
      right: 0;
   }
}
@media (min-width: 768px) {
   .navbar-vertical.navbar-expand-md .navbar-brand {
      padding: 0.75rem 0;
      justify-content: center;
   }
}
@media (min-width: 768px) {
   .navbar-vertical.navbar-expand-md .navbar-collapse {
      align-items: stretch;
   }
}
@media (min-width: 768px) {
   .navbar-vertical.navbar-expand-md .navbar-nav {
      flex-direction: column;
      flex-grow: 1;
      min-height: auto;
   }
   .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
   }
}
@media (min-width: 768px) {
   .navbar-vertical.navbar-expand-md > [class^="container"] {
      flex-direction: column;
      align-items: stretch;
      min-height: 100%;
      justify-content: flex-start;
      padding: 0;
   }
}
@media (min-width: 768px) {
   .navbar-vertical.navbar-expand-md ~ .page {
      padding-left: 15rem;
   }
   .navbar-vertical.navbar-expand-md ~ .page [class^="container"] {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
   }
}
@media (min-width: 768px) {
   .navbar-vertical.navbar-expand-md.navbar-right ~ .page {
      padding-left: 0;
      padding-right: 15rem;
   }
}
@media (min-width: 768px) {
   .navbar-vertical.navbar-expand-md .navbar-collapse {
      flex-direction: column;
   }
   .navbar-vertical.navbar-expand-md .navbar-collapse [class^="container"] {
      flex-direction: column;
      align-items: stretch;
      padding: 0;
   }
   .navbar-vertical.navbar-expand-md .navbar-collapse .navbar-nav {
      margin-left: 0;
      margin-right: 0;
   }
   .navbar-vertical.navbar-expand-md .navbar-collapse .navbar-nav .nav-link {
      padding: 0.5rem calc(var(--tblr-content-padding) / 2);
      justify-content: flex-start;
   }
   .navbar-vertical.navbar-expand-md .navbar-collapse .dropdown-menu-columns {
      flex-direction: column;
   }
   .navbar-vertical.navbar-expand-md .navbar-collapse .dropdown-menu {
      padding: 0;
      background: transparent;
      position: static;
      color: inherit;
      box-shadow: none;
      border: none;
      min-width: 0;
      margin: 0;
   }
   .navbar-vertical.navbar-expand-md
      .navbar-collapse
      .dropdown-menu
      .dropdown-item {
      min-width: 0;
      display: flex;
      width: auto;
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 1.75rem);
      color: inherit;
   }
   .navbar-vertical.navbar-expand-md
      .navbar-collapse
      .dropdown-menu
      .dropdown-item.active,
   .navbar-vertical.navbar-expand-md
      .navbar-collapse
      .dropdown-menu
      .dropdown-item:active {
      background: var(--tblr-navbar-active-bg);
   }
   .navbar-vertical.navbar-expand-md
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 3.25rem);
   }
   .navbar-vertical.navbar-expand-md
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 4.75rem);
   }
   .navbar-vertical.navbar-expand-md .navbar-collapse .dropdown-toggle:after {
      margin-left: auto;
   }
   .navbar-vertical.navbar-expand-md .navbar-collapse .nav-item.active:after {
      border-bottom-width: 0;
      border-left-width: 3px;
      right: auto;
      top: 0;
      bottom: 0;
   }
}
@media (min-width: 992px) {
   .navbar-vertical.navbar-expand-lg {
      width: 15rem;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1030;
      align-items: flex-start;
      transition: transform 0.3s;
      overflow-y: scroll;
      padding: 0;
   }
}
@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
   .navbar-vertical.navbar-expand-lg {
      transition: none;
   }
}
@media (min-width: 992px) {
   .navbar-vertical.navbar-expand-lg.navbar-right {
      left: auto;
      right: 0;
   }
}
@media (min-width: 992px) {
   .navbar-vertical.navbar-expand-lg .navbar-brand {
      padding: 0.75rem 0;
      justify-content: center;
   }
}
@media (min-width: 992px) {
   .navbar-vertical.navbar-expand-lg .navbar-collapse {
      align-items: stretch;
   }
}
@media (min-width: 992px) {
   .navbar-vertical.navbar-expand-lg .navbar-nav {
      flex-direction: column;
      flex-grow: 1;
      min-height: auto;
   }
   .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
   }
}
@media (min-width: 992px) {
   .navbar-vertical.navbar-expand-lg > [class^="container"] {
      flex-direction: column;
      align-items: stretch;
      min-height: 100%;
      justify-content: flex-start;
      padding: 0;
   }
}
@media (min-width: 992px) {
   .navbar-vertical.navbar-expand-lg ~ .page {
      padding-left: 15rem;
   }
   .navbar-vertical.navbar-expand-lg ~ .page [class^="container"] {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
   }
}
@media (min-width: 992px) {
   .navbar-vertical.navbar-expand-lg.navbar-right ~ .page {
      padding-left: 0;
      padding-right: 15rem;
   }
}
@media (min-width: 992px) {
   .navbar-vertical.navbar-expand-lg .navbar-collapse {
      flex-direction: column;
   }
   .navbar-vertical.navbar-expand-lg .navbar-collapse [class^="container"] {
      flex-direction: column;
      align-items: stretch;
      padding: 0;
   }
   .navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav {
      margin-left: 0;
      margin-right: 0;
   }
   .navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .nav-link {
      padding: 0.5rem calc(var(--tblr-content-padding) / 2);
      justify-content: flex-start;
   }
   .navbar-vertical.navbar-expand-lg .navbar-collapse .dropdown-menu-columns {
      flex-direction: column;
   }
   .navbar-vertical.navbar-expand-lg .navbar-collapse .dropdown-menu {
      padding: 0;
      background: transparent;
      position: static;
      color: inherit;
      box-shadow: none;
      border: none;
      min-width: 0;
      margin: 0;
   }
   .navbar-vertical.navbar-expand-lg
      .navbar-collapse
      .dropdown-menu
      .dropdown-item {
      min-width: 0;
      display: flex;
      width: auto;
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 1.75rem);
      color: inherit;
   }
   .navbar-vertical.navbar-expand-lg
      .navbar-collapse
      .dropdown-menu
      .dropdown-item.active,
   .navbar-vertical.navbar-expand-lg
      .navbar-collapse
      .dropdown-menu
      .dropdown-item:active {
      background: var(--tblr-navbar-active-bg);
   }
   .navbar-vertical.navbar-expand-lg
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 3.25rem);
   }
   .navbar-vertical.navbar-expand-lg
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 4.75rem);
   }
   .navbar-vertical.navbar-expand-lg .navbar-collapse .dropdown-toggle:after {
      margin-left: auto;
   }
   .navbar-vertical.navbar-expand-lg .navbar-collapse .nav-item.active:after {
      border-bottom-width: 0;
      border-left-width: 3px;
      right: auto;
      top: 0;
      bottom: 0;
   }
}
@media (min-width: 1200px) {
   .navbar-vertical.navbar-expand-xl {
      width: 15rem;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1030;
      align-items: flex-start;
      transition: transform 0.3s;
      overflow-y: scroll;
      padding: 0;
   }
}
@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
   .navbar-vertical.navbar-expand-xl {
      transition: none;
   }
}
@media (min-width: 1200px) {
   .navbar-vertical.navbar-expand-xl.navbar-right {
      left: auto;
      right: 0;
   }
}
@media (min-width: 1200px) {
   .navbar-vertical.navbar-expand-xl .navbar-brand {
      padding: 0.75rem 0;
      justify-content: center;
   }
}
@media (min-width: 1200px) {
   .navbar-vertical.navbar-expand-xl .navbar-collapse {
      align-items: stretch;
   }
}
@media (min-width: 1200px) {
   .navbar-vertical.navbar-expand-xl .navbar-nav {
      flex-direction: column;
      flex-grow: 1;
      min-height: auto;
   }
   .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
   }
}
@media (min-width: 1200px) {
   .navbar-vertical.navbar-expand-xl > [class^="container"] {
      flex-direction: column;
      align-items: stretch;
      min-height: 100%;
      justify-content: flex-start;
      padding: 0;
   }
}
@media (min-width: 1200px) {
   .navbar-vertical.navbar-expand-xl ~ .page {
      padding-left: 15rem;
   }
   .navbar-vertical.navbar-expand-xl ~ .page [class^="container"] {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
   }
}
@media (min-width: 1200px) {
   .navbar-vertical.navbar-expand-xl.navbar-right ~ .page {
      padding-left: 0;
      padding-right: 15rem;
   }
}
@media (min-width: 1200px) {
   .navbar-vertical.navbar-expand-xl .navbar-collapse {
      flex-direction: column;
   }
   .navbar-vertical.navbar-expand-xl .navbar-collapse [class^="container"] {
      flex-direction: column;
      align-items: stretch;
      padding: 0;
   }
   .navbar-vertical.navbar-expand-xl .navbar-collapse .navbar-nav {
      margin-left: 0;
      margin-right: 0;
   }
   .navbar-vertical.navbar-expand-xl .navbar-collapse .navbar-nav .nav-link {
      padding: 0.5rem calc(var(--tblr-content-padding) / 2);
      justify-content: flex-start;
   }
   .navbar-vertical.navbar-expand-xl .navbar-collapse .dropdown-menu-columns {
      flex-direction: column;
   }
   .navbar-vertical.navbar-expand-xl .navbar-collapse .dropdown-menu {
      padding: 0;
      background: transparent;
      position: static;
      color: inherit;
      box-shadow: none;
      border: none;
      min-width: 0;
      margin: 0;
   }
   .navbar-vertical.navbar-expand-xl
      .navbar-collapse
      .dropdown-menu
      .dropdown-item {
      min-width: 0;
      display: flex;
      width: auto;
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 1.75rem);
      color: inherit;
   }
   .navbar-vertical.navbar-expand-xl
      .navbar-collapse
      .dropdown-menu
      .dropdown-item.active,
   .navbar-vertical.navbar-expand-xl
      .navbar-collapse
      .dropdown-menu
      .dropdown-item:active {
      background: var(--tblr-navbar-active-bg);
   }
   .navbar-vertical.navbar-expand-xl
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 3.25rem);
   }
   .navbar-vertical.navbar-expand-xl
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 4.75rem);
   }
   .navbar-vertical.navbar-expand-xl .navbar-collapse .dropdown-toggle:after {
      margin-left: auto;
   }
   .navbar-vertical.navbar-expand-xl .navbar-collapse .nav-item.active:after {
      border-bottom-width: 0;
      border-left-width: 3px;
      right: auto;
      top: 0;
      bottom: 0;
   }
}
@media (min-width: 1400px) {
   .navbar-vertical.navbar-expand-xxl {
      width: 15rem;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1030;
      align-items: flex-start;
      transition: transform 0.3s;
      overflow-y: scroll;
      padding: 0;
   }
}
@media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
   .navbar-vertical.navbar-expand-xxl {
      transition: none;
   }
}
@media (min-width: 1400px) {
   .navbar-vertical.navbar-expand-xxl.navbar-right {
      left: auto;
      right: 0;
   }
}
@media (min-width: 1400px) {
   .navbar-vertical.navbar-expand-xxl .navbar-brand {
      padding: 0.75rem 0;
      justify-content: center;
   }
}
@media (min-width: 1400px) {
   .navbar-vertical.navbar-expand-xxl .navbar-collapse {
      align-items: stretch;
   }
}
@media (min-width: 1400px) {
   .navbar-vertical.navbar-expand-xxl .navbar-nav {
      flex-direction: column;
      flex-grow: 1;
      min-height: auto;
   }
   .navbar-vertical.navbar-expand-xxl .navbar-nav .nav-link {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
   }
}
@media (min-width: 1400px) {
   .navbar-vertical.navbar-expand-xxl > [class^="container"] {
      flex-direction: column;
      align-items: stretch;
      min-height: 100%;
      justify-content: flex-start;
      padding: 0;
   }
}
@media (min-width: 1400px) {
   .navbar-vertical.navbar-expand-xxl ~ .page {
      padding-left: 15rem;
   }
   .navbar-vertical.navbar-expand-xxl ~ .page [class^="container"] {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
   }
}
@media (min-width: 1400px) {
   .navbar-vertical.navbar-expand-xxl.navbar-right ~ .page {
      padding-left: 0;
      padding-right: 15rem;
   }
}
@media (min-width: 1400px) {
   .navbar-vertical.navbar-expand-xxl .navbar-collapse {
      flex-direction: column;
   }
   .navbar-vertical.navbar-expand-xxl .navbar-collapse [class^="container"] {
      flex-direction: column;
      align-items: stretch;
      padding: 0;
   }
   .navbar-vertical.navbar-expand-xxl .navbar-collapse .navbar-nav {
      margin-left: 0;
      margin-right: 0;
   }
   .navbar-vertical.navbar-expand-xxl .navbar-collapse .navbar-nav .nav-link {
      padding: 0.5rem calc(var(--tblr-content-padding) / 2);
      justify-content: flex-start;
   }
   .navbar-vertical.navbar-expand-xxl .navbar-collapse .dropdown-menu-columns {
      flex-direction: column;
   }
   .navbar-vertical.navbar-expand-xxl .navbar-collapse .dropdown-menu {
      padding: 0;
      background: transparent;
      position: static;
      color: inherit;
      box-shadow: none;
      border: none;
      min-width: 0;
      margin: 0;
   }
   .navbar-vertical.navbar-expand-xxl
      .navbar-collapse
      .dropdown-menu
      .dropdown-item {
      min-width: 0;
      display: flex;
      width: auto;
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 1.75rem);
      color: inherit;
   }
   .navbar-vertical.navbar-expand-xxl
      .navbar-collapse
      .dropdown-menu
      .dropdown-item.active,
   .navbar-vertical.navbar-expand-xxl
      .navbar-collapse
      .dropdown-menu
      .dropdown-item:active {
      background: var(--tblr-navbar-active-bg);
   }
   .navbar-vertical.navbar-expand-xxl
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 3.25rem);
   }
   .navbar-vertical.navbar-expand-xxl
      .navbar-collapse
      .dropdown-menu
      .dropdown-menu
      .dropdown-menu
      .dropdown-item {
      padding-left: calc(calc(var(--tblr-content-padding) / 2) + 4.75rem);
   }
   .navbar-vertical.navbar-expand-xxl .navbar-collapse .dropdown-toggle:after {
      margin-left: auto;
   }
   .navbar-vertical.navbar-expand-xxl .navbar-collapse .nav-item.active:after {
      border-bottom-width: 0;
      border-left-width: 3px;
      right: auto;
      top: 0;
      bottom: 0;
   }
}
.navbar-vertical.navbar-expand {
   width: 15rem;
   position: fixed;
   top: 0;
   left: 0;
   bottom: 0;
   z-index: 1030;
   align-items: flex-start;
   transition: transform 0.3s;
   overflow-y: scroll;
   padding: 0;
}
@media (prefers-reduced-motion: reduce) {
   .navbar-vertical.navbar-expand {
      transition: none;
   }
}
.navbar-vertical.navbar-expand.navbar-right {
   left: auto;
   right: 0;
}
.navbar-vertical.navbar-expand .navbar-brand {
   padding: 0.75rem 0;
   justify-content: center;
}
.navbar-vertical.navbar-expand .navbar-collapse {
   align-items: stretch;
}
.navbar-vertical.navbar-expand .navbar-nav {
   flex-direction: column;
   flex-grow: 1;
   min-height: auto;
}
.navbar-vertical.navbar-expand .navbar-nav .nav-link {
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
}
.navbar-vertical.navbar-expand > [class^="container"] {
   flex-direction: column;
   align-items: stretch;
   min-height: 100%;
   justify-content: flex-start;
   padding: 0;
}
.navbar-vertical.navbar-expand ~ .page {
   padding-left: 15rem;
}
.navbar-vertical.navbar-expand ~ .page [class^="container"] {
   padding-left: 1.5rem;
   padding-right: 1.5rem;
}
.navbar-vertical.navbar-expand.navbar-right ~ .page {
   padding-left: 0;
   padding-right: 15rem;
}
.navbar-vertical.navbar-expand .navbar-collapse {
   flex-direction: column;
}
.navbar-vertical.navbar-expand .navbar-collapse [class^="container"] {
   flex-direction: column;
   align-items: stretch;
   padding: 0;
}
.navbar-vertical.navbar-expand .navbar-collapse .navbar-nav {
   margin-left: 0;
   margin-right: 0;
}
.navbar-vertical.navbar-expand .navbar-collapse .navbar-nav .nav-link {
   padding: 0.5rem calc(var(--tblr-content-padding) / 2);
   justify-content: flex-start;
}
.navbar-vertical.navbar-expand .navbar-collapse .dropdown-menu-columns {
   flex-direction: column;
}
.navbar-vertical.navbar-expand .navbar-collapse .dropdown-menu {
   padding: 0;
   background: transparent;
   position: static;
   color: inherit;
   box-shadow: none;
   border: none;
   min-width: 0;
   margin: 0;
}
.navbar-vertical.navbar-expand .navbar-collapse .dropdown-menu .dropdown-item {
   min-width: 0;
   display: flex;
   width: auto;
   padding-left: calc(calc(var(--tblr-content-padding) / 2) + 1.75rem);
   color: inherit;
}
.navbar-vertical.navbar-expand
   .navbar-collapse
   .dropdown-menu
   .dropdown-item.active,
.navbar-vertical.navbar-expand
   .navbar-collapse
   .dropdown-menu
   .dropdown-item:active {
   background: var(--tblr-navbar-active-bg);
}
.navbar-vertical.navbar-expand
   .navbar-collapse
   .dropdown-menu
   .dropdown-menu
   .dropdown-item {
   padding-left: calc(calc(var(--tblr-content-padding) / 2) + 3.25rem);
}
.navbar-vertical.navbar-expand
   .navbar-collapse
   .dropdown-menu
   .dropdown-menu
   .dropdown-menu
   .dropdown-item {
   padding-left: calc(calc(var(--tblr-content-padding) / 2) + 4.75rem);
}
.navbar-vertical.navbar-expand .navbar-collapse .dropdown-toggle:after {
   margin-left: auto;
}
.navbar-vertical.navbar-expand .navbar-collapse .nav-item.active:after {
   border-bottom-width: 0;
   border-left-width: 3px;
   right: auto;
   top: 0;
   bottom: 0;
}

.navbar-overlap:after {
   content: "";
   height: 9rem;
   position: absolute;
   top: 100%;
   left: 0;
   right: 0;
   background: inherit;
   z-index: -1;
   box-shadow: inherit;
}

.page {
   display: flex;
   flex-direction: column;
   position: relative;
   min-height: 100vh;
}

.page-center {
   justify-content: center;
}

.page-wrapper {
   flex: 1;
   display: flex;
   flex-direction: column;
}
@media print {
   .page-wrapper {
      margin: 0 !important;
   }
}

.page-wrapper-full .page-body:first-child {
   margin: 0;
   border-top: 0;
}

.page-body {
   margin-top: var(--tblr-content-padding);
   margin-bottom: var(--tblr-content-padding);
}

.page-body-card {
   background: var(--tblr-bg-surface);
   border-top: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
   padding: var(--tblr-content-padding) 0;
   margin-bottom: 0;
   flex: 1;
}
.page-body ~ .page-body-card {
   margin-top: 0;
}

.page-cover {
   background: no-repeat center/cover;
   min-height: 9rem;
}
@media (min-width: 768px) {
   .page-cover {
      min-height: 12rem;
   }
}
@media (min-width: 992px) {
   .page-cover {
      min-height: 15rem;
   }
}

.page-cover-overlay {
   position: relative;
}
.page-cover-overlay:after {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 100%
   );
}

.page-header {
   display: flex;
   flex-wrap: wrap;
   min-height: 2.25rem;
   flex-direction: column;
   justify-content: center;
}
.page-wrapper .page-header {
   margin: var(--tblr-content-padding) 0 0;
}

.page-header-border {
   border-bottom: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
   padding: var(--tblr-content-padding) 0;
   margin: 0 !important;
   background-color: var(--tblr-bg-surface);
}

.page-pretitle {
   font-size: 0.625rem;
   font-weight: var(--tblr-font-weight-bold);
   text-transform: uppercase;
   letter-spacing: 0.04em;
   line-height: 1rem;
   color: var(--tblr-muted);
}

.page-title {
   margin: 0;
   font-size: 1.25rem;
   line-height: 1.75rem;
   font-weight: var(--tblr-font-weight-bold);
   color: inherit;
   display: flex;
   align-items: center;
}
.page-title svg {
   width: 1.5rem;
   height: 1.5rem;
   margin-right: 0.25rem;
}

.page-title-lg {
   font-size: 1.5rem;
   line-height: 2rem;
}

.page-subtitle {
   margin-top: 0.25rem;
   color: var(--tblr-muted);
}

.page-cover {
   --tblr-page-cover-blur: 20px;
   --tblr-page-cover-padding: 1rem;
   min-height: 6rem;
   padding: var(--tblr-page-cover-padding) 0;
   position: relative;
   overflow: hidden;
}

.page-cover-img {
   position: absolute;
   top: calc(-2 * var(--tblr-page-cover-blur, 0));
   left: calc(-2 * var(--tblr-page-cover-blur, 0));
   right: calc(-2 * var(--tblr-page-cover-blur, 0));
   bottom: calc(-2 * var(--tblr-page-cover-blur, 0));
   pointer-events: none;
   filter: blur(var(--tblr-page-cover-blur));
   -o-object-fit: cover;
   object-fit: cover;
   background-size: cover;
   background-position: center;
   z-index: -1;
}

.page-tabs {
   margin-top: 0.5rem;
   position: relative;
}

.page-header-tabs .nav-bordered {
   border: 0;
}
.page-header-tabs + .page-body-card {
   margin-top: 0;
}

.footer {
   border-top: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
   background-color: #ffffff;
   padding: 2rem 0;
   color: var(--tblr-muted);
   margin-top: auto;
}

.footer-transparent {
   background-color: transparent;
   border-top: 0;
}

body:not(.theme-dark) .hide-theme-light {
   display: none !important;
}

@media not print {
   .theme-dark {
      --tblr-body-color: #f8fafc;
      --tblr-body-color-rgb: 248, 250, 252;
      --tblr-muted: rgba(153, 159, 164, 1);
      --tblr-body-bg: #1a2234;
      --tblr-body-bg-rgb: 26, 34, 52;
      --tblr-bg-forms: #1a2234;
      --tblr-bg-surface: #1d273b;
      --tblr-bg-surface-dark: #1a2234;
      --tblr-bg-surface-secondary: #202c42;
      --tblr-bg-surface-tertiary: #1a2234;
      --tblr-link-color: #307fdd;
      --tblr-link-hover-color: #206bc4;
      --tblr-active-bg: #202c42;
      --tblr-disabled-color: var(--tblr-gray-700);
      --tblr-card-bg: #1a2234;
      --tblr-card-bg-hover: #1a2234;
      --tblr-card-bg-rgb: 26, 34, 52;
      --tblr-card-color: #f8fafc;
      --tblr-border-color: var(--tblr-dark-mode-border-color);
      --tblr-border-color-light: var(--tblr-dark-mode-border-color-light);
      --tblr-border-color-active: var(--tblr-dark-mode-border-color-active);
      --tblr-btn-color: #1a2234;
      --tblr-code-color: var(--tblr-body-color);
      --tblr-code-bg: #243049;
      color: #f8fafc;
      background-color: #1a2234;
   }
   .theme-dark .page {
      color: #f8fafc;
   }
   .theme-dark .hide-theme-dark {
      display: none !important;
   }
   .theme-dark .text-body {
      color: #f8fafc !important;
   }
   .theme-dark .card,
   .theme-dark .card-footer,
   .theme-dark .card-stacked::after,
   .theme-dark .footer:not(.footer-transparent),
   .theme-dark .modal-content,
   .theme-dark .modal-header,
   .theme-dark .dropdown-menu,
   .theme-dark .toast,
   .theme-dark .toast-header,
   .theme-dark .alert:not(.alert-important) {
      background-color: var(--tblr-bg-surface);
      color: inherit;
   }
   .theme-dark .modal {
      --tblr-modal-border-color: var(--tblr-border-color);
   }
   .theme-dark .bg-light {
      background-color: #1a2234 !important;
   }
   .theme-dark .card-tabs .nav-tabs .nav-link {
      background-color: #1a2234;
      color: inherit;
   }
   .theme-dark .card-tabs .nav-tabs .nav-link.active {
      background-color: #1d273b;
      color: inherit;
   }
   .theme-dark .form-check-input:not(:checked),
   .theme-dark .form-select,
   .theme-dark .form-file-text,
   .theme-dark .form-control,
   .theme-dark .form-selectgroup-check {
      background-color: #1a2234;
      color: #f8fafc;
      border-color: #243049;
   }
   .theme-dark .form-control-plaintext {
      color: #f8fafc;
   }
   .theme-dark .input-group-flat .input-group-text {
      background-color: #1a2234;
   }
   .theme-dark .input-group-text {
      border-color: #243049;
   }
   .theme-dark .highlight {
      background-color: #1a2234;
   }
   .theme-dark .timeline-event-icon {
      background-color: #202c42;
   }
   .theme-dark .markdown,
   .theme-dark .markdown > *,
   .theme-dark .accordion-button {
      color: inherit;
   }
   .theme-dark .btn-close,
   .theme-dark .accordion-button:after {
      filter: invert(1) grayscale(100%) brightness(200%);
   }
   .theme-dark .apexcharts-text {
      fill: #f8fafc;
   }
   .theme-dark .apexcharts-gridline {
      stroke: var(--tblr-border-color);
   }
   .theme-dark .apexcharts-legend-text {
      color: inherit !important;
   }
   .theme-dark .navbar-brand-autodark {
      filter: brightness(0) invert(1);
   }
   .theme-dark .table thead th,
   .theme-dark .markdown > table thead th,
   .theme-dark .input-group-text {
      background: transparent;
   }
   .theme-dark .list-group-header {
      background: #1a2234;
   }
   .theme-dark .list-group-item:not(.disabled):not(:disabled) {
      color: #f8fafc;
   }
   .theme-dark .list-group-item.disabled,
   .theme-dark .list-group-item:disabled {
      color: #49566c;
   }
   .theme-dark .apexcharts-radialbar-area {
      stroke: #243049;
   }
   .theme-dark .form-control.is-invalid,
   .theme-dark .was-validated .form-control:invalid {
      border-color: var(--tblr-danger);
   }
   .theme-dark .form-control.is-valid,
   .theme-dark .was-validated .form-control:valid {
      border-color: var(--tblr-success);
   }
}
@media not print {
   @media (prefers-color-scheme: dark) {
      .theme-dark-auto {
         --tblr-body-color: #f8fafc;
         --tblr-body-color-rgb: 248, 250, 252;
         --tblr-muted: rgba(153, 159, 164, 1);
         --tblr-body-bg: #1a2234;
         --tblr-body-bg-rgb: 26, 34, 52;
         --tblr-bg-forms: #1a2234;
         --tblr-bg-surface: #1d273b;
         --tblr-bg-surface-dark: #1a2234;
         --tblr-bg-surface-secondary: #202c42;
         --tblr-bg-surface-tertiary: #1a2234;
         --tblr-link-color: #307fdd;
         --tblr-link-hover-color: #206bc4;
         --tblr-active-bg: #202c42;
         --tblr-disabled-color: var(--tblr-gray-700);
         --tblr-card-bg: #1a2234;
         --tblr-card-bg-hover: #1a2234;
         --tblr-card-bg-rgb: 26, 34, 52;
         --tblr-card-color: #f8fafc;
         --tblr-border-color: var(--tblr-dark-mode-border-color);
         --tblr-border-color-light: var(--tblr-dark-mode-border-color-light);
         --tblr-border-color-active: var(--tblr-dark-mode-border-color-active);
         --tblr-btn-color: #1a2234;
         --tblr-code-color: var(--tblr-body-color);
         --tblr-code-bg: #243049;
         color: #f8fafc;
         background-color: #1a2234;
      }
      .theme-dark-auto .page {
         color: #f8fafc;
      }
      .theme-dark-auto .hide-theme-dark {
         display: none !important;
      }
      .theme-dark-auto .text-body {
         color: #f8fafc !important;
      }
      .theme-dark-auto .card,
      .theme-dark-auto .card-footer,
      .theme-dark-auto .card-stacked::after,
      .theme-dark-auto .footer:not(.footer-transparent),
      .theme-dark-auto .modal-content,
      .theme-dark-auto .modal-header,
      .theme-dark-auto .dropdown-menu,
      .theme-dark-auto .toast,
      .theme-dark-auto .toast-header,
      .theme-dark-auto .alert:not(.alert-important) {
         background-color: var(--tblr-bg-surface);
         color: inherit;
      }
      .theme-dark-auto .modal {
         --tblr-modal-border-color: var(--tblr-border-color);
      }
      .theme-dark-auto .bg-light {
         background-color: #1a2234 !important;
      }
      .theme-dark-auto .card-tabs .nav-tabs .nav-link {
         background-color: #1a2234;
         color: inherit;
      }
      .theme-dark-auto .card-tabs .nav-tabs .nav-link.active {
         background-color: #1d273b;
         color: inherit;
      }
      .theme-dark-auto .form-check-input:not(:checked),
      .theme-dark-auto .form-select,
      .theme-dark-auto .form-file-text,
      .theme-dark-auto .form-control,
      .theme-dark-auto .form-selectgroup-check {
         background-color: #1a2234;
         color: #f8fafc;
         border-color: #243049;
      }
      .theme-dark-auto .form-control-plaintext {
         color: #f8fafc;
      }
      .theme-dark-auto .input-group-flat .input-group-text {
         background-color: #1a2234;
      }
      .theme-dark-auto .input-group-text {
         border-color: #243049;
      }
      .theme-dark-auto .highlight {
         background-color: #1a2234;
      }
      .theme-dark-auto .timeline-event-icon {
         background-color: #202c42;
      }
      .theme-dark-auto .markdown,
      .theme-dark-auto .markdown > *,
      .theme-dark-auto .accordion-button {
         color: inherit;
      }
      .theme-dark-auto .btn-close,
      .theme-dark-auto .accordion-button:after {
         filter: invert(1) grayscale(100%) brightness(200%);
      }
      .theme-dark-auto .apexcharts-text {
         fill: #f8fafc;
      }
      .theme-dark-auto .apexcharts-gridline {
         stroke: var(--tblr-border-color);
      }
      .theme-dark-auto .apexcharts-legend-text {
         color: inherit !important;
      }
      .theme-dark-auto .navbar-brand-autodark {
         filter: brightness(0) invert(1);
      }
      .theme-dark-auto .table thead th,
      .theme-dark-auto .markdown > table thead th,
      .theme-dark-auto .input-group-text {
         background: transparent;
      }
      .theme-dark-auto .list-group-header {
         background: #1a2234;
      }
      .theme-dark-auto .list-group-item:not(.disabled):not(:disabled) {
         color: #f8fafc;
      }
      .theme-dark-auto .list-group-item.disabled,
      .theme-dark-auto .list-group-item:disabled {
         color: #49566c;
      }
      .theme-dark-auto .apexcharts-radialbar-area {
         stroke: #243049;
      }
      .theme-dark-auto .form-control.is-invalid,
      .theme-dark-auto .was-validated .form-control:invalid {
         border-color: var(--tblr-danger);
      }
      .theme-dark-auto .form-control.is-valid,
      .theme-dark-auto .was-validated .form-control:valid {
         border-color: var(--tblr-success);
      }
   }
}
.accordion {
   --tblr-accordion-color: var(--tblr-body-color);
}

.accordion-button:focus:not(:focus-visible) {
   outline: none;
   box-shadow: none;
}
.accordion-button:after {
   opacity: 0.7;
}
.accordion-button:not(.collapsed) {
   font-weight: var(--tblr-font-weight-bold);
   border-bottom-color: transparent;
   box-shadow: none;
}
.accordion-button:not(.collapsed):after {
   opacity: 1;
}

.alert {
   --tblr-alert-color: var(--tblr-muted);
   background: #ffffff;
   border: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color-translucent);
   border-left: 0.25rem var(--tblr-border-style) var(--tblr-alert-color);
   box-shadow: rgba(29, 39, 59, 0.04) 0 2px 4px 0;
}
.alert > :last-child {
   margin-bottom: 0;
}

.alert-important {
   border-color: transparent;
   background: var(--tblr-alert-color);
   color: #fff;
}
.alert-important .alert-icon,
.alert-important .alert-link {
   color: inherit;
}
.alert-important .alert-link:hover {
   color: inherit;
}

.alert-link,
.alert-link:hover {
   color: var(--tblr-alert-color);
}

.alert-primary {
   --tblr-alert-color: var(--tblr-primary);
}

.alert-secondary {
   --tblr-alert-color: var(--tblr-secondary);
}

.alert-success {
   --tblr-alert-color: var(--tblr-success);
}

.alert-info {
   --tblr-alert-color: var(--tblr-info);
}

.alert-warning {
   --tblr-alert-color: var(--tblr-warning);
}

.alert-danger {
   --tblr-alert-color: var(--tblr-danger);
}

.alert-light {
   --tblr-alert-color: var(--tblr-light);
}

.alert-dark {
   --tblr-alert-color: var(--tblr-dark);
}

.alert-muted {
   --tblr-alert-color: var(--tblr-muted);
}

.alert-blue {
   --tblr-alert-color: var(--tblr-blue);
}

.alert-azure {
   --tblr-alert-color: var(--tblr-azure);
}

.alert-indigo {
   --tblr-alert-color: var(--tblr-indigo);
}

.alert-purple {
   --tblr-alert-color: var(--tblr-purple);
}

.alert-pink {
   --tblr-alert-color: var(--tblr-pink);
}

.alert-red {
   --tblr-alert-color: var(--tblr-red);
}

.alert-orange {
   --tblr-alert-color: var(--tblr-orange);
}

.alert-yellow {
   --tblr-alert-color: var(--tblr-yellow);
}

.alert-lime {
   --tblr-alert-color: var(--tblr-lime);
}

.alert-green {
   --tblr-alert-color: var(--tblr-green);
}

.alert-teal {
   --tblr-alert-color: var(--tblr-teal);
}

.alert-cyan {
   --tblr-alert-color: var(--tblr-cyan);
}

.alert-facebook {
   --tblr-alert-color: var(--tblr-facebook);
}

.alert-twitter {
   --tblr-alert-color: var(--tblr-twitter);
}

.alert-linkedin {
   --tblr-alert-color: var(--tblr-linkedin);
}

.alert-google {
   --tblr-alert-color: var(--tblr-google);
}

.alert-youtube {
   --tblr-alert-color: var(--tblr-youtube);
}

.alert-vimeo {
   --tblr-alert-color: var(--tblr-vimeo);
}

.alert-dribbble {
   --tblr-alert-color: var(--tblr-dribbble);
}

.alert-github {
   --tblr-alert-color: var(--tblr-github);
}

.alert-instagram {
   --tblr-alert-color: var(--tblr-instagram);
}

.alert-pinterest {
   --tblr-alert-color: var(--tblr-pinterest);
}

.alert-vk {
   --tblr-alert-color: var(--tblr-vk);
}

.alert-rss {
   --tblr-alert-color: var(--tblr-rss);
}

.alert-flickr {
   --tblr-alert-color: var(--tblr-flickr);
}

.alert-bitbucket {
   --tblr-alert-color: var(--tblr-bitbucket);
}

.alert-tabler {
   --tblr-alert-color: var(--tblr-tabler);
}

.alert-icon {
   color: var(--tblr-alert-color);
   width: 1.5rem !important;
   height: 1.5rem !important;
   margin: -0.125rem 1rem -0.125rem 0;
}

.alert-title {
   font-size: 0.875rem;
   line-height: 1.25rem;
   font-weight: var(--tblr-font-weight-bold);
   margin-bottom: 0.25rem;
   color: var(--tblr-alert-color);
}

.avatar {
   --tblr-avatar-size: 2.5rem;
   --tblr-avatar-bg: var(--tblr-bg-surface-secondary);
   --tblr-avatar-shadow: inset rgba(var(--tblr-body-color-rgb), 0.04) 0 0 0 1px;
   position: relative;
   width: var(--tblr-avatar-size);
   height: var(--tblr-avatar-size);
   font-size: calc(var(--tblr-avatar-size) / 2.8571428572);
   font-weight: var(--tblr-font-weight-medium);
   display: inline-flex;
   align-items: center;
   justify-content: center;
   color: var(--tblr-muted);
   text-align: center;
   text-transform: uppercase;
   vertical-align: bottom;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   background: var(--tblr-avatar-bg) no-repeat center/cover;
   border-radius: var(--tblr-border-radius);
   box-shadow: var(--tblr-avatar-shadow);
}
.avatar svg {
   width: calc(var(--tblr-avatar-size) / 1.6666666667);
   height: calc(var(--tblr-avatar-size) / 1.6666666667);
}
.avatar .badge {
   position: absolute;
   right: 0;
   bottom: 0;
   border-radius: 100rem;
   box-shadow: 0 0 0 2px var(--tblr-bg-surface);
}
a.avatar {
   cursor: pointer;
}

.avatar-rounded {
   border-radius: 100rem;
}

.avatar-xs {
   --tblr-avatar-size: 1.5rem;
}

.avatar-xs .badge:empty {
   width: 0.375rem;
   height: 0.375rem;
}

.avatar-sm {
   --tblr-avatar-size: 2rem;
}

.avatar-sm .badge:empty {
   width: 0.5rem;
   height: 0.5rem;
}

.avatar-md {
   --tblr-avatar-size: 4rem;
}

.avatar-md .badge:empty {
   width: 1rem;
   height: 1rem;
}

.avatar-lg {
   --tblr-avatar-size: 5.5rem;
}

.avatar-lg .badge:empty {
   width: 1.375rem;
   height: 1.375rem;
}

.avatar-xl {
   --tblr-avatar-size: 7rem;
}

.avatar-xl .badge:empty {
   width: 1.75rem;
   height: 1.75rem;
}

.avatar-2xl {
   --tblr-avatar-size: 11rem;
}

.avatar-2xl .badge:empty {
   width: 2.75rem;
   height: 2.75rem;
}

.avatar-list {
   --tblr-list-gap: 0.5rem;
   display: flex;
   flex-wrap: wrap;
   gap: var(--tblr-list-gap);
}
.avatar-list a.avatar:hover {
   z-index: 1;
}

.avatar-list-stacked {
   display: block;
   --tblr-list-gap: 0;
}
.avatar-list-stacked .avatar {
   margin-right: calc(-0.5 * var(--tblr-avatar-size)) !important;
   box-shadow: var(--tblr-avatar-shadow),
      0 0 0 2px var(--tblr-card-bg, var(--tblr-bg-surface));
}

.avatar-upload {
   width: 4rem;
   height: 4rem;
   border: var(--tblr-border-width) dashed var(--tblr-border-color);
   background: var(--tblr-bg-forms);
   flex-direction: column;
   transition: color 0.3s, background-color 0.3s;
}
@media (prefers-reduced-motion: reduce) {
   .avatar-upload {
      transition: none;
   }
}
.avatar-upload svg {
   width: 1.5rem;
   height: 1.5rem;
   stroke-width: 1;
}
.avatar-upload:hover {
   border-color: var(--tblr-primary);
   color: var(--tblr-primary);
   text-decoration: none;
}

.avatar-upload-text {
   font-size: 0.625rem;
   line-height: 1;
   margin-top: 0.25rem;
}

.avatar-cover {
   margin-top: calc(-0.5 * var(--tblr-avatar-size));
   box-shadow: 0 0 0 0.25rem var(--tblr-card-bg, var(--tblr-body-bg));
}

.badge {
   justify-content: center;
   align-items: center;
   background: #6c7a91;
   overflow: hidden;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   border: var(--tblr-border-width) var(--tblr-border-style) transparent;
   min-width: 1.35714285em;
   font-weight: var(--tblr-font-weight-bold);
   letter-spacing: 0.04em;
   vertical-align: bottom;
}
a.badge {
   color: var(--tblr-bg-surface);
}

.badge:empty {
   display: inline-block;
   width: 0.5rem;
   height: 0.5rem;
   min-width: 0;
   min-height: auto;
   padding: 0;
   border-radius: 100rem;
   vertical-align: baseline;
}
.badge .avatar {
   box-sizing: content-box;
   width: 1.25rem;
   height: 1.25rem;
   margin: 0 0.5rem 0 -0.5rem;
}
.badge .icon {
   width: 1em;
   height: 1em;
   font-size: 1rem;
   stroke-width: 2;
}

.badge-outline {
   background-color: transparent;
   border: var(--tblr-border-width) var(--tblr-border-style) currentColor;
}

.badge-pill {
   border-radius: 100rem;
}

.badges-list {
   --tblr-list-gap: 0.5rem;
   display: flex;
   flex-wrap: wrap;
   gap: var(--tblr-list-gap);
}

.badge-notification {
   position: absolute !important;
   top: 0 !important;
   right: 0 !important;
   transform: translate(50%, -50%);
   z-index: 1;
}

.badge-blink {
   animation: blink 2s infinite;
}

.breadcrumb {
   --tblr-breadcrumb-item-active-font-weight: var(--tblr-font-weight-bold);
   --tblr-breadcrumb-item-disabled-color: var(--tblr-disabled-color);
   --tblr-breadcrumb-link-color: var(--tblr-link-color);
   padding: 0;
   margin: 0;
   background: transparent;
}
.breadcrumb a {
   color: var(--tblr-breadcrumb-link-color);
}
.breadcrumb a:hover {
   text-decoration: underline;
}

.breadcrumb-muted {
   --tblr-breadcrumb-link-color: var(--tblr-muted);
}

.breadcrumb-item.active {
   font-weight: var(--tblr-breadcrumb-item-active-font-weight);
}
.breadcrumb-item.active a {
   color: inherit;
   pointer-events: none;
}
.breadcrumb-item.disabled {
   color: var(--tblr-breadcrumb-item-disabled-color);
}
.breadcrumb-item.disabled:before {
   color: inherit;
}
.breadcrumb-item.disabled a {
   color: inherit;
   pointer-events: none;
}

.breadcrumb-dots {
   --tblr-breadcrumb-divider: "·";
}

.breadcrumb-arrows {
   --tblr-breadcrumb-divider: "›";
}

.breadcrumb-bullets {
   --tblr-breadcrumb-divider: "•";
}

.btn {
   --tblr-btn-bg: var(--tblr-bg-surface);
   --tblr-btn-hover-bg: var(--tblr-bg-surface);
   --tblr-btn-icon-size: 1.25rem;
   --tblr-btn-bg: var(--tblr-bg-surface);
   --tblr-btn-color: var(--tblr-body-color);
   --tblr-btn-border-color: var(--tblr-border-color);
   --tblr-btn-hover-bg: var(--tblr-btn-bg);
   --tblr-btn-hover-border-color: var(--tblr-border-color-active);
   --tblr-btn-box-shadow: var(--tblr-shadow-button);
   --tblr-btn-active-color: var(--tblr-primary);
   --tblr-btn-active-bg: rgba(var(--tblr-primary-rgb), 0.04);
   --tblr-btn-active-border-color: var(--tblr-primary);
   display: inline-flex;
   align-items: center;
   justify-content: center;
   white-space: nowrap;
   box-shadow: var(--tblr-btn-box-shadow);
}
.btn .icon {
   width: var(--tblr-btn-icon-size);
   height: var(--tblr-btn-icon-size);
   min-width: var(--tblr-btn-icon-size);
   margin: 0 calc(var(--tblr-btn-padding-x) / 2) 0
      calc(var(--tblr-btn-padding-x) / -4);
   vertical-align: bottom;
   color: inherit;
}
.btn .avatar {
   width: var(--tblr-btn-icon-size);
   height: var(--tblr-btn-icon-size);
   margin: 0 calc(var(--tblr-btn-padding-x) / 2) 0
      calc(var(--tblr-btn-padding-x) / -4);
}
.btn .icon-right {
   margin: 0 calc(var(--tblr-btn-padding-x) / -4) 0
      calc(var(--tblr-btn-padding-x) / 2);
}
.btn .badge {
   top: auto;
}
.btn-check + .btn:hover {
   color: var(--tblr-btn-hover-color);
   background-color: var(--tblr-btn-hover-bg);
   border-color: var(--tblr-btn-hover-border-color);
}

.btn-link {
   color: #206bc4;
   background-color: transparent;
   border-color: transparent;
   box-shadow: none;
}
.btn-link .icon {
   color: inherit;
}
.btn-link:hover {
   color: #1a569d;
   border-color: transparent;
}

.btn-primary {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-primary-fg);
   --tblr-btn-bg: var(--tblr-primary);
   --tblr-btn-hover-color: var(--tblr-primary-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-primary-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-primary-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-primary-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-primary);
   --tblr-btn-disabled-color: var(--tblr-primary-fg);
   // --tblr-btn-box-shadow: var(--tblr-shadow-button), var(--tblr-shadow-button-inset);
}

.btn-outline-primary {
   --tblr-btn-color: var(--tblr-primary);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-primary);
   --tblr-btn-hover-color: var(--tblr-primary-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-primary);
   --tblr-btn-active-color: var(--tblr-primary-fg);
   --tblr-btn-active-bg: var(--tblr-primary);
   --tblr-btn-disabled-color: var(--tblr-primary);
   --tblr-btn-disabled-border-color: var(--tblr-primary);
}

.btn-secondary {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-secondary-fg);
   --tblr-btn-bg: var(--tblr-secondary);
   --tblr-btn-hover-color: var(--tblr-secondary-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-secondary-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-secondary-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-secondary-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-secondary);
   --tblr-btn-disabled-color: var(--tblr-secondary-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-secondary {
   --tblr-btn-color: var(--tblr-secondary);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-secondary);
   --tblr-btn-hover-color: var(--tblr-secondary-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-secondary);
   --tblr-btn-active-color: var(--tblr-secondary-fg);
   --tblr-btn-active-bg: var(--tblr-secondary);
   --tblr-btn-disabled-color: var(--tblr-secondary);
   --tblr-btn-disabled-border-color: var(--tblr-secondary);
}

.btn-success {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-success-fg);
   --tblr-btn-bg: var(--tblr-success);
   --tblr-btn-hover-color: var(--tblr-success-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-success-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-success-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-success-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-success);
   --tblr-btn-disabled-color: var(--tblr-success-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-success {
   --tblr-btn-color: var(--tblr-success);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-success);
   --tblr-btn-hover-color: var(--tblr-success-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-success);
   --tblr-btn-active-color: var(--tblr-success-fg);
   --tblr-btn-active-bg: var(--tblr-success);
   --tblr-btn-disabled-color: var(--tblr-success);
   --tblr-btn-disabled-border-color: var(--tblr-success);
}

.btn-info {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-info-fg);
   --tblr-btn-bg: var(--tblr-info);
   --tblr-btn-hover-color: var(--tblr-info-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-info-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-info-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-info-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-info);
   --tblr-btn-disabled-color: var(--tblr-info-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-info {
   --tblr-btn-color: var(--tblr-info);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-info);
   --tblr-btn-hover-color: var(--tblr-info-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-info);
   --tblr-btn-active-color: var(--tblr-info-fg);
   --tblr-btn-active-bg: var(--tblr-info);
   --tblr-btn-disabled-color: var(--tblr-info);
   --tblr-btn-disabled-border-color: var(--tblr-info);
}

.btn-warning {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-warning-fg);
   --tblr-btn-bg: var(--tblr-warning);
   --tblr-btn-hover-color: var(--tblr-warning-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-warning-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-warning-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-warning-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-warning);
   --tblr-btn-disabled-color: var(--tblr-warning-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-warning {
   --tblr-btn-color: var(--tblr-warning);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-warning);
   --tblr-btn-hover-color: var(--tblr-warning-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-warning);
   --tblr-btn-active-color: var(--tblr-warning-fg);
   --tblr-btn-active-bg: var(--tblr-warning);
   --tblr-btn-disabled-color: var(--tblr-warning);
   --tblr-btn-disabled-border-color: var(--tblr-warning);
}

.btn-danger {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-danger-fg);
   --tblr-btn-bg: var(--tblr-danger);
   --tblr-btn-hover-color: var(--tblr-danger-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-danger-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-danger-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-danger-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-danger);
   --tblr-btn-disabled-color: var(--tblr-danger-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-danger {
   --tblr-btn-color: var(--tblr-danger);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-danger);
   --tblr-btn-hover-color: var(--tblr-danger-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-danger);
   --tblr-btn-active-color: var(--tblr-danger-fg);
   --tblr-btn-active-bg: var(--tblr-danger);
   --tblr-btn-disabled-color: var(--tblr-danger);
   --tblr-btn-disabled-border-color: var(--tblr-danger);
}

.btn-light {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-light-fg);
   --tblr-btn-bg: var(--tblr-light);
   --tblr-btn-hover-color: var(--tblr-light-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-light-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-light-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-light-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-light);
   --tblr-btn-disabled-color: var(--tblr-light-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-light {
   --tblr-btn-color: var(--tblr-light);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-light);
   --tblr-btn-hover-color: var(--tblr-light-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-light);
   --tblr-btn-active-color: var(--tblr-light-fg);
   --tblr-btn-active-bg: var(--tblr-light);
   --tblr-btn-disabled-color: var(--tblr-light);
   --tblr-btn-disabled-border-color: var(--tblr-light);
}

.btn-dark {
   --tblr-btn-border-color: var(--tblr-dark-mode-border-color);
   --tblr-btn-hover-border-color: var(--tblr-dark-mode-border-color-active);
   --tblr-btn-active-border-color: var(--tblr-dark-mode-border-color-active);
   --tblr-btn-color: var(--tblr-dark-fg);
   --tblr-btn-bg: var(--tblr-dark);
   --tblr-btn-hover-color: var(--tblr-dark-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-dark-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-dark-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-dark-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-dark);
   --tblr-btn-disabled-color: var(--tblr-dark-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-dark {
   --tblr-btn-color: var(--tblr-dark);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-dark);
   --tblr-btn-hover-color: var(--tblr-dark-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-dark);
   --tblr-btn-active-color: var(--tblr-dark-fg);
   --tblr-btn-active-bg: var(--tblr-dark);
   --tblr-btn-disabled-color: var(--tblr-dark);
   --tblr-btn-disabled-border-color: var(--tblr-dark);
}

.btn-muted {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-muted-fg);
   --tblr-btn-bg: var(--tblr-muted);
   --tblr-btn-hover-color: var(--tblr-muted-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-muted-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-muted-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-muted-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-muted);
   --tblr-btn-disabled-color: var(--tblr-muted-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-muted {
   --tblr-btn-color: var(--tblr-muted);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-muted);
   --tblr-btn-hover-color: var(--tblr-muted-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-muted);
   --tblr-btn-active-color: var(--tblr-muted-fg);
   --tblr-btn-active-bg: var(--tblr-muted);
   --tblr-btn-disabled-color: var(--tblr-muted);
   --tblr-btn-disabled-border-color: var(--tblr-muted);
}

.btn-blue {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-blue-fg);
   --tblr-btn-bg: var(--tblr-blue);
   --tblr-btn-hover-color: var(--tblr-blue-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-blue-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-blue-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-blue-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-blue);
   --tblr-btn-disabled-color: var(--tblr-blue-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-blue {
   --tblr-btn-color: var(--tblr-blue);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-blue);
   --tblr-btn-hover-color: var(--tblr-blue-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-blue);
   --tblr-btn-active-color: var(--tblr-blue-fg);
   --tblr-btn-active-bg: var(--tblr-blue);
   --tblr-btn-disabled-color: var(--tblr-blue);
   --tblr-btn-disabled-border-color: var(--tblr-blue);
}

.btn-azure {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-azure-fg);
   --tblr-btn-bg: var(--tblr-azure);
   --tblr-btn-hover-color: var(--tblr-azure-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-azure-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-azure-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-azure-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-azure);
   --tblr-btn-disabled-color: var(--tblr-azure-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-azure {
   --tblr-btn-color: var(--tblr-azure);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-azure);
   --tblr-btn-hover-color: var(--tblr-azure-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-azure);
   --tblr-btn-active-color: var(--tblr-azure-fg);
   --tblr-btn-active-bg: var(--tblr-azure);
   --tblr-btn-disabled-color: var(--tblr-azure);
   --tblr-btn-disabled-border-color: var(--tblr-azure);
}

.btn-indigo {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-indigo-fg);
   --tblr-btn-bg: var(--tblr-indigo);
   --tblr-btn-hover-color: var(--tblr-indigo-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-indigo-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-indigo-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-indigo-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-indigo);
   --tblr-btn-disabled-color: var(--tblr-indigo-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-indigo {
   --tblr-btn-color: var(--tblr-indigo);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-indigo);
   --tblr-btn-hover-color: var(--tblr-indigo-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-indigo);
   --tblr-btn-active-color: var(--tblr-indigo-fg);
   --tblr-btn-active-bg: var(--tblr-indigo);
   --tblr-btn-disabled-color: var(--tblr-indigo);
   --tblr-btn-disabled-border-color: var(--tblr-indigo);
}

.btn-purple {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-purple-fg);
   --tblr-btn-bg: var(--tblr-purple);
   --tblr-btn-hover-color: var(--tblr-purple-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-purple-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-purple-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-purple-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-purple);
   --tblr-btn-disabled-color: var(--tblr-purple-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-purple {
   --tblr-btn-color: var(--tblr-purple);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-purple);
   --tblr-btn-hover-color: var(--tblr-purple-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-purple);
   --tblr-btn-active-color: var(--tblr-purple-fg);
   --tblr-btn-active-bg: var(--tblr-purple);
   --tblr-btn-disabled-color: var(--tblr-purple);
   --tblr-btn-disabled-border-color: var(--tblr-purple);
}

.btn-pink {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-pink-fg);
   --tblr-btn-bg: var(--tblr-pink);
   --tblr-btn-hover-color: var(--tblr-pink-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-pink-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-pink-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-pink-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-pink);
   --tblr-btn-disabled-color: var(--tblr-pink-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-pink {
   --tblr-btn-color: var(--tblr-pink);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-pink);
   --tblr-btn-hover-color: var(--tblr-pink-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-pink);
   --tblr-btn-active-color: var(--tblr-pink-fg);
   --tblr-btn-active-bg: var(--tblr-pink);
   --tblr-btn-disabled-color: var(--tblr-pink);
   --tblr-btn-disabled-border-color: var(--tblr-pink);
}

.btn-red {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-red-fg);
   --tblr-btn-bg: var(--tblr-red);
   --tblr-btn-hover-color: var(--tblr-red-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-red-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-red-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-red-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-red);
   --tblr-btn-disabled-color: var(--tblr-red-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-red {
   --tblr-btn-color: var(--tblr-red);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-red);
   --tblr-btn-hover-color: var(--tblr-red-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-red);
   --tblr-btn-active-color: var(--tblr-red-fg);
   --tblr-btn-active-bg: var(--tblr-red);
   --tblr-btn-disabled-color: var(--tblr-red);
   --tblr-btn-disabled-border-color: var(--tblr-red);
}

.btn-orange {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-orange-fg);
   --tblr-btn-bg: var(--tblr-orange);
   --tblr-btn-hover-color: var(--tblr-orange-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-orange-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-orange-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-orange-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-orange);
   --tblr-btn-disabled-color: var(--tblr-orange-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-orange {
   --tblr-btn-color: var(--tblr-orange);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-orange);
   --tblr-btn-hover-color: var(--tblr-orange-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-orange);
   --tblr-btn-active-color: var(--tblr-orange-fg);
   --tblr-btn-active-bg: var(--tblr-orange);
   --tblr-btn-disabled-color: var(--tblr-orange);
   --tblr-btn-disabled-border-color: var(--tblr-orange);
}

.btn-yellow {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-yellow-fg);
   --tblr-btn-bg: var(--tblr-yellow);
   --tblr-btn-hover-color: var(--tblr-yellow-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-yellow-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-yellow-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-yellow-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-yellow);
   --tblr-btn-disabled-color: var(--tblr-yellow-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-yellow {
   --tblr-btn-color: var(--tblr-yellow);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-yellow);
   --tblr-btn-hover-color: var(--tblr-yellow-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-yellow);
   --tblr-btn-active-color: var(--tblr-yellow-fg);
   --tblr-btn-active-bg: var(--tblr-yellow);
   --tblr-btn-disabled-color: var(--tblr-yellow);
   --tblr-btn-disabled-border-color: var(--tblr-yellow);
}

.btn-lime {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-lime-fg);
   --tblr-btn-bg: var(--tblr-lime);
   --tblr-btn-hover-color: var(--tblr-lime-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-lime-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-lime-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-lime-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-lime);
   --tblr-btn-disabled-color: var(--tblr-lime-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-lime {
   --tblr-btn-color: var(--tblr-lime);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-lime);
   --tblr-btn-hover-color: var(--tblr-lime-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-lime);
   --tblr-btn-active-color: var(--tblr-lime-fg);
   --tblr-btn-active-bg: var(--tblr-lime);
   --tblr-btn-disabled-color: var(--tblr-lime);
   --tblr-btn-disabled-border-color: var(--tblr-lime);
}

.btn-green {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-green-fg);
   --tblr-btn-bg: var(--tblr-green);
   --tblr-btn-hover-color: var(--tblr-green-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-green-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-green-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-green-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-green);
   --tblr-btn-disabled-color: var(--tblr-green-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-green {
   --tblr-btn-color: var(--tblr-green);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-green);
   --tblr-btn-hover-color: var(--tblr-green-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-green);
   --tblr-btn-active-color: var(--tblr-green-fg);
   --tblr-btn-active-bg: var(--tblr-green);
   --tblr-btn-disabled-color: var(--tblr-green);
   --tblr-btn-disabled-border-color: var(--tblr-green);
}

.btn-teal {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-teal-fg);
   --tblr-btn-bg: var(--tblr-teal);
   --tblr-btn-hover-color: var(--tblr-teal-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-teal-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-teal-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-teal-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-teal);
   --tblr-btn-disabled-color: var(--tblr-teal-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-teal {
   --tblr-btn-color: var(--tblr-teal);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-teal);
   --tblr-btn-hover-color: var(--tblr-teal-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-teal);
   --tblr-btn-active-color: var(--tblr-teal-fg);
   --tblr-btn-active-bg: var(--tblr-teal);
   --tblr-btn-disabled-color: var(--tblr-teal);
   --tblr-btn-disabled-border-color: var(--tblr-teal);
}

.btn-cyan {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-cyan-fg);
   --tblr-btn-bg: var(--tblr-cyan);
   --tblr-btn-hover-color: var(--tblr-cyan-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-cyan-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-cyan-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-cyan-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-cyan);
   --tblr-btn-disabled-color: var(--tblr-cyan-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-cyan {
   --tblr-btn-color: var(--tblr-cyan);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-cyan);
   --tblr-btn-hover-color: var(--tblr-cyan-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-cyan);
   --tblr-btn-active-color: var(--tblr-cyan-fg);
   --tblr-btn-active-bg: var(--tblr-cyan);
   --tblr-btn-disabled-color: var(--tblr-cyan);
   --tblr-btn-disabled-border-color: var(--tblr-cyan);
}

.btn-facebook {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-facebook-fg);
   --tblr-btn-bg: var(--tblr-facebook);
   --tblr-btn-hover-color: var(--tblr-facebook-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-facebook-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-facebook-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-facebook-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-facebook);
   --tblr-btn-disabled-color: var(--tblr-facebook-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-facebook {
   --tblr-btn-color: var(--tblr-facebook);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-facebook);
   --tblr-btn-hover-color: var(--tblr-facebook-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-facebook);
   --tblr-btn-active-color: var(--tblr-facebook-fg);
   --tblr-btn-active-bg: var(--tblr-facebook);
   --tblr-btn-disabled-color: var(--tblr-facebook);
   --tblr-btn-disabled-border-color: var(--tblr-facebook);
}

.btn-twitter {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-twitter-fg);
   --tblr-btn-bg: var(--tblr-twitter);
   --tblr-btn-hover-color: var(--tblr-twitter-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-twitter-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-twitter-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-twitter-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-twitter);
   --tblr-btn-disabled-color: var(--tblr-twitter-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-twitter {
   --tblr-btn-color: var(--tblr-twitter);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-twitter);
   --tblr-btn-hover-color: var(--tblr-twitter-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-twitter);
   --tblr-btn-active-color: var(--tblr-twitter-fg);
   --tblr-btn-active-bg: var(--tblr-twitter);
   --tblr-btn-disabled-color: var(--tblr-twitter);
   --tblr-btn-disabled-border-color: var(--tblr-twitter);
}

.btn-linkedin {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-linkedin-fg);
   --tblr-btn-bg: var(--tblr-linkedin);
   --tblr-btn-hover-color: var(--tblr-linkedin-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-linkedin-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-linkedin-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-linkedin-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-linkedin);
   --tblr-btn-disabled-color: var(--tblr-linkedin-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-linkedin {
   --tblr-btn-color: var(--tblr-linkedin);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-linkedin);
   --tblr-btn-hover-color: var(--tblr-linkedin-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-linkedin);
   --tblr-btn-active-color: var(--tblr-linkedin-fg);
   --tblr-btn-active-bg: var(--tblr-linkedin);
   --tblr-btn-disabled-color: var(--tblr-linkedin);
   --tblr-btn-disabled-border-color: var(--tblr-linkedin);
}

.btn-google {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-google-fg);
   --tblr-btn-bg: var(--tblr-google);
   --tblr-btn-hover-color: var(--tblr-google-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-google-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-google-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-google-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-google);
   --tblr-btn-disabled-color: var(--tblr-google-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-google {
   --tblr-btn-color: var(--tblr-google);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-google);
   --tblr-btn-hover-color: var(--tblr-google-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-google);
   --tblr-btn-active-color: var(--tblr-google-fg);
   --tblr-btn-active-bg: var(--tblr-google);
   --tblr-btn-disabled-color: var(--tblr-google);
   --tblr-btn-disabled-border-color: var(--tblr-google);
}

.btn-youtube {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-youtube-fg);
   --tblr-btn-bg: var(--tblr-youtube);
   --tblr-btn-hover-color: var(--tblr-youtube-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-youtube-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-youtube-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-youtube-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-youtube);
   --tblr-btn-disabled-color: var(--tblr-youtube-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-youtube {
   --tblr-btn-color: var(--tblr-youtube);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-youtube);
   --tblr-btn-hover-color: var(--tblr-youtube-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-youtube);
   --tblr-btn-active-color: var(--tblr-youtube-fg);
   --tblr-btn-active-bg: var(--tblr-youtube);
   --tblr-btn-disabled-color: var(--tblr-youtube);
   --tblr-btn-disabled-border-color: var(--tblr-youtube);
}

.btn-vimeo {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-vimeo-fg);
   --tblr-btn-bg: var(--tblr-vimeo);
   --tblr-btn-hover-color: var(--tblr-vimeo-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-vimeo-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-vimeo-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-vimeo-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-vimeo);
   --tblr-btn-disabled-color: var(--tblr-vimeo-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-vimeo {
   --tblr-btn-color: var(--tblr-vimeo);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-vimeo);
   --tblr-btn-hover-color: var(--tblr-vimeo-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-vimeo);
   --tblr-btn-active-color: var(--tblr-vimeo-fg);
   --tblr-btn-active-bg: var(--tblr-vimeo);
   --tblr-btn-disabled-color: var(--tblr-vimeo);
   --tblr-btn-disabled-border-color: var(--tblr-vimeo);
}

.btn-dribbble {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-dribbble-fg);
   --tblr-btn-bg: var(--tblr-dribbble);
   --tblr-btn-hover-color: var(--tblr-dribbble-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-dribbble-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-dribbble-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-dribbble-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-dribbble);
   --tblr-btn-disabled-color: var(--tblr-dribbble-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-dribbble {
   --tblr-btn-color: var(--tblr-dribbble);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-dribbble);
   --tblr-btn-hover-color: var(--tblr-dribbble-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-dribbble);
   --tblr-btn-active-color: var(--tblr-dribbble-fg);
   --tblr-btn-active-bg: var(--tblr-dribbble);
   --tblr-btn-disabled-color: var(--tblr-dribbble);
   --tblr-btn-disabled-border-color: var(--tblr-dribbble);
}

.btn-github {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-github-fg);
   --tblr-btn-bg: var(--tblr-github);
   --tblr-btn-hover-color: var(--tblr-github-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-github-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-github-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-github-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-github);
   --tblr-btn-disabled-color: var(--tblr-github-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-github {
   --tblr-btn-color: var(--tblr-github);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-github);
   --tblr-btn-hover-color: var(--tblr-github-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-github);
   --tblr-btn-active-color: var(--tblr-github-fg);
   --tblr-btn-active-bg: var(--tblr-github);
   --tblr-btn-disabled-color: var(--tblr-github);
   --tblr-btn-disabled-border-color: var(--tblr-github);
}

.btn-instagram {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-instagram-fg);
   --tblr-btn-bg: var(--tblr-instagram);
   --tblr-btn-hover-color: var(--tblr-instagram-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-instagram-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-instagram-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-instagram-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-instagram);
   --tblr-btn-disabled-color: var(--tblr-instagram-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-instagram {
   --tblr-btn-color: var(--tblr-instagram);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-instagram);
   --tblr-btn-hover-color: var(--tblr-instagram-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-instagram);
   --tblr-btn-active-color: var(--tblr-instagram-fg);
   --tblr-btn-active-bg: var(--tblr-instagram);
   --tblr-btn-disabled-color: var(--tblr-instagram);
   --tblr-btn-disabled-border-color: var(--tblr-instagram);
}

.btn-pinterest {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-pinterest-fg);
   --tblr-btn-bg: var(--tblr-pinterest);
   --tblr-btn-hover-color: var(--tblr-pinterest-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-pinterest-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-pinterest-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-pinterest-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-pinterest);
   --tblr-btn-disabled-color: var(--tblr-pinterest-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-pinterest {
   --tblr-btn-color: var(--tblr-pinterest);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-pinterest);
   --tblr-btn-hover-color: var(--tblr-pinterest-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-pinterest);
   --tblr-btn-active-color: var(--tblr-pinterest-fg);
   --tblr-btn-active-bg: var(--tblr-pinterest);
   --tblr-btn-disabled-color: var(--tblr-pinterest);
   --tblr-btn-disabled-border-color: var(--tblr-pinterest);
}

.btn-vk {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-vk-fg);
   --tblr-btn-bg: var(--tblr-vk);
   --tblr-btn-hover-color: var(--tblr-vk-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-vk-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-vk-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-vk-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-vk);
   --tblr-btn-disabled-color: var(--tblr-vk-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-vk {
   --tblr-btn-color: var(--tblr-vk);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-vk);
   --tblr-btn-hover-color: var(--tblr-vk-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-vk);
   --tblr-btn-active-color: var(--tblr-vk-fg);
   --tblr-btn-active-bg: var(--tblr-vk);
   --tblr-btn-disabled-color: var(--tblr-vk);
   --tblr-btn-disabled-border-color: var(--tblr-vk);
}

.btn-rss {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-rss-fg);
   --tblr-btn-bg: var(--tblr-rss);
   --tblr-btn-hover-color: var(--tblr-rss-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-rss-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-rss-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-rss-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-rss);
   --tblr-btn-disabled-color: var(--tblr-rss-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-rss {
   --tblr-btn-color: var(--tblr-rss);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-rss);
   --tblr-btn-hover-color: var(--tblr-rss-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-rss);
   --tblr-btn-active-color: var(--tblr-rss-fg);
   --tblr-btn-active-bg: var(--tblr-rss);
   --tblr-btn-disabled-color: var(--tblr-rss);
   --tblr-btn-disabled-border-color: var(--tblr-rss);
}

.btn-flickr {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-flickr-fg);
   --tblr-btn-bg: var(--tblr-flickr);
   --tblr-btn-hover-color: var(--tblr-flickr-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-flickr-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-flickr-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-flickr-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-flickr);
   --tblr-btn-disabled-color: var(--tblr-flickr-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-flickr {
   --tblr-btn-color: var(--tblr-flickr);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-flickr);
   --tblr-btn-hover-color: var(--tblr-flickr-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-flickr);
   --tblr-btn-active-color: var(--tblr-flickr-fg);
   --tblr-btn-active-bg: var(--tblr-flickr);
   --tblr-btn-disabled-color: var(--tblr-flickr);
   --tblr-btn-disabled-border-color: var(--tblr-flickr);
}

.btn-bitbucket {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-bitbucket-fg);
   --tblr-btn-bg: var(--tblr-bitbucket);
   --tblr-btn-hover-color: var(--tblr-bitbucket-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-bitbucket-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-bitbucket-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-bitbucket-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-bitbucket);
   --tblr-btn-disabled-color: var(--tblr-bitbucket-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-bitbucket {
   --tblr-btn-color: var(--tblr-bitbucket);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-bitbucket);
   --tblr-btn-hover-color: var(--tblr-bitbucket-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-bitbucket);
   --tblr-btn-active-color: var(--tblr-bitbucket-fg);
   --tblr-btn-active-bg: var(--tblr-bitbucket);
   --tblr-btn-disabled-color: var(--tblr-bitbucket);
   --tblr-btn-disabled-border-color: var(--tblr-bitbucket);
}

.btn-tabler {
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-color: var(--tblr-tabler-fg);
   --tblr-btn-bg: var(--tblr-tabler);
   --tblr-btn-hover-color: var(--tblr-tabler-fg);
   --tblr-btn-hover-bg: rgba(var(--tblr-tabler-rgb), 0.8);
   --tblr-btn-active-color: var(--tblr-tabler-fg);
   --tblr-btn-active-bg: rgba(var(--tblr-tabler-rgb), 0.8);
   --tblr-btn-disabled-bg: var(--tblr-tabler);
   --tblr-btn-disabled-color: var(--tblr-tabler-fg);
   --tblr-btn-box-shadow: var(--tblr-shadow-button),
      var(--tblr-shadow-button-inset);
}

.btn-outline-tabler {
   --tblr-btn-color: var(--tblr-tabler);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: var(--tblr-tabler);
   --tblr-btn-hover-color: var(--tblr-tabler-fg);
   --tblr-btn-hover-border-color: transparent;
   --tblr-btn-hover-bg: var(--tblr-tabler);
   --tblr-btn-active-color: var(--tblr-tabler-fg);
   --tblr-btn-active-bg: var(--tblr-tabler);
   --tblr-btn-disabled-color: var(--tblr-tabler);
   --tblr-btn-disabled-border-color: var(--tblr-tabler);
}

.btn-ghost-primary {
   --tblr-btn-color: var(--tblr-primary);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-primary-fg);
   --tblr-btn-hover-bg: var(--tblr-primary);
   --tblr-btn-hover-border-color: var(--tblr-primary);
   --tblr-btn-active-color: var(--tblr-primary-fg);
   --tblr-btn-active-bg: var(--tblr-primary);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-primary);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-secondary {
   --tblr-btn-color: var(--tblr-secondary);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-secondary-fg);
   --tblr-btn-hover-bg: var(--tblr-secondary);
   --tblr-btn-hover-border-color: var(--tblr-secondary);
   --tblr-btn-active-color: var(--tblr-secondary-fg);
   --tblr-btn-active-bg: var(--tblr-secondary);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-secondary);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-success {
   --tblr-btn-color: var(--tblr-success);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-success-fg);
   --tblr-btn-hover-bg: var(--tblr-success);
   --tblr-btn-hover-border-color: var(--tblr-success);
   --tblr-btn-active-color: var(--tblr-success-fg);
   --tblr-btn-active-bg: var(--tblr-success);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-success);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-info {
   --tblr-btn-color: var(--tblr-info);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-info-fg);
   --tblr-btn-hover-bg: var(--tblr-info);
   --tblr-btn-hover-border-color: var(--tblr-info);
   --tblr-btn-active-color: var(--tblr-info-fg);
   --tblr-btn-active-bg: var(--tblr-info);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-info);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-warning {
   --tblr-btn-color: var(--tblr-warning);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-warning-fg);
   --tblr-btn-hover-bg: var(--tblr-warning);
   --tblr-btn-hover-border-color: var(--tblr-warning);
   --tblr-btn-active-color: var(--tblr-warning-fg);
   --tblr-btn-active-bg: var(--tblr-warning);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-warning);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-danger {
   --tblr-btn-color: var(--tblr-danger);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-danger-fg);
   --tblr-btn-hover-bg: var(--tblr-danger);
   --tblr-btn-hover-border-color: var(--tblr-danger);
   --tblr-btn-active-color: var(--tblr-danger-fg);
   --tblr-btn-active-bg: var(--tblr-danger);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-danger);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-light {
   --tblr-btn-color: var(--tblr-light);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-light-fg);
   --tblr-btn-hover-bg: var(--tblr-light);
   --tblr-btn-hover-border-color: var(--tblr-light);
   --tblr-btn-active-color: var(--tblr-light-fg);
   --tblr-btn-active-bg: var(--tblr-light);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-light);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-dark {
   --tblr-btn-color: var(--tblr-dark);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-dark-fg);
   --tblr-btn-hover-bg: var(--tblr-dark);
   --tblr-btn-hover-border-color: var(--tblr-dark);
   --tblr-btn-active-color: var(--tblr-dark-fg);
   --tblr-btn-active-bg: var(--tblr-dark);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-dark);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-muted {
   --tblr-btn-color: var(--tblr-muted);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-muted-fg);
   --tblr-btn-hover-bg: var(--tblr-muted);
   --tblr-btn-hover-border-color: var(--tblr-muted);
   --tblr-btn-active-color: var(--tblr-muted-fg);
   --tblr-btn-active-bg: var(--tblr-muted);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-muted);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-blue {
   --tblr-btn-color: var(--tblr-blue);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-blue-fg);
   --tblr-btn-hover-bg: var(--tblr-blue);
   --tblr-btn-hover-border-color: var(--tblr-blue);
   --tblr-btn-active-color: var(--tblr-blue-fg);
   --tblr-btn-active-bg: var(--tblr-blue);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-blue);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-azure {
   --tblr-btn-color: var(--tblr-azure);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-azure-fg);
   --tblr-btn-hover-bg: var(--tblr-azure);
   --tblr-btn-hover-border-color: var(--tblr-azure);
   --tblr-btn-active-color: var(--tblr-azure-fg);
   --tblr-btn-active-bg: var(--tblr-azure);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-azure);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-indigo {
   --tblr-btn-color: var(--tblr-indigo);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-indigo-fg);
   --tblr-btn-hover-bg: var(--tblr-indigo);
   --tblr-btn-hover-border-color: var(--tblr-indigo);
   --tblr-btn-active-color: var(--tblr-indigo-fg);
   --tblr-btn-active-bg: var(--tblr-indigo);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-indigo);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-purple {
   --tblr-btn-color: var(--tblr-purple);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-purple-fg);
   --tblr-btn-hover-bg: var(--tblr-purple);
   --tblr-btn-hover-border-color: var(--tblr-purple);
   --tblr-btn-active-color: var(--tblr-purple-fg);
   --tblr-btn-active-bg: var(--tblr-purple);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-purple);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-pink {
   --tblr-btn-color: var(--tblr-pink);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-pink-fg);
   --tblr-btn-hover-bg: var(--tblr-pink);
   --tblr-btn-hover-border-color: var(--tblr-pink);
   --tblr-btn-active-color: var(--tblr-pink-fg);
   --tblr-btn-active-bg: var(--tblr-pink);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-pink);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-red {
   --tblr-btn-color: var(--tblr-red);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-red-fg);
   --tblr-btn-hover-bg: var(--tblr-red);
   --tblr-btn-hover-border-color: var(--tblr-red);
   --tblr-btn-active-color: var(--tblr-red-fg);
   --tblr-btn-active-bg: var(--tblr-red);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-red);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-orange {
   --tblr-btn-color: var(--tblr-orange);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-orange-fg);
   --tblr-btn-hover-bg: var(--tblr-orange);
   --tblr-btn-hover-border-color: var(--tblr-orange);
   --tblr-btn-active-color: var(--tblr-orange-fg);
   --tblr-btn-active-bg: var(--tblr-orange);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-orange);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-yellow {
   --tblr-btn-color: var(--tblr-yellow);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-yellow-fg);
   --tblr-btn-hover-bg: var(--tblr-yellow);
   --tblr-btn-hover-border-color: var(--tblr-yellow);
   --tblr-btn-active-color: var(--tblr-yellow-fg);
   --tblr-btn-active-bg: var(--tblr-yellow);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-yellow);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-lime {
   --tblr-btn-color: var(--tblr-lime);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-lime-fg);
   --tblr-btn-hover-bg: var(--tblr-lime);
   --tblr-btn-hover-border-color: var(--tblr-lime);
   --tblr-btn-active-color: var(--tblr-lime-fg);
   --tblr-btn-active-bg: var(--tblr-lime);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-lime);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-green {
   --tblr-btn-color: var(--tblr-green);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-green-fg);
   --tblr-btn-hover-bg: var(--tblr-green);
   --tblr-btn-hover-border-color: var(--tblr-green);
   --tblr-btn-active-color: var(--tblr-green-fg);
   --tblr-btn-active-bg: var(--tblr-green);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-green);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-teal {
   --tblr-btn-color: var(--tblr-teal);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-teal-fg);
   --tblr-btn-hover-bg: var(--tblr-teal);
   --tblr-btn-hover-border-color: var(--tblr-teal);
   --tblr-btn-active-color: var(--tblr-teal-fg);
   --tblr-btn-active-bg: var(--tblr-teal);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-teal);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-cyan {
   --tblr-btn-color: var(--tblr-cyan);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-cyan-fg);
   --tblr-btn-hover-bg: var(--tblr-cyan);
   --tblr-btn-hover-border-color: var(--tblr-cyan);
   --tblr-btn-active-color: var(--tblr-cyan-fg);
   --tblr-btn-active-bg: var(--tblr-cyan);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-cyan);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-facebook {
   --tblr-btn-color: var(--tblr-facebook);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-facebook-fg);
   --tblr-btn-hover-bg: var(--tblr-facebook);
   --tblr-btn-hover-border-color: var(--tblr-facebook);
   --tblr-btn-active-color: var(--tblr-facebook-fg);
   --tblr-btn-active-bg: var(--tblr-facebook);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-facebook);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-twitter {
   --tblr-btn-color: var(--tblr-twitter);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-twitter-fg);
   --tblr-btn-hover-bg: var(--tblr-twitter);
   --tblr-btn-hover-border-color: var(--tblr-twitter);
   --tblr-btn-active-color: var(--tblr-twitter-fg);
   --tblr-btn-active-bg: var(--tblr-twitter);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-twitter);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-linkedin {
   --tblr-btn-color: var(--tblr-linkedin);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-linkedin-fg);
   --tblr-btn-hover-bg: var(--tblr-linkedin);
   --tblr-btn-hover-border-color: var(--tblr-linkedin);
   --tblr-btn-active-color: var(--tblr-linkedin-fg);
   --tblr-btn-active-bg: var(--tblr-linkedin);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-linkedin);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-google {
   --tblr-btn-color: var(--tblr-google);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-google-fg);
   --tblr-btn-hover-bg: var(--tblr-google);
   --tblr-btn-hover-border-color: var(--tblr-google);
   --tblr-btn-active-color: var(--tblr-google-fg);
   --tblr-btn-active-bg: var(--tblr-google);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-google);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-youtube {
   --tblr-btn-color: var(--tblr-youtube);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-youtube-fg);
   --tblr-btn-hover-bg: var(--tblr-youtube);
   --tblr-btn-hover-border-color: var(--tblr-youtube);
   --tblr-btn-active-color: var(--tblr-youtube-fg);
   --tblr-btn-active-bg: var(--tblr-youtube);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-youtube);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-vimeo {
   --tblr-btn-color: var(--tblr-vimeo);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-vimeo-fg);
   --tblr-btn-hover-bg: var(--tblr-vimeo);
   --tblr-btn-hover-border-color: var(--tblr-vimeo);
   --tblr-btn-active-color: var(--tblr-vimeo-fg);
   --tblr-btn-active-bg: var(--tblr-vimeo);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-vimeo);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-dribbble {
   --tblr-btn-color: var(--tblr-dribbble);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-dribbble-fg);
   --tblr-btn-hover-bg: var(--tblr-dribbble);
   --tblr-btn-hover-border-color: var(--tblr-dribbble);
   --tblr-btn-active-color: var(--tblr-dribbble-fg);
   --tblr-btn-active-bg: var(--tblr-dribbble);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-dribbble);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-github {
   --tblr-btn-color: var(--tblr-github);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-github-fg);
   --tblr-btn-hover-bg: var(--tblr-github);
   --tblr-btn-hover-border-color: var(--tblr-github);
   --tblr-btn-active-color: var(--tblr-github-fg);
   --tblr-btn-active-bg: var(--tblr-github);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-github);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-instagram {
   --tblr-btn-color: var(--tblr-instagram);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-instagram-fg);
   --tblr-btn-hover-bg: var(--tblr-instagram);
   --tblr-btn-hover-border-color: var(--tblr-instagram);
   --tblr-btn-active-color: var(--tblr-instagram-fg);
   --tblr-btn-active-bg: var(--tblr-instagram);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-instagram);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-pinterest {
   --tblr-btn-color: var(--tblr-pinterest);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-pinterest-fg);
   --tblr-btn-hover-bg: var(--tblr-pinterest);
   --tblr-btn-hover-border-color: var(--tblr-pinterest);
   --tblr-btn-active-color: var(--tblr-pinterest-fg);
   --tblr-btn-active-bg: var(--tblr-pinterest);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-pinterest);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-vk {
   --tblr-btn-color: var(--tblr-vk);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-vk-fg);
   --tblr-btn-hover-bg: var(--tblr-vk);
   --tblr-btn-hover-border-color: var(--tblr-vk);
   --tblr-btn-active-color: var(--tblr-vk-fg);
   --tblr-btn-active-bg: var(--tblr-vk);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-vk);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-rss {
   --tblr-btn-color: var(--tblr-rss);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-rss-fg);
   --tblr-btn-hover-bg: var(--tblr-rss);
   --tblr-btn-hover-border-color: var(--tblr-rss);
   --tblr-btn-active-color: var(--tblr-rss-fg);
   --tblr-btn-active-bg: var(--tblr-rss);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-rss);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-flickr {
   --tblr-btn-color: var(--tblr-flickr);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-flickr-fg);
   --tblr-btn-hover-bg: var(--tblr-flickr);
   --tblr-btn-hover-border-color: var(--tblr-flickr);
   --tblr-btn-active-color: var(--tblr-flickr-fg);
   --tblr-btn-active-bg: var(--tblr-flickr);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-flickr);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-bitbucket {
   --tblr-btn-color: var(--tblr-bitbucket);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-bitbucket-fg);
   --tblr-btn-hover-bg: var(--tblr-bitbucket);
   --tblr-btn-hover-border-color: var(--tblr-bitbucket);
   --tblr-btn-active-color: var(--tblr-bitbucket-fg);
   --tblr-btn-active-bg: var(--tblr-bitbucket);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-bitbucket);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-ghost-tabler {
   --tblr-btn-color: var(--tblr-tabler);
   --tblr-btn-bg: transparent;
   --tblr-btn-border-color: transparent;
   --tblr-btn-hover-color: var(--tblr-tabler-fg);
   --tblr-btn-hover-bg: var(--tblr-tabler);
   --tblr-btn-hover-border-color: var(--tblr-tabler);
   --tblr-btn-active-color: var(--tblr-tabler-fg);
   --tblr-btn-active-bg: var(--tblr-tabler);
   --tblr-btn-active-border-color: transparent;
   --tblr-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --tblr-btn-disabled-color: var(--tblr-tabler);
   --tblr-btn-disabled-bg: transparent;
   --tblr-btn-disabled-border-color: transparent;
   --tblr-gradient: none;
   --tblr-btn-box-shadow: none;
}

.btn-sm,
.btn-group-sm > .btn {
   --tblr-btn-line-height: 1.5;
   --tblr-btn-icon-size: 0.75rem;
}

.btn-lg,
.btn-group-lg > .btn {
   --tblr-btn-line-height: 1.5;
   --tblr-btn-icon-size: 2rem;
}

.btn-pill {
   padding-right: 1.5em;
   padding-left: 1.5em;
   border-radius: 10rem;
}
.btn-pill[class*="btn-icon"] {
   padding: 0.375rem 15px;
}

.btn-square {
   border-radius: 0;
}

.btn-icon {
   min-width: calc(
      var(--tblr-btn-line-height) * var(--tblr-btn-font-size) +
         var(--tblr-btn-padding-y) * 2 + var(--tblr-btn-border-width) * 2
   );
   min-height: calc(
      var(--tblr-btn-line-height) * var(--tblr-btn-font-size) +
         var(--tblr-btn-padding-y) * 2 + var(--tblr-btn-border-width) * 2
   );
   padding-left: 0;
   padding-right: 0;
}
.btn-icon .icon {
   margin: calc(-1 * var(--tblr-btn-padding-x));
}

.btn-list {
   --tblr-list-gap: 0.5rem;
   display: flex;
   flex-wrap: wrap;
   gap: var(--tblr-list-gap);
}

.btn-floating {
   position: fixed;
   z-index: 1030;
   bottom: 1.5rem;
   right: 1.5rem;
   border-radius: 100rem;
}

.btn-loading {
   position: relative;
   color: transparent !important;
   text-shadow: none !important;
   pointer-events: none;
}
.btn-loading > * {
   opacity: 0;
}
.btn-loading:after {
   content: "";
   display: inline-block;
   vertical-align: text-bottom;
   border: 2px var(--tblr-border-style) currentColor;
   border-right-color: transparent;
   border-radius: 100rem;
   color: var(--tblr-btn-color);
   position: absolute;
   width: var(--tblr-btn-icon-size);
   height: var(--tblr-btn-icon-size);
   left: calc(50% - var(--tblr-btn-icon-size) / 2);
   top: calc(50% - var(--tblr-btn-icon-size) / 2);
   animation: spinner-border 0.75s linear infinite;
}

.btn-action {
   padding: 0;
   border: 0;
   color: var(--tblr-muted);
   display: inline-flex;
   width: 2rem;
   height: 2rem;
   align-items: center;
   justify-content: center;
   border-radius: var(--tblr-border-radius);
   background: transparent;
}
.btn-action:after {
   content: none;
}
.btn-action:focus {
   outline: none;
   box-shadow: none;
}
.btn-action:hover,
.btn-action.show {
   color: var(--tblr-body-color);
   background: var(--tblr-active-bg);
}
.btn-action.show {
   color: var(--tblr-primary);
}
.btn-action .icon {
   margin: 0;
   width: 1.25rem;
   height: 1.25rem;
   font-size: 1.25rem;
   stroke-width: 1;
}

.btn-actions {
   display: flex;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
   z-index: 5;
}
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus {
   z-index: 1;
}

.calendar {
   display: block;
   font-size: 0.765625rem;
   border: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
   border-radius: var(--tblr-border-radius);
}

.calendar-nav {
   display: flex;
   align-items: center;
}

.calendar-title {
   flex: 1;
   text-align: center;
}

.calendar-body,
.calendar-header {
   display: flex;
   flex-wrap: wrap;
   justify-content: flex-start;
   padding: 0.5rem 0;
}

.calendar-header {
   color: var(--tblr-muted);
}

.calendar-date {
   flex: 0 0 14.2857142857%;
   max-width: 14.2857142857%;
   padding: 0.2rem;
   text-align: center;
   border: 0;
}
.calendar-date.prev-month,
.calendar-date.next-month {
   opacity: 0.25;
}
.calendar-date .date-item {
   position: relative;
   display: inline-block;
   width: 1.4rem;
   height: 1.4rem;
   line-height: 1.4rem;
   color: #66758c;
   text-align: center;
   text-decoration: none;
   white-space: nowrap;
   vertical-align: middle;
   cursor: pointer;
   background: 0 0;
   border: var(--tblr-border-width) var(--tblr-border-style) transparent;
   border-radius: 100rem;
   outline: 0;
   transition: background 0.3s, border 0.3s, box-shadow 0.32s, color 0.3s;
}
@media (prefers-reduced-motion: reduce) {
   .calendar-date .date-item {
      transition: none;
   }
}
.calendar-date .date-item:hover {
   color: var(--tblr-primary);
   text-decoration: none;
   background: #fefeff;
   border-color: var(--tblr-border-color);
}
.calendar-date .date-today {
   color: var(--tblr-primary);
   border-color: var(--tblr-border-color);
}

.calendar-range {
   position: relative;
}
.calendar-range:before {
   position: absolute;
   top: 50%;
   right: 0;
   left: 0;
   height: 1.4rem;
   content: "";
   background: rgba(var(--tblr-primary-rgb), 0.1);
   transform: translateY(-50%);
}
.calendar-range.range-start .date-item,
.calendar-range.range-end .date-item {
   color: #ffffff;
   background: var(--tblr-primary);
   border-color: var(--tblr-primary);
}
.calendar-range.range-start:before {
   left: 50%;
}
.calendar-range.range-end:before {
   right: 50%;
}

.carousel-indicators-vertical {
   left: auto;
   top: 0;
   margin: 0 1rem 0 0;
   flex-direction: column;
}
.carousel-indicators-vertical [data-bs-target] {
   margin: 3px 0 3px;
   width: 3px;
   height: 30px;
   border: 0;
   border-left: 10px var(--tblr-border-style) transparent;
   border-right: 10px var(--tblr-border-style) transparent;
}

.carousel-indicators-dot [data-bs-target] {
   width: 0.5rem;
   height: 0.5rem;
   border-radius: 100rem;
   border: 10px var(--tblr-border-style) transparent;
   margin: 0;
}

.carousel-indicators-thumb [data-bs-target] {
   width: 2rem;
   height: auto;
   background: no-repeat center/cover;
   border: 0;
   border-radius: var(--tblr-border-radius);
   box-shadow: rgba(var(--tblr-body-color-rgb), 0.04) 0 2px 4px 0;
   margin: 0 3px;
   opacity: 0.75;
}
@media (min-width: 992px) {
   .carousel-indicators-thumb [data-bs-target] {
      width: 4rem;
   }
}
.carousel-indicators-thumb [data-bs-target]:before {
   content: "";
   padding-top: var(--tblr-aspect-ratio, 100%);
   display: block;
}
.carousel-indicators-thumb.carousel-indicators-vertical [data-bs-target] {
   margin: 3px 0;
}

.carousel-caption-background {
   background: red;
   position: absolute;
   left: 0;
   right: 0;
   bottom: 0;
   height: 90%;
   background: linear-gradient(
      0deg,
      rgba(29, 39, 59, 0.9),
      rgba(29, 39, 59, 0)
   );
}

.card {
   transition: transform 0.3s ease-out, opacity 0.3s ease-out,
      box-shadow 0.3s ease-out;
}
@media (prefers-reduced-motion: reduce) {
   .card {
      transition: none;
   }
}
@media print {
   .card {
      border: none;
      box-shadow: none;
   }
}
a.card {
   color: inherit;
}
a.card:hover {
   text-decoration: none;
   box-shadow: rgba(var(--tblr-body-color-rgb), 0.16) 0 2px 16px 0;
}

.card .card {
   box-shadow: none;
}

.card-borderless,
.card-borderless .card-header,
.card-borderless .card-footer {
   border-color: transparent;
}

.card-stamp {
   --tblr-stamp-size: 7rem;
   position: absolute;
   top: 0;
   right: 0;
   width: calc(var(--tblr-stamp-size) * 1);
   height: calc(var(--tblr-stamp-size) * 1);
   max-height: 100%;
   border-top-right-radius: 4px;
   opacity: 0.2;
   overflow: hidden;
   pointer-events: none;
}

.card-stamp-lg {
   --tblr-stamp-size: 13rem;
}

.card-stamp-icon {
   background: var(--tblr-muted);
   color: var(--tblr-card-bg, var(--tblr-bg-surface));
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 100rem;
   width: calc(var(--tblr-stamp-size) * 1);
   height: calc(var(--tblr-stamp-size) * 1);
   position: relative;
   top: calc(var(--tblr-stamp-size) * -0.25);
   right: calc(var(--tblr-stamp-size) * -0.25);
   font-size: calc(var(--tblr-stamp-size) * 0.75);
   transform: rotate(10deg);
}
.card-stamp-icon .icon {
   stroke-width: 2;
   width: calc(var(--tblr-stamp-size) * 0.75);
   height: calc(var(--tblr-stamp-size) * 0.75);
}

.card-img,
.card-img-start {
   border-top-left-radius: calc(
      var(--tblr-border-radius) - (var(--tblr-border-width))
   );
   border-bottom-left-radius: calc(
      var(--tblr-border-radius) - (var(--tblr-border-width))
   );
}

.card-img,
.card-img-end {
   border-top-right-radius: calc(
      var(--tblr-border-radius) - (var(--tblr-border-width))
   );
   border-bottom-right-radius: calc(
      var(--tblr-border-radius) - (var(--tblr-border-width))
   );
}

.card-img-overlay {
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
}

.card-img-overlay-dark {
   background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 100%
   );
}

.card-inactive {
   pointer-events: none;
   box-shadow: none;
}
.card-inactive .card-body {
   opacity: 0.64;
}

.card-active {
   --tblr-card-border-color: var(--tblr-primary);
   --tblr-card-bg: var(--tblr-active-bg);
}

.card-btn {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 1rem 1.5rem;
   text-align: center;
   transition: background 0.3s;
   border-top: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
   flex: 1;
   color: inherit;
   font-weight: var(--tblr-font-weight-medium);
}
@media (prefers-reduced-motion: reduce) {
   .card-btn {
      transition: none;
   }
}
.card-btn:hover {
   text-decoration: none;
   background: rgba(var(--tblr-primary-rgb), 0.04);
}
.card-btn + .card-btn {
   border-left: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
}

/**
Stacked card
 */
.card-stacked {
   --tblr-card-stacked-offset: 0.25rem;
   position: relative;
}
.card-stacked:after {
   position: absolute;
   top: calc(-1 * var(--tblr-card-stacked-offset));
   right: var(--tblr-card-stacked-offset);
   left: var(--tblr-card-stacked-offset);
   height: var(--tblr-card-stacked-offset);
   content: "";
   background: var(--tblr-card-bg, var(--tblr-bg-surface));
   border: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-card-border-color);
   border-radius: var(--tblr-card-border-radius) var(--tblr-card-border-radius)
      0 0;
}

.card-cover {
   position: relative;
   padding: 1rem 1.5rem;
   background: #666666 no-repeat center/cover;
}
.card-cover:before {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   content: "";
   background: rgba(29, 39, 59, 0.48);
}
.card-cover:first-child,
.card-cover:first-child:before {
   border-radius: 4px 4px 0 0;
}

.card-cover-blurred:before {
   -webkit-backdrop-filter: blur(2px);
   backdrop-filter: blur(2px);
}

.card-actions {
   margin: -0.5rem -0.5rem -0.5rem auto;
   padding-left: 0.5rem;
}
.card-actions a {
   text-decoration: none;
}

.card-header {
   color: inherit;
   display: flex;
   align-items: center;
   background: transparent;
}
.card-header:first-child {
   border-radius: var(--tblr-card-border-radius) var(--tblr-card-border-radius)
      0 0;
}

.card-header-light {
   border-bottom-color: transparent;
   background: var(--tblr-bg-surface-tertiary);
}

.card-header-tabs {
   background: var(--tblr-bg-surface-tertiary);
   flex: 1;
   margin: calc(var(--tblr-card-cap-padding-y) * -1)
      calc(var(--tblr-card-cap-padding-x) * -1)
      calc(var(--tblr-card-cap-padding-y) * -1);
   padding: calc(var(--tblr-card-cap-padding-y) * 0.5)
      calc(var(--tblr-card-cap-padding-x) * 0.5) 0;
}

.card-header-pills {
   flex: 1;
   margin-top: -0.5rem;
   margin-bottom: -0.5rem;
}

.card-rotate-left {
   transform: rotate(-1.5deg);
}

.card-rotate-right {
   transform: rotate(1.5deg);
}

.card-link {
   color: inherit;
}
.card-link:hover {
   color: inherit;
   text-decoration: none;
   box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
}

.card-link-rotate:hover {
   transform: rotate(1.5deg);
   opacity: 1;
}

.card-link-pop:hover {
   transform: translateY(-2px);
   opacity: 1;
}

.card-footer {
   margin-top: auto;
}
.card-footer:last-child {
   border-radius: 0 0 var(--tblr-card-border-radius)
      var(--tblr-card-border-radius);
}

.card-footer-transparent {
   background: transparent;
   border-color: transparent;
   padding-top: 0;
}

.card-footer-borderless {
   border-top: none;
}

.card-progress {
   height: 0.25rem;
}
.card-progress:last-child {
   border-radius: 0 0 2px 2px;
}
.card-progress:first-child {
   border-radius: 2px 2px 0 0;
}

.card-meta {
   color: var(--tblr-muted);
}

.card-title {
   display: block;
   margin: 0 0 1rem;
   font-size: 1rem;
   font-weight: var(--tblr-font-weight-medium);
   line-height: 1.5rem;
}
a.card-title:hover {
   color: inherit;
}

.card-header .card-title {
   margin: 0;
}

.card-subtitle {
   margin-bottom: 1.25rem;
   color: var(--tblr-muted);
   font-weight: normal;
}
.card-header .card-subtitle {
   margin: 0;
}
.card-title .card-subtitle {
   margin: 0 0 0 0.25rem;
   font-size: 0.875rem;
}

.card-body {
   position: relative;
}
.card-body > :last-child {
   margin-bottom: 0;
}
.card-sm > .card-body {
   padding: 1rem;
}
@media (min-width: 768px) {
   .card-md > .card-body {
      padding: 2.5rem;
   }
}
@media (min-width: 768px) {
   .card-lg > .card-body {
      padding: 2rem;
   }
}
@media (min-width: 992px) {
   .card-lg > .card-body {
      padding: 4rem;
   }
}
@media print {
   .card-body {
      padding: 0;
   }
}
.card-body + .card-body {
   border-top: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
}

.card-body-scrollable {
   overflow: auto;
}

/**
Card optinos
 */
.card-options {
   top: 1.5rem;
   right: 0.75rem;
   display: flex;
   margin-left: auto;
}

.card-options-link {
   display: inline-block;
   min-width: 1rem;
   margin-left: 0.25rem;
   color: var(--tblr-muted);
}

/**
Card status
 */
.card-status-top {
   position: absolute;
   top: 0;
   right: 0;
   left: 0;
   height: 2px;
   border-radius: var(--tblr-card-border-radius) var(--tblr-card-border-radius)
      0 0;
}

.card-status-start {
   position: absolute;
   right: auto;
   bottom: 0;
   width: 2px;
   height: 100%;
   border-radius: var(--tblr-card-border-radius) 0 0
      var(--tblr-card-border-radius);
}

.card-status-bottom {
   position: absolute;
   top: initial;
   bottom: 0;
   width: 100%;
   height: 2px;
   border-radius: 0 0 var(--tblr-card-border-radius)
      var(--tblr-card-border-radius);
}

/**
Card table
 */
.card-table {
   margin-bottom: 0 !important;
}
.card-table tr td:first-child,
.card-table tr th:first-child {
   padding-left: 1.5rem;
   border-left: 0;
}
.card-table tr td:last-child,
.card-table tr th:last-child {
   padding-right: 1.5rem;
   border-right: 0;
}
.card-table thead tr:first-child,
.card-table tbody tr:first-child,
.card-table tfoot tr:first-child {
   border-top: 0;
}
.card-table thead tr:first-child td,
.card-table thead tr:first-child th,
.card-table tbody tr:first-child td,
.card-table tbody tr:first-child th,
.card-table tfoot tr:first-child td,
.card-table tfoot tr:first-child th {
   border-top: 0;
}
.card-body + .card-table {
   border-top: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-table-border-color);
}

/*
Card code
 */
.card-code {
   padding: 0;
}
.card-code .highlight {
   margin: 0;
   border: 0;
}
.card-code pre {
   margin: 0 !important;
   border: 0 !important;
}

/*
Card chart
 */
.card-chart {
   position: relative;
   z-index: 1;
   height: 3.5rem;
}

/**
Card avatar
 */
.card-avatar {
   margin-left: auto;
   margin-right: auto;
   box-shadow: 0 0 0 0.25rem var(--tblr-card-bg, var(--tblr-bg-surface));
   margin-top: calc(-1 * var(--tblr-avatar-size) * 0.5);
}

/*
Card list group
 */
.card-body + .card-list-group {
   border-top: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
}
.card-list-group .list-group-item {
   padding-right: 1.5rem;
   padding-left: 1.5rem;
   border-right: 0;
   border-left: 0;
   border-radius: 0;
}
.card-list-group .list-group-item:last-child {
   border-bottom: 0;
}
.card-list-group .list-group-item:first-child {
   border-top: 0;
}

.card-tabs .nav-tabs {
   position: relative;
   z-index: 1000;
   border-bottom: 0;
}
.card-tabs .nav-tabs .nav-link {
   background: var(--tblr-bg-surface-tertiary);
   border: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
}
.card-tabs .nav-tabs .nav-link.active,
.card-tabs .nav-tabs .nav-link:active,
.card-tabs .nav-tabs .nav-link:hover {
   border-color: var(--tblr-border-color);
   color: var(--tblr-body-color);
}
.card-tabs .nav-tabs .nav-link.active {
   background: var(--tblr-card-bg, var(--tblr-bg-surface));
   border-bottom-color: transparent;
}
.card-tabs .nav-tabs .nav-item:not(:first-child) .nav-link {
   border-top-left-radius: 0;
}
.card-tabs .nav-tabs .nav-item:not(:last-child) .nav-link {
   border-top-right-radius: 0;
}
.card-tabs .nav-tabs .nav-item + .nav-item {
   margin-left: calc(-1 * var(--tblr-border-width));
}
.card-tabs .nav-tabs-bottom {
   margin-bottom: 0;
}
.card-tabs .nav-tabs-bottom .nav-link {
   margin-bottom: 0;
}
.card-tabs .nav-tabs-bottom .nav-link.active {
   border-top-color: transparent;
}
.card-tabs .nav-tabs-bottom .nav-item {
   margin-top: calc(-1 * var(--tblr-border-width));
   margin-bottom: 0;
}
.card-tabs .nav-tabs-bottom .nav-item .nav-link {
   border-bottom: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
   border-radius: 0 0 var(--tblr-border-radius) var(--tblr-border-radius);
}
.card-tabs .nav-tabs-bottom .nav-item:not(:first-child) .nav-link {
   border-bottom-left-radius: 0;
}
.card-tabs .nav-tabs-bottom .nav-item:not(:last-child) .nav-link {
   border-bottom-right-radius: 0;
}
.card-tabs .card {
   border-bottom-left-radius: 0;
}
.card-tabs .nav-tabs + .tab-content .card {
   border-bottom-left-radius: var(--tblr-card-border-radius);
   border-top-left-radius: 0;
}

.btn-close {
   cursor: pointer;
}
.btn-close:focus {
   outline: none;
}

.dropdown-menu {
   box-shadow: 0 0.5rem 1rem rgba(var(--tblr-body-color-rgb), 0.15);
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   margin: 0 !important;
}
.dropdown-menu.card {
   padding: 0;
   min-width: 25rem;
   display: none;
}
.dropdown-menu.card.show {
   display: flex;
}

.dropdown-item {
   min-width: 11rem;
   display: flex;
   align-items: center;
   margin: 0;
   line-height: 1.4285714286;
}

.dropdown-item-icon {
   width: 1.25rem !important;
   height: 1.25rem !important;
   margin-right: 0.5rem;
   color: var(--tblr-muted);
   opacity: 0.7;
   text-align: center;
}

.dropdown-item-indicator {
   margin-right: 0.5rem;
   margin-left: -0.25rem;
   height: 1.25rem;
   display: inline-flex;
   line-height: 1;
   vertical-align: bottom;
   align-items: center;
}

.dropdown-header {
   font-size: 0.625rem;
   font-weight: var(--tblr-font-weight-bold);
   text-transform: uppercase;
   letter-spacing: 0.04em;
   line-height: 1rem;
   color: var(--tblr-muted);
   padding-bottom: 0.25rem;
   pointer-events: none;
}

.dropdown-menu-scrollable {
   height: auto;
   max-height: 13rem;
   overflow-x: hidden;
}

.dropdown-menu-column {
   min-width: 11rem;
}
.dropdown-menu-column .dropdown-item {
   min-width: 0;
}

.dropdown-menu-columns {
   display: flex;
   flex: 0 0.25rem;
}

.dropdown-menu-arrow:before {
   content: "";
   position: absolute;
   top: -0.25rem;
   left: 0.75rem;
   display: block;
   background: inherit;
   width: 14px;
   height: 14px;
   transform: rotate(45deg);
   transform-origin: center;
   border: 1px solid;
   border-color: inherit;
   z-index: -1;
   clip: rect(0px, 9px, 9px, 0px);
}
.dropdown-menu-arrow.dropdown-menu-end:before {
   right: 0.75rem;
   left: auto;
}

.dropend > .dropdown-menu {
   margin-top: calc(-0.25rem - 1px);
   margin-left: -0.25rem;
}
.dropend .dropdown-toggle:after {
   margin-left: auto;
}

.dropdown-menu-card {
   padding: 0;
}
.dropdown-menu-card > .card {
   margin: 0;
   border: 0;
   box-shadow: none;
}

.datagrid {
   --tblr-datagrid-padding: 1.5rem;
   --tblr-datagrid-item-width: 15rem;
   display: grid;
   grid-gap: var(--tblr-datagrid-padding);
   grid-template-columns: repeat(
      auto-fit,
      minmax(var(--tblr-datagrid-item-width), 1fr)
   );
}

.datagrid-title {
   font-size: 0.625rem;
   font-weight: var(--tblr-font-weight-bold);
   text-transform: uppercase;
   letter-spacing: 0.04em;
   line-height: 1rem;
   color: var(--tblr-muted);
   margin-bottom: 0.25rem;
}

.empty {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 100%;
   padding: 1rem;
   text-align: center;
}
@media (min-width: 768px) {
   .empty {
      padding: 3rem;
   }
}

.empty-icon {
   margin: 0 0 1rem;
   width: 3rem;
   height: 3rem;
   line-height: 1;
   color: var(--tblr-muted);
}
.empty-icon svg {
   width: 100%;
   height: 100%;
}

.empty-img {
   margin: 0 0 2rem;
   line-height: 1;
}
.empty-img img {
   height: 8rem;
   width: auto;
}

.empty-header {
   margin: 0 0 1rem;
   font-size: 4rem;
   font-weight: var(--tblr-font-weight-light);
   line-height: 1;
   color: var(--tblr-muted);
}

.empty-title {
   font-size: 1.25rem;
   line-height: 1.75rem;
   font-weight: var(--tblr-font-weight-bold);
}

.empty-title,
.empty-subtitle {
   margin: 0 0 0.5rem;
}

.empty-action {
   margin-top: 1.5rem;
}

.empty-bordered {
   border: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
   border-radius: var(--tblr-border-radius);
}

.row > * {
   min-width: 0;
}

.col-separator {
   border-left: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
}

@media (max-width: 991.98px) {
   .container,
   .container-fluid,
   .container-sm,
   .container-md,
   .container-lg,
   .container-xl,
   .container-xxl {
      --tblr-gutter-x: var(--tblr-content-padding);
   }
}

.container-slim {
   --tblr-gutter-x: var(--tblr-content-padding);
   --tblr-gutter-y: 0;
   width: 100%;
   padding-right: calc(var(--tblr-gutter-x) * 0.5);
   padding-left: calc(var(--tblr-gutter-x) * 0.5);
   margin-right: auto;
   margin-left: auto;
   max-width: 16rem;
}
.container-tight {
   --tblr-gutter-x: var(--tblr-content-padding);
   --tblr-gutter-y: 0;
   width: 100%;
   padding-right: calc(var(--tblr-gutter-x) * 0.5);
   padding-left: calc(var(--tblr-gutter-x) * 0.5);
   margin-right: auto;
   margin-left: auto;
   max-width: 30rem;
}
.container-narrow {
   --tblr-gutter-x: var(--tblr-content-padding);
   --tblr-gutter-y: 0;
   width: 100%;
   padding-right: calc(var(--tblr-gutter-x) * 0.5);
   padding-left: calc(var(--tblr-gutter-x) * 0.5);
   margin-right: auto;
   margin-left: auto;
   max-width: 45rem;
}

.row-0 {
   margin-right: 0;
   margin-left: 0;
}
.row-0 > .col,
.row-0 > [class*="col-"] {
   padding-right: 0;
   padding-left: 0;
}
.row-0 .card {
   margin-bottom: 0;
}

.row-sm {
   margin-right: -0.375rem;
   margin-left: -0.375rem;
}
.row-sm > .col,
.row-sm > [class*="col-"] {
   padding-right: 0.375rem;
   padding-left: 0.375rem;
}
.row-sm .card {
   margin-bottom: 0.75rem;
}

.row-md {
   margin-right: -1.5rem;
   margin-left: -1.5rem;
}
.row-md > .col,
.row-md > [class*="col-"] {
   padding-right: 1.5rem;
   padding-left: 1.5rem;
}
.row-md .card {
   margin-bottom: 3rem;
}

.row-lg {
   margin-right: -3rem;
   margin-left: -3rem;
}
.row-lg > .col,
.row-lg > [class*="col-"] {
   padding-right: 3rem;
   padding-left: 3rem;
}
.row-lg .card {
   margin-bottom: 6rem;
}

.row-deck > .col,
.row-deck > [class*="col-"] {
   display: flex;
   align-items: stretch;
}
.row-deck > .col .card,
.row-deck > [class*="col-"] .card {
   flex: 1 1 auto;
}

.row-cards {
   --tblr-gutter-x: 1rem;
   --tblr-gutter-y: 1rem;
   min-width: 0;
}
.row-cards .row-cards {
   flex: 1;
}
@media (max-width: 991.98px) {
   .row-cards {
      --tblr-gutter-x: 0.5rem;
      --tblr-gutter-y: 0.5rem;
   }
}

.space-y {
   display: flex;
   flex-direction: column;
   gap: 1rem;
}

.space-x {
   display: flex;
   gap: 1rem;
}

.space-y-0 {
   display: flex;
   flex-direction: column;
   gap: 0;
}

.space-x-0 {
   display: flex;
   gap: 0;
}

.space-y-1 {
   display: flex;
   flex-direction: column;
   gap: 0.25rem;
}

.space-x-1 {
   display: flex;
   gap: 0.25rem;
}

.space-y-2 {
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
}

.space-x-2 {
   display: flex;
   gap: 0.5rem;
}

.space-y-3 {
   display: flex;
   flex-direction: column;
   gap: 1rem;
}

.space-x-3 {
   display: flex;
   gap: 1rem;
}

.space-y-4 {
   display: flex;
   flex-direction: column;
   gap: 2rem;
}

.space-x-4 {
   display: flex;
   gap: 2rem;
}

.space-y-5 {
   display: flex;
   flex-direction: column;
   gap: 4rem;
}

.space-x-5 {
   display: flex;
   gap: 4rem;
}

.divide-y > :not(template) ~ :not(template) {
   border-top: var(--tblr-border-width) var(--tblr-border-style)
      rgba(97, 104, 118, 0.16) !important;
}
.divide-y > :not(template):not(:first-child) {
   padding-top: 1rem !important;
}
.divide-y > :not(template):not(:last-child) {
   padding-bottom: 1rem !important;
}

.divide-x > :not(template) ~ :not(template) {
   border-left: var(--tblr-border-width) var(--tblr-border-style)
      rgba(97, 104, 118, 0.16) !important;
}
.divide-x > :not(template):not(:first-child) {
   padding-left: 1rem !important;
}
.divide-x > :not(template):not(:last-child) {
   padding-right: 1rem !important;
}

.divide-y-0 > :not(template) ~ :not(template) {
   border-top: var(--tblr-border-width) var(--tblr-border-style)
      rgba(97, 104, 118, 0.16) !important;
}
.divide-y-0 > :not(template):not(:first-child) {
   padding-top: 0 !important;
}
.divide-y-0 > :not(template):not(:last-child) {
   padding-bottom: 0 !important;
}

.divide-x-0 > :not(template) ~ :not(template) {
   border-left: var(--tblr-border-width) var(--tblr-border-style)
      rgba(97, 104, 118, 0.16) !important;
}
.divide-x-0 > :not(template):not(:first-child) {
   padding-left: 0 !important;
}
.divide-x-0 > :not(template):not(:last-child) {
   padding-right: 0 !important;
}

.divide-y-1 > :not(template) ~ :not(template) {
   border-top: var(--tblr-border-width) var(--tblr-border-style)
      rgba(97, 104, 118, 0.16) !important;
}
.divide-y-1 > :not(template):not(:first-child) {
   padding-top: 0.25rem !important;
}
.divide-y-1 > :not(template):not(:last-child) {
   padding-bottom: 0.25rem !important;
}

.divide-x-1 > :not(template) ~ :not(template) {
   border-left: var(--tblr-border-width) var(--tblr-border-style)
      rgba(97, 104, 118, 0.16) !important;
}
.divide-x-1 > :not(template):not(:first-child) {
   padding-left: 0.25rem !important;
}
.divide-x-1 > :not(template):not(:last-child) {
   padding-right: 0.25rem !important;
}

.divide-y-2 > :not(template) ~ :not(template) {
   border-top: var(--tblr-border-width) var(--tblr-border-style)
      rgba(97, 104, 118, 0.16) !important;
}
.divide-y-2 > :not(template):not(:first-child) {
   padding-top: 0.5rem !important;
}
.divide-y-2 > :not(template):not(:last-child) {
   padding-bottom: 0.5rem !important;
}

.divide-x-2 > :not(template) ~ :not(template) {
   border-left: var(--tblr-border-width) var(--tblr-border-style)
      rgba(97, 104, 118, 0.16) !important;
}
.divide-x-2 > :not(template):not(:first-child) {
   padding-left: 0.5rem !important;
}
.divide-x-2 > :not(template):not(:last-child) {
   padding-right: 0.5rem !important;
}

.divide-y-3 > :not(template) ~ :not(template) {
   border-top: var(--tblr-border-width) var(--tblr-border-style)
      rgba(97, 104, 118, 0.16) !important;
}
.divide-y-3 > :not(template):not(:first-child) {
   padding-top: 1rem !important;
}
.divide-y-3 > :not(template):not(:last-child) {
   padding-bottom: 1rem !important;
}

.divide-x-3 > :not(template) ~ :not(template) {
   border-left: var(--tblr-border-width) var(--tblr-border-style)
      rgba(97, 104, 118, 0.16) !important;
}
.divide-x-3 > :not(template):not(:first-child) {
   padding-left: 1rem !important;
}
.divide-x-3 > :not(template):not(:last-child) {
   padding-right: 1rem !important;
}

.divide-y-4 > :not(template) ~ :not(template) {
   border-top: var(--tblr-border-width) var(--tblr-border-style)
      rgba(97, 104, 118, 0.16) !important;
}
.divide-y-4 > :not(template):not(:first-child) {
   padding-top: 2rem !important;
}
.divide-y-4 > :not(template):not(:last-child) {
   padding-bottom: 2rem !important;
}

.divide-x-4 > :not(template) ~ :not(template) {
   border-left: var(--tblr-border-width) var(--tblr-border-style)
      rgba(97, 104, 118, 0.16) !important;
}
.divide-x-4 > :not(template):not(:first-child) {
   padding-left: 2rem !important;
}
.divide-x-4 > :not(template):not(:last-child) {
   padding-right: 2rem !important;
}

.divide-y-5 > :not(template) ~ :not(template) {
   border-top: var(--tblr-border-width) var(--tblr-border-style)
      rgba(97, 104, 118, 0.16) !important;
}
.divide-y-5 > :not(template):not(:first-child) {
   padding-top: 4rem !important;
}
.divide-y-5 > :not(template):not(:last-child) {
   padding-bottom: 4rem !important;
}

.divide-x-5 > :not(template) ~ :not(template) {
   border-left: var(--tblr-border-width) var(--tblr-border-style)
      rgba(97, 104, 118, 0.16) !important;
}
.divide-x-5 > :not(template):not(:first-child) {
   padding-left: 4rem !important;
}
.divide-x-5 > :not(template):not(:last-child) {
   padding-right: 4rem !important;
}

.divide-y-fill {
   display: flex;
   flex-direction: column;
   height: 100%;
}
.divide-y-fill > :not(template) {
   flex: 1;
   display: flex;
   justify-content: center;
   flex-direction: column;
}

.icon {
   --tblr-icon-size: 1.25rem;
   width: var(--tblr-icon-size);
   height: var(--tblr-icon-size);
   font-size: var(--tblr-icon-size);
   vertical-align: bottom;
   stroke-width: 1.5;
}
.icon:hover {
   text-decoration: none;
}

.icon-inline {
   --tblr-icon-size: 1rem;
   vertical-align: -0.2rem;
}

.icon-filled {
   fill: currentColor;
}

.icon-sm {
   --tblr-icon-size: 1rem;
   stroke-width: 1;
}

.icon-md {
   --tblr-icon-size: 2.5rem;
   stroke-width: 1;
}

.icon-lg {
   --tblr-icon-size: 3.5rem;
   stroke-width: 1;
}

.icon-pulse {
   transition: all 0.15s ease 0s;
   animation: pulse 2s ease infinite;
   animation-fill-mode: both;
}

.icon-tada {
   transition: all 0.15s ease 0s;
   animation: tada 3s ease infinite;
   animation-fill-mode: both;
}

.icon-rotate {
   transition: all 0.15s ease 0s;
   animation: rotate-360 3s linear infinite;
   animation-fill-mode: both;
}

.img-responsive {
   --tblr-img-responsive-ratio: 75%;
   background: no-repeat center/cover;
   padding-top: var(--tblr-img-responsive-ratio);
}

.img-responsive-grid {
   padding-top: calc(
      var(--tblr-img-responsive-ratio) - var(--tblr-gutter-y) / 2
   );
}

.img-responsive-1x1 {
   --tblr-img-responsive-ratio: 100%;
}

.img-responsive-2x1 {
   --tblr-img-responsive-ratio: 50%;
}

.img-responsive-1x2 {
   --tblr-img-responsive-ratio: 200%;
}

.img-responsive-3x1 {
   --tblr-img-responsive-ratio: 33.3333333333%;
}

.img-responsive-1x3 {
   --tblr-img-responsive-ratio: 300%;
}

.img-responsive-4x3 {
   --tblr-img-responsive-ratio: 75%;
}

.img-responsive-3x4 {
   --tblr-img-responsive-ratio: 133.3333333333%;
}

.img-responsive-16x9 {
   --tblr-img-responsive-ratio: 56.25%;
}

.img-responsive-9x16 {
   --tblr-img-responsive-ratio: 177.7777777778%;
}

.img-responsive-21x9 {
   --tblr-img-responsive-ratio: 42.8571428571%;
}

.img-responsive-9x21 {
   --tblr-img-responsive-ratio: 233.3333333333%;
}

textarea[cols] {
   height: auto;
}

/**
Form label
 */
.col-form-label,
.form-label {
   display: block;
   font-weight: var(--tblr-font-weight-medium);
}
.col-form-label.required:after,
.form-label.required:after {
   content: "*";
   margin-left: 0.25rem;
   color: #d63939;
}

.form-label-description {
   float: right;
   font-weight: var(--tblr-font-weight-normal);
   color: var(--tblr-muted);
}

/**
Form hint
 */
.form-hint {
   display: block;
   color: var(--tblr-muted);
}
.form-hint:last-child {
   margin-bottom: 0;
}
.form-hint + .form-control {
   margin-top: 0.25rem;
}
.form-label + .form-hint {
   margin-top: -0.25rem;
}
.input-group + .form-hint,
.form-control + .form-hint,
.form-select + .form-hint {
   margin-top: 0.5rem;
}

/**
Form select
 */
.form-select:-moz-focusring {
   color: var(--tblr-body-color);
}

/**
Form control
 */
.form-control:-webkit-autofill {
   box-shadow: 0 0 0 1000px var(--tblr-body-bg) inset;
   color: var(--tblr-body-color);
   -webkit-text-fill-color: var(--tblr-body-color);
}
.form-control:disabled,
.form-control.disabled {
   color: var(--tblr-muted);
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}
.form-control[size] {
   width: auto;
}

.form-control-light {
   background-color: var(--tblr-gray-100);
   border-color: transparent;
}

.form-control-dark {
   background-color: rgba(0, 0, 0, 0.1);
   color: #ffffff;
   border-color: transparent;
}
.form-control-dark:focus {
   background-color: rgba(0, 0, 0, 0.1);
   box-shadow: none;
   border-color: rgba(255, 255, 255, 0.24);
}
.form-control-dark::-webkit-input-placeholder {
   color: rgba(255, 255, 255, 0.6);
}
.form-control-dark::-moz-placeholder {
   color: rgba(255, 255, 255, 0.6);
}
.form-control-dark:-ms-input-placeholder {
   color: rgba(255, 255, 255, 0.6);
}
.form-control-dark::-ms-input-placeholder {
   color: rgba(255, 255, 255, 0.6);
}
.form-control-dark::placeholder {
   color: rgba(255, 255, 255, 0.6);
}

.form-control-rounded {
   border-radius: 10rem;
}

.form-control-flush {
   padding: 0;
   background: none !important;
   border-color: transparent !important;
   resize: none;
   box-shadow: none !important;
   line-height: inherit;
}

.form-footer {
   margin-top: 2rem;
}

.form-fieldset {
   padding: 1rem;
   margin-bottom: 1rem;
   background: var(--tblr-body-bg);
   border: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
   border-radius: var(--tblr-border-radius);
}

/**
Form help
 */
.form-help {
   display: inline-flex;
   font-weight: var(--tblr-font-weight-bold);
   align-items: center;
   justify-content: center;
   width: 1.125rem;
   height: 1.125rem;
   font-size: 0.75rem;
   color: var(--tblr-muted);
   text-align: center;
   text-decoration: none;
   cursor: pointer;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   background: var(--tblr-gray-100);
   border-radius: 100rem;
   transition: background-color 0.3s, color 0.3s;
}
@media (prefers-reduced-motion: reduce) {
   .form-help {
      transition: none;
   }
}
.form-help:hover,
.form-help[aria-describedby] {
   color: #ffffff;
   background: var(--tblr-primary);
}

/**
Input group
 */
.input-group-link {
   font-size: 0.75rem;
}

.input-group-flat:focus-within {
   box-shadow: 0 0 0 0.25rem rgba(32, 107, 196, 0.25);
   border-radius: var(--tblr-border-radius);
}
.input-group-flat:focus-within .form-control,
.input-group-flat:focus-within .input-group-text {
   border-color: #90b5e2 !important;
}
.input-group-flat .form-control:focus {
   border-color: var(--tblr-border-color);
   box-shadow: none;
}
.input-group-flat .form-control:not(:last-child) {
   border-right: 0;
}
.input-group-flat .form-control:not(:first-child) {
   border-left: 0;
}
.input-group-flat .input-group-text {
   background: var(--tblr-bg-forms);
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
   .input-group-flat .input-group-text {
      transition: none;
   }
}
.input-group-flat .input-group-text:first-child {
   padding-right: 0;
}
.input-group-flat .input-group-text:last-child {
   padding-left: 0;
}

/**
Upload files
 */
.form-file-button {
   margin-left: 0;
   border-left: 0;
}

/**
Icon input
 */
.input-icon {
   position: relative;
}
.input-icon .form-control:not(:last-child),
.input-icon .form-select:not(:last-child) {
   padding-right: 2.5rem;
}
.input-icon .form-control:not(:first-child),
.input-icon .form-select:not(:last-child) {
   padding-left: 2.5rem;
}

.input-icon-addon {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   min-width: 2.5rem;
   color: var(--tblr-icon-color);
   pointer-events: none;
   font-size: 1.2em;
}
.input-icon-addon:last-child {
   right: 0;
   left: auto;
}

/*
Color Input
 */
.form-colorinput {
   position: relative;
   display: inline-block;
   margin: 0;
   line-height: 1;
   cursor: pointer;
}

.form-colorinput-input {
   position: absolute;
   z-index: -1;
   opacity: 0;
}

.form-colorinput-color {
   display: block;
   width: 1.5rem;
   height: 1.5rem;
   color: #ffffff;
   border: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color-translucent);
   border-radius: 3px;
   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.form-colorinput-color:before {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   content: "";
   background: no-repeat center center/1rem;
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8.5l2.5 2.5l5.5 -5.5'/%3e%3c/svg%3e");
   opacity: 0;
   transition: opacity 0.3s;
}
@media (prefers-reduced-motion: reduce) {
   .form-colorinput-color:before {
      transition: none;
   }
}
.form-colorinput-input:checked ~ .form-colorinput-color:before {
   opacity: 1;
}
.form-colorinput-input:focus ~ .form-colorinput-color {
   border-color: var(--tblr-primary);
   box-shadow: 0 0 0 0.25rem rgba(32, 107, 196, 0.25);
}
.form-colorinput-light .form-colorinput-color:before {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%231d273b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8.5l2.5 2.5l5.5 -5.5'/%3e%3c/svg%3e");
}

/**
Image check
 */
.form-imagecheck {
   position: relative;
   margin: 0;
   cursor: pointer;
}

.form-imagecheck-input {
   position: absolute;
   z-index: -1;
   opacity: 0;
}

.form-imagecheck-figure {
   position: relative;
   display: block;
   margin: 0;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   border: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
   border-radius: 3px;
}
.form-imagecheck-input:focus ~ .form-imagecheck-figure {
   border-color: var(--tblr-primary);
   box-shadow: 0 0 0 0.25rem rgba(32, 107, 196, 0.25);
}
.form-imagecheck-input:checked ~ .form-imagecheck-figure {
   border-color: var(--tblr-primary);
}
.form-imagecheck-figure:before {
   position: absolute;
   top: 0.25rem;
   left: 0.25rem;
   z-index: 1;
   display: block;
   width: 1rem;
   height: 1rem;
   color: #ffffff;
   pointer-events: none;
   content: "";
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   background: var(--tblr-bg-forms);
   border: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
   border-radius: var(--tblr-border-radius);
   transition: opacity 0.3s;
}
@media (prefers-reduced-motion: reduce) {
   .form-imagecheck-figure:before {
      transition: none;
   }
}
.form-imagecheck-input:checked ~ .form-imagecheck-figure:before {
   background-color: var(--tblr-primary);
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8.5l2.5 2.5l5.5 -5.5'/%3e%3c/svg%3e");
   background-repeat: repeat;
   background-position: center;
   background-size: 1rem;
   border-color: var(--tblr-border-color-translucent);
}
.form-imagecheck-input[type="radio"] ~ .form-imagecheck-figure:before {
   border-radius: 50%;
}
.form-imagecheck-input[type="radio"]:checked ~ .form-imagecheck-figure:before {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3ccircle r='3' fill='%23ffffff' cx='8' cy='8' /%3e%3c/svg%3e");
}

.form-imagecheck-image {
   max-width: 100%;
   display: block;
   opacity: 0.64;
   transition: opacity 0.3s;
}
@media (prefers-reduced-motion: reduce) {
   .form-imagecheck-image {
      transition: none;
   }
}
.form-imagecheck-image:first-child {
   border-top-left-radius: 2px;
   border-top-right-radius: 2px;
}
.form-imagecheck-image:last-child {
   border-bottom-right-radius: 2px;
   border-bottom-left-radius: 2px;
}
.form-imagecheck:hover .form-imagecheck-image,
.form-imagecheck-input:focus ~ .form-imagecheck-figure .form-imagecheck-image,
.form-imagecheck-input:checked
   ~ .form-imagecheck-figure
   .form-imagecheck-image {
   opacity: 1;
}

.form-imagecheck-caption {
   padding: 0.25rem;
   font-size: 0.765625rem;
   color: var(--tblr-muted);
   text-align: center;
   transition: color 0.3s;
}
@media (prefers-reduced-motion: reduce) {
   .form-imagecheck-caption {
      transition: none;
   }
}
.form-imagecheck:hover .form-imagecheck-caption,
.form-imagecheck-input:focus ~ .form-imagecheck-figure .form-imagecheck-caption,
.form-imagecheck-input:checked
   ~ .form-imagecheck-figure
   .form-imagecheck-caption {
   color: var(--tblr-body-color);
}

/*
Select group
 */
.form-selectgroup {
   display: inline-flex;
   margin: 0 -0.5rem -0.5rem 0;
   flex-wrap: wrap;
}
.form-selectgroup .form-selectgroup-item {
   margin: 0 0.5rem 0.5rem 0;
}

.form-selectgroup-vertical {
   flex-direction: column;
}

.form-selectgroup-item {
   display: block;
   position: relative;
}

.form-selectgroup-input {
   position: absolute;
   top: 0;
   left: 0;
   z-index: -1;
   opacity: 0;
}

.form-selectgroup-label {
   position: relative;
   display: block;
   min-width: calc(
      1.4285714286em + 0.875rem + calc(var(--tblr-border-width) * 2)
   );
   margin: 0;
   padding: 0.4375rem 0.75rem;
   font-size: 0.875rem;
   line-height: 1.4285714286;
   color: var(--tblr-muted);
   background: var(--tblr-bg-forms);
   text-align: center;
   cursor: pointer;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   border: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
   border-radius: 3px;
   transition: border-color 0.3s, background 0.3s, color 0.3s;
}
@media (prefers-reduced-motion: reduce) {
   .form-selectgroup-label {
      transition: none;
   }
}
.form-selectgroup-label .icon:only-child {
   margin: 0 -0.25rem;
}
.form-selectgroup-label:hover {
   color: var(--tblr-body-color);
}

.form-selectgroup-check {
   display: inline-block;
   width: 1rem;
   height: 1rem;
   border: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color-translucent);
   vertical-align: middle;
}
.form-selectgroup-input[type="checkbox"]
   + .form-selectgroup-label
   .form-selectgroup-check {
   border-radius: var(--tblr-border-radius);
}
.form-selectgroup-input[type="radio"]
   + .form-selectgroup-label
   .form-selectgroup-check {
   border-radius: 50%;
}
.form-selectgroup-input:checked
   + .form-selectgroup-label
   .form-selectgroup-check {
   background-color: var(--tblr-primary);
   background-repeat: repeat;
   background-position: center;
   background-size: 1rem;
   border-color: var(--tblr-border-color-translucent);
}
.form-selectgroup-input[type="checkbox"]:checked
   + .form-selectgroup-label
   .form-selectgroup-check {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8.5l2.5 2.5l5.5 -5.5'/%3e%3c/svg%3e");
}
.form-selectgroup-input[type="radio"]:checked
   + .form-selectgroup-label
   .form-selectgroup-check {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3ccircle r='3' fill='%23ffffff' cx='8' cy='8' /%3e%3c/svg%3e");
}

.form-selectgroup-check-floated {
   position: absolute;
   top: 0.4375rem;
   right: 0.4375rem;
}

.form-selectgroup-input:checked + .form-selectgroup-label {
   z-index: 1;
   color: var(--tblr-primary);
   background: rgba(var(--tblr-primary-rgb), 0.04);
   border-color: var(--tblr-primary);
}

.form-selectgroup-input:focus + .form-selectgroup-label {
   z-index: 2;
   color: var(--tblr-primary);
   border-color: var(--tblr-primary);
   box-shadow: 0 0 0 0.25rem rgba(32, 107, 196, 0.25);
}

/**
Alternate version of form select group
 */
.form-selectgroup-boxes .form-selectgroup-label {
   text-align: left;
   padding: 1.5rem 1rem;
   color: inherit;
}
.form-selectgroup-boxes
   .form-selectgroup-input:checked
   + .form-selectgroup-label {
   color: inherit;
}
.form-selectgroup-boxes
   .form-selectgroup-input:checked
   + .form-selectgroup-label
   .form-selectgroup-title {
   color: var(--tblr-primary);
}
.form-selectgroup-boxes
   .form-selectgroup-input:checked
   + .form-selectgroup-label
   .form-selectgroup-label-content {
   opacity: 1;
}

/**
Select group
 */
.form-selectgroup-pills {
   flex-wrap: wrap;
   align-items: flex-start;
}
.form-selectgroup-pills .form-selectgroup-item {
   flex-grow: 0;
}
.form-selectgroup-pills .form-selectgroup-label {
   border-radius: 50px;
}

/**
Bootstrap color input
 */
.form-control-color::-webkit-color-swatch {
   border: none;
}

/**
Remove the cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button {
   -webkit-appearance: none;
}

/**
Form control dark theme fix
 */
.form-control::-webkit-file-upload-button {
   background-color: var(--tblr-btn-color, var(--tblr-tertiary-bg));
}
.form-control::file-selector-button {
   background-color: var(--tblr-btn-color, var(--tblr-tertiary-bg));
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
   background-color: var(--tblr-btn-color, var(--tblr-secondary-bg));
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
   background-color: var(--tblr-btn-color, var(--tblr-secondary-bg));
}

/*
Form check
 */
.form-check {
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}
.form-check.form-check-highlight
   .form-check-input:not(:checked)
   ~ .form-check-label {
   color: var(--tblr-muted);
}
.form-check .form-check-label-off {
   color: var(--tblr-muted);
}
.form-check .form-check-input:checked ~ .form-check-label-off {
   display: none;
}
.form-check .form-check-input:not(:checked) ~ .form-check-label-on {
   display: none;
}

.form-check-input {
   background-size: 1rem;
   margin-top: 0.125rem;
}
.form-switch .form-check-input {
   transition: background-color 0.3s, background-position 0.3s;
}
@media (prefers-reduced-motion: reduce) {
   .form-switch .form-check-input {
      transition: none;
   }
}

.form-check-label {
   display: block;
}
.form-check-label.required:after {
   content: "*";
   margin-left: 0.25rem;
   color: #d63939;
}

.form-check-description {
   display: block;
   color: var(--tblr-muted);
   font-size: 0.75rem;
   margin-top: 0.25rem;
}

.form-check-single {
   margin: 0;
}
.form-check-single .form-check-input {
   margin: 0;
}

/*
Form switch
 */
.form-switch .form-check-input {
   height: 1.125rem;
   margin-top: 0.0625rem;
}

.form-switch-lg {
   padding-left: 3.5rem;
   min-height: 1.5rem;
}
.form-switch-lg .form-check-input {
   height: 1.5rem;
   width: 2.75rem;
   background-size: 1.5rem;
   margin-left: -3.5rem;
}
.form-switch-lg .form-check-label {
   padding-top: 0.125rem;
}

/*Correction of Form-check position*/
.form-check-input:checked {
   border: none;
}

.form-select.is-invalid-lite,
.form-control.is-invalid-lite,
.form-select.is-valid-lite,
.form-control.is-valid-lite {
   border-color: var(--tblr-border-color) !important;
}

/**
Legend
 */
.legend {
   --tblr-legend-size: 0.75em;
   display: inline-block;
   background: var(--tblr-border-color);
   width: var(--tblr-legend-size);
   height: var(--tblr-legend-size);
   border-radius: var(--tblr-border-radius-sm);
}

.list-group {
   margin-left: 0;
   margin-right: 0;
}

.list-group-header {
   background: var(--tblr-light);
   padding: 0.5rem 1.5rem;
   font-size: 0.75rem;
   font-weight: var(--tblr-font-weight-medium);
   line-height: 1;
   text-transform: uppercase;
   color: var(--tblr-muted);
   border-bottom: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
}
.list-group-flush > .list-group-header:last-child {
   border-bottom-width: 0;
}

.list-group-item {
   background-color: inherit;
}

.list-group-item.active {
   background-color: rgba(var(--tblr-muted-rgb), 0.04);
   border-left-color: #206bc4;
   border-left-width: 2px;
}

.list-group-item:active,
.list-group-item:focus,
.list-group-item:hover {
   background-color: rgba(var(--tblr-muted-rgb), 0.04);
}

.list-group-item.disabled,
.list-group-item:disabled {
   color: #6c7a91;
   background-color: rgba(var(--tblr-muted-rgb), 0.04);
}

.list-bordered .list-item {
   border-top: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
   margin-top: -1px;
}
.list-bordered .list-item:first-child {
   border-top: none;
}

.list-group-hoverable .list-group-item-actions {
   opacity: 0;
   transition: opacity 0.3s;
}
@media (prefers-reduced-motion: reduce) {
   .list-group-hoverable .list-group-item-actions {
      transition: none;
   }
}
.list-group-hoverable .list-group-item:hover .list-group-item-actions,
.list-group-hoverable .list-group-item-actions.show {
   opacity: 1;
}

.list-group-transparent {
   --tblr-list-group-border-radius: 0;
   margin: 0 -1.5rem;
}
.list-group-transparent .list-group-item {
   background: none;
   border: 0;
}
.list-group-transparent .list-group-item .icon {
   color: var(--tblr-muted);
}
.list-group-transparent .list-group-item.active {
   font-weight: var(--tblr-font-weight-bold);
   color: inherit;
   background: var(--tblr-active-bg);
}
.list-group-transparent .list-group-item.active .icon {
   color: inherit;
}

.list-separated-item {
   padding: 1rem 0;
}
.list-separated-item:first-child {
   padding-top: 0;
}
.list-separated-item:last-child {
   padding-bottom: 0;
}
.list-separated-item + .list-separated-item {
   border-top: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
}

/**
Inline list
 */
.list-inline-item:not(:last-child) {
   margin-right: auto;
   -webkit-margin-end: 0.5rem;
   margin-inline-end: 0.5rem;
}

.list-inline-dots .list-inline-item + .list-inline-item:before {
   content: " · ";
   -webkit-margin-end: 0.5rem;
   margin-inline-end: 0.5rem;
}

.loader {
   position: relative;
   display: block;
   width: 2.5rem;
   height: 2.5rem;
   color: #206bc4;
   vertical-align: middle;
}
.loader:after {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   content: "";
   border: 1px var(--tblr-border-style);
   border-color: transparent;
   border-top-color: currentColor;
   border-left-color: currentColor;
   border-radius: 100rem;
   animation: rotate-360 0.6s linear;
   animation-iteration-count: infinite;
}

/**
Dimmer
*/
.dimmer {
   position: relative;
}
.dimmer .loader {
   position: absolute;
   top: 50%;
   right: 0;
   left: 0;
   display: none;
   margin: 0 auto;
   transform: translateY(-50%);
}
.dimmer.active .loader {
   display: block;
}
.dimmer.active .dimmer-content {
   pointer-events: none;
   opacity: 0.1;
}

@keyframes animated-dots {
   0% {
      transform: translateX(-100%);
   }
}
.animated-dots {
   display: inline-block;
   overflow: hidden;
   vertical-align: bottom;
}
.animated-dots:after {
   display: inline-block;
   content: "...";
   animation: animated-dots 1.2s steps(4, jump-none) infinite;
}

.modal-content .btn-close {
   position: absolute;
   top: 0;
   right: 0;
   width: 2.5rem;
   height: 2.5rem;
   margin: 0;
   padding: 0;
   z-index: 10;
}
.modal-content .btn-close:hover {
   background-color: #f2f2f2;
}

.modal-body {
   scrollbar-color: rgba(
         var(--tblr-scrollbar-color, var(--tblr-body-color-rgb)),
         0.16
      )
      transparent;
}
.modal-body::-webkit-scrollbar {
   width: 1rem;
   height: 1rem;
   -webkit-transition: background 0.3s;
   transition: background 0.3s;
}
@media (prefers-reduced-motion: reduce) {
   .modal-body::-webkit-scrollbar {
      -webkit-transition: none;
      transition: none;
   }
}
.modal-body::-webkit-scrollbar-thumb {
   border-radius: 1rem;
   border: 5px solid transparent;
   box-shadow: inset 0 0 0 1rem
      rgba(var(--tblr-scrollbar-color, var(--tblr-body-color-rgb)), 0.16);
}
.modal-body::-webkit-scrollbar-track {
   background: transparent;
}
.modal-body:hover::-webkit-scrollbar-thumb {
   box-shadow: inset 0 0 0 1rem
      rgba(var(--tblr-scrollbar-color, var(--tblr-body-color-rgb)), 0.32);
}
.modal-body::-webkit-scrollbar-corner {
   background: transparent;
}
.modal-body .modal-title {
   margin-bottom: 1rem;
}
.modal-body + .modal-body {
   border-top: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
}

.modal-status {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   height: 2px;
   background: var(--tblr-muted);
   border-radius: var(--tblr-border-radius-lg) var(--tblr-border-radius-lg) 0 0;
}

.modal-header {
   align-items: center;
   min-height: 3.5rem;
   padding: 0 3.5rem 0 1.5rem;
}

.modal-title {
   font-size: 1rem;
   font-weight: var(--tblr-font-weight-bold);
   line-height: 1.4285714286;
}

.modal-footer {
   padding-top: 0;
   padding-bottom: 0.75rem;
}

.modal-blur {
   -webkit-backdrop-filter: blur(4px);
   backdrop-filter: blur(4px);
}

.modal-full-width {
   max-width: none;
   margin: 0 0.5rem;
}

.nav-vertical,
.nav-vertical .nav {
   flex-direction: column;
   flex-wrap: nowrap;
}
.nav-vertical .nav {
   margin-left: 1.25rem;
   border-left: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
   padding-left: 0.5rem;
}
.nav-vertical .nav-link.active,
.nav-vertical .nav-item.show .nav-link {
   font-weight: var(--tblr-font-weight-bold);
}
.nav-vertical.nav-pills {
   margin: 0 -0.75rem;
}

.nav-bordered {
   border-bottom: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
}
.nav-bordered .nav-item + .nav-item {
   margin-left: 1.25rem;
}
.nav-bordered .nav-link {
   padding-left: 0;
   padding-right: 0;
   margin: 0 0 -var(--tblr-border-width);
   border: 0;
   border-bottom: 2px var(--tblr-border-style) transparent;
   color: var(--tblr-muted);
}
.nav-bordered .nav-link.active,
.nav-bordered .nav-item.show .nav-link {
   color: var(--tblr-primary);
   border-color: var(--tblr-primary);
}

.nav-link {
   display: flex;
   transition: color 0.3s;
   align-items: center;
}
@media (prefers-reduced-motion: reduce) {
   .nav-link {
      transition: none;
   }
}

.nav-link-toggle {
   margin-left: auto;
   padding: 0 0.25rem;
   transition: transform 0.3s;
}
@media (prefers-reduced-motion: reduce) {
   .nav-link-toggle {
      transition: none;
   }
}
.nav-link-toggle:after {
   content: "";
   display: inline-block;
   vertical-align: 0.306em;
   width: 0.36em;
   height: 0.36em;
   border-bottom: 1px var(--tblr-border-style);
   border-left: 1px var(--tblr-border-style);
   margin-right: 0.1em;
   margin-left: 0.4em;
   transform: rotate(-45deg);
}
.nav-link-toggle:after {
   margin: 0;
}
.nav-link[aria-expanded="true"] .nav-link-toggle {
   transform: rotate(180deg);
}

.nav-link-icon {
   width: 1.25rem;
   height: 1.25rem;
   margin-right: 0.5rem;
   color: var(--tblr-icon-color);
}
.nav-link-icon svg {
   display: block;
   height: 100%;
}

.nav-fill .nav-item .nav-link {
   justify-content: center;
}

.stars {
   display: inline-flex;
   color: #9ba9be;
   font-size: 0.75rem;
}
.stars .star:not(:first-child) {
   margin-left: 0.25rem;
}

.pagination {
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.page-link {
   min-width: 1.75rem;
   border-radius: var(--tblr-border-radius);
}

.page-item {
   text-align: center;
}
.page-item:not(.active) .page-link:hover {
   background: transparent;
}
.page-item.page-prev,
.page-item.page-next {
   flex: 0 0 50%;
   text-align: left;
}
.page-item.page-next {
   margin-left: auto;
   text-align: right;
}

.page-item-subtitle {
   margin-bottom: 2px;
   font-size: 12px;
   color: var(--tblr-muted);
   text-transform: uppercase;
}
.page-item.disabled .page-item-subtitle {
   color: var(--tblr-disabled-color);
}

.page-item-title {
   font-size: 1rem;
   font-weight: var(--tblr-font-weight-normal);
   color: var(--tblr-body-color);
}
.page-link:hover .page-item-title {
   color: #206bc4;
}
.page-item.disabled .page-item-title {
   color: var(--tblr-disabled-color);
}

@keyframes progress-indeterminate {
   0% {
      right: 100%;
      left: -35%;
   }
   100%,
   60% {
      right: -90%;
      left: 100%;
   }
}
/**
Progress
 */
.progress {
   position: relative;
   width: 100%;
   line-height: 0.5rem;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
}
.progress::-webkit-progress-bar {
   background: var(--tblr-progress-bg);
}
.progress::-webkit-progress-value {
   background-color: var(--tblr-primary);
}
.progress::-moz-progress-bar {
   background-color: var(--tblr-primary);
}
.progress::-ms-fill {
   background-color: var(--tblr-primary);
   border: none;
}

.progress-sm {
   height: 0.25rem;
}

/**
Progress bar
 */
.progress-bar {
   height: 100%;
}

.progress-bar-indeterminate:after,
.progress-bar-indeterminate:before {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   content: "";
   background-color: inherit;
   will-change: left, right;
}
.progress-bar-indeterminate:before {
   animation: progress-indeterminate 1.5s
      cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.progress-separated .progress-bar {
   box-shadow: 0 0 0 2px var(--tblr-card-bg, var(--tblr-bg-surface));
}

/**
Progressbg
 */
.progressbg {
   position: relative;
   padding: 0.25rem 0.5rem;
   display: flex;
}

.progressbg-text {
   position: relative;
   z-index: 1;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.progressbg-progress {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 0;
   height: 100%;
   background: transparent;
   pointer-events: none;
}

.progressbg-value {
   font-weight: var(--tblr-font-weight-medium);
   margin-left: auto;
   padding-left: 2rem;
}

.ribbon {
   --tblr-ribbon-margin: 0.25rem;
   --tblr-ribbon-border-radius: var(--tblr-border-radius);
   position: absolute;
   top: 0.75rem;
   right: calc(-1 * var(--tblr-ribbon-margin));
   z-index: 1;
   padding: 0.25rem 0.75rem;
   font-size: 0.625rem;
   font-weight: var(--tblr-font-weight-bold);
   line-height: 1;
   color: #ffffff;
   text-align: center;
   text-transform: uppercase;
   background: var(--tblr-primary);
   border-color: var(--tblr-primary);
   border-radius: var(--tblr-ribbon-border-radius) 0
      var(--tblr-ribbon-border-radius) var(--tblr-ribbon-border-radius);
   display: inline-flex;
   align-items: center;
   justify-content: center;
   min-height: 2rem;
   min-width: 2rem;
}
.ribbon:before {
   position: absolute;
   right: 0;
   bottom: 100%;
   width: 0;
   height: 0;
   content: "";
   filter: brightness(70%);
   border: calc(var(--tblr-ribbon-margin) * 0.5) var(--tblr-border-style);
   border-color: inherit;
   border-top-color: transparent;
   border-right-color: transparent;
}
.ribbon.bg-blue {
   border-color: var(--tblr-blue);
}
.ribbon.bg-blue-lt {
   border-color: rgba(var(--tblr-blue-rgb), 0.1) !important;
}
.ribbon.bg-azure {
   border-color: var(--tblr-azure);
}
.ribbon.bg-azure-lt {
   border-color: rgba(var(--tblr-azure-rgb), 0.1) !important;
}
.ribbon.bg-indigo {
   border-color: var(--tblr-indigo);
}
.ribbon.bg-indigo-lt {
   border-color: rgba(var(--tblr-indigo-rgb), 0.1) !important;
}
.ribbon.bg-purple {
   border-color: var(--tblr-purple);
}
.ribbon.bg-purple-lt {
   border-color: rgba(var(--tblr-purple-rgb), 0.1) !important;
}
.ribbon.bg-pink {
   border-color: var(--tblr-pink);
}
.ribbon.bg-pink-lt {
   border-color: rgba(var(--tblr-pink-rgb), 0.1) !important;
}
.ribbon.bg-red {
   border-color: var(--tblr-red);
}
.ribbon.bg-red-lt {
   border-color: rgba(var(--tblr-red-rgb), 0.1) !important;
}
.ribbon.bg-orange {
   border-color: var(--tblr-orange);
}
.ribbon.bg-orange-lt {
   border-color: rgba(var(--tblr-orange-rgb), 0.1) !important;
}
.ribbon.bg-yellow {
   border-color: var(--tblr-yellow);
}
.ribbon.bg-yellow-lt {
   border-color: rgba(var(--tblr-yellow-rgb), 0.1) !important;
}
.ribbon.bg-lime {
   border-color: var(--tblr-lime);
}
.ribbon.bg-lime-lt {
   border-color: rgba(var(--tblr-lime-rgb), 0.1) !important;
}
.ribbon.bg-green {
   border-color: var(--tblr-green);
}
.ribbon.bg-green-lt {
   border-color: rgba(var(--tblr-green-rgb), 0.1) !important;
}
.ribbon.bg-teal {
   border-color: var(--tblr-teal);
}
.ribbon.bg-teal-lt {
   border-color: rgba(var(--tblr-teal-rgb), 0.1) !important;
}
.ribbon.bg-cyan {
   border-color: var(--tblr-cyan);
}
.ribbon.bg-cyan-lt {
   border-color: rgba(var(--tblr-cyan-rgb), 0.1) !important;
}
.ribbon .icon {
   width: 1.25rem;
   height: 1.25rem;
   font-size: 1.25rem;
}

.ribbon-top {
   top: calc(-1 * var(--tblr-ribbon-margin));
   right: 0.75rem;
   width: 2rem;
   padding: 0.5rem 0;
   border-radius: 0 var(--tblr-ribbon-border-radius)
      var(--tblr-ribbon-border-radius) var(--tblr-ribbon-border-radius);
}
.ribbon-top:before {
   top: 0;
   right: 100%;
   bottom: auto;
   border-color: inherit;
   border-top-color: transparent;
   border-left-color: transparent;
}
.ribbon-top.ribbon-start {
   right: auto;
   left: 0.75rem;
}
.ribbon-top.ribbon-start:before {
   top: 0;
   right: 100%;
   left: auto;
}

.ribbon-start {
   right: auto;
   left: calc(-1 * var(--tblr-ribbon-margin));
}
.ribbon-start:before {
   top: auto;
   bottom: 100%;
   left: 0;
   border-color: inherit;
   border-top-color: transparent;
   border-left-color: transparent;
}

.ribbon-bottom {
   top: auto;
   bottom: 0.75rem;
}

.ribbon-bookmark {
   padding-left: 0.25rem;
   border-radius: 0 0 var(--tblr-ribbon-border-radius) 0;
}
.ribbon-bookmark:after {
   position: absolute;
   top: 0;
   right: 100%;
   display: block;
   width: 0;
   height: 0;
   content: "";
   border: 1rem var(--tblr-border-style);
   border-color: inherit;
   border-right-width: 0;
   border-left-color: transparent;
   border-left-width: 0.5rem;
}
.ribbon-bookmark.ribbon-left {
   padding-right: 0.5rem;
}
.ribbon-bookmark.ribbon-left:after {
   right: auto;
   left: 100%;
   border-right-color: transparent;
   border-right-width: 0.5rem;
   border-left-width: 0;
}
.ribbon-bookmark.ribbon-top {
   padding-right: 0;
   padding-bottom: 0.25rem;
   padding-left: 0;
   border-radius: 0 var(--tblr-ribbon-border-radius) 0 0;
}
.ribbon-bookmark.ribbon-top:after {
   top: 100%;
   right: 0;
   left: 0;
   border-color: inherit;
   border-width: 1rem;
   border-top-width: 0;
   border-bottom-color: transparent;
   border-bottom-width: 0.5rem;
}

/**
Markdown
 */
.markdown {
   line-height: 1.7142857143;
}
.markdown > :first-child {
   margin-top: 0;
}
.markdown > :last-child,
.markdown > :last-child .highlight {
   margin-bottom: 0;
}
@media (min-width: 768px) {
   .markdown > hr,
   .markdown > .hr {
      margin-top: 3em;
      margin-bottom: 3em;
   }
}
.markdown > h1,
.markdown > .h1,
.markdown > h2,
.markdown > .h2,
.markdown > h3,
.markdown > .h3,
.markdown > h4,
.markdown > .h4,
.markdown > h5,
.markdown > .h5,
.markdown > h6,
.markdown > .h6 {
   font-weight: var(--tblr-font-weight-bold);
}
.markdown > blockquote {
   font-size: 1rem;
   margin: 1.5rem 0;
   padding: 0.5rem 1.5rem;
}
.markdown > img {
   border-radius: var(--tblr-border-radius);
}

.placeholder:not(.btn):not([class*="bg-"]) {
   background-color: currentColor !important;
}
.placeholder:not(.avatar):not([class*="card-img-"]) {
   border-radius: var(--tblr-border-radius);
}

.steps {
   --tblr-steps-color: var(--tblr-primary);
   --tblr-steps-inactive-color: var(--tblr-border-color);
   --tblr-steps-dot-size: 0.5rem;
   --tblr-steps-border-width: 2px;
   display: flex;
   flex-wrap: nowrap;
   width: 100%;
   padding: 0;
   margin: 0;
   list-style: none;
}

.steps-blue {
   --tblr-steps-color: var(--tblr-blue);
}

.steps-azure {
   --tblr-steps-color: var(--tblr-azure);
}

.steps-indigo {
   --tblr-steps-color: var(--tblr-indigo);
}

.steps-purple {
   --tblr-steps-color: var(--tblr-purple);
}

.steps-pink {
   --tblr-steps-color: var(--tblr-pink);
}

.steps-red {
   --tblr-steps-color: var(--tblr-red);
}

.steps-orange {
   --tblr-steps-color: var(--tblr-orange);
}

.steps-yellow {
   --tblr-steps-color: var(--tblr-yellow);
}

.steps-lime {
   --tblr-steps-color: var(--tblr-lime);
}

.steps-green {
   --tblr-steps-color: var(--tblr-green);
}

.steps-teal {
   --tblr-steps-color: var(--tblr-teal);
}

.steps-cyan {
   --tblr-steps-color: var(--tblr-cyan);
}

.step-item {
   position: relative;
   flex: 1 1 0;
   min-height: 1rem;
   margin-top: 0;
   color: inherit;
   text-align: center;
   cursor: default;
   padding-top: calc(var(--tblr-steps-dot-size));
}
a.step-item {
   cursor: pointer;
}
a.step-item:hover {
   color: inherit;
}

.step-item:after,
.step-item:before {
   background: var(--tblr-steps-color);
}
.step-item:not(:last-child):after {
   position: absolute;
   left: 50%;
   width: 100%;
   content: "";
   transform: translateY(-50%);
}
.step-item:after {
   top: calc(var(--tblr-steps-dot-size) * 0.5);
   height: var(--tblr-steps-border-width);
}
.step-item:before {
   content: "";
   position: absolute;
   top: 0;
   left: 50%;
   z-index: 1;
   box-sizing: content-box;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 100rem;
   transform: translateX(-50%);
   color: var(--tblr-white);
   width: var(--tblr-steps-dot-size);
   height: var(--tblr-steps-dot-size);
}
.step-item.active {
   font-weight: var(--tblr-font-weight-bold);
}
.step-item.active:after {
   background: var(--tblr-steps-inactive-color);
}
.step-item.active ~ .step-item {
   color: var(--tblr-disabled-color);
}
.step-item.active ~ .step-item:after,
.step-item.active ~ .step-item:before {
   background: var(--tblr-steps-inactive-color);
}

.steps-counter {
   --tblr-steps-dot-size: 1.5rem;
   counter-reset: steps;
}
.steps-counter .step-item {
   counter-increment: steps;
}
.steps-counter .step-item:before {
   content: counter(steps);
}

.steps-vertical {
   --tblr-steps-dot-offset: 6px;
   flex-direction: column;
}
.steps-vertical.steps-counter {
   --tblr-steps-dot-offset: -2px;
}
.steps-vertical .step-item {
   text-align: left;
   padding-top: 0;
   padding-left: calc(var(--tblr-steps-dot-size) + 1rem);
   min-height: auto;
}
.steps-vertical .step-item:not(:first-child) {
   margin-top: 1rem;
}
.steps-vertical .step-item:before {
   top: var(--tblr-steps-dot-offset);
   left: 0;
   transform: translate(0, 0);
}
.steps-vertical .step-item:not(:last-child):after {
   position: absolute;
   content: "";
   transform: translateX(-50%);
   top: var(--tblr-steps-dot-offset);
   left: calc(var(--tblr-steps-dot-size) * 0.5);
   width: var(--tblr-steps-border-width);
   height: calc(100% + 1rem);
}

@keyframes status-pulsate-main {
   40% {
      transform: scale(1.25, 1.25);
   }
   60% {
      transform: scale(1.25, 1.25);
   }
}
@keyframes status-pulsate-secondary {
   10% {
      transform: scale(1, 1);
   }
   30% {
      transform: scale(3, 3);
   }
   80% {
      transform: scale(3, 3);
   }
   100% {
      transform: scale(1, 1);
   }
}
@keyframes status-pulsate-tertiary {
   25% {
      transform: scale(1, 1);
   }
   80% {
      transform: scale(3, 3);
      opacity: 0;
   }
   100% {
      transform: scale(3, 3);
      opacity: 0;
   }
}
.status {
   --tblr-status-height: 1.5rem;
   --tblr-status-color: #616876;
   --tblr-status-color-rgb: 97, 104, 118;
   display: inline-flex;
   align-items: center;
   height: var(--tblr-status-height);
   padding: 0.25rem 0.75rem;
   gap: 0.5rem;
   color: var(--tblr-status-color);
   background: rgba(var(--tblr-status-color-rgb), 0.1);
   font-size: 0.875rem;
   text-transform: none;
   letter-spacing: normal;
   border-radius: 100rem;
   font-weight: var(--tblr-font-weight-medium);
   line-height: 1;
   margin: 0;
}
.status .status-dot {
   background: var(--tblr-status-color);
}
.status .icon {
   font-size: 1.25rem;
}

.status-lite {
   border: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color) !important;
   background: transparent !important;
   color: var(--tblr-body-color) !important;
}

.status-primary {
   --tblr-status-color: #206bc4;
   --tblr-status-color-rgb: 32, 107, 196;
}

.status-secondary {
   --tblr-status-color: #616876;
   --tblr-status-color-rgb: 97, 104, 118;
}

.status-success {
   --tblr-status-color: #2fb344;
   --tblr-status-color-rgb: 47, 179, 68;
}

.status-info {
   --tblr-status-color: #4299e1;
   --tblr-status-color-rgb: 66, 153, 225;
}

.status-warning {
   --tblr-status-color: #f76707;
   --tblr-status-color-rgb: 247, 103, 7;
}

.status-danger {
   --tblr-status-color: #d63939;
   --tblr-status-color-rgb: 214, 57, 57;
}

.status-light {
   --tblr-status-color: #f8fafc;
   --tblr-status-color-rgb: 248, 250, 252;
}

.status-dark {
   --tblr-status-color: #1d273b;
   --tblr-status-color-rgb: 29, 39, 59;
}

.status-muted {
   --tblr-status-color: #616876;
   --tblr-status-color-rgb: 97, 104, 118;
}

.status-blue {
   --tblr-status-color: #206bc4;
   --tblr-status-color-rgb: 32, 107, 196;
}

.status-azure {
   --tblr-status-color: #4299e1;
   --tblr-status-color-rgb: 66, 153, 225;
}

.status-indigo {
   --tblr-status-color: #4263eb;
   --tblr-status-color-rgb: 66, 99, 235;
}

.status-purple {
   --tblr-status-color: #ae3ec9;
   --tblr-status-color-rgb: 174, 62, 201;
}

.status-pink {
   --tblr-status-color: #d6336c;
   --tblr-status-color-rgb: 214, 51, 108;
}

.status-red {
   --tblr-status-color: #d63939;
   --tblr-status-color-rgb: 214, 57, 57;
}

.status-orange {
   --tblr-status-color: #f76707;
   --tblr-status-color-rgb: 247, 103, 7;
}

.status-yellow {
   --tblr-status-color: #f59f00;
   --tblr-status-color-rgb: 245, 159, 0;
}

.status-lime {
   --tblr-status-color: #74b816;
   --tblr-status-color-rgb: 116, 184, 22;
}

.status-green {
   --tblr-status-color: #2fb344;
   --tblr-status-color-rgb: 47, 179, 68;
}

.status-teal {
   --tblr-status-color: #0ca678;
   --tblr-status-color-rgb: 12, 166, 120;
}

.status-cyan {
   --tblr-status-color: #17a2b8;
   --tblr-status-color-rgb: 23, 162, 184;
}

.status-facebook {
   --tblr-status-color: #1877f2;
   --tblr-status-color-rgb: 24, 119, 242;
}

.status-twitter {
   --tblr-status-color: #1da1f2;
   --tblr-status-color-rgb: 29, 161, 242;
}

.status-linkedin {
   --tblr-status-color: #0a66c2;
   --tblr-status-color-rgb: 10, 102, 194;
}

.status-google {
   --tblr-status-color: #dc4e41;
   --tblr-status-color-rgb: 220, 78, 65;
}

.status-youtube {
   --tblr-status-color: #ff0000;
   --tblr-status-color-rgb: 255, 0, 0;
}

.status-vimeo {
   --tblr-status-color: #1ab7ea;
   --tblr-status-color-rgb: 26, 183, 234;
}

.status-dribbble {
   --tblr-status-color: #ea4c89;
   --tblr-status-color-rgb: 234, 76, 137;
}

.status-github {
   --tblr-status-color: #181717;
   --tblr-status-color-rgb: 24, 23, 23;
}

.status-instagram {
   --tblr-status-color: #e4405f;
   --tblr-status-color-rgb: 228, 64, 95;
}

.status-pinterest {
   --tblr-status-color: #bd081c;
   --tblr-status-color-rgb: 189, 8, 28;
}

.status-vk {
   --tblr-status-color: #6383a8;
   --tblr-status-color-rgb: 99, 131, 168;
}

.status-rss {
   --tblr-status-color: #ffa500;
   --tblr-status-color-rgb: 255, 165, 0;
}

.status-flickr {
   --tblr-status-color: #0063dc;
   --tblr-status-color-rgb: 0, 99, 220;
}

.status-bitbucket {
   --tblr-status-color: #0052cc;
   --tblr-status-color-rgb: 0, 82, 204;
}

.status-tabler {
   --tblr-status-color: #206bc4;
   --tblr-status-color-rgb: 32, 107, 196;
}

.status-dot {
   --tblr-status-dot-color: var(--tblr-status-color, #616876);
   --tblr-status-size: 0.5rem;
   position: relative;
   display: inline-block;
   width: var(--tblr-status-size);
   height: var(--tblr-status-size);
   background: var(--tblr-status-dot-color);
   border-radius: 100rem;
}

.status-dot-animated:before {
   content: "";
   position: absolute;
   inset: 0;
   z-index: 0;
   background: inherit;
   border-radius: inherit;
   opacity: 0.6;
   animation: 1s linear 2s backwards infinite status-pulsate-tertiary;
}

.status-indicator {
   --tblr-status-indicator-size: 2.5rem;
   --tblr-status-indicator-color: var(--tblr-status-color, #616876);
   display: block;
   position: relative;
   width: var(--tblr-status-indicator-size);
   height: var(--tblr-status-indicator-size);
}

.status-indicator-circle {
   --tblr-status-circle-size: 0.75rem;
   position: absolute;
   left: 50%;
   top: 50%;
   margin: calc(var(--tblr-status-circle-size) / -2) 0 0
      calc(var(--tblr-status-circle-size) / -2);
   width: var(--tblr-status-circle-size);
   height: var(--tblr-status-circle-size);
   border-radius: 100rem;
   background: var(--tblr-status-color);
}
.status-indicator-circle:nth-child(1) {
   z-index: 3;
}
.status-indicator-circle:nth-child(2) {
   z-index: 2;
   opacity: 0.1;
}
.status-indicator-circle:nth-child(3) {
   z-index: 1;
   opacity: 0.3;
}

.status-indicator-animated .status-indicator-circle:nth-child(1) {
   animation: 2s linear 1s infinite backwards status-pulsate-main;
}
.status-indicator-animated .status-indicator-circle:nth-child(2) {
   animation: 2s linear 1s infinite backwards status-pulsate-secondary;
}
.status-indicator-animated .status-indicator-circle:nth-child(3) {
   animation: 2s linear 1s infinite backwards status-pulsate-tertiary;
}

.switch-icon {
   display: inline-block;
   line-height: 1;
   border: 0;
   padding: 0;
   background: transparent;
   width: 1.25rem;
   height: 1.25rem;
   vertical-align: bottom;
   position: relative;
   cursor: pointer;
}
.switch-icon.disabled {
   pointer-events: none;
   opacity: 0.4;
}
.switch-icon:focus {
   outline: none;
}
.switch-icon svg {
   display: block;
   width: 100%;
   height: 100%;
}
.switch-icon .switch-icon-a,
.switch-icon .switch-icon-b {
   display: block;
   width: 100%;
   height: 100%;
}
.switch-icon .switch-icon-a {
   opacity: 1;
}
.switch-icon .switch-icon-b {
   position: absolute;
   top: 0;
   left: 0;
   opacity: 0;
}
.switch-icon.active .switch-icon-a {
   opacity: 0;
}
.switch-icon.active .switch-icon-b {
   opacity: 1;
}

.switch-icon-fade .switch-icon-a,
.switch-icon-fade .switch-icon-b {
   transition: opacity 0.5s;
}
@media (prefers-reduced-motion: reduce) {
   .switch-icon-fade .switch-icon-a,
   .switch-icon-fade .switch-icon-b {
      transition: none;
   }
}

.switch-icon-scale .switch-icon-a,
.switch-icon-scale .switch-icon-b {
   transition: opacity 0.5s, transform 0s 0.5s;
}
@media (prefers-reduced-motion: reduce) {
   .switch-icon-scale .switch-icon-a,
   .switch-icon-scale .switch-icon-b {
      transition: none;
   }
}
.switch-icon-scale .switch-icon-b {
   transform: scale(1.5);
}
.switch-icon-scale.active .switch-icon-a,
.switch-icon-scale.active .switch-icon-b {
   transition: opacity 0s, transform 0.5s;
}
@media (prefers-reduced-motion: reduce) {
   .switch-icon-scale.active .switch-icon-a,
   .switch-icon-scale.active .switch-icon-b {
      transition: none;
   }
}
.switch-icon-scale.active .switch-icon-b {
   transform: scale(1);
}

.switch-icon-flip {
   perspective: 10em;
}
.switch-icon-flip .switch-icon-a,
.switch-icon-flip .switch-icon-b {
   -webkit-backface-visibility: hidden;
   backface-visibility: hidden;
   transform-style: preserve-3d;
   transition: opacity 0s 0.2s, transform 0.4s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
   .switch-icon-flip .switch-icon-a,
   .switch-icon-flip .switch-icon-b {
      transition: none;
   }
}
.switch-icon-flip .switch-icon-a {
   opacity: 1;
   transform: rotateY(0deg);
}
.switch-icon-flip .switch-icon-b {
   opacity: 1;
   transform: rotateY(-180deg);
}
.switch-icon-flip.active .switch-icon-a {
   opacity: 1;
   transform: rotateY(180deg);
}
.switch-icon-flip.active .switch-icon-b {
   opacity: 1;
   transform: rotateY(0deg);
}

.switch-icon-slide-up,
.switch-icon-slide-left,
.switch-icon-slide-right,
.switch-icon-slide-down {
   overflow: hidden;
}
.switch-icon-slide-up .switch-icon-a,
.switch-icon-slide-up .switch-icon-b,
.switch-icon-slide-left .switch-icon-a,
.switch-icon-slide-left .switch-icon-b,
.switch-icon-slide-right .switch-icon-a,
.switch-icon-slide-right .switch-icon-b,
.switch-icon-slide-down .switch-icon-a,
.switch-icon-slide-down .switch-icon-b {
   transition: opacity 0.3s, transform 0.3s;
}
@media (prefers-reduced-motion: reduce) {
   .switch-icon-slide-up .switch-icon-a,
   .switch-icon-slide-up .switch-icon-b,
   .switch-icon-slide-left .switch-icon-a,
   .switch-icon-slide-left .switch-icon-b,
   .switch-icon-slide-right .switch-icon-a,
   .switch-icon-slide-right .switch-icon-b,
   .switch-icon-slide-down .switch-icon-a,
   .switch-icon-slide-down .switch-icon-b {
      transition: none;
   }
}
.switch-icon-slide-up .switch-icon-a,
.switch-icon-slide-left .switch-icon-a,
.switch-icon-slide-right .switch-icon-a,
.switch-icon-slide-down .switch-icon-a {
   transform: translateY(0);
}
.switch-icon-slide-up .switch-icon-b,
.switch-icon-slide-left .switch-icon-b,
.switch-icon-slide-right .switch-icon-b,
.switch-icon-slide-down .switch-icon-b {
   transform: translateY(100%);
}
.switch-icon-slide-up.active .switch-icon-a,
.switch-icon-slide-left.active .switch-icon-a,
.switch-icon-slide-right.active .switch-icon-a,
.switch-icon-slide-down.active .switch-icon-a {
   transform: translateY(-100%);
}
.switch-icon-slide-up.active .switch-icon-b,
.switch-icon-slide-left.active .switch-icon-b,
.switch-icon-slide-right.active .switch-icon-b,
.switch-icon-slide-down.active .switch-icon-b {
   transform: translateY(0);
}

.switch-icon-slide-left .switch-icon-a {
   transform: translateX(0);
}
.switch-icon-slide-left .switch-icon-b {
   transform: translateX(100%);
}
.switch-icon-slide-left.active .switch-icon-a {
   transform: translateX(-100%);
}
.switch-icon-slide-left.active .switch-icon-b {
   transform: translateX(0);
}

.switch-icon-slide-right .switch-icon-a {
   transform: translateX(0);
}
.switch-icon-slide-right .switch-icon-b {
   transform: translateX(-100%);
}
.switch-icon-slide-right.active .switch-icon-a {
   transform: translateX(100%);
}
.switch-icon-slide-right.active .switch-icon-b {
   transform: translateX(0);
}

.switch-icon-slide-down .switch-icon-a {
   transform: translateY(0);
}
.switch-icon-slide-down .switch-icon-b {
   transform: translateY(-100%);
}
.switch-icon-slide-down.active .switch-icon-a {
   transform: translateY(100%);
}
.switch-icon-slide-down.active .switch-icon-b {
   transform: translateY(0);
}

@media not print {
   .theme-dark .table-primary {
      --tblr-table-color: #f8fafc;
      --tblr-table-bg: #134076;
      --tblr-table-border-color: #2a5383;
      --tblr-table-striped-bg: #1e497d;
      --tblr-table-striped-color: #f8fafc;
      --tblr-table-active-bg: #2a5383;
      --tblr-table-active-color: #f8fafc;
      --tblr-table-hover-bg: #244e80;
      --tblr-table-hover-color: #f8fafc;
      color: var(--tblr-table-color);
      border-color: var(--tblr-table-border-color);
   }
   .theme-dark .table-secondary {
      --tblr-table-color: #f8fafc;
      --tblr-table-bg: #3a3e47;
      --tblr-table-border-color: #4d5159;
      --tblr-table-striped-bg: #444750;
      --tblr-table-striped-color: #f8fafc;
      --tblr-table-active-bg: #4d5159;
      --tblr-table-active-color: #f8fafc;
      --tblr-table-hover-bg: #484c55;
      --tblr-table-hover-color: #f8fafc;
      color: var(--tblr-table-color);
      border-color: var(--tblr-table-border-color);
   }
   .theme-dark .table-success {
      --tblr-table-color: #f8fafc;
      --tblr-table-bg: #1c6b29;
      --tblr-table-border-color: #32793e;
      --tblr-table-striped-bg: #277234;
      --tblr-table-striped-color: #f8fafc;
      --tblr-table-active-bg: #32793e;
      --tblr-table-active-color: #f8fafc;
      --tblr-table-hover-bg: #2d7639;
      --tblr-table-hover-color: #f8fafc;
      color: var(--tblr-table-color);
      border-color: var(--tblr-table-border-color);
   }
   .theme-dark .table-info {
      --tblr-table-color: #f8fafc;
      --tblr-table-bg: #285c87;
      --tblr-table-border-color: #3d6c93;
      --tblr-table-striped-bg: #32648d;
      --tblr-table-striped-color: #f8fafc;
      --tblr-table-active-bg: #3d6c93;
      --tblr-table-active-color: #f8fafc;
      --tblr-table-hover-bg: #386890;
      --tblr-table-hover-color: #f8fafc;
      color: var(--tblr-table-color);
      border-color: var(--tblr-table-border-color);
   }
   .theme-dark .table-warning {
      --tblr-table-color: #f8fafc;
      --tblr-table-bg: #943e04;
      --tblr-table-border-color: #9e511d;
      --tblr-table-striped-bg: #994710;
      --tblr-table-striped-color: #f8fafc;
      --tblr-table-active-bg: #9e511d;
      --tblr-table-active-color: #f8fafc;
      --tblr-table-hover-bg: #9c4c17;
      --tblr-table-hover-color: #f8fafc;
      color: var(--tblr-table-color);
      border-color: var(--tblr-table-border-color);
   }
   .theme-dark .table-danger {
      --tblr-table-color: #f8fafc;
      --tblr-table-bg: #802222;
      --tblr-table-border-color: #8c3838;
      --tblr-table-striped-bg: #862d2d;
      --tblr-table-striped-color: #f8fafc;
      --tblr-table-active-bg: #8c3838;
      --tblr-table-active-color: #f8fafc;
      --tblr-table-hover-bg: #893232;
      --tblr-table-hover-color: #f8fafc;
      color: var(--tblr-table-color);
      border-color: var(--tblr-table-border-color);
   }
}
@media not print {
   @media (prefers-color-scheme: dark) {
      .theme-dark-auto .table-primary {
         --tblr-table-color: #f8fafc;
         --tblr-table-bg: #134076;
         --tblr-table-border-color: #2a5383;
         --tblr-table-striped-bg: #1e497d;
         --tblr-table-striped-color: #f8fafc;
         --tblr-table-active-bg: #2a5383;
         --tblr-table-active-color: #f8fafc;
         --tblr-table-hover-bg: #244e80;
         --tblr-table-hover-color: #f8fafc;
         color: var(--tblr-table-color);
         border-color: var(--tblr-table-border-color);
      }
      .theme-dark-auto .table-secondary {
         --tblr-table-color: #f8fafc;
         --tblr-table-bg: #3a3e47;
         --tblr-table-border-color: #4d5159;
         --tblr-table-striped-bg: #444750;
         --tblr-table-striped-color: #f8fafc;
         --tblr-table-active-bg: #4d5159;
         --tblr-table-active-color: #f8fafc;
         --tblr-table-hover-bg: #484c55;
         --tblr-table-hover-color: #f8fafc;
         color: var(--tblr-table-color);
         border-color: var(--tblr-table-border-color);
      }
      .theme-dark-auto .table-success {
         --tblr-table-color: #f8fafc;
         --tblr-table-bg: #1c6b29;
         --tblr-table-border-color: #32793e;
         --tblr-table-striped-bg: #277234;
         --tblr-table-striped-color: #f8fafc;
         --tblr-table-active-bg: #32793e;
         --tblr-table-active-color: #f8fafc;
         --tblr-table-hover-bg: #2d7639;
         --tblr-table-hover-color: #f8fafc;
         color: var(--tblr-table-color);
         border-color: var(--tblr-table-border-color);
      }
      .theme-dark-auto .table-info {
         --tblr-table-color: #f8fafc;
         --tblr-table-bg: #285c87;
         --tblr-table-border-color: #3d6c93;
         --tblr-table-striped-bg: #32648d;
         --tblr-table-striped-color: #f8fafc;
         --tblr-table-active-bg: #3d6c93;
         --tblr-table-active-color: #f8fafc;
         --tblr-table-hover-bg: #386890;
         --tblr-table-hover-color: #f8fafc;
         color: var(--tblr-table-color);
         border-color: var(--tblr-table-border-color);
      }
      .theme-dark-auto .table-warning {
         --tblr-table-color: #f8fafc;
         --tblr-table-bg: #943e04;
         --tblr-table-border-color: #9e511d;
         --tblr-table-striped-bg: #994710;
         --tblr-table-striped-color: #f8fafc;
         --tblr-table-active-bg: #9e511d;
         --tblr-table-active-color: #f8fafc;
         --tblr-table-hover-bg: #9c4c17;
         --tblr-table-hover-color: #f8fafc;
         color: var(--tblr-table-color);
         border-color: var(--tblr-table-border-color);
      }
      .theme-dark-auto .table-danger {
         --tblr-table-color: #f8fafc;
         --tblr-table-bg: #802222;
         --tblr-table-border-color: #8c3838;
         --tblr-table-striped-bg: #862d2d;
         --tblr-table-striped-color: #f8fafc;
         --tblr-table-active-bg: #8c3838;
         --tblr-table-active-color: #f8fafc;
         --tblr-table-hover-bg: #893232;
         --tblr-table-hover-color: #f8fafc;
         color: var(--tblr-table-color);
         border-color: var(--tblr-table-border-color);
      }
   }
}
.table thead th,
.markdown > table thead th {
   color: var(--tblr-muted);
   background: var(--tblr-bg-surface-tertiary);
   font-size: 0.625rem;
   font-weight: var(--tblr-font-weight-bold);
   text-transform: uppercase;
   letter-spacing: 0.04em;
   line-height: 1rem;
   color: var(--tblr-muted);
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
   white-space: nowrap;
}
@media print {
   .table thead th,
   .markdown > table thead th {
      background: transparent;
   }
}

.table-responsive .table,
.table-responsive .markdown > table {
   margin-bottom: 0;
}
.table-responsive + .card-footer {
   border-top: 0;
}

.table-transparent thead th {
   background: transparent;
}

.table-nowrap > :not(caption) > * > * {
   white-space: nowrap;
}

.table-vcenter > :not(caption) > * > * {
   vertical-align: middle;
}

.table-center > :not(caption) > * > * {
   text-align: center;
}

.td-truncate {
   max-width: 1px;
   width: 100%;
}

.table-mobile {
   display: block;
}
.table-mobile thead {
   display: none;
}
.table-mobile tbody,
.table-mobile tr {
   display: flex;
   flex-direction: column;
}
.table-mobile td {
   display: block;
   padding: 0.75rem 0.75rem !important;
   border: none;
   color: var(--tblr-body-color) !important;
}
.table-mobile td[data-label]:before {
   font-size: 0.625rem;
   font-weight: var(--tblr-font-weight-bold);
   text-transform: uppercase;
   letter-spacing: 0.04em;
   line-height: 1rem;
   color: var(--tblr-muted);
   content: attr(data-label);
   display: block;
}
.table-mobile tr {
   border-bottom: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color-translucent);
}
.table-mobile .btn {
   display: block;
}
@media (max-width: 575.98px) {
   .table-mobile-sm {
      display: block;
   }
   .table-mobile-sm thead {
      display: none;
   }
   .table-mobile-sm tbody,
   .table-mobile-sm tr {
      display: flex;
      flex-direction: column;
   }
   .table-mobile-sm td {
      display: block;
      padding: 0.75rem 0.75rem !important;
      border: none;
      color: var(--tblr-body-color) !important;
   }
   .table-mobile-sm td[data-label]:before {
      font-size: 0.625rem;
      font-weight: var(--tblr-font-weight-bold);
      text-transform: uppercase;
      letter-spacing: 0.04em;
      line-height: 1rem;
      color: var(--tblr-muted);
      content: attr(data-label);
      display: block;
   }
   .table-mobile-sm tr {
      border-bottom: var(--tblr-border-width) var(--tblr-border-style)
         var(--tblr-border-color-translucent);
   }
   .table-mobile-sm .btn {
      display: block;
   }
}
@media (max-width: 767.98px) {
   .table-mobile-md {
      display: block;
   }
   .table-mobile-md thead {
      display: none;
   }
   .table-mobile-md tbody,
   .table-mobile-md tr {
      display: flex;
      flex-direction: column;
   }
   .table-mobile-md td {
      display: block;
      padding: 0.75rem 0.75rem !important;
      border: none;
      color: var(--tblr-body-color) !important;
   }
   .table-mobile-md td[data-label]:before {
      font-size: 0.625rem;
      font-weight: var(--tblr-font-weight-bold);
      text-transform: uppercase;
      letter-spacing: 0.04em;
      line-height: 1rem;
      color: var(--tblr-muted);
      content: attr(data-label);
      display: block;
   }
   .table-mobile-md tr {
      border-bottom: var(--tblr-border-width) var(--tblr-border-style)
         var(--tblr-border-color-translucent);
   }
   .table-mobile-md .btn {
      display: block;
   }
}
@media (max-width: 991.98px) {
   .table-mobile-lg {
      display: block;
   }
   .table-mobile-lg thead {
      display: none;
   }
   .table-mobile-lg tbody,
   .table-mobile-lg tr {
      display: flex;
      flex-direction: column;
   }
   .table-mobile-lg td {
      display: block;
      padding: 0.75rem 0.75rem !important;
      border: none;
      color: var(--tblr-body-color) !important;
   }
   .table-mobile-lg td[data-label]:before {
      font-size: 0.625rem;
      font-weight: var(--tblr-font-weight-bold);
      text-transform: uppercase;
      letter-spacing: 0.04em;
      line-height: 1rem;
      color: var(--tblr-muted);
      content: attr(data-label);
      display: block;
   }
   .table-mobile-lg tr {
      border-bottom: var(--tblr-border-width) var(--tblr-border-style)
         var(--tblr-border-color-translucent);
   }
   .table-mobile-lg .btn {
      display: block;
   }
}
@media (max-width: 1199.98px) {
   .table-mobile-xl {
      display: block;
   }
   .table-mobile-xl thead {
      display: none;
   }
   .table-mobile-xl tbody,
   .table-mobile-xl tr {
      display: flex;
      flex-direction: column;
   }
   .table-mobile-xl td {
      display: block;
      padding: 0.75rem 0.75rem !important;
      border: none;
      color: var(--tblr-body-color) !important;
   }
   .table-mobile-xl td[data-label]:before {
      font-size: 0.625rem;
      font-weight: var(--tblr-font-weight-bold);
      text-transform: uppercase;
      letter-spacing: 0.04em;
      line-height: 1rem;
      color: var(--tblr-muted);
      content: attr(data-label);
      display: block;
   }
   .table-mobile-xl tr {
      border-bottom: var(--tblr-border-width) var(--tblr-border-style)
         var(--tblr-border-color-translucent);
   }
   .table-mobile-xl .btn {
      display: block;
   }
}
@media (max-width: 1399.98px) {
   .table-mobile-xxl {
      display: block;
   }
   .table-mobile-xxl thead {
      display: none;
   }
   .table-mobile-xxl tbody,
   .table-mobile-xxl tr {
      display: flex;
      flex-direction: column;
   }
   .table-mobile-xxl td {
      display: block;
      padding: 0.75rem 0.75rem !important;
      border: none;
      color: var(--tblr-body-color) !important;
   }
   .table-mobile-xxl td[data-label]:before {
      font-size: 0.625rem;
      font-weight: var(--tblr-font-weight-bold);
      text-transform: uppercase;
      letter-spacing: 0.04em;
      line-height: 1rem;
      color: var(--tblr-muted);
      content: attr(data-label);
      display: block;
   }
   .table-mobile-xxl tr {
      border-bottom: var(--tblr-border-width) var(--tblr-border-style)
         var(--tblr-border-color-translucent);
   }
   .table-mobile-xxl .btn {
      display: block;
   }
}

.table-sort {
   font: inherit;
   color: inherit;
   text-transform: inherit;
   letter-spacing: inherit;
   border: 0;
   background: inherit;
   display: block;
   width: 100%;
   text-align: inherit;
   transition: color 0.3s;
   margin: -0.5rem -0.75rem;
   padding: 0.5rem 0.75rem;
}
@media (prefers-reduced-motion: reduce) {
   .table-sort {
      transition: none;
   }
}
.table-sort:hover,
.table-sort.asc,
.table-sort.desc {
   color: var(--tblr-body-color);
}
.table-sort:after,
.table-sort.asc:after,
.table-sort.desc:after {
   content: "";
   display: inline-flex;
   width: 1rem;
   height: 1rem;
   vertical-align: bottom;
   background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'><path d='M5 7l3 -3l3 3'/><path d='M5 10l3 3l3 -3'/></svg>")
      no-repeat center;
   opacity: 0.2;
}
.table-sort.asc:after {
   background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M5 10l3 -3l3 3'/></svg>")
      no-repeat center;
   opacity: 1;
}
.table-sort.desc:after {
   background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M5 7l3 3l3 -3'/></svg>")
      no-repeat center;
   opacity: 1;
}

.table-borderless thead th {
   background: transparent;
}

.toast {
   background: #ffffff;
   border: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color-translucent);
   box-shadow: rgba(29, 39, 59, 0.04) 0 2px 4px 0;
}
.toast .toast-header {
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}
.toast button[data-bs-dismiss="toast"] {
   outline: none;
}

.toast-primary {
   --tblr-toast-color: #206bc4;
}

.toast-secondary {
   --tblr-toast-color: #616876;
}

.toast-success {
   --tblr-toast-color: #2fb344;
}

.toast-info {
   --tblr-toast-color: #4299e1;
}

.toast-warning {
   --tblr-toast-color: #f76707;
}

.toast-danger {
   --tblr-toast-color: #d63939;
}

.toast-light {
   --tblr-toast-color: #f8fafc;
}

.toast-dark {
   --tblr-toast-color: #1d273b;
}

.toast-muted {
   --tblr-toast-color: #616876;
}

.toast-blue {
   --tblr-toast-color: #206bc4;
}

.toast-azure {
   --tblr-toast-color: #4299e1;
}

.toast-indigo {
   --tblr-toast-color: #4263eb;
}

.toast-purple {
   --tblr-toast-color: #ae3ec9;
}

.toast-pink {
   --tblr-toast-color: #d6336c;
}

.toast-red {
   --tblr-toast-color: #d63939;
}

.toast-orange {
   --tblr-toast-color: #f76707;
}

.toast-yellow {
   --tblr-toast-color: #f59f00;
}

.toast-lime {
   --tblr-toast-color: #74b816;
}

.toast-green {
   --tblr-toast-color: #2fb344;
}

.toast-teal {
   --tblr-toast-color: #0ca678;
}

.toast-cyan {
   --tblr-toast-color: #17a2b8;
}

.toast-facebook {
   --tblr-toast-color: #1877f2;
}

.toast-twitter {
   --tblr-toast-color: #1da1f2;
}

.toast-linkedin {
   --tblr-toast-color: #0a66c2;
}

.toast-google {
   --tblr-toast-color: #dc4e41;
}

.toast-youtube {
   --tblr-toast-color: #ff0000;
}

.toast-vimeo {
   --tblr-toast-color: #1ab7ea;
}

.toast-dribbble {
   --tblr-toast-color: #ea4c89;
}

.toast-github {
   --tblr-toast-color: #181717;
}

.toast-instagram {
   --tblr-toast-color: #e4405f;
}

.toast-pinterest {
   --tblr-toast-color: #bd081c;
}

.toast-vk {
   --tblr-toast-color: #6383a8;
}

.toast-rss {
   --tblr-toast-color: #ffa500;
}

.toast-flickr {
   --tblr-toast-color: #0063dc;
}

.toast-bitbucket {
   --tblr-toast-color: #0052cc;
}

.toast-tabler {
   --tblr-toast-color: #206bc4;
}

.toolbar {
   display: flex;
   flex-wrap: nowrap;
   flex-shrink: 0;
   margin: 0 -0.5rem;
}
.toolbar > * {
   margin: 0 0.5rem;
}

.tracking {
   --tblr-tracking-height: 1.5rem;
   --tblr-tracking-gap-width: 0.125rem;
   --tblr-tracking-block-border-radius: var(--tblr-border-radius);
   display: flex;
   gap: var(--tblr-tracking-gap-width);
}

.tracking-squares {
   --tblr-tracking-block-border-radius: var(--tblr-border-radius-sm);
}
.tracking-squares .tracking-block {
   height: auto;
}
.tracking-squares .tracking-block:before {
   content: "";
   display: block;
   padding-top: 100%;
}

.tracking-block {
   flex: 1;
   border-radius: var(--tblr-tracking-block-border-radius);
   height: var(--tblr-tracking-height);
   min-width: 0.25rem;
   background: var(--tblr-border-color);
}

.timeline {
   --tblr-timeline-icon-size: 2.5rem;
   position: relative;
   list-style: none;
   padding: 0;
}

.timeline-event {
   position: relative;
}
.timeline-event:not(:last-child) {
   margin-bottom: var(--tblr-content-padding);
}
.timeline-event:not(:last-child):before {
   content: "";
   position: absolute;
   top: var(--tblr-timeline-icon-size);
   left: calc(var(--tblr-timeline-icon-size) / 2);
   bottom: calc(-1 * var(--tblr-content-padding));
   width: var(--tblr-border-width);
   background-color: var(--tblr-border-color);
   border-radius: var(--tblr-border-radius);
}

.timeline-event-icon {
   position: absolute;
   display: flex;
   align-items: center;
   justify-content: center;
   width: var(--tblr-timeline-icon-size, 2.5rem);
   height: var(--tblr-timeline-icon-size, 2.5rem);
   background: var(--tblr-gray-200);
   color: var(--tblr-muted);
   border-radius: var(--tblr-border-radius);
   z-index: 5;
}

.timeline-event-card {
   margin-left: calc(
      var(--tblr-timeline-icon-size, 2.5rem) + var(--tblr-content-padding)
   );
}

.timeline-simple .timeline-event-icon {
   display: none;
}
.timeline-simple .timeline-event-card {
   margin-left: 0;
}

/**
Horizontal rules
 */
/**
Hr text
 */
.hr-text {
   display: flex;
   align-items: center;
   margin: 2rem 0;
   font-size: 0.7rem;
   font-weight: 500;
   text-transform: uppercase;
   letter-spacing: 0.04em;
   line-height: 1rem;
   color: var(--tblr-muted);
   height: 1px;
}
.hr-text:after,
.hr-text:before {
   flex: 1 1 auto;
   height: 1px;
   background-color: var(--tblr-border-color);
}
.hr-text:before {
   content: "";
   margin-right: 0.5rem;
}
.hr-text:after {
   content: "";
   margin-left: 0.5rem;
}
.hr-text > *:first-child {
   padding-right: 0.5rem;
   padding-left: 0;
   color: var(--tblr-muted);
}
.hr-text.hr-text-left:before {
   content: none;
}
.hr-text.hr-text-left > *:first-child {
   padding-right: 0.5rem;
   padding-left: 0.5rem;
}
.hr-text.hr-text-right:before {
   content: "";
}
.hr-text.hr-text-right:after {
   content: none;
}
.hr-text.hr-text-right > *:first-child {
   padding-right: 0;
   padding-left: 0.5rem;
}
.card > .hr-text {
   margin: 0;
}

.hr-text-spaceless {
   margin: -0.5rem 0;
}

.lead {
   line-height: 1.4;
}

a {
   -webkit-text-decoration-skip: ink;
   text-decoration-skip-ink: auto;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
   color: inherit;
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.h1 a:hover,
.h2 a:hover,
.h3 a:hover,
.h4 a:hover,
.h5 a:hover,
.h6 a:hover {
   color: inherit;
}

h1,
.h1 {
   font-size: var(--tblr-font-size-h1);
   line-height: var(--tblr-line-height-h1);
}

h2,
.h2 {
   font-size: var(--tblr-font-size-h2);
   line-height: var(--tblr-line-height-h2);
}

h3,
.h3 {
   font-size: var(--tblr-font-size-h3);
   line-height: var(--tblr-line-height-h3);
}

h4,
.h4 {
   font-size: var(--tblr-font-size-h4);
   line-height: var(--tblr-line-height-h4);
}

h5,
.h5 {
   font-size: var(--tblr-font-size-h5);
   line-height: var(--tblr-line-height-h5);
}

h6,
.h6 {
   font-size: var(--tblr-font-size-h6);
   line-height: var(--tblr-line-height-h6);
}

strong,
.strong,
b {
   font-weight: var(--tblr-font-weight-bold);
}

blockquote {
   padding-left: 1rem;
   border-left: 2px var(--tblr-border-style) var(--tblr-border-color);
}
blockquote p {
   margin-bottom: 1rem;
}
blockquote cite {
   display: block;
   text-align: right;
}
blockquote cite:before {
   content: "— ";
}

ul,
ol {
   padding-left: 1.5rem;
}

hr,
.hr {
   margin: 2rem 0;
}

dl dd:last-child {
   margin-bottom: 0;
}

pre {
   padding: 1rem;
   background: var(--tblr-bg-surface-dark);
   color: var(--tblr-light);
   border-radius: var(--tblr-border-radius);
}
pre code {
   background: transparent;
}

code {
   background: var(--tblr-code-bg);
   padding: 2px 4px;
   border-radius: var(--tblr-border-radius);
}

kbd,
.kbd {
   border: var(--tblr-border-width) var(--tblr-border-style)
      var(--tblr-border-color);
   display: inline-block;
   box-sizing: border-box;
   max-width: 100%;
   font-size: var(--tblr-font-size-h5);
   font-weight: var(--tblr-font-weight-medium);
   line-height: 1;
   vertical-align: baseline;
   border-radius: var(--tblr-border-radius);
}

img {
   max-width: 100%;
}

.list-unstyled {
   margin-left: 0;
}

/**
Selection
 */
::-moz-selection {
   background-color: rgba(var(--tblr-primary-rgb), 0.16);
}
::selection {
   background-color: rgba(var(--tblr-primary-rgb), 0.16);
}

/**
Links
 */
[class^="link-"].disabled,
[class*=" link-"].disabled {
   color: var(--tblr-disabled-color);
   pointer-events: none;
}

/**
Subheader
 */
.subheader {
   font-size: 0.625rem;
   font-weight: var(--tblr-font-weight-bold);
   text-transform: uppercase;
   letter-spacing: 0.04em;
   line-height: 1rem;
   color: var(--tblr-muted);
}

.chart {
   display: block;
   min-height: 10rem;
}
.chart text {
   font-family: inherit;
}

.chart-sm {
   height: 2.5rem;
}

.chart-lg {
   height: 15rem;
}

.chart-square {
   height: 5.75rem;
}

/**
Chart sparkline
 */
.chart-sparkline {
   position: relative;
   width: 4rem;
   height: 2.5rem;
   line-height: 1;
   min-height: 0 !important;
}

.chart-sparkline-sm {
   height: 1.5rem;
}

.chart-sparkline-square {
   width: 2.5rem;
}

.chart-sparkline-wide {
   width: 6rem;
}

.chart-sparkline-label {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 0.625rem;
}
.chart-sparkline-label .icon {
   width: 1rem;
   height: 1rem;
   font-size: 1rem;
}

.offcanvas-header {
   border-bottom: var(--tblr-border-width) var(--tblr-border-style)
      rgba(97, 104, 118, 0.16);
}

.offcanvas-footer {
   padding: 1.5rem 1.5rem;
}

.offcanvas-title {
   font-size: 1rem;
   font-weight: var(--tblr-font-weight-medium);
   line-height: 1.5rem;
}

.offcanvas-narrow {
   width: 20rem;
}

.bg-white-overlay {
   color: #ffffff;
   background-color: rgba(248, 250, 252, 0.24);
}

.bg-dark-overlay {
   color: #ffffff;
   background-color: rgba(29, 39, 59, 0.24);
}

.bg-cover {
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
}

.bg-primary {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-primary-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-primary-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-primary-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-primary-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-secondary {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-secondary-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-secondary-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-secondary-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-secondary-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-success {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-success-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-success-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-success-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-success-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-info {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-info-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-info-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-info-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-info-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-warning {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-warning-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-warning-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-warning-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-warning-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-danger {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-danger-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-danger-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-danger-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-danger-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-light {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-light-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-light-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-light-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-light-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-dark {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-dark-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-dark-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-dark-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-dark-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-muted {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-muted-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-muted-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-muted-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-muted-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-blue {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-blue-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-blue-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-blue-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-blue-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-azure {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-azure-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-azure-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-azure-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-azure-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-indigo {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-indigo-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-indigo-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-indigo-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-indigo-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-purple {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-purple-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-purple-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-purple-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-purple-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-pink {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-pink-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-pink-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-pink-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-pink-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-red {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-red-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-red-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-red-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-red-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-orange {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-orange-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-orange-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-orange-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-orange-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-yellow {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-yellow-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-yellow-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-yellow-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-yellow-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-lime {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-lime-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-lime-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-lime-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-lime-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-green {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-green-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-green-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-green-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-green-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-teal {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-teal-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-teal-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-teal-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-teal-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-cyan {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-cyan-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-cyan-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-cyan-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-cyan-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-facebook {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-facebook-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-facebook-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-facebook-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-facebook-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-twitter {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-twitter-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-twitter-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-twitter-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-twitter-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-linkedin {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-linkedin-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-linkedin-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-linkedin-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-linkedin-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-google {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-google-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-google-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-google-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-google-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-youtube {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-youtube-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-youtube-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-youtube-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-youtube-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-vimeo {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-vimeo-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-vimeo-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-vimeo-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-vimeo-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-dribbble {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-dribbble-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-dribbble-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-dribbble-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-dribbble-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-github {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-github-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-github-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-github-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-github-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-instagram {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-instagram-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-instagram-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-instagram-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-instagram-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-pinterest {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-pinterest-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-pinterest-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-pinterest-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-pinterest-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-vk {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-vk-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-vk-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-vk-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-vk-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-rss {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-rss-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-rss-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-rss-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-rss-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-flickr {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-flickr-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-flickr-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-flickr-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-flickr-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-bitbucket {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-bitbucket-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-bitbucket-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-bitbucket-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-bitbucket-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-tabler {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-tabler-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-tabler-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-tabler-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-tabler-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-white {
   --tblr-bg-opacity: 1;
   background-color: rgba(
      var(--tblr-white-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.bg-white-lt {
   --tblr-bg-opacity: 0.1;
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-white-rgb), var(--tblr-text-opacity)) !important;
   background-color: rgba(
      var(--tblr-white-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.text-primary {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-primary-rgb), var(--tblr-text-opacity)) !important;
}

.text-primary-fg {
   color: var(--tblr-primary-fg) !important;
}

.text-secondary {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-secondary-rgb), var(--tblr-text-opacity)) !important;
}

.text-secondary-fg {
   color: var(--tblr-secondary-fg) !important;
}

.text-success {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-success-rgb), var(--tblr-text-opacity)) !important;
}

.text-success-fg {
   color: var(--tblr-success-fg) !important;
}

.text-info {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-info-rgb), var(--tblr-text-opacity)) !important;
}

.text-info-fg {
   color: var(--tblr-info-fg) !important;
}

.text-warning {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-warning-rgb), var(--tblr-text-opacity)) !important;
}

.text-warning-fg {
   color: var(--tblr-warning-fg) !important;
}

.text-danger {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-danger-rgb), var(--tblr-text-opacity)) !important;
}

.text-danger-fg {
   color: var(--tblr-danger-fg) !important;
}

.text-light {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-light-rgb), var(--tblr-text-opacity)) !important;
}

.text-light-fg {
   color: var(--tblr-light-fg) !important;
}

.text-dark {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-dark-rgb), var(--tblr-text-opacity)) !important;
}

.text-dark-fg {
   color: var(--tblr-dark-fg) !important;
}

.text-muted {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-muted-rgb), var(--tblr-text-opacity)) !important;
}

.text-muted-fg {
   color: var(--tblr-muted-fg) !important;
}

.text-blue {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-blue-rgb), var(--tblr-text-opacity)) !important;
}

.text-blue-fg {
   color: var(--tblr-blue-fg) !important;
}

.text-azure {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-azure-rgb), var(--tblr-text-opacity)) !important;
}

.text-azure-fg {
   color: var(--tblr-azure-fg) !important;
}

.text-indigo {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-indigo-rgb), var(--tblr-text-opacity)) !important;
}

.text-indigo-fg {
   color: var(--tblr-indigo-fg) !important;
}

.text-purple {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-purple-rgb), var(--tblr-text-opacity)) !important;
}

.text-purple-fg {
   color: var(--tblr-purple-fg) !important;
}

.text-pink {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-pink-rgb), var(--tblr-text-opacity)) !important;
}

.text-pink-fg {
   color: var(--tblr-pink-fg) !important;
}

.text-red {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-red-rgb), var(--tblr-text-opacity)) !important;
}

.text-red-fg {
   color: var(--tblr-red-fg) !important;
}

.text-orange {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-orange-rgb), var(--tblr-text-opacity)) !important;
}

.text-orange-fg {
   color: var(--tblr-orange-fg) !important;
}

.text-yellow {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-yellow-rgb), var(--tblr-text-opacity)) !important;
}

.text-yellow-fg {
   color: var(--tblr-yellow-fg) !important;
}

.text-lime {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-lime-rgb), var(--tblr-text-opacity)) !important;
}

.text-lime-fg {
   color: var(--tblr-lime-fg) !important;
}

.text-green {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-green-rgb), var(--tblr-text-opacity)) !important;
}

.text-green-fg {
   color: var(--tblr-green-fg) !important;
}

.text-teal {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-teal-rgb), var(--tblr-text-opacity)) !important;
}

.text-teal-fg {
   color: var(--tblr-teal-fg) !important;
}

.text-cyan {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-cyan-rgb), var(--tblr-text-opacity)) !important;
}

.text-cyan-fg {
   color: var(--tblr-cyan-fg) !important;
}

.text-facebook {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-facebook-rgb), var(--tblr-text-opacity)) !important;
}

.text-facebook-fg {
   color: var(--tblr-facebook-fg) !important;
}

.text-twitter {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-twitter-rgb), var(--tblr-text-opacity)) !important;
}

.text-twitter-fg {
   color: var(--tblr-twitter-fg) !important;
}

.text-linkedin {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-linkedin-rgb), var(--tblr-text-opacity)) !important;
}

.text-linkedin-fg {
   color: var(--tblr-linkedin-fg) !important;
}

.text-google {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-google-rgb), var(--tblr-text-opacity)) !important;
}

.text-google-fg {
   color: var(--tblr-google-fg) !important;
}

.text-youtube {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-youtube-rgb), var(--tblr-text-opacity)) !important;
}

.text-youtube-fg {
   color: var(--tblr-youtube-fg) !important;
}

.text-vimeo {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-vimeo-rgb), var(--tblr-text-opacity)) !important;
}

.text-vimeo-fg {
   color: var(--tblr-vimeo-fg) !important;
}

.text-dribbble {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-dribbble-rgb), var(--tblr-text-opacity)) !important;
}

.text-dribbble-fg {
   color: var(--tblr-dribbble-fg) !important;
}

.text-github {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-github-rgb), var(--tblr-text-opacity)) !important;
}

.text-github-fg {
   color: var(--tblr-github-fg) !important;
}

.text-instagram {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-instagram-rgb), var(--tblr-text-opacity)) !important;
}

.text-instagram-fg {
   color: var(--tblr-instagram-fg) !important;
}

.text-pinterest {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-pinterest-rgb), var(--tblr-text-opacity)) !important;
}

.text-pinterest-fg {
   color: var(--tblr-pinterest-fg) !important;
}

.text-vk {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-vk-rgb), var(--tblr-text-opacity)) !important;
}

.text-vk-fg {
   color: var(--tblr-vk-fg) !important;
}

.text-rss {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-rss-rgb), var(--tblr-text-opacity)) !important;
}

.text-rss-fg {
   color: var(--tblr-rss-fg) !important;
}

.text-flickr {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-flickr-rgb), var(--tblr-text-opacity)) !important;
}

.text-flickr-fg {
   color: var(--tblr-flickr-fg) !important;
}

.text-bitbucket {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-bitbucket-rgb), var(--tblr-text-opacity)) !important;
}

.text-bitbucket-fg {
   color: var(--tblr-bitbucket-fg) !important;
}

.text-tabler {
   --tblr-text-opacity: 1;
   color: rgba(var(--tblr-tabler-rgb), var(--tblr-text-opacity)) !important;
}

.text-tabler-fg {
   color: var(--tblr-tabler-fg) !important;
}

.bg-gray-50 {
   --tblr-bg-opacity: 0.1;
   background-color: rgba(
      var(--tblr-gray-50-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.text-gray-50-fg {
   color: #1d273b !important;
}

.bg-gray-100 {
   --tblr-bg-opacity: 0.1;
   background-color: rgba(
      var(--tblr-gray-100-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.text-gray-100-fg {
   color: #1d273b !important;
}

.bg-gray-200 {
   --tblr-bg-opacity: 0.1;
   background-color: rgba(
      var(--tblr-gray-200-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.text-gray-200-fg {
   color: #1d273b !important;
}

.bg-gray-300 {
   --tblr-bg-opacity: 0.1;
   background-color: rgba(
      var(--tblr-gray-300-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.text-gray-300-fg {
   color: #1d273b !important;
}

.bg-gray-400 {
   --tblr-bg-opacity: 0.1;
   background-color: rgba(
      var(--tblr-gray-400-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.text-gray-400-fg {
   color: #f8fafc !important;
}

.bg-gray-500 {
   --tblr-bg-opacity: 0.1;
   background-color: rgba(
      var(--tblr-gray-500-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.text-gray-500-fg {
   color: #f8fafc !important;
}

.bg-gray-600 {
   --tblr-bg-opacity: 0.1;
   background-color: rgba(
      var(--tblr-gray-600-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.text-gray-600-fg {
   color: #f8fafc !important;
}

.bg-gray-700 {
   --tblr-bg-opacity: 0.1;
   background-color: rgba(
      var(--tblr-gray-700-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.text-gray-700-fg {
   color: #f8fafc !important;
}

.bg-gray-800 {
   --tblr-bg-opacity: 0.1;
   background-color: rgba(
      var(--tblr-gray-800-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.text-gray-800-fg {
   color: #f8fafc !important;
}

.bg-gray-900 {
   --tblr-bg-opacity: 0.1;
   background-color: rgba(
      var(--tblr-gray-900-rgb),
      var(--tblr-bg-opacity)
   ) !important;
}

.text-gray-900-fg {
   color: #f8fafc !important;
}

/*
Scrollable
*/
.scrollable {
   overflow-x: hidden;
   overflow-y: auto;
   -webkit-overflow-scrolling: touch;
}
.scrollable.hover {
   overflow-y: hidden;
}
.scrollable.hover > * {
   margin-top: -1px;
}
.scrollable.hover:hover,
.scrollable.hover:focus,
.scrollable.hover:active {
   overflow: visible;
   overflow-y: auto;
}
.touch .scrollable {
   overflow-y: auto !important;
}

.scroll-x,
.scroll-y {
   overflow: hidden;
   -webkit-overflow-scrolling: touch;
}

.scroll-y {
   overflow-y: auto;
}

.scroll-x {
   overflow-x: auto;
}

.no-scroll {
   overflow: hidden;
}

.w-0 {
   width: 0 !important;
}

.h-0 {
   height: 0 !important;
}

.w-1 {
   width: 0.25rem !important;
}

.h-1 {
   height: 0.25rem !important;
}

.w-2 {
   width: 0.5rem !important;
}

.h-2 {
   height: 0.5rem !important;
}

.w-3 {
   width: 1rem !important;
}

.h-3 {
   height: 1rem !important;
}

.w-4 {
   width: 2rem !important;
}

.h-4 {
   height: 2rem !important;
}

.w-5 {
   width: 4rem !important;
}

.h-5 {
   height: 4rem !important;
}

.w-auto {
   width: auto !important;
}

.h-auto {
   height: auto !important;
}

.w-px {
   width: 1px !important;
}

.h-px {
   height: 1px !important;
}

.w-full {
   width: 100% !important;
}

.h-full {
   height: 100% !important;
}

.opacity-0 {
   opacity: 0 !important;
}

.opacity-5 {
   opacity: 0.05 !important;
}

.opacity-10 {
   opacity: 0.1 !important;
}

.opacity-15 {
   opacity: 0.15 !important;
}

.opacity-20 {
   opacity: 0.2 !important;
}

.opacity-25 {
   opacity: 0.25 !important;
}

.opacity-30 {
   opacity: 0.3 !important;
}

.opacity-35 {
   opacity: 0.35 !important;
}

.opacity-40 {
   opacity: 0.4 !important;
}

.opacity-45 {
   opacity: 0.45 !important;
}

.opacity-50 {
   opacity: 0.5 !important;
}

.opacity-55 {
   opacity: 0.55 !important;
}

.opacity-60 {
   opacity: 0.6 !important;
}

.opacity-65 {
   opacity: 0.65 !important;
}

.opacity-70 {
   opacity: 0.7 !important;
}

.opacity-75 {
   opacity: 0.75 !important;
}

.opacity-80 {
   opacity: 0.8 !important;
}

.opacity-85 {
   opacity: 0.85 !important;
}

.opacity-90 {
   opacity: 0.9 !important;
}

.opacity-95 {
   opacity: 0.95 !important;
}

.opacity-100 {
   opacity: 1 !important;
}

.hover-shadow-sm:hover {
   box-shadow: 0 0.125rem 0.25rem rgba(var(--tblr-body-color-rgb), 0.075) !important;
}

.hover-shadow:hover {
   box-shadow: 0 0.5rem 1rem rgba(var(--tblr-body-color-rgb), 0.15) !important;
}

.hover-shadow-lg:hover {
   box-shadow: 0 1rem 3rem rgba(var(--tblr-body-color-rgb), 0.175) !important;
}

.hover-shadow-none:hover {
   box-shadow: none !important;
}

/**
Antialiasing
 */
.antialiased {
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
   -webkit-font-smoothing: auto;
   -moz-osx-font-smoothing: auto;
}
