.question_decription {
   .question_title {
      margin: 50px 0 30px 0;
      font-size: 25px;
      text-align: center;
      text-transform: uppercase;
   }
   .list_question {
      .u-list {
         display: flex;
         flex-wrap: wrap;
         -webkit-box-orient: horizontal;
         -webkit-box-direction: normal;
         flex-direction: row;
         -webkit-box-align: start;
         align-items: flex-start;
         font-size: 16px;
         list-style-type: none;
         padding-left: 0;
         font-weight: 500;
         color: #18a0fb;
         li {
            width: 32%;
            margin-right: 4px;
            cursor: pointer;
         }
         li:hover {
            text-decoration: underline;
         }
      }
   }
}
@media only screen and (max-width: 767px) {
   .list_question {
      .u-list {
         flex-direction: column !important;
         li {
            width: 100% !important;
            position: relative;
            padding-left: 12px;
         }
         li::before {
            content: '*';
            line-height: 20px;
            position: absolute;
            left: 0;
            top: 20%;
         }

      }
   }
}
