.navbar-light .navbar-nav {
   gap: 20px;
}

.logo_login{
   border-radius: 30%;
}

.logo_btech {
   border-radius: 20%;
   display: flex;
   // justify-content: center;
   align-items: center;
   font-size: 28px;
   font-weight: 900;
   &:hover {
      text-decoration: none;
   }
   img {
      width: 40px;
   }
   .logo_text {
      margin-left: 12px;
      background: linear-gradient(20deg, #09009f, #02b46a 50%);
      background-clip: text;
      color: transparent;
   }
}

.navbar-nav {
   .nav-list {
      gap: 20px;
   }
   .nav-item {
      .nav-link.active::after {
         content: "";
         position: absolute;
         left: 0;
         right: 0;
         bottom: -10px;
         border: 0 var(--tblr-border-style) #18a0fb;
         border-bottom-width: 3px;
      }
   }
   .icon-avatar {
      width: 24px;
      height: 24px;
      font-size: 24px;
   }
   .icon-bell {
      position: relative;
   }
   .icon-bell::after {
      position: absolute;
      top: -3px;
      right: 0;
      content: "";
      width: 0.3rem;
      height: 0.3rem;
      border-radius: 100%;
      background-color: #D63939;

   }
   .nav-link.header {
      user-select: none;
   }
   .nav-link.header:hover {
      opacity: 0.9;
      cursor: pointer;
   }
   .nav-item-hover:hover {
      .nav-link:not(.active)::after{
         content: "";
         position: absolute;
         left: 0;
         right: 0;
         bottom: -10px;
         border: 0 var(--tblr-border-style) #ccc;
         border-bottom-width: 3px;
      }
   }
   .empty {
      padding: 2rem !important;
      p {
         margin: 0 !important;
      }
      .empty-icon {
         width: initial;
         height: initial;
         .ti.ti-bell-cancel {
            font-size: 26px;
         }
      }
   }
   .dropdown-menu.dropdown-notifications.show {
      top: 50px;
      right: 567px;
   }
   .dropdown-menu.dropdown-user.show {
      top: 50px;
      right: 17px;
   }
   .dropdown-menu-arrow.dropdown-user:before {
      right: 8.75rem;
      left: auto;
   }
}
