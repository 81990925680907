.footer-transparent {
   background-color: #fff !important;
}

.logo_btech {
   width: 40px;
}

.list-inline {
   .list-inline-item {
      .link-secondary:hover {
         color: #106bc4 !important;
      }
      i {
         color: #18A0FB;
         font-size: 22px;
         margin-left: 20px;
         cursor: pointer;
      }
      i:hover {
         color: #3980c8;
      }

   }
}

// .list-inline-item:not(:last-child) {
//    margin-right: auto;
//    -webkit-margin-end: 0.5rem;
//    margin-inline-end: 0.5rem;
// }

.list-inline-dots .list-inline-item + .list-inline-item:before {
   content: "" !important;
   //    -webkit-margin-end: 0.5rem;
   //    margin-inline-end: 0.5rem;
}
