.search {
   display: flex;
   align-items: center;
   .icon-close {
      margin: 0 20px;
      cursor: pointer;
      border-radius: 4px;
      user-select: none;
      i {
         font-size: 25px;
      }
   }
   .icon-close:hover {
      background-color: #e6e7e9;
   }
}
.input-icon {
   height: 36px;
}
.modal-content.mt-md {
   margin-top: 100px;
}
.devices__app {
   display: flex;
   justify-content: space-between;
   font-size: 16px;
   font-weight: 500;
   cursor: pointer;
   user-select: none;
   .conduct-devices,
   .status-devides {
      display: flex;
   }
   .slash {
      margin-top: 2px;
   }
   .slash::after {
      content: "/";
      color: black;
      margin: 0 16px;
   }
   .checked {
      color: #18a0fb !important;
   }
   .conduct-devices,
   .status-devides {
      .devices--total,
      .devices--managed,
      .succinct,
      .detail {
         padding: 4px 16px;
         border-radius: 4px;
      }
      .isHover:hover {
         background-color: rgba(41, 77, 117, 0.09);
         color: #18a0fb;
      }
      .isHover.checked:hover {
         background-color: initial;
         cursor: auto;
      }
   }
}

.card-header {
   justify-content: center;
   user-select: none;
}

.u_device {
   .u_device-img {
      position: relative;
      width: 80px;
      height: 100%;
      .u_device-icon {
         position: absolute;
         display: flex;
         align-items: center;
         left: 4px;
         bottom: 15px;
         width: 72px;
         height: 130px;
         background-color: #d7d7d7;
         border-radius: 2px;
         img {
            width: 80px;
            height: 45px;
            z-index: 1;
            fill: #fff;
         }
      }
   }
}

.b-bottom {
   border-bottom: 1px solid #e6e7e9;
}
.card-content {
   margin: 12px 0;
   display: flex;
   justify-content: space-between;
   align-items: center;
   cursor: pointer;
   .device_name:not(.detail) {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      max-width: 100%;
   }
   .device_name.detail {
      margin: 0;
      text-align: center;
   }
   .edit_name_device {
      font-size: 20px;
      padding: 4px;
      border-radius: 4px;
      user-select: none;
   }
   .button-group {
      display: flex;
      gap: 30px;
      button,
      div {
         width: 100%;
         margin-top: 16px;
      }
   }
   .edit_name_device:hover {
      background-color: #e6e7e9;
   }
   .arrow-icon {
      font-size: 20px;
      font-weight: 500;
      padding: 10px;
      cursor: pointer;
   }
   .arrow-icon:hover {
      background-color: #f2f2f2;
   }
}

.device_decription {
   .info {
      font-size: 16px;
   }
}

.device__icon {
   display: flex;
   justify-content: center;

   img {
      width: 40px;
      height: 40px;
      cursor: pointer;
   }
}

.card.card-hover:hover {
   border: 1px solid #86bdfc;
   transform: translateY(-5px);
   cursor: pointer;
}

.device-extended {
   .set-pdr {
      padding-right: 0 !important;
   }
   .device-extended_card {
      // border-radius: 4px 0 0 4px !important;
      // border: 1px solid #e6e7e9;
      .card {
         border-radius: 4px 0 0 4px !important;
         border: 1px solid #e6e7e9 !important;
      }
      .device__icon {
         img {
            width: 40px;
            height: 40px;
            cursor: pointer;
         }
      }
   }
   .device-extended_content {
      height: 100%;
      padding: 20px 80px 20px 0;
      position: relative;
      background-color: #ffffff;
      border-radius: 0 4px 4px 0 !important;
      border: 1px solid #e6e7e9 !important;
      .arrow-icon {
         position: absolute;
         right: 20px;
         top: 43%;
         color: #797979;
         cursor: pointer;
         width: 44px;
         padding: 10px;
      }
      .arrow-icon:hover {
         background-color: #f2f2f2;
      }
      .seperate:first-child::before {
         content: "";
         border-right: 1px solid #e6e7e9;
         float: right;
         height: 305px;
      }
      .device-extended_panel {
         padding: 0.5rem 1.5rem;
         .overview_list {
            .overview_title {
               p {
                  font-size: 16px;
                  font-weight: 600;
               }
            }
            .overview_content {
               list-style-type: none;
               padding: 0;
               .overview_content-item {
                  display: flex;
                  justify-content: flex-start;
                  padding: 0 0 8px 16px;
                  font-size: 16px;
                  img {
                     margin-right: 8px;
                  }
               }
            }
         }
         .feature_list {
            .feature_title {
               p {
                  font-size: 16px;
                  font-weight: 600;
               }
            }
            .feature_content {
               list-style-type: none;
               padding: 0;
               .feature_content-item {
                  display: flex;
                  justify-content: flex-start;
                  padding: 0 0 8px 16px;
                  font-size: 16px;
                  line-height: 30px;
                  color: #18a0fb;
                  img {
                     margin-right: 8px;
                  }
               }
            }
         }
      }
   }
}

.device_detail-page {
   .slash {
      margin-top: 2px;
   }
   .slash::after {
      content: "/";
      color: black;
      margin: 0 16px;
      color: #18a0fb;
   }
   .device_detail-menu {
      display: flex;
      .device_detail--item {
         padding: 4px 16px;
         border-radius: 4px;
         color: #18a0fb;
         font-size: 16px;
         cursor: pointer;
      }
      .device_detail--item:last-child {
         color: #22344966;
         cursor: default;
      }
      .device_detail--item:last-child:hover {
         background-color: initial;
      }
      .device_detail--item:hover {
         background-color: rgba(41, 77, 117, 0.09);
      }
   }
   .button-back {
      border: none;
      background-color: initial;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      padding: 4px 12px 4px 4px;
      border-radius: 4px;
      margin-top: 8px;
      color: #18a0fb;
   }
   .button-back:hover {
      background-color: rgba(41, 77, 117, 0.09);
   }
}

.devices-detail {
   .btn_detail {
      padding: 6px 0;
      width: 230px;
      margin-bottom: 12px;
   }
   .btn_detail.btn_mugshot {
      background-color: #ffffff;
      color: #22344966;
      border: 1px solid rgba(29, 54, 83, 0.17);
      font-weight: 700;
   }
   .recovery_code {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon_help {
         font-size: 20px;
      }
   }
   .recovery_code_value {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      input {
         border: none;
         font-size: 16px;
         width: 128px;
      }
      input:focus-visible {
         outline: none !important;
      }
      span {
         position: absolute;
         right: -4px;
         font-size: 18px;
         padding: 2px 8px;
         border-radius: 6px;
      }
      span:hover {
         background-color: rgba(41, 77, 117, 0.09);
      }
   }
   .disconnect_device {
      text-align: center;
      margin-bottom: 20px;
      user-select: none;
      span {
         padding: 6px 12px;
         border-radius: 4px;
         cursor: pointer;
      }
      span:hover {
         background-color: rgba(41, 77, 117, 0.09);
         color: #18a0fb;
      }
   }
}

.devices-extended__overview {
   .overview__header {
      .overview_header-menu {
         display: flex;
         position: relative;
         list-style-type: none;
         font-size: 16px;
         padding-left: 0;
         .overview_header-item:first-child {
            margin-right: 16px;
         }
         .overview_header-item {
            padding-bottom: 4px;
            cursor: pointer;
            // transition: all 0.2s ease;
         }
         .overview_header-item.active {
            border-bottom: 3px solid #18a0fb;
         }
      }
   }
   .overview__content {
      font-size: 16px;
      .overview_content_list {
         background-color: #ffffff;
         padding: 20px 0;
         border-radius: 8px;
         border: 1px solid #e6e7e9;
         .overview_content {
            margin-bottom: 0;
            list-style-type: none;
            font-weight: 500;
            .overview_content-item {
               display: flex;
               justify-content: flex-start;
               padding: 0 0 12px 16px;
               font-size: 16px;
               img {
                  margin-right: 12px;
               }
            }
            .overview_content-item:last-child {
               padding-bottom: 0;
            }
         }
      }
      .overview__content_mydevice {
         .mydevice__item {
            background-color: #ffffff;
            border-radius: 8px;
            border: 1px solid #e6e7e9;
            margin-bottom: 24px;
            .mydevice__item-tile_control {
               display: flex;
               padding: 30px 30px;
               gap: 30px;
               align-items: center;
               justify-content: space-between;
               .mydevice__item-tile_decription {
                  width: 567px;
                  .title {
                     display: flex;
                     // align-items: center;
                     b {
                        margin-bottom: 0.25rem;
                        margin-right: 0.5rem;
                     }
                     span {
                        font-size: 20px;
                     }
                  }
                  .icon-info {
                     cursor: pointer;
                     user-select: none;
                  }
                  .decription {
                     p {
                        margin-bottom: 0.25rem;
                     }
                  }
               }
               .mydevice__item-tile_btn {
                  width: 123px;
               }
            }
            .mydevice__item-tile_history {
               padding: 0 30px 50px 122px;

               .notify {
                  display: flex;
                  align-items: center;
                  padding: 10px 20px;
                  border: 1px solid #e6e7e9;
                  span {
                     color: #18a0fb;
                     margin-right: 12px;
                     cursor: default;
                  }
                  .notify_decription {
                     p {
                        margin: 0;
                     }
                  }
               }
               .notify.error {
                  border: 1px solid #d63939;
               }
               .notify.success {
                  border: 1px solid #2fb344;
               }
            }
         }
      }
   }
   .icon-check {
      color: #2fb344;
      margin-right: 20px;
      cursor: default;
      font-size: 1.75rem;
      height: 100%;
   }
   .icon-error {
      color: #d63939;
      margin-right: 20px;
      cursor: default;
      font-size: 1.75rem;
      height: 100%;
   }
}

.no-devices {
   display: grid;
   justify-content: center;
   align-items: center;
   .no-device_icon {
      width: 140px;
      margin: 0 auto;
   }
   .no-device_decription {
      font-size: 16px;
   }
}

.modal-content {
   .modal-header {
      position: relative;
      .modal-title {
         margin-top: 40px;
         font-size: 24px;
      }
      i {
         position: absolute;
         right: 0;
         top: 0;
         border-radius: 0 8px 0 4px;
         width: 38px;
         height: 38px;
      }
      i:hover {
         cursor: pointer;
         background-color: #e6e7e9;
      }
      i::before {
         font-size: 26px;
         position: absolute;
         top: 6px;
         right: 6px;
      }
   }
   .btn-close:focus {
      box-shadow: none;
   }
}
.modal-body.modal-pd {
   padding: 24px 140px !important;
}

.modal-body {
   .form_add {
      text-align: center;
      border: 1px solid #e6e7e9;
      border-radius: 12px;
      box-shadow: 1px 1px 1px 1px #e6e7e9;
      padding: 20px 30px;
      .modal-subtitle {
         font-size: 35px;
         font-weight: 600;
      }
      .modal-decription,
      .modal-sub_decription,
      .modal-help1,
      .modal-help2 {
         font-size: 16px;
         @media (max-width: 460px) {
            font-size: 14px;
         }
      }
      .modal-help1 {
         padding-top: 16px;
         border-top: 1px solid #e6e7e9;
         margin-bottom: 0.5rem;
      }
      .modal-help2 {
         margin: 0;
      }
   }
   .modal_head {
      display: flex;
      align-items: center;
      padding: 0 20px;
      .icon_modal {
         padding: 12px;
         border-radius: 100%;
         background-color: #18a0fb;
         color: #ffffff;
         margin-right: 20px;
         span {
            color: #ffffff;
         }
      }
      .desc_modal {
         margin: 0;
         font-size: 16px;
         font-weight: 500;
      }
   }
   .modal_content {
      padding: 0 20px;
      .warning_text {
         font-size: 16px;
         color: #d63939;
      }
      .about {
         display: flex;
         padding: 16px;
         font-size: 16px;
         border: 1px solid #e6e7e9;
         background-color: #eff2f6;
         .about_box {
            .title {
               margin: 0;
               font-weight: 600;
            }
            .content {
               margin: 0;
            }
         }
      }
   }
}

.modal-footer {
   display: flex;
   flex-direction: column;
   font-size: 16px;
   margin-bottom: calc(40px - 0.75rem);
   @media (max-width: 460px) {
      font-size: 14px;
      text-align: center;
   }
   .modal-title {
      font-size: 20px;
      @media (max-width: 460px) {
         font-size: 16px;
         font-weight: 100;
      }
   }
   .btn-confirm {
      position: relative;
   }
   .confirm-form {
      position: absolute;
      padding: 20px 45px;
      border: 1px solid #e6e7e9;
      background-color: #ffffff;
      border-radius: 8px;
      text-align: center;
      display: none;
   }
   .confirm-form.show {
      display: block;
   }
}
.modal-footer > * {
   margin: 0 !important;
}

/* =====> RESPONSIVE <===== */

/* =-=-=-=-=> MOBILE <=-=-=-=-= */
@media only screen and (max-width: 309px) {
   .devices__app {
      justify-content: flex-end;
      .conduct-devices {
         display: none !important;
      }
   }
}
@media only screen and (max-width: 460px) {
   .modal-content {
      .modal-header {
         .modal-title {
            font-size: 18px !important;
            font-weight: 100;
         }
      }
      .modal-body.modal-pd {
         padding: 10px 20px !important;
         .form_add {
            box-shadow: none;
            border-radius: 6px;
            .modal-subtitle {
               font-size: 20px !important;
               font-weight: 100;
            }
         }
      }
   }
}
@media only screen and (max-width: 767px) {
   .d-none-sm {
      display: none !important;
   }
   .devices__app {
      align-items: center;
      height: 52px;
      .status-devides {
         display: none;
      }
   }
   .devices__app.mb-3 {
      margin-bottom: 0 !important;
   }
   .devices__app .slash::after {
      margin: 0;
   }
   .search {
      margin-left: auto;
      .icon-close {
         margin: 0 0 0 20px;
      }
   }
}
@media only screen and (min-width: 460px) and (max-width: 767px) {
   .btn-mb {
      display: none !important;
   }
}

/* =-=-=-=-=> TABLET <=-=-=-=-= */
@media only screen and (min-width: 768px){
   .hidden-elm {
      display: none !important;
   }
   .d-flex.hidden-elm {
      display: none !important;
   }
}
@media only screen and (max-width: 991px){
   .modal-content {
      .modal-header {
         .modal-title {
            font-size: 20px;
            margin-top: 20px;
         }
      }
      .modal-body.modal-pd {
         padding: 10px 20px !important;
         .form_add {
            box-shadow: none;
            border-radius: 6px;
            .modal-subtitle {
               font-size: 25px;
            }
         }
      }
   }
}
/* =-=-=-=-=> PC <=-=-=-=-= */
