.pagination {
    justify-content: center;
}
.page-item {
   margin: 0 4px;
}
.page-item:hover {
   .page-link {
      background-color: #e6e7e9 !important;
   }
}

.page-item.disabled:hover {
   .page-link {
      background-color: initial !important;
   }
}

.page-item.active:hover {
    .page-link {
       background-color: #18A0FB !important;
    }
 }
