.loading_page {
   display: flex;
   height: 100vh;
   justify-content: center;
   align-items: center;
   background-color: #f1f5f9;
   .loading {
      font-size: 35px;
   }
   .set-spinner-border {
      --tblr-spinner-width: 3.5rem !important;
      --tblr-spinner-height: 3.5rem !important;
      --tblr-spinner-border-width: 4px !important;
   }
}
