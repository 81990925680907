.event__detail {
   .btn-back {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      border: none;
      background-color: initial;
   }
   .btn-back:hover {
      color: #18a0fb;
   }
   .list__item {
      padding: 0 100px;
      margin-top: 40px;
      .item {
         display: flex;
         margin-bottom: 8px;
         .image {
            width: 40%;
            .item_img {
               height: 150px;
            }
         }
         .item_description {
            width: 60%;
            p {
               margin-bottom: 4px !important;
            }
            p:nth-child(1) {
               margin-top: 12px;
            }
         }
      }
   }
   .tutorial {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 270px;
      h2 {
         margin: 40px 0 20px 0;
      }
      img {
         margin-bottom: 16px;
         width: 300px;
      }
      p {
         text-align: center;
      }
   }
}
