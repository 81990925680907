.icon-facebook {
   i {
      margin-right: 6px;
      line-height: 18px;
      font-size: 18px;
   }
}

.text_logo {
   font-size: 22px;
   text-transform: uppercase;
   padding-left: 12px;
}

.icon-google {
   img {
      margin-right: 6px;
   }
}
.card {
   .card-body {
      .card-title {
         font-size: 1.5rem;
      }
   }
}

.form-label {
   font-size: 1rem !important;
   margin-bottom: 0.25rem !important;
}

.invalid-feedback {
   font-size: 16px !important;
   color: #d63939 !important;
}

.input-groupp {
   position: relative;
   .icon-eye {
      position: absolute;
      z-index: 999;
      right: 0;
      top: calc(22% + 5px);
      left: 92%;
      cursor: pointer;
   }
}

.text-muted {
   font-size: 16px;
}

.form-footer {
   .btn {
      font-size: 16px;
   }
}

.card-icon {
   display: flex;
   justify-content: center;
}

.spinner-mr {
   margin-right: 4px;
}

.fz {
   font-size: 14px;
}

.box {
   i,
   h4 {
      line-height: 24px;
   }
   h4 {
      margin: 0;
   }
}

.help-form {
   display: flex;
   justify-content: space-between;
}

/* =====> RESPONSIVE <===== */

/* =-=-=-=-=> MOBILE <=-=-=-=-= */
@media only screen and (max-width: 310px) {
   .card {
      .card-body {
         .card-title.mb-4 {
            display: none;
         }
      }
   }
}

@media only screen and (min-width: 393px) {
   .alert-icon {
      margin: -0.125rem 0.5rem -0.125rem 0 !important;
   }
}

@media only screen and (max-width: 767px) {
   .mb-4.mb-mb {
      margin-bottom: 1rem !important;
      img {
         height: 50px;
      }
   }
   .card {
      .card-body {
         .card-title.mb-4 {
            margin-bottom: 1rem !important;
            font-size: 20px;
         }
      }
   }
}
@media only screen and (min-width: 460px) and (max-width: 767px) {
}

/* =-=-=-=-=> TABLET <=-=-=-=-= */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
   .card {
      .card-body {
         .card-title {
            font-size: 1.5rem;
         }
      }
   }
}
/* =-=-=-=-=> PC <=-=-=-=-= */
